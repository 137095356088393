import React from 'react'

interface ClimateExplorerModalContentProps {
  lastUpdated?: string
}

export const ClimateExplorerModalContent: React.FC<ClimateExplorerModalContentProps> = () => (
  <>
    <h5>Taking 4P into the future! </h5>
    <p>
      Measuring is the key to impact the future and now we can visualize the journey to 2030 goals for operational
      climate footprint in 4P! We take the goals and match it with actions, together we can then work to close the gap.
    </p>
    <h3>How to reach the goal? </h3>
    <p>
      If there is a gap to your goal you need more actions to be identified. Given we show the details for all actions
      the impact of cancelling a project is transparent and savings then need to be compensated elsewhere.
    </p>
    <p>
      It may also be we are not able to show all the good intentions as for example refrigerant leakages are not easy
      for forecast and not part of RELTP as they should not leak.{' '}
    </p>
    <p>
      It is important that any change and investment is done considering the impact on climate footprint goal and any
      new site is expected to perform to the FY30 ambitions.{' '}
    </p>
    <p>
      You can use the{' '}
      <a className="Underlined" href="https://4p.ingka.com/ALL/whatif-planet?func%5B0%5D=ALL&functionArea=ingka">
        “What if” scenario tool
      </a>{' '}
      in 4P to find out what contributor impacts most for Ingka, country, function or site within the current year.{' '}
    </p>
    <h5>Goals </h5>
    <p>
      We are committed to taking action on climate change in line with the Paris Agreement, and to play our part in
      limiting the global temperature rise to 1.5°C above pre-industrial levels. As part of that we commit to “Reduce
      absolute greenhouse gas emissions from our own operations (scope 1 and 2) by 80% by 2030 (from FY16)”.
    </p>
    <p>
      To make this actionable the internal Ingka goal for FY30 is set to 100 kton CO2e for the formal scope 1+2 and 135
      kton for the “operational climate footprint” also including water, waste and energy from leased locations and
      obtained from tenants.{' '}
    </p>
    <h3>How has the goals been set? </h3>
    <p>
      Goal for the coming 2 years are based on “bottom-up” principle. Latest full year result is the start and then we
      add plans made by different functions, mainly from RELTP, the Real Estate Long Term Plan and transition to
      renewable electricity by Procurement. In addition we consider sites recently opened and closed, confirmed
      expansion as well as expect all sites to contribute to reductions by achieving a 3% yearly reduction.{' '}
    </p>
    <p>
      Goals for FY30 are set based on “top-down” principle. The focus has been mainly on where we need to be than where
      countries were or are as all countries developed differently. All sites should aim for using renewable energy,
      prevent refrigerant leakages, prevent waste and save water. Given this principle the total Inka goal has been
      divided per m2 and saving some for expansions, giving all countries maximum 3,8 kg CO2e/m2. Countries already
      performing better are expected to keep reducing 3% yearly until FY30. The goal is in total ton but the logic
      behind is based on the kg CO2e/m2 as a model.{' '}
    </p>
    <h3>Where is the data coming from? How often it is updated? </h3>
    <p>Goals are taken from Questionnaire 16 in Sustain on a monthly basis. </p>
    <p>
      The goals down to country level are set according to Ingka process for all goals run by Business steering and
      follow a defined year cycle. Goals for coming year are negotiated during spring and set around June in respective
      country board meetings for Retail.{' '}
    </p>
    <p>
      Goals for the years beyond next fiscal year are ambitions and they will be assessed again and revisited before
      confirmed as we get closer. It is still relevant to show them as they provide insights to the journey we need to
      take. As many actions needed to reduce the climate footprint require investments and planning it is not enough to
      just look at next year.{' '}
    </p>
    <p>
      Goals on site level are not as formal but also tracked from Sustain. Here the country can assign site goals as
      wished once country goal is set and as long as the sum of the sites is not exceeding the country goal. The sum of
      site goals can be lower than country goal if for example a buffer for unintended refrigerant leak at any site.{' '}
    </p>
    <h5>Actions</h5>
    <p>
      The actions show the available plans and their impact on the climate footprint. The intention is to visualize
      where we are on track and where more is needed to reach FY30 goals.
    </p>
    <h3>How are actions set?</h3>
    <p>
      RELTP is copied into Sustain and impact from actions in MWh is translated to ton CO2e. The timelines are based on
      the goal process needs and Real estate work. For now the plan is to import the data 4 times/year but the goal is
      to make this part of monthly updates. All actions apart from cancelled are included. They are expected to have
      impact from the year after implementation.
    </p>
    <p>
      Confirmed expansions are given a CO2e budget based on opening date, area (m2) and the CO2e/m2 for the country FY30
      goal performance. Impact from Procurement signing contacts for renewable electricity of biogas are added based on
      their forecast.
    </p>
    <h3>Where is the data coming from? How often it is updated?</h3>
    <p>Actions are taken from Sustain on a monthly basis. Updates are taken from</p>
    <ul>
      <li>
        RELTP 4 times/year. Actions are expected to have impact from the year after implementation. All actions apart
        from cancelled are included.
      </li>
      <li>Expansion plans, yearly during goal setting process. </li>
      <li>Procurement, ad-hoc as updates are available, minimum during goal setting process </li>
    </ul>
    <h5>Why are the goals and the actions not matching? </h5>
    <p>
      The intention is to base goals for the coming 1-2 year on bottom up plans as described. Even if reality is more
      complex than the roadmap we show we hope it is still a good help. There are some things that still may impact that
      actions does not really match the goal as:{' '}
    </p>
    <ul>
      <li>
        Any further reductions as the 3% yearly or other reductions assigned in goals not linking to confirmed actions
        are not showing.
      </li>
      <li>Final negotiations of goal may make them deviate from the provided input</li>
      <li>
        Reductions expected to come from reduces leakage of refrigerants are not showing in actions as they are not
        really possible to forecast and not part of RELTP
      </li>
      <li>
        Base year starting point can be higher than actual value as sites opening during the year are extrapolated to
        full year value
      </li>
      <li>RELTP and Procurement plan may have been update since goal was set</li>
    </ul>
    <p>
      The goal set in spring FY23 is based on FY22 performance and actions done impacting from FY23 and coming years.
      Given the actual performance in FY23 YTD may be more accurate than the FY22 + actions we have decided to start to
      show actions impacting from FY24 (done in FY23) linked to FY23 goal rather than FY23 actions linked to FY22
      performance.
    </p>
  </>
)
