import { RefrigerantFactorsType } from '../../../../../api/src/types/climate'

const colors = ['#388E3C', '#FCB82D', '#F44336']

const lowValue = 150
const highValue = 2500

export const createColorScheme = (factors: RefrigerantFactorsType) => {
  return Object.keys(factors).reduce((result, current) => {
    if (factors[current] <= lowValue)
      return {
        ...result,
        [current]: colors[0]
      }

    if (factors[current] > highValue)
      return {
        ...result,
        [current]: colors[2]
      }

    const weight = 1 - (factors[current] - lowValue) / (highValue - lowValue)
    const rgbColor = pickHex(hexToRgb(colors[1]), hexToRgb(colors[2]), weight)

    return {
      ...result,
      [current]: rgbToHex(rgbColor[0], rgbColor[1], rgbColor[2])
    }
  }, {})
}

const pickHex = (color1: number[], color2: number[], weight: number) => {
  const w1 = weight
  const w2 = 1 - w1
  return [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)
  ]
}

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0]
}

const componentToHex = (component: number) => {
  const hex = component.toString(16)
  return hex.length == 1 ? '0' + hex : hex
}

const rgbToHex = (r: number, g: number, b: number) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}
