import React from 'react'

const ArrowDownToBase = ({ classes, onClick }: { classes?: string; onClick: () => void }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
      onClick={onClick}
    >
      <path
        d="M13 12.1696L16.243 8.92661L17.6572 10.3408L12 16L6.34352 10.3408L7.75773 8.92661L11 12.1689V4H13V12.1696Z"
        fill="#767676"
      />
      <path d="M22 20.999H2V11.999H4L4 18.999L20 18.999V11.999H22V20.999Z" fill="#767676" />
    </svg>
  )
}

export default ArrowDownToBase
