export enum Route {
  Root = '/',
  LandingPage = 'landing',
  SnapshotPage = 'snapshot',
  LeaderboardPage = 'leaderboard',
  Explore = 'explore',
  People = 'people',
  SelfServicePage = 'self-service',
  PlanetImpactPage = 'planetimpact',
  WhatIfPlanetPage = '/',
  InStorePage = 'instore',
  InstoreConfiguratorPage = 'instoreconfigurator',
  ExploreRenewablePage = 'explore/renewable',
  ExplorePeoplePage = 'explore/renewable',
  NewsLetterUnsubscribe = 'newsletter-unsubscribe',
  SustainabilityMentionsKPIPage = 'sustainability-mentions-kpi',
  ChangeMakersKPIPage = 'change-makers-kpi',
  SocialImpactKPIPage = 'social-impact-kpi',
  ZeroWasteKPIPage = 'zerowaste-kpi',
  ZeroWasteExplorePage = 'zerowaste-explore',
  FoodIngredientsKPIPage = 'food-kpi',
  ClimatePage = 'climate',
  ClimateKPIPage = 'climate-kpi',
  ClimateExplorePage = 'climate-explore',
  PppSalesKPIPage = 'ppp-sales-kpi',
  PppSalesExplorePage = 'ppp-sales-explore',
  CustomerDeliveriesKPIPage = 'customer-deliveries-kpi',
  CustomerTravelKPIPage = 'customer-travel-kpi',
  EnergyEfficiencyKPIPage = 'energy-efficiency-kpi',

  // TODO: change this when the WaterEfficiencyKPIPage is created
  WaterEfficiencyKPIPage = 'water-efficiency-kpi',
  WaterExplorePage = 'water-explore',

  // Deprecated routes, used only for redirects:
  WhatIfPage = '/',
  PerceptionDeprecated = 'perception',
  SocialImpactPageDeprecated = 'socialimpact',
  PlanetFootprintPageDeprecated = 'planetfootprint',
  ProfitGrowthPageDeprecated = 'profitgrowth',
  ZeroWastePageDeprecated = 'zerowaste',

  // Beta pages

  CircularRevenueKPIPage = 'circular-revenue'
}
