import { LocationResults } from '../../../../components/Benchmark/SiteResultsTable'
import { isNaN, isNil } from 'lodash'
import { useDataAvailabilityContext } from '../../../../context'
import { ZeroWasteBenchmark } from '../../../KPIPages/ZeroWaste/ZeroWasteKPIPage'

export const emptyResult = {
  comparisonValue: NaN,
  goal: NaN,
  goalNextFy: NaN,
  goalPrevFy: NaN,
  locationName: '',
  previousFy: NaN,
  selectable: false
}

export const emptyGoal = {
  countryCode: '',
  siteId: '',
  changeMakersGoal: undefined,
  changeMakersGoalNextFY: undefined,
  changeMakersGoalPrevFY: undefined,
  climateFootprintGoal: undefined,
  climateFootprintGoalNextFY: undefined,
  climateFootprintGoalPrevFY: undefined,
  deliveriesClimateFootprintGoal: undefined,
  deliveriesZeroEmissionGoal: undefined,
  deliveriesZeroEmissionGoalNextFY: undefined,
  deliveriesZeroEmissionGoalPrevFY: undefined,
  foodClimateFootprintGoal: undefined,
  perceptionGoal: undefined,
  pppShareGoal: undefined,
  pppShareGoalNextFY: undefined,
  pppShareGoalPrevFY: undefined,
  socialImpactGoal: undefined,
  socialImpactGoalNextFY: undefined,
  socialImpactGoalPrevFY: undefined,
  zeroWasteGoal: undefined,
  zeroWasteGoalNextFY: undefined,
  zeroWasteGoalPrevFY: undefined,
  energyEfficiencyGoal: undefined,
  energyEfficiencyGoalNextFY: undefined,
  energyEfficiencyGoalPrevFY: undefined,
  recyclingRateGoal: undefined,
  recyclingRateGoalNextFY: undefined,
  recyclingRateGoalPrevFY: undefined
}

export const formatToPercentagesInit =
  (isFormattedGoal: boolean) =>
  (results: LocationResults): LocationResults => ({
    ...results,
    previousFy: !isNil(results.previousFy) ? results.previousFy * 100 : NaN,
    comparisonValue: !isNil(results.comparisonValue) ? results.comparisonValue * 100 : NaN,
    goal: !isNil(results.goal) ? results.goal * (isFormattedGoal ? 1 : 100) : null,
    goalNextFy: !isNil(results.goalNextFy) ? results.goalNextFy * (isFormattedGoal ? 1 : 100) : null,
    goalPrevFy: !isNil(results.goalPrevFy) ? results.goalPrevFy * (isFormattedGoal ? 1 : 100) : null
  })

export const useBenchmarkHelperData = (currFY?: number) => {
  const { dataAvailability } = useDataAvailabilityContext()

  const socialImpactCurrentFY = dataAvailability?.socialImpactCurrentFY ?? -1
  const currFy =
    currFY ||
    Math.max(dataAvailability?.planetCurrentFY ?? -1, dataAvailability?.profitCurrentFY ?? -1, socialImpactCurrentFY)
  const prevFy = currFy - 1

  const prevFyLabel = `FY${String(prevFy).slice(2)}`
  const currFyLabel = `FY${String(currFy).slice(2)}`
  const nextFyLabel = `FY${String(currFy + 1).slice(2)}`
  const prevOptions = [`${prevFyLabel} YTD`, `${prevFyLabel} Result`] as const
  const currOptions = [`${currFyLabel} YTD`, `Rolling 12 mos`] as const

  const prevFyLabelMinusOne = `FY${String(prevFy - 1).slice(2)}`
  const currFyLabelMinusOne = `FY${String(currFy - 1).slice(2)}`
  const nextFyLabelMinusOne = `FY${String(currFy).slice(2)}`
  const prevOptionsMinusOne = [`${prevFyLabelMinusOne} YTD`, `${prevFyLabelMinusOne} Result`] as const
  const currOptionsMinusOne = [`${currFyLabelMinusOne} YTD`, `Rolling 12 mos`] as const

  return {
    prevOptions,
    currOptions,
    minusOneYear: {
      prevOptionsMinusOne,
      currOptionsMinusOne,
      currFyLabelMinusOne,
      nextFyLabelMinusOne,
      prevFyLabelMinusOne
    },
    currFy,
    prevFy,
    currFyLabel,
    prevFyLabel,
    nextFyLabel,
    socialImpactCurrentFY
  }
}

export const useBenchmarkHelperDataSI = () => {
  const { dataAvailability } = useDataAvailabilityContext()

  const currFy = dataAvailability?.socialImpactCurrentFY ?? -1
  const prevFy = currFy - 1

  const prevFyLabel = `FY${String(prevFy).slice(2)}`
  const currFyLabel = `FY${String(currFy).slice(2)}`
  const nextFyLabel = `FY${String(currFy + 1).slice(2)}`
  const prevOptions = [`${prevFyLabel} YTD`, `${prevFyLabel} Result`] as const
  const currOptions = [`${currFyLabel} YTD`, `Rolling 12 mos`] as const

  return {
    prevOptions,
    currOptions,
    currFyLabel,
    nextFyLabel,
    prevFyLabel,
    currFy
  }
}

export const sortByComparisonValue = (desc: boolean) => (a: LocationResults, b: LocationResults) => {
  const nanValue = desc ? Number.MIN_VALUE : Number.MAX_VALUE
  const parseOptional = (a: number | null) => (a ? (isNaN(a) ? nanValue : a) : nanValue)
  const aComparisonValue = parseOptional(a.comparisonValue)
  const bComparisonValue = parseOptional(b.comparisonValue)
  const aPreviousFy = parseOptional(a.previousFy)
  const bPreviousFy = parseOptional(b.previousFy)
  if (aComparisonValue === bComparisonValue || isNaN(aComparisonValue - bComparisonValue)) {
    return desc ? bPreviousFy - aPreviousFy : aPreviousFy - bPreviousFy
  }
  return desc ? bComparisonValue - aComparisonValue : aComparisonValue - bComparisonValue
}

export function sumZeroWaste(acc: ZeroWasteBenchmark, curr: ZeroWasteBenchmark) {
  return {
    id: curr.id,
    label: curr.label,
    selectable: curr.selectable,
    currentYtd: acc.currentYtd + curr.currentYtd,
    previousFyResult: acc.previousFyResult + curr.previousFyResult,
    previousYtd: acc.previousYtd + curr.previousYtd,
    rolling12: acc.rolling12 + curr.rolling12
  }
}
