import React from 'react'
import { DataAvailability } from '../../api/src/common-types'

export interface DataAvailabilityWithSetter {
  dataAvailability?: DataAvailability
  setDataAvailability: (dataAvailability: DataAvailability) => void
}
export const DataAvailabilityContext = React.createContext<DataAvailabilityWithSetter>({
  dataAvailability: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDataAvailability: () => {}
})

export const useDataAvailabilityContext = (): DataAvailabilityWithSetter => React.useContext(DataAvailabilityContext)
