import { capitalize } from 'lodash'
import React from 'react'
import {
  ContributorCountryPlugins,
  InsightDominantCategory,
  InsightDominantFactor,
  InsightDominantWaste,
  InsightGlobalRelativeFpBest,
  InsightGlobalRelativeFpWorst,
  InsightHfbShareComparison1,
  InsightHfbShareComparison2,
  InsightRecoveredProducts,
  InsightRecyclingRate,
  InsightRelativeFootprint,
  InsightSalesShareQuartile,
  InsightYoyBiggestDecrease,
  InsightYoyBiggestIncrease,
  InsightTenantCASplit,
  ContributorCountry,
  CountryFp
} from '../../../api/src/common-types'

import styles from './Insights.module.scss'
import { formatAbsoluteNumber, formatPercentage, formatRelativeNumber, formatValueAsTons } from '../Utils/format'
import { getCountry, getLocationId, getLocationLabel, isSiteId } from '../Utils/utils'
import { ReactComponent as ArrowLink } from '../../images/CircleArrow/Arrow.svg'
import { Link } from '../Link'
import { isHfbShareComparison1 } from './guards'
import { useLocations, LanguageContext } from '../../context'
import { InStoreI18n } from '../../Localisation'
import classNames from 'classnames'
import { Route } from '../../routes'

export const DominantCategoryText = ({ plugins }: InsightDominantCategory) => {
  const lang = React.useContext(LanguageContext)

  const plugin1FootprintYtd = plugins['1'].footprint_ytd
  const plugin2FootprintYtd = plugins['2']?.footprint_ytd ?? 0
  const plugin3FootprintYtd = plugins['3']?.footprint_ytd ?? 0

  const totalFootprintYtd = plugin1FootprintYtd + plugin2FootprintYtd + plugin3FootprintYtd
  const dominantCategoryPercentage = formatPercentage(plugin1FootprintYtd / totalFootprintYtd)
  const dominantCategoryTonnes = formatValueAsTons(plugin1FootprintYtd)

  return (
    <>
      <p className={styles.Header}>
        {capitalize(InStoreI18n[lang].Generic[plugins['1'].planet_lvl_1])}{' '}
        {InStoreI18n[lang]['Climate Largest Contributors'].title}:
      </p>
      <div className={styles.Values}>
        <div className={styles.ValueRow}>
          <span className={styles.Large}>{dominantCategoryPercentage}</span>{' '}
          <span className={classNames(styles.Small, styles.Grey)}>
            {InStoreI18n[lang]['Climate Largest Contributors'].proportion}
          </span>
        </div>
        <div className={styles.ValueRow}>
          <span className={styles.Large}>{dominantCategoryTonnes}</span>{' '}
          <span className={classNames(styles.Small, styles.Grey)}>{InStoreI18n[lang].Generic.tonnesCO2e}</span>
        </div>
      </div>
    </>
  )
}

export const DominantFactorText = ({ plugins }: InsightDominantFactor) => (
  <>
    <p className={styles.Header}>
      {capitalize(plugins['1'].lvl_2.planet_lvl_2_desc)} is the dominant {plugins['1'].planet_lvl_1} type:
    </p>
    <div className={styles.Values}>
      <div className={styles.ValueRow}>
        <span className={styles.Large}>{formatRelativeNumber(plugins['1'].lvl_2.fp_pc * 100)}</span>{' '}
        <span className={classNames(styles.Small, styles.Grey)}>% of {plugins['1'].planet_lvl_1} footprint</span>
      </div>
      <div className={styles.ValueRow}>
        <span className={styles.Large}>
          {formatAbsoluteNumber(Math.round(plugins['1'].lvl_2.footprint_ytd / 1000))}
        </span>{' '}
        <span className={classNames(styles.Small, styles.Grey)}>tonnes CO2e</span>
      </div>
      <div className={styles.ValueRow}>
        <span className={styles.Large}>
          {formatAbsoluteNumber(Math.round(plugins['1'].lvl_2.reference_quantity_ytd))}
        </span>{' '}
        <span className={classNames(styles.Small, styles.Grey)}>{plugins['1'].lvl_2.reference_unit_name}</span>
      </div>
    </div>
  </>
)

export const GlobalRelativeFpText = ({ plugins }: InsightGlobalRelativeFpBest | InsightGlobalRelativeFpWorst) => {
  const pluginText = (idx: '1' | '2' | '3') => {
    if (!plugins[idx]) {
      return null
    }
    const plugin = plugins[idx] as CountryFp
    return (
      <div key={plugin.country_name} className={styles.Values}>
        <div className={styles.LocationRow}>{plugin.country_name}</div>
        <div className={styles.ValueRow}>
          <span className={styles.Large}>{formatRelativeNumber(plugin.entity_fp_m2)}</span>
          <span className={classNames(styles.Small, styles.Grey)}>kg CO2e/m2</span>
        </div>
      </div>
    )
  }
  return (
    <>
      {(['1', '2', '3'] as const).map(index => pluginText(index))}
      <br />
      Global average is {Math.round(plugins[1].global_fp_m2)} kg CO2e/m2
    </>
  )
}

export const RecyclingRateText = ({ plugins }: InsightRecyclingRate) => {
  const { locations } = useLocations()
  if (!locations || locations.length === 0) {
    return null
  }
  const { countryName } = getCountry(plugins.top_entity_unit.country_code, locations)
  return (
    <>
      <p className={styles.Header}>
        {plugins.entity_unit.entity_name} vs. {plugins.top_entity_unit.entity_name} (
        {plugins.top_entity_unit.function_selection} unit with highest recycling rate in {countryName}):
      </p>
      <div className={styles.Values}>
        <div className={styles.LocationRow}>{plugins.entity_unit.entity_name}</div>
        <div className={styles.ValueRow}>
          <span className={styles.Large}>{(plugins.entity_unit.recycle_pc * 100).toFixed(1)}</span>{' '}
          <span className={classNames(styles.Small, styles.Grey)}>%</span>
        </div>
        <div className={styles.LocationRow}>{plugins.top_entity_unit.entity_name}</div>
        <div className={styles.ValueRow}>
          <span className={styles.Large}>{(plugins.top_entity_unit.recycle_pc * 100).toFixed(1)}</span>{' '}
          <span className={classNames(styles.Small, styles.Grey)}>%</span>
        </div>
      </div>
    </>
  )
}

export const RelativeFootprintText = ({ plugins }: InsightRelativeFootprint) => {
  const { locations } = useLocations()
  if (!locations || locations.length === 0) {
    return null
  }
  const { countryName } = getCountry(plugins.top_entity_unit.country_code, locations)
  return (
    <>
      <p className={styles.Header}>
        {plugins.entity_unit.entity_name} vs. {plugins.top_entity_unit.entity_name} (
        {plugins.top_entity_unit.function_selection} unit with highest recycling rate in {countryName}):
      </p>
      <div className={styles.Values}>
        <div className={styles.LocationRow}>{plugins.entity_unit.entity_name}</div>
        <div className={styles.ValueRow}>
          <span className={styles.Large}>{plugins.entity_unit.entity_fp_m2}</span>
          <span className={classNames(styles.Small, styles.Grey)}>kg CO2e/m2</span>
        </div>
        <div className={styles.LocationRow}>{plugins.top_entity_unit.entity_name}</div>
        <div className={styles.ValueRow}>
          <span className={styles.Large}>{plugins.top_entity_unit.entity_fp_m2}</span>{' '}
          <span className={classNames(styles.Small, styles.Grey)}>kg CO2e/m2</span>
        </div>
      </div>
    </>
  )
}

export const YoyBiggestChangeText = ({ plugins }: InsightYoyBiggestIncrease | InsightYoyBiggestDecrease) => (
  <>
    <p className={styles.Header}>{plugins['1'].planet_lvl_2_desc}:</p>
    <div className={styles.Values}>
      <div className={styles.ValueRow}>
        <span className={styles.Large}>
          {plugins['1'].lvl_2.footprint_delta_ytd > 0 ? '+' : ''}
          {formatAbsoluteNumber(Math.round(plugins['1'].lvl_2.footprint_delta_ytd / 1000))}
        </span>{' '}
        <span className={classNames(styles.Small, styles.Grey)}>tonnes CO2e</span>
      </div>
      <div className={styles.ValueRow}>
        <span className={styles.Large}>
          {plugins['1'].lvl_2.reference_quantity_delta_ytd > 0 ? '+' : ''}
          {formatAbsoluteNumber(Math.round(plugins['1'].lvl_2.reference_quantity_delta_ytd))}
        </span>{' '}
        <span className={classNames(styles.Small, styles.Grey)}>{plugins['1'].lvl_2.reference_unit_name}</span>
      </div>
    </div>
  </>
)

export const CoCreationLinkText = () => (
  <div>
    <p className={styles.Header}>
      Looking for ideas to increase your P+PP sales? Check out other stores&apos; initiatives!
    </p>
    <p className={styles.InsightLink}>
      <a href="https://vetlanda.ingka.com/improvements/sustainable-mini-shop" target="_blank" rel="noreferrer">
        <ArrowLink />
        Sustainable Mini Shop
      </a>
    </p>
    <p className={styles.InsightLink}>
      <a
        href="https://vetlanda.ingka.com/improvements/this-is-why-we-need-to-live-more-sustainable"
        target="_blank"
        rel="noreferrer"
      >
        <ArrowLink />
        This is why we need to live more sustainable
      </a>
    </p>
  </div>
)

export const HfbShareComparisonText = (insight: InsightHfbShareComparison1 | InsightHfbShareComparison2) => {
  const { currentLocation } = useLocations()
  const comparedTo = isSiteId(getLocationId(currentLocation)) ? 'country' : 'global'
  const text1 = isHfbShareComparison1(insight)
    ? `'s top 5 P+PP selling HFBs compared to ${comparedTo} share`
    : `'s top 5 HFBs with P+PP sales share below ${comparedTo} share`
  return (
    <p>
      {getLocationLabel(currentLocation)}
      {text1}
    </p>
  )
}

export const SalesShareQuartileText = ({ plugins }: InsightSalesShareQuartile) => (
  <div className={styles.Values}>
    <div className={styles.LocationRow}>{plugins.entity_unit.entity_name}</div>
    <div className={styles.ValueRow}>
      <span className={styles.Large}>{(plugins.entity_unit.ppp_share * 100).toFixed(1)}</span>
      <span className={classNames(styles.Small, styles.Grey)}>%</span>
    </div>
    <div className={styles.LocationRow}>{plugins.top_entity_unit.entity_name}</div>
    <div className={styles.ValueRow}>
      <span className={styles.Large}>{(plugins.top_entity_unit.ppp_share * 100).toFixed(1)}</span>{' '}
      <span className={classNames(styles.Small, styles.Grey)}>%</span>
    </div>
  </div>
)

export const DominantWasteText = ({ plugins }: InsightDominantWaste) => {
  const nonRecycled = plugins['1'].waste_type === 'non_recycled' ? plugins['1'] : plugins['2']
  return (
    <>
      <p className={styles.Header}>Main material type that is not recyled is {nonRecycled.lvl_2.planet_lvl_2_desc}</p>
      <div className={styles.Values}>
        <div className={styles.ValueRow}>
          <span className={styles.Large}>{Math.round(nonRecycled.lvl_2.fp_pc * 100)}</span>{' '}
          <span className={classNames(styles.Small, styles.Grey)}>% of non-recycled waste</span>
        </div>
        <div className={styles.ValueRow}>
          <span className={styles.Large}>
            {formatAbsoluteNumber(Math.round(nonRecycled.lvl_2.footprint_ytd / 1000))}
          </span>{' '}
          <span className={classNames(styles.Small, styles.Grey)}>tonnes CO2e</span>
        </div>
      </div>
      <Link page={Route.ClimateExplorePage} className={styles.WasteSankeyLink}>
        Head over to Climate Explore page to explore all waste types and how they are treated
        <div className={styles.ArrowContainer}>
          <ArrowLink />
        </div>
      </Link>
    </>
  )
}

export const RecoveredProductsText = ({ plugins }: InsightRecoveredProducts) => (
  <>
    <p className={styles.Header}>Breakdown of recovered products</p>
    <div className={styles.Values}>
      <div className={styles.ValueRow}>
        <span className={styles.Large}>{(plugins.asis_sold_pc * 100).toFixed(1)}</span>{' '}
        <span className={classNames(styles.Small, styles.Grey)}>% As-Is sold</span>
      </div>
      <div className={styles.ValueRow}>
        <span className={styles.Large}>{(plugins.repack_pc * 100).toFixed(1)}</span>
        <span className={classNames(styles.Small, styles.Grey)}>% Repack</span>
      </div>
    </div>
  </>
)

export const SmallestOrLargestContributorsText = <T extends { plugins: ContributorCountryPlugins }>({ plugins }: T) => {
  const sum = plugins[1].fp_pc + (plugins[2]?.fp_pc ?? 0) + (plugins[3]?.fp_pc ?? 0)
  const pluginText = (idx: '1' | '2' | '3') => {
    if (!plugins[idx]) {
      return null
    }
    const plugin = plugins[idx] as ContributorCountry
    return (
      <li className={styles.SmallestOrLargestContributorsListText} key={idx}>
        {plugin.country_name} {formatRelativeNumber(plugin.fp_pc * 100)} % ({formatValueAsTons(plugin.footprint_ytd)}{' '}
        tonnes CO2e)
      </li>
    )
  }
  return (
    <>
      <ol>{(['1', '2', '3'] as const).map(index => pluginText(index))}</ol>
      {sum > 0 && (
        <div className={styles.SmallestOrLargestContributorsText}>
          They jointly account for {formatRelativeNumber(sum * 100)} % of Ingka&apos;s global climate footprint YTD.
        </div>
      )}
    </>
  )
}

export const TenantCASplitText = ({ plugins }: InsightTenantCASplit) => {
  const totalFootprint = plugins[1].footprint_ytd + (plugins[2]?.footprint_ytd ?? 0)
  const percentages = {
    '1': plugins[1].footprint_ytd / totalFootprint,
    '2': (plugins[2]?.footprint_ytd ?? 0) / totalFootprint
  }
  return (
    <>
      {(['1', '2'] as const).map(idx => {
        const plugin = plugins[idx]
        if (!plugin) {
          return null
        }
        return (
          <span key={idx}>
            <p className={styles.Header}>{plugin.planet_lvl_4_desc}:</p>
            <div className={styles.Values}>
              <div className={styles.ValueRow}>
                <span className={styles.Large}>{formatValueAsTons(plugin.footprint_ytd)}</span>
                <span className={classNames(styles.Small, styles.Grey)}> tonnes CO2e</span>
              </div>
              <div className={styles.ValueRow}>
                <span className={styles.Large}>{formatPercentage(percentages[idx])}</span>
                <span className={classNames(styles.Small, styles.Grey)}>% of total footprint</span>
              </div>
            </div>
          </span>
        )
      })}
    </>
  )
}
