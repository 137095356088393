import React from 'react'
import { isSite, LocationWithType } from '../../lib/APIClient'
import { formatAbsoluteNumber } from '../Utils/format'
import { useFootprint, usePppSales } from '../SnapshotTopCards'
import { Tooltip } from '../Tooltip'
import { useDataAvailabilityContext } from '../../context'
import { Link } from '../Link'
import { SiteFunction } from '../../../api/src/common-types'
import { Route } from '../../routes'

interface SiteOrCountryInfoModalContentProps {
  func: SiteFunction[]
  siteOrCountry: LocationWithType
}

const formatNumber = (n: number) => formatAbsoluteNumber(n)

export const SiteOrCountryInfoModalContent: React.FC<SiteOrCountryInfoModalContentProps> = ({
  func,
  siteOrCountry
}) => {
  const { dataAvailability } = useDataAvailabilityContext()
  const id = isSite(siteOrCountry) ? siteOrCountry.siteId : siteOrCountry.countryCode

  const [footprintTonnesYtd, footprintTonnesYtdUnit] = useFootprint(
    id,
    func,
    dataAvailability?.planetPreviousFY ?? 2000,
    dataAvailability?.planetCurrentFY ?? 2000
  )
  const [currentPppShare, currentPppShareUnit] = usePppSales(
    id,
    dataAvailability?.profitPreviousFY ?? 2000,
    dataAvailability?.profitCurrentFY ?? 2000
  )

  const values = [
    {
      header: 'Climate Footprint',
      page: Route.PlanetFootprintPageDeprecated,
      value: footprintTonnesYtd,
      unit: footprintTonnesYtdUnit,
      tooltip: "Visit location's Planet KPI page"
    },
    {
      header: 'People + Planet Positive Sales',
      page: Route.ProfitGrowthPageDeprecated,
      value: currentPppShare,
      unit: currentPppShareUnit,
      tooltip: "Visit location's Profit KPI page"
    }
  ] as const

  const isVisible = ({ header }: { header: string }) =>
    !isSite(siteOrCountry) || siteOrCountry.functionCode === 'Retail' || header === 'Climate Footprint'
  return (
    <div>
      {values.filter(isVisible).map(({ header, page, value, unit, tooltip }) => (
        <React.Fragment key={header}>
          <h3 className="SiteOrCountryInfoHeader">
            <Tooltip className="SiteOrCountryInfoTooltip" tooltipText={tooltip}>
              {page ? (
                <Link page={page}>
                  {header} <span className="LinkIndicator"> </span>
                </Link>
              ) : (
                <span>{header}</span>
              )}
            </Tooltip>
          </h3>
          <div className="SiteOrCountryInfoValue">
            {value ? formatNumber(value) : '…'}
            <span className="SiteOrCountryInfoUnit">{unit}</span>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
