import React from 'react'
import { useSharedSelections } from '../SharedSelections'

interface SwitchScopeBtonProps {
  setIsOld: (arg0: boolean) => void
  isOld: boolean
  textLeftBtn?: string
  textRightBtn?: string
  queryParamFirst?: 'sales' | 'oldscope'
  queryParamLast?: 'quantity' | 'sbti'
}

export const SwitchScopeBtn = ({
  setIsOld,
  isOld,
  textLeftBtn,
  textRightBtn,
  queryParamFirst,
  queryParamLast
}: SwitchScopeBtonProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ scope }, updateSharedSelections] = useSharedSelections()

  return (
    <div>
      <button
        type="button"
        className={`btn btn--scope ${isOld ? 'active' : ''}`}
        onClick={() => {
          updateSharedSelections({ scope: queryParamFirst })
          setIsOld(true)
        }}
      >
        {textLeftBtn}
      </button>
      <button
        type="button"
        className={`btn btn--sbti ${!isOld ? 'active' : ''}`}
        onClick={() => {
          updateSharedSelections({ scope: queryParamLast })
          setIsOld(false)
        }}
      >
        {textRightBtn}
      </button>
    </div>
  )
}
