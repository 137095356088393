/* eslint-disable react/display-name */
import React from 'react'
import Select, { StylesConfig, ValueType, OptionProps, components } from 'react-select'
import classNames from 'classnames'

import { ArrowSelectDropdownIndicator, ArrowSelectDropdownIndicatorProps } from '../ArrowIcon'

import './FilterDropdownMenu.scss'
import colours from '../../Colours.module.scss'

export interface Option {
  label: string
  value: string
  disabled?: boolean
}

interface FilterDropdownMenuProps {
  label: string
  name: string
  selected: string
  options: Option[]
  onChange(value: string): void
  disabled: boolean
}

const height = '24px'
const selectedStyles: StylesConfig<Option, false> = {
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    minHeight: 0,
    height,
    padding: '0 16px',
    backgroundColor: colours.white
  }),
  indicatorsContainer: () => ({
    height
  }),
  menu: base => ({
    ...base,
    marginTop: '4px',
    left: 0
  }),
  menuList: base => ({
    ...base,
    maxHeight: undefined,
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    padding: `8px 24px`,
    backgroundColor: (state.isSelected && 'rgba(0, 0, 0, 0.08)') || 'white',
    color: state.isDisabled ? 'lightgray' : 'black',
    height: '48px',
    display: 'flex',
    alignItems: 'center'
  }),
  singleValue: base => ({
    ...base,
    maxWidth: 'calc(100% - 92px)',
    marginLeft: 0
  }),
  valueContainer: () => ({
    padding: 0,
    margin: 0,
    height
  })
}

export const FilterDropdownIndicator: React.FC<{
  selectProps: ArrowSelectDropdownIndicatorProps
}> = ({ selectProps }) => <ArrowSelectDropdownIndicator {...selectProps} isDisabled={true} />

export const FilterDropdownMenuOption: React.FC<OptionProps<Option, false>> = props => (
  // Option- is prefixed to className to avoid invalid css names for numeric values
  <components.Option className={classNames('FilterOption', `Option-${props.data.value}`)} {...props}>
    <div className={classNames('Label', { bold: !!props.data.pName })}>{props.label}</div>
    <div className="PName">{props.data.pName}</div>
  </components.Option>
)

export const FilterDropdownMenu: React.FC<FilterDropdownMenuProps> = ({
  label,
  name,
  selected,
  options,
  onChange,
  disabled
}) => {
  const selectedValue = options.find(x => x.value === selected)
  const onSelectionChanged = (selection: ValueType<Option, false>) => onChange((selection as Option).value)

  return (
    <div className={classNames('FilterDropdownMenu', name)}>
      <div className="DropdownLabel">{label}</div>
      <Select
        className="DropdownOptionsMenu"
        classNamePrefix="DropdownOptionsMenu"
        options={options}
        value={selectedValue || null}
        styles={selectedStyles}
        isSearchable={false}
        isOptionDisabled={option => !!option.disabled}
        components={{
          DropdownIndicator: FilterDropdownIndicator,
          IndicatorSeparator: () => null,
          Option: FilterDropdownMenuOption
        }}
        onChange={onSelectionChanged}
        isDisabled={disabled}
      />
    </div>
  )
}
