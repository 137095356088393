import { GraphUnit } from '../../../../components/UnitSelector'

export function getYAxisText(graphUnit: GraphUnit) {
  switch (graphUnit) {
    case GraphUnit.ConvertedUnits:
      return 'tonnes CO2e'
    case GraphUnit.RawWater:
      return 'litres'
    case GraphUnit.RawWaste:
      return 'kg'
    case GraphUnit.MassKg:
    case GraphUnit.RawWasteKg:
      return 'kg'
    case GraphUnit.RawUnits:
      return 'kWh'
    case GraphUnit.RelativeRawUnits:
      return 'kWh / m2'
    case GraphUnit.RelativeUnits:
      return 'kg CO2e / m2'
    case GraphUnit.RecycledWaste:
      return 'Recycling share (%)'
    case GraphUnit.Landfill:
      return 'Landfill share (%)'
  }
}
