import React from 'react'
import classNames from 'classnames'

import './RadioButton.scss'

interface RadioButtonProps {
  text: string
  selected: boolean
  onClick: () => void
}

export const RadioButton = ({ text, selected, onClick }: RadioButtonProps) => {
  const selectedClassName = selected ? 'Selected' : 'Deselected'
  return (
    <div className="RadioButton" onClick={onClick}>
      <div className={classNames('Toggle', selectedClassName)} />
      <div className="Label">{text}</div>
    </div>
  )
}
