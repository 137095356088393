import React from 'react'
import classnames from 'classnames'
import SkapaTooltip from '@ingka/tooltip'

import './Tooltip.scss'

interface TooltipProps {
  className?: string
  tooltipText?: string
  onClick?: () => void
  onEnter?: () => void
  onLeave?: () => void
  position?: 'top' | 'bottom'
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFn = () => {}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  className,
  tooltipText,
  onClick,
  onEnter,
  onLeave,
  position
}) => {
  const content = tooltipText ? (
    <SkapaTooltip
      tooltipText={tooltipText}
      className={classnames('SkapaTooltip', className)}
      position={position}
      interactiveCustomTrigger={true}
    >
      <div
        className={className}
        onMouseEnter={onEnter || emptyFn}
        onMouseLeave={onLeave || emptyFn}
        onClick={onClick || emptyFn}
      >
        {children}
      </div>
    </SkapaTooltip>
  ) : (
    <div
      className={className}
      onMouseEnter={onEnter || emptyFn}
      onMouseLeave={onLeave || emptyFn}
      onClick={onClick || emptyFn}
    >
      {children}
    </div>
  )

  return content
}
