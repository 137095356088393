import React from 'react'
import classnames from 'classnames'
import { ReactComponent as NoDataLogo } from '../../images/no-data-illustration.svg'
import './NoDataView.scss'

interface NoDataViewProps {
  className?: string
  text?: string | JSX.Element
}

export const NoDataView = ({ className, text }: NoDataViewProps) => {
  const textToShow =
    text ||
    "The location or the function you've selected does not have data you are looking for. Try to widen your filters or explore another graph."
  return (
    <div className={classnames('NoDataView', className)}>
      <NoDataLogo className="Image" />
      <div>
        <div className="SubTitle">Missing data</div>
        <div className="Headline">There&apos;s no data here</div>
        <div className="Text">{textToShow}</div>
      </div>
    </div>
  )
}

interface NoDataViewSmallProps {
  text?: string | JSX.Element
}

export const NoDataViewSmall = ({ text }: NoDataViewSmallProps) => {
  return (
    <div className="NoDataViewSmall">
      <NoDataLogo className="Image" />
      <div className="Text">
        <div className="Headline">No data available!</div>
        {text ? <div>{text}</div> : <div>Try selecting another location or function.</div>}
      </div>
    </div>
  )
}
