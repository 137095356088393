import React, { ReactNode } from 'react'
import * as XLSX from 'xlsx'
import { ClimateBenchmark } from '../../api/src/types/benchmark'
import { exportDataProps } from '../pages/ExplorePages/ExploreGraphCard'

interface ExcelExportButtonProps {
  data: ClimateBenchmark[]
  children: ReactNode
  classes?: string
}

const ExcelExportButton: React.FC<ExcelExportButtonProps> = ({ data, children, classes }) => {
  return (
    <button onClick={() => exportToExcel(data)} className={classes}>
      {children}
    </button>
  )
}

export default ExcelExportButton

export const exportToExcel = (data: ClimateBenchmark[] | exportDataProps[], name?: string) => {
  const ws = XLSX.utils.json_to_sheet(data)

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Data')

  XLSX.writeFile(wb, `${name || 'data'}.xlsx`)
}
