import React from 'react'
import { Navigate } from 'react-router'
import { isNil } from 'lodash'

import * as APIClient from '../lib/APIClient'
import { TopBar } from '../components/TopBar'
import { useSharedSelections } from '../SharedSelections'
import { getLocationOrDefault, isCluster } from '../components/Utils/utils'
import { SnapshotTopCards } from '../components/SnapshotTopCards'

import styles from './SnapshotPage.module.scss'
import { Insight, InsightType } from '../../api/src/common-types'
import { LoadingSkeleton } from '../components/LoadingSkeleton'
import { useLocations } from '../context'
import { BottomLegend, InsightGraph, InsightText, TopLegend } from '../components/Insights/Insight'
import { Link } from '../components/Link'
import { Route } from '../routes'
import { Benchmarking } from './Snapshot/Benchmarking/Benchmarking'

export const SnapshotPage = () => {
  React.useEffect(() => {
    document.title = 'Sustainability Snapshot'
  }, [])
  const { currentLocation, locations } = useLocations()
  const siteOrCountry = getLocationOrDefault()

  if (locations.length > 0 && currentLocation == null && !isCluster(siteOrCountry)) {
    return <Navigate to="/" replace />
  }

  return (
    <div className={styles.SnapshotPage}>
      <TopBar currentPage={Route.SnapshotPage} />
      <div className={styles.PageContainer}>
        <SnapshotTopCards siteOrCountry={siteOrCountry} currentLocation={currentLocation} />
        {false && <SnapshotInsights siteOrCountry={siteOrCountry} />}
        <Benchmarking />
      </div>
    </div>
  )
}

interface SnapshotInsightsProps {
  siteOrCountry: string
}

const SnapshotInsights: React.FC<SnapshotInsightsProps> = ({ siteOrCountry }) => {
  const [insights, setInsights] = React.useState<Insight[] | null>(null)
  const [{ func }] = useSharedSelections()

  React.useEffect(() => {
    const fetchInsights = async () => {
      const response = await APIClient.getSnapshotInsights(siteOrCountry, func)
      setInsights(response)
    }
    setInsights(null)
    fetchInsights()
  }, [siteOrCountry, JSON.stringify(func)])

  return !isNil(insights) && insights.length == 0 ? null : (
    <>
      <div className={styles.InsightHeader}>Selected Insights</div>
      <div className={styles.InsightCards}>
        {isNil(insights) ? (
          <>
            <LoadingSkeleton />
            <LoadingSkeleton />
          </>
        ) : (
          insights.map((insight, i) => <InsightCard key={i} insight={insight} />)
        )}
      </div>
    </>
  )
}

interface InsightCardProps {
  insight: Insight
}

const InsightCard: React.FC<InsightCardProps> = ({ insight }: InsightCardProps) => {
  return (
    <div className={styles.InsightCard} data-testid="insight-card">
      <h3>{insight.title}</h3>
      <div className={styles.InsightCardContent}>
        <div className={styles.InsightCardHalf}>
          <div className={styles.InsightTexts}>
            <InsightText insight={insight} />
          </div>
          <Link className={styles.LearnMore} page={getLink(insight.type)}>
            Learn more ›
          </Link>
        </div>
        <div className={styles.InsightCardHalf}>
          <TopLegend className={styles.InsightGraphLegend} insight={insight} />
          <div className={styles.InsightGraph}>
            <InsightGraph insight={insight} />
          </div>
          <BottomLegend className={styles.InsightGraphLegend} insight={insight} />
        </div>
      </div>
    </div>
  )
}

const getLink = (insightType: InsightType): Route => {
  switch (insightType) {
    case 'climate-footprint':
      return Route.ClimateKPIPage
    case 'sales':
      return Route.PppSalesKPIPage
    case 'recycling-rate':
      return Route.ZeroWasteKPIPage
  }
}
