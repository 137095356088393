import React from 'react'

import { formatPercentage } from './Utils/format'
import { SunburstGraph } from './BaseGraphs/SunburstGraph'
import styles from './DonutChart.module.scss'

interface DonutChartSerie {
  name: string
  color: string
  value: number
}

interface DonutChartProps {
  series: DonutChartSerie[]
  testId?: string
}

export const PercentageDonutChart: React.FC<DonutChartProps> = ({ series, testId }) => {
  return (
    <div className={styles.SunburstFigureContainer}>
      <div className={styles.Legends}>
        {series.map(serie => (
          <div key={serie.name} className={styles.LegendContainer}>
            <span className={styles.LegendBullet} style={{ backgroundColor: serie.color }} />
            <span>{serie.name}</span>
            <div className={styles.Percentage}>{formatPercentage(serie.value)}%</div>
          </div>
        ))}
      </div>
      <div className={styles.SunburstContainer}>
        <SunburstGraph testId={testId} series={[series]} />
      </div>
    </div>
  )
}
