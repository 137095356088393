import React from 'react'
import { InformationIndicator } from '../BaseGraphs/Indicators'

interface FunctionSelectorTipProps {
  text: string
}

export const FunctionSelectorTip: React.FC<FunctionSelectorTipProps> = ({ text }) => (
  <div className="FunctionSelectorTip">
    <InformationIndicator /> By using <strong>function selector</strong>, you can select to {text}
  </div>
)
