import React from 'react'
import { sumBy } from 'lodash'
import { FootprintType, getPlanetFootprints, SelectorWithCountryCodes } from '../../lib/APIClient'
import { formatAbsoluteNumber } from '../Utils/format'
import { getCurrentLocationLabel } from '../Utils/utils'
import { SlideSelectorWithCountryCodesProps } from './ClimateFootprintLargestContributorSlide'
import { InStoreSlide } from './InStoreSlide'
import { useDataAvailabilityContext, LanguageContext } from '../../context'
import { InStoreI18n } from '../../Localisation'
import { dataSourceText } from '../../Localisation'

import './WasteTreatmentSlide.scss'
import classNames from 'classnames'
import { fetchWasteData, PlanetWasteByDisposalGraph } from '../PlanetWasteGraphs'
import { ApiWasteFootprint } from '../../../api/src/common-types'

interface WasteTreatmentByLocationSlideProps extends SlideSelectorWithCountryCodesProps {
  selectorLastFY: SelectorWithCountryCodes
}

export const WasteTreatmentByLocationSlide: React.FC<WasteTreatmentByLocationSlideProps> = ({
  currentLocation,
  selector,
  selectorLastFY
}) => {
  const [wasteTotalThisFY, setWasteTotalThisFY] = React.useState(-1)
  const [wasteTotalLastFY, setWasteTotalLastFY] = React.useState(-1)
  const [wasteDataSource, setWasteDataSource] = React.useState('')
  const { dataAvailability } = useDataAvailabilityContext()
  const lang = React.useContext(LanguageContext)
  const i18n = InStoreI18n[lang]

  const currentLocationLabel = getCurrentLocationLabel(currentLocation, lang)

  React.useEffect(() => {
    getPlanetFootprints(FootprintType.Waste, { ...selector, start_fy: dataAvailability?.planetCurrentFY }).then(
      response => {
        const wastes = response.data
        setWasteTotalThisFY(sumBy(wastes, 'raw'))
        if (wastes.length > 0) {
          setWasteDataSource(
            dataSourceText(lang, wastes[wastes.length - 1].readableDate, i18n.Generic.sustainData, dataAvailability)
          )

          const maxPeriodCurrentFY = Math.max(...wastes.map(({ period }) => period))
          getPlanetFootprints(FootprintType.Waste, selectorLastFY).then(wastesLastFY => {
            setWasteTotalLastFY(
              sumBy(
                wastesLastFY.data.filter(({ period }) => period <= maxPeriodCurrentFY),
                'raw'
              )
            )
          })
        }
      }
    )
  }, [dataAvailability])

  return (
    <InStoreSlide
      className="WasteTreatmentSlide"
      header={{
        location: currentLocationLabel,
        description: i18n['Waste Treatment'].description,
        title: i18n['Waste Treatment'].title,
        dataSource: wasteDataSource
      }}
      series={{
        name: i18n['Waste Treatment'].totalYTD,
        value: formatAbsoluteNumber(Math.round(wasteTotalThisFY)),
        unit: 'kg',
        badge: `${formatAbsoluteNumber(Math.round(Math.abs(wasteTotalThisFY - wasteTotalLastFY)))} kg ${
          wasteTotalThisFY < wasteTotalLastFY ? i18n.Generic.less : i18n.Generic.more
        } ${i18n.Generic.thanThisTime}`,
        onTrack: wasteTotalThisFY - wasteTotalLastFY
      }}
      graph={<GraphContainer selector={selector} />}
      isLoading={wasteTotalThisFY === -1 || wasteTotalLastFY === -1}
    />
  )
}

interface GraphContainerProps {
  selector: SelectorWithCountryCodes
}

const GraphContainer = ({ selector }: GraphContainerProps) => {
  const [data, setData] = React.useState<ApiWasteFootprint[]>()

  React.useEffect(() => {
    const getData = async () => {
      const { data } = await fetchWasteData(selector)
      setData(data as ApiWasteFootprint[])
    }
    getData()
  }, [selector])

  return data ? (
    <div className={classNames('graphContainer', 'LessXAxisTicks')}>
      <PlanetWasteByDisposalGraph dateFormat="monthWithYear" data={data} siteOrCountry={selector.locationId} />
    </div>
  ) : null
}
