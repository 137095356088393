import React from 'react'
import Button from '@ingka/button'
import classNames from 'classnames'
import { findIndex, toPairs } from 'lodash'
import qs from 'qs'

import { createInstoreLink } from '../../../lib/APIClient'
import { CheckBox } from '../../../components/Checkbox'
import { Slide } from '../InStorePage'
import { LocationSearch } from '../../../components/LocationSearch'
import { ReactComponent as FourPLogo } from '../../../images/Logo.svg'

import './ConfiguratorPage.scss'
import { Selector } from '../../../components/UnitSelector'
import { Language } from '../../../Localisation'
import { Link } from '../../../components/Link'
import { schema } from '../../../Localisation'
import { Route } from '../../../routes'

type SlideGroup = 'planet' | 'profit' | 'none'

interface SlideOption {
  label: string
  values: Slide[]
  group: SlideGroup
}

const slideOptions: SlideOption[] = [
  {
    label: 'Introduction',
    values: ['intro'],
    group: 'none'
  },
  {
    label: 'Climate Footprint Year to Date',
    values: ['climatefootprintytd'],
    group: 'planet'
  },
  {
    label: 'Climate Footprint - Largest Contributor',
    values: ['climatefootprintinsight'],
    group: 'planet'
  },
  { label: 'Energy efficiency kWh/m2', values: ['energyefficiency'], group: 'planet' },
  {
    label: 'Waste Treatment by Type',
    values: ['wastetreatment'],
    group: 'planet'
  },
  {
    label: 'Waste Recycling Rate',
    values: ['wasterecycling'],
    group: 'planet'
  },
  {
    label: 'Water Efficiency',
    values: ['waterefficiency'],
    group: 'planet'
  },
  { label: 'People + Planet Positive Sales', values: ['pppsales'], group: 'profit' },
  { label: 'HFB Categories in P+PP Sales', values: ['hfbtop', 'hfbbottom'], group: 'profit' }
]

export const InStoreConfiguratorPage = () => {
  React.useEffect(() => {
    document.title = '4P Display Configurator'
  }, [])
  const [siteOrCountry, setSiteOrCountry] = React.useState('')
  const [selectedSlides, setSelectedSlides] = React.useState<Slide[]>([])
  const [language, setLanguage] = React.useState<Language>('en')
  const toggleSlide = (values: Slide[]) => {
    setSelectedSlides(
      values.every(value => selectedSlides.includes(value))
        ? selectedSlides.filter(x => !values.includes(x))
        : [...selectedSlides, ...values]
    )
  }

  let lastGroup = ''

  const isOptionValidForLocation = ({ values }: SlideOption) =>
    siteOrCountry.length > 3 || (!values.includes('energyefficiency') && !values.includes('wasterecycling'))

  const languageOptions = toPairs(schema)
    .slice(1)
    .map(pair => ({ label: pair[0], value: pair[1].prop }))

  return (
    <div className="InstoreConfiguratorPage">
      <div className="LeftPane">
        <div className="Title">
          <FourPLogo className="Logo" />
          4P Display
          <br />
          Configurator
        </div>
        <div className="LinkToFrontpage">
          More data visualizations at
          <br />
          <Link className="Link" page={Route.Root}>
            4P Dashboard
          </Link>
        </div>
        <div className="Description">
          <div>Slides are optimized to be viewed on big screens</div>
          <div>Timing per screen is automated to 30 seconds</div>
          <a
            className="Link InstructionsLink"
            href="https://iweof.sharepoint.com/:p:/t/o365g_sbpsustainabilitydashboard_issemal/ERamVOMBd5BBmG3fzHmlFsABkhD0PcWYdaJ5UmUOLDu3rQ?e=OcsBbI"
            target="_blank"
            rel="noreferrer"
          >
            How to use the configurator
          </a>
          <div>Send questions and feedback to</div>
          <a className="Link" href="mailto:4p-dashboard.support.se@ingka.ikea.com">
            4p-dashboard.support.se@ingka.ikea.com
          </a>
        </div>
      </div>
      <div className="Content">
        <h5>Select location</h5>
        <LocationSearch
          functionArea="ingka"
          filter={({ value }) => value !== 'ALL'}
          onLocationSelected={setSiteOrCountry}
        />
        <h5>Select language</h5>
        <Selector
          enabled={true}
          value={languageOptions.find(opt => opt.value === language) || languageOptions[0]}
          options={languageOptions}
          onChange={(lang: Language) => setLanguage(lang)}
        />
        <h5>Select screens</h5>
        <div className="SlideList">
          {slideOptions.filter(isOptionValidForLocation).map(({ label, values, group }) => {
            const isSelected = values.every(value => selectedSlides.includes(value))
            const isSameGroup = group === lastGroup
            const item = (
              <React.Fragment key={label}>
                {!isSameGroup && group !== 'none' ? <span className="SlideGroupName">{group}</span> : null}
                <div key={values[0]} className={classNames('SlideListItem', { isSameGroup })}>
                  <span className="Title" onClick={() => toggleSlide(values)}>
                    {label}
                  </span>
                  <CheckBox id={values[0]} isSelected={isSelected} onChange={() => toggleSlide(values)} />
                </div>
              </React.Fragment>
            )
            lastGroup = group
            return item
          })}
        </div>
        <Button
          className="GenerateLinkButton"
          disabled={selectedSlides.length === 0 || !siteOrCountry}
          text="Start presentation"
          onClick={async () => {
            const response = await createInstoreLink(
              siteOrCountry,
              selectedSlides
                .sort(
                  (a, b) =>
                    findIndex(slideOptions, option => option.values.includes(a)) -
                    findIndex(slideOptions, option => option.values.includes(b))
                )
                .map(s => s.valueOf())
            )
            const instoreUrl = `${window.location.origin}/${siteOrCountry}/instore?${qs.stringify({
              slides: selectedSlides,
              key: response.key,
              token: response.auth,
              lang: language
            })}`
            window.open(instoreUrl, '_blank')
          }}
        />
      </div>
    </div>
  )
}
