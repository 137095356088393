import React from 'react'
import classNames from 'classnames'

import styles from './TabBar.module.scss'

interface TabBarProps<TabType> {
  activeTab: TabType
  tabs: TabType[]
  onTabChanged: (tab: TabType) => void
  className?: string
  summary?: JSX.Element
  testId?: string
}

export function TabBar<TabType>({ activeTab, tabs, onTabChanged, className, summary, testId }: TabBarProps<TabType>) {
  return (
    <div className={classNames('TabBar', styles.TabBar, className)} data-testid={testId}>
      {tabs.map(tab => (
        <button
          data-testid={testId ? `${testId}-tab: ${tab}` : ''}
          key={String(tab)}
          className={classNames(styles.Tab, activeTab === tab && styles.ActiveTab)}
          onClick={() => onTabChanged(tab)}
        >
          {tab}
        </button>
      ))}
      <div className={styles.Spacer} />
      {summary}
    </div>
  )
}
