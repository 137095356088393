import React from 'react'

interface KpiPppSalesModalContentProps {
  lastUpdated?: string
}

export const KpiPppSalesModalContent: React.FC<KpiPppSalesModalContentProps> = ({ lastUpdated }) => (
  <>
    <p className="IntroParagraph">
      Sustainability ambitions and commitments impact how IKEA range is designed, developed and produced and lead the
      way to creating a positive change for people and planet. The IKEA Range is part of the solution, from how
      materials are chosen and sourced, to the functions that inspire and enable healthy and sustainable living to be a
      desirable choice.
    </p>
    <p className="IntroParagraph">
      People and Planet Positive Sales % is a KPI that aims to show growth generated from defined IKEA products and
      solutions that contribute to IKEA’s sustainability agenda and movements.
    </p>
    <h3>Followed metrics</h3>
    <div>
      <p className="IntroParagraph">People and Planet Positive Sales Share (%)</p>
      <ul>
        <li>
          <p>
            <b>Definition</b>: Delivered net sales from both As-Is sales and the sustainable range (articles on the
            People + Planet Positive Product list) as a share of total sales
          </p>
        </li>
        <li>
          <b>Data sources</b>:
          <ul>
            <li>Delivered net sales from Common Sales Table</li>
            <li>People + Planet Positive Product list</li>
          </ul>
        </li>
        <li>
          <p>
            <b>Frequency of 4P data update</b>: Weekly
          </p>
        </li>
      </ul>
    </div>

    <h3>Clarifications</h3>
    <div>
      <ul>
        <li>
          4P Dashboard hosts a dimension table for the P+PP items list that includes all historical versions of the list
          and their corresponding period of validity.
        </li>
        <li>
          Current P+P product list usage: Version 18 of the PPP list is available on the{' '}
          <a
            className="Underlined"
            href="https://iweof.sharepoint.com/:f:/r/teams/o365g_selling_irssemal/Shared%20Documents/People%20%26%20Planet%20Sales/P+P%20List?csf=1&web=1&e=pU5U5V"
          >
            Ingka Selling Hub.
          </a>
        </li>
        <li>
          4P dashboard,{' '}
          <a className="Underlined" href="https://flight-tower.ingka.com/">
            Retail Flight Tower
          </a>{' '}
          and MSFR “Sustainable item” filter are now using this list of version 18.{' '}
        </li>
        <li>The Total Sales in the calculation include all HFBs.</li>
        <li>YTD sales only include full weeks, meaning the week 36 is the first week.</li>
        <li>Russia and Ukraine are excluded from FY23 sales figure.</li>
      </ul>
    </div>

    <h3>How to contribute to this KPI?</h3>
    <div>
      <p>
        We can all contribute to this KPI, using our knowledge and creativity to steer sales, support customers in
        making sustainable choices, and implement the IKEA People & Planet Positive strategy. The PPP list can support
        in selecting the products when planning the different sustainability messages, always respecting country
        legislation on communication of course.
      </p>
    </div>

    <div className="LastUpdated">
      {lastUpdated && <p>{`Last updated on ${lastUpdated} from Delivered net sales`}</p>}
    </div>
  </>
)
