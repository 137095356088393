import React from 'react'
import classNames from 'classnames'
import { difference, flatten, flattenDeep, isEqual, omit, uniq } from 'lodash'

import { ArrowSelectDropdownIndicator } from '../ArrowIcon'

import './FilterDropdownMenu.scss'
import './MultiSelectionMenu.scss'
import { CheckBox } from '../Checkbox'
import { LocationFunction } from '../../SharedSelections'
import { SiteFunction } from '../../../api/src/common-types'

interface MenuItem {
  value: string
  label: string
  disabled?: boolean
}

interface MultiSelectionMenuProps {
  label?: string
  name: string
  className?: string
  disabled: boolean
  selected: SiteFunction[]
  options: LocationFunction[]
  onChange: (selection: SiteFunction[]) => void
}

function updateSelected(value: SiteFunction, selected: SiteFunction[], options: LocationFunction[]): SiteFunction[] {
  const values = flatten(
    options.map(({ value, children }) => (children.length > 0 ? children.map(({ value }) => value) : value))
  )
  const topLevelValues = options.map(({ value, children }) => (children.length > 0 ? value : undefined)).filter(x => x)

  if (options.length === 1) {
    const childValues = options[0].children.map(({ value }) => value)

    if (value === options[0].value) {
      return childValues
    }

    if (selected.includes(value)) {
      return selected.length === 1 ? childValues : difference(selected, [value])
    }

    return [...selected, value]
  }

  if (topLevelValues.includes(value)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const top = options.find(opt => opt.value === value)!
    const childValues = top.children.map(({ value }) => value)
    if (difference(childValues, selected).length === 0) {
      const unselected = difference<SiteFunction>(selected, [...childValues, 'ALL'])
      return unselected.length === 0 ? ['ALL'] : unselected
    } else {
      const selectedWithChildren = difference<SiteFunction>(uniq([...selected, ...childValues]), ['ALL'])
      return selectedWithChildren.length === values.length - 1 ? ['ALL'] : selectedWithChildren
    }
  }

  if (value === 'ALL' || uniq([...selected, value]).length === values.length - 1) {
    return ['ALL']
  }
  if (selected.includes(value)) {
    return difference(selected, [value, 'ALL'])
  }
  return difference([...selected, value], ['ALL'])
}

export const MultiSelectionMenu = ({
  label,
  name,
  className,
  disabled,
  selected,
  options,
  onChange
}: MultiSelectionMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const firstSelectedLabel = flattenDeep([
    options.map(opt => omit(opt, ['children', 'id', 'type'])), // omit here to satisfy TypeScript
    options.map(({ children }) => children)
  ]).find(opt => opt.value === selected[0])?.label

  const justTopLevelSelected = options.find(({ children }) =>
    isEqual(selected.sort(), children.map(({ value }) => value).sort())
  )

  const isDisabled = disabled || (options.length === 1 && options[0].children.length === 0)

  const textToShow =
    selected.length > 1 && justTopLevelSelected
      ? justTopLevelSelected.label
      : isDisabled
      ? firstSelectedLabel
      : selected.length > 1
      ? `${selected.length} functions selected`
      : firstSelectedLabel

  return (
    <>
      <div className={classNames(className, name)}>
        {label && <div className="DropdownLabel">{label}</div>}
        <div className={classNames('MultiSelectContainer', { disabled: isDisabled })}>
          <div className="MultiSelectSelection" onClick={() => !isDisabled && setIsOpen(!isOpen)}>
            <div className="DropdownOptionsMenu">{textToShow}</div>
            <ArrowSelectDropdownIndicator isDisabled={true} menuIsOpen={isOpen} />
          </div>
          {isOpen && (
            <div className="MultiSelectMenu">
              {options.map(opt => {
                return [
                  <MultiSelectionMenuItem
                    key={opt.value}
                    item={opt}
                    isSelected={
                      opt.type === 'without-children'
                        ? selected.includes(opt.value)
                        : opt.children.every(child => selected.includes(child.value))
                    }
                    isTopLevel
                    onChange={(value: string) => onChange(updateSelected(value as SiteFunction, selected, options))}
                  />,
                  ...opt.children.map(child => (
                    <MultiSelectionMenuItem
                      key={child.value}
                      item={child}
                      isSelected={selected.includes(child.value)}
                      isTopLevel={false}
                      onChange={(value: string) => onChange(updateSelected(value as SiteFunction, selected, options))}
                    />
                  ))
                ]
              })}
            </div>
          )}
        </div>
      </div>
      {isOpen && <div className="ClickCatcher" onClick={() => setIsOpen(false)} />}
    </>
  )
}

interface MultiSelectionMenuItemProps {
  item: MenuItem
  isSelected: boolean
  isTopLevel: boolean
  disabled?: boolean
  onChange: (value: string) => void
}

const MultiSelectionMenuItem = (props: MultiSelectionMenuItemProps) => {
  const { item, isTopLevel } = props
  const { disabled } = item

  return (
    <div className={classNames('ItemContainer', { disabled, isTopLevel })}>
      <div className="MultiSelectMenuItem">{item.label}</div>
      <CheckBox id={item.value} isSelected={props.isSelected} onChange={props.onChange} />
    </div>
  )
}
