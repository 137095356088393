import {
  Insight,
  InsightCoCreationLink,
  InsightDominantCategory,
  InsightDominantFactor,
  InsightDominantWaste,
  InsightGlobalRelativeFpBest,
  InsightGlobalRelativeFpWorst,
  InsightHfbShareComparison1,
  InsightHfbShareComparison2,
  InsightLargestContributors,
  InsightRecoveredProducts,
  InsightRecyclingRate,
  InsightRelativeFootprint,
  InsightSalesShareQuartile,
  InsightSmallestContributors,
  InsightYoyBiggestDecrease,
  InsightYoyBiggestIncrease,
  InsightTenantCASplit
} from '../../../api/src/common-types'

export const isDominantCategory = (insight: Insight): insight is InsightDominantCategory =>
  insight.name === 'dominant_category'

export const isDominantFactor = (insight: Insight): insight is InsightDominantFactor =>
  insight.name === 'dominant_factor'

export const isGlobalRelativeFpBest = (insight: Insight): insight is InsightGlobalRelativeFpBest =>
  insight.name === 'global_relative_fp_best'

export const isGlobalRelativeFpBWorst = (insight: Insight): insight is InsightGlobalRelativeFpWorst =>
  insight.name === 'global_relative_fp_worst'

export const isRecyclingRate = (insight: Insight): insight is InsightRecyclingRate => insight.name === 'recycling_rate'

export const isRelativeFootprint = (insight: Insight): insight is InsightRelativeFootprint =>
  insight.name === 'relative_footprint'

export const isYoyBiggestIncrease = (insight: Insight): insight is InsightYoyBiggestIncrease =>
  insight.name === 'yoy_biggest_increase'

export const isYoyBiggestDecrease = (insight: Insight): insight is InsightYoyBiggestDecrease =>
  insight.name === 'yoy_biggest_decrease'

export const isCoCreationLink = (insight: Insight): insight is InsightCoCreationLink =>
  insight.name === 'co_creation_link'

export const isHfbShareComparison1 = (insight: Insight): insight is InsightHfbShareComparison1 =>
  insight.name === 'hfb_share_comparison_1'

export const isHfbShareComparison2 = (insight: Insight): insight is InsightHfbShareComparison2 =>
  insight.name === 'hfb_share_comparison_2'

export const isSalesShareQuartile = (insight: Insight): insight is InsightSalesShareQuartile =>
  insight.name === 'sales_share_quartile'

export const isDominantWaste = (insight: Insight): insight is InsightDominantWaste =>
  insight.name === 'dominant_waste_type_zw'

export const isRecoveredProducts = (insight: Insight): insight is InsightRecoveredProducts =>
  insight.name === 'bd_recovered_produts_zw'

export const isGlobalSmallestContributors = (insight: Insight): insight is InsightSmallestContributors =>
  insight.name === 'global_fp_bottom_share'

export const isGlobalLargestContributors = (insight: Insight): insight is InsightLargestContributors =>
  insight.name === 'global_fp_top_share'

export const isTenantCASplit = (insight: Insight): insight is InsightTenantCASplit => insight.name === 'tenant_ca_split'
