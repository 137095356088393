import { format } from 'date-fns'
import {
  enGB as localeEnGB,
  de as localeDE,
  cs as localeCS,
  ru as localeRU,
  fr as localeFR,
  nl as localeNL,
  ja as localeJA,
  it as localeIT,
  zhCN as localeZhCN,
  pt as localePT,
  es as localeES,
  nb as localeNO
} from 'date-fns/locale'
import { padStart, range, toPairs } from 'lodash'
import { DataAvailability } from '../api/src/common-types'
import en from './localisations/en.json'
import de from './localisations/de.json'
import cs from './localisations/cs.json'
import ru from './localisations/ru.json'
import fr from './localisations/fr.json'
import nl from './localisations/nl.json'
import ja from './localisations/ja.json'
import it from './localisations/it.json'
import zh from './localisations/zh.json'
import pt from './localisations/pt.json'
import es from './localisations/es.json'
import no from './localisations/no.json'

const Languages = ['en', 'de', 'cs', 'ru', 'fr', 'nl', 'ja', 'it', 'zh', 'pt', 'es', 'no']
export type Language = typeof Languages[number]
export type Localisation = typeof en
export const InStoreI18n: Record<Language, Localisation> = { en, de, cs, ru, fr, nl, ja, it, zh, pt, es, no }

export const schema = {
  Id: { prop: 'id' as const, type: String },
  English: { prop: 'en' as const, type: String },
  German: { prop: 'de' as const, type: String },
  Czech: { prop: 'cs' as const, type: String },
  Russia: { prop: 'ru' as const, type: String },
  French: { prop: 'fr' as const, type: String },
  Dutch: { prop: 'nl' as const, type: String },
  Japanese: { prop: 'ja' as const, type: String },
  Italian: { prop: 'it' as const, type: String },
  Chinese: { prop: 'zh' as const, type: String },
  Portuguese: { prop: 'pt' as const, type: String },
  Spanish: { prop: 'es' as const, type: String },
  Norwegian: { prop: 'no' as const, type: String }
}

export function parseLanguageCode(lang: string | undefined): Language {
  if (lang !== undefined && isValidLanguage(lang)) {
    return lang
  }
  return 'en'
}

export function isValidLanguage(lang: string): lang is Language {
  return Languages.indexOf(lang) !== -1
}

export function datefnsLocale(lang: string) {
  switch (lang) {
    case 'de':
      return localeDE
    case 'cs':
      return localeCS
    case 'ru':
      return localeRU
    case 'fr':
      return localeFR
    case 'nl':
      return localeNL
    case 'ja':
      return localeJA
    case 'it':
      return localeIT
    case 'zh':
      return localeZhCN
    case 'pt':
      return localePT
    case 'es':
      return localeES
    case 'no':
      return localeNO
    case 'en':
    default:
      return localeEnGB
  }
}

export function monthNames(lang: string): Record<string, string> {
  const locale = datefnsLocale(lang)
  return range(1, 13).reduce((acc, m) => {
    const month = new Date(`2021-${padStart(m.toString(), 2, '0')}-01`)
    return { ...acc, [format(month, 'MMMM', { locale: localeEnGB })]: format(month, 'MMMM', { locale }) }
  }, {})
}

export function dataSourceText(
  lang: string,
  latestData: string | Date,
  sustainData: string,
  dataAvailability?: DataAvailability
) {
  return `${sustainData} ${format(new Date(`${(dataAvailability?.planetPreviousFY ?? 1) - 1}-09-01`), 'MMM yyyy', {
    locale: datefnsLocale(lang)
  })} - ${format(new Date(latestData), 'MMM yyyy', { locale: datefnsLocale(lang) })}`
}

export function dataSourceTextWithStartDate(
  lang: string,
  firstData: string | Date,
  latestData: string | Date,
  sustainData: string
) {
  return `${sustainData} ${format(new Date(firstData), 'MMM yyyy', {
    locale: datefnsLocale(lang)
  })} - ${format(new Date(latestData), 'MMM yyyy', { locale: datefnsLocale(lang) })}`
}

export function formatLocalisationString(template: string, values: Record<string, string>) {
  return toPairs(values).reduce((acc, [key, value]) => acc.replace(new RegExp(`\\$\\{${key}}`, 'g'), value), template)
}
