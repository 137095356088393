import React from 'react'

const energyColors: { [key: string]: string } = {
  Electricity: '#003F5C',
  'Fossil fuel onsite': '#3D548C',
  'Biofuel onsite': '#7A5195',
  'District Heating': '#BC5090',
  'District Cooling': '#EF5675',
  'district-heating-cooling-building': '#EF5675',
  'electricity-grid-building': '#00324A',
  'fuels-diesel-building': '#3D548C',
  'fuels-diesel-own-vechicles': '#7A5195',
  'upstream-emissions-fuel-production': 'url(#small-yellow-strokes-pattern',
  'upstream-emissions-grid-loss-electricity': 'url(#small-dkblue-strokes-pattern',
  'grid-electricity-building': '#6F5762',
  'onsite-electricity-generated-and-used-building': '#8C5C40',
  'natural-gas-building': '#F0E442',
  Refrigerants: '#FF764A',
  Transport: '#FFA600'
}

export const ThemeContext = React.createContext(energyColors)
