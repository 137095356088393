import classNames from 'classnames'
import React from 'react'

const FilterSelector = ({
  filters,
  selectedFilter,
  setSelectedFilter,
  filterLabels,
  currTitle
}: {
  filters: { label: string; disabled?: boolean }[]
  selectedFilter: string
  setSelectedFilter: (value: React.SetStateAction<string>) => void
  filterLabels: {
    [key: string]: string
  }
  currTitle?: string
}) => {
  return (
    <div className="FilterSelector">
      <div className="Left">
        {currTitle && <span className="Title">{currTitle.toUpperCase()}</span>}
        <div className="FilterButtons">
          {filters.map(filter => (
            <button
              key={filter.label}
              className={classNames(`Button ${filter.label}`, { Selected: selectedFilter === filter.label })}
              onClick={() => setSelectedFilter(filter.label)}
              disabled={filter.disabled}
            >
              {filterLabels[filter.label]}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FilterSelector
