import { useSharedSelections } from '../../../../SharedSelections'

const customer = {
  single: 'customer',
  multiple: 'customers'
}
const visitor = {
  single: 'visitor',
  multiple: 'visitors'
}
const person = {
  single: 'person',
  multiple: 'people'
}

export const useDynamicLabel = () => {
  const [{ func }] = useSharedSelections()

  const onlyRetail = !func.some(
    item => item !== 'Retail' && item !== 'Customer Fulfillment' && item !== 'Support units' && item !== 'Stores'
  )
  const onlyCentres = !func.some(item => item !== 'Ingka Centres' && item !== 'Common Areas' && item !== 'Tenants')

  if (onlyRetail) return customer
  if (onlyCentres) return visitor

  return person
}
