import React, { useEffect } from 'react'
import { flatMap, groupBy, toPairs } from 'lodash'
import { isLeapYear, isSameMonth, subDays, subYears } from 'date-fns'
import { useSharedSelections } from '../SharedSelections'
import { ChartContainer, Serie } from './BaseGraphs/ChartContainer'
import { barChart, DateFormat, lineChart } from './BaseGraphs/GraphUtil'
import { WasteData } from '../pages/KPIPages/ZeroWaste/ZeroWasteKPIPage'
import { useDataAvailabilityContext } from '../context'
import { formatAbsoluteNumber, formatPercentage, formatRelativeNumber } from './Utils/format'
import { getIngkaFinancialYear } from './Utils/dates'
import colours from '../Colours.module.scss'
import { ApiTotalFootprint } from '../../api/src/common-types'
import { TimeRange } from '../pages/ExplorePages/ExplorePage'
import { GraphUnit } from './UnitSelector'
import { createSeriesWithKey, sumByUnit, WasteByTypeGraph } from '../pages/ExplorePages/Climate/ClimateExplorePage'
import { wasteGroupColorMap } from './PlanetWasteMap'
import { LoadingSkeleton } from './LoadingSkeleton'

interface ZeroWasteGraphProps {
  data: WasteData[] | undefined
  dates: Date[] | undefined
  dateFormat: DateFormat
}

const colors = [colours.lightPurple, colours.purple1]

export const zeroWasteTypes = ['aerobic-digestion', 'anaerobic-digestion', 'ikea-circular-flows', 'material-recycling']

export const getZeroWasteSeries = (waste: WasteData[], dateFormat: DateFormat) => {
  if (waste == null || waste.length === 0) {
    return []
  }

  const nonZeroWaste = waste.filter(w => !zeroWasteTypes.includes(w.disposalType))
  if (dateFormat === 'monthWithYear') {
    const totals = toPairs(groupBy(nonZeroWaste, 'readableDate')).map(([readableDate, waste]) => {
      return { x: new Date(readableDate), y: waste.reduce((acc, w) => acc + w.raw, 0) }
    })
    return [{ name: 'Non-recycled waste', color: colors[1], data: totals }]
  }

  if (dateFormat === 'month') {
    return flatMap(toPairs(groupBy(nonZeroWaste, 'fiscalYear')), ([fy, waste], i) => {
      const monthlyWaste = toPairs(groupBy(waste, 'readableDate')).map(([readableDate, w]) => {
        let date = new Date(readableDate)
        if (isLeapYear(date) && date.getMonth() === 1) {
          date = subDays(date, 1)
        }
        return {
          x: subYears(date, i),
          y: w.reduce((acc, wd) => acc + wd.raw, 0)
        }
      })
      return { name: `FY${fy}`, color: colors[i], data: monthlyWaste }
    })
  }

  if (dateFormat === 'fy') {
    const totals = toPairs(groupBy(nonZeroWaste, 'fiscalYear')).map(([fy, waste]) => {
      return { x: new Date(`${Number(fy) + 1999}-09-30`), y: waste.reduce((acc, w) => acc + w.raw, 0) }
    })

    return [{ name: 'Non-recycled waste', color: colors[1], data: totals }]
  }

  return []
}

export const ZeroWasteGraph = ({ data, dates, dateFormat }: ZeroWasteGraphProps) => {
  const series = data ? getZeroWasteSeries(data, dateFormat) : undefined
  const domain =
    dateFormat === 'month' ? Object.values(groupBy(dates, d => getIngkaFinancialYear(d).getFullYear()))[0] : dates

  return (
    <ChartContainer
      generator={barChart}
      domain={domain}
      series={series}
      dateFormat={dateFormat}
      yAxisTitle="tonnes"
      hideLegend={dateFormat !== 'month'}
    />
  )
}

interface ZeroWasteSmallGraphsProps {
  className?: string
  totalWaste: WasteData[] | undefined
  recyclingSeries: Serie[] | undefined
  recoveredSeries: Serie[] | undefined
  allDates: Date[] | undefined
  recoveredProductsYtdShare?: number
  recyclingYtdShare?: number
  dateFormat: DateFormat
  dates: Date[] | undefined
  subtitle: string | JSX.Element | undefined
}

export const ZeroWasteSmallGraphs: React.FC<ZeroWasteSmallGraphsProps> = ({
  className,
  totalWaste,
  recoveredSeries,
  allDates,
  recoveredProductsYtdShare,
  dateFormat,
  dates,
  subtitle
}) => {
  const [{ functionArea }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()

  const serie = React.useMemo(() => {
    if (!totalWaste) {
      return undefined
    }
    if (totalWaste.length === 0) {
      return []
    }
    const totalWasteSeries = allDates
      ? flatMap(allDates, date => {
          const prevYearMonth = subYears(date, 1)
          const curr = totalWaste
            .filter(w => isSameMonth(new Date(w.readableDate), date))
            .reduce((acc, w) => acc + w.raw, 0)
          const prev = totalWaste
            .filter(w => isSameMonth(new Date(w.readableDate), prevYearMonth))
            .reduce((acc, w) => acc + w.raw, 0)
          return curr > 0 || prev > 0 ? { x: date, curr, prev } : []
        })
      : undefined
    return totalWaste.length === 0 || !totalWasteSeries
      ? []
      : [
          {
            name: `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2000}`,
            color: colours.yellow1,
            data: totalWasteSeries.map(w => ({ x: w.x, y: w.curr })),
            secondaryName: `FY${(dataAvailability?.planetPreviousFY ?? 2000) - 2000}`,
            secondaryColor: colours.lightYellow1,
            secondaryData: totalWasteSeries.map(w => ({ x: w.x, y: w.prev }))
          }
        ]
  }, [JSON.stringify(allDates), JSON.stringify(totalWaste)])

  const totalYTD = totalWaste
    ? totalWaste
        .filter(w => w.fiscalYear === (dataAvailability?.planetCurrentFY ?? 2000) - 2000)
        .reduce((acc, w) => acc + w.raw, 0)
    : 0
  const series = totalWaste ? getZeroWasteSeries(totalWaste, dateFormat) : undefined
  const domain =
    dateFormat === 'month' ? Object.values(groupBy(dates, d => getIngkaFinancialYear(d).getFullYear()))[0] : dates
  return (
    <div className={className ?? 'SideGraphs'}>
      <div className="GraphSideBarCard">
        <div className="CardHeading">
          <h3>Total waste generated</h3>
          {totalWaste && totalWaste.length > 0 && (
            <div>
              <span className="Amount">{formatAbsoluteNumber(totalYTD)}</span>
              <span className="Unit"> tonnes YTD</span>
            </div>
          )}
        </div>
        <ChartContainer
          generator={barChart}
          series={serie}
          dateFormat="month"
          domain={allDates}
          yAxisTitle="tonnes"
          tooltipSummary={date => {
            const totalSerie = serie ? serie[0] : undefined
            if (!totalSerie) {
              return []
            }
            const curr = totalSerie.data.find(w => isSameMonth(w.x, date))
            const prev = totalSerie.secondaryData.find(w => isSameMonth(w.x, date))
            const diff = curr && prev && curr.y > 0 ? (curr.y - prev.y) / curr.y : NaN
            return [
              {
                icon: <span style={{ fontWeight: 700 }}>%</span>,
                title: 'Difference',
                value: formatPercentage(diff),
                onTrack: Number.isNaN(diff) ? undefined : diff < 0
              }
            ]
          }}
          isSmallGraph
        />
      </div>
      <div className="GraphSideBarCard">
        <div className="CardHeading">
          <h3>Non-recycled waste</h3>
          {subtitle}
        </div>
        <ChartContainer
          generator={barChart}
          domain={domain}
          series={series}
          dateFormat={dateFormat}
          yAxisTitle="tonnes"
          hideLegend={dateFormat !== 'month'}
          isSmallGraph
        />
      </div>
      {functionArea !== 'centres' && (
        <div className="GraphSideBarCard">
          <div className="CardHeading">
            <div>
              <h3>Share of Recovered Products</h3>
            </div>
            {recoveredProductsYtdShare && (
              <div>
                <span className="Amount">{formatRelativeNumber(recoveredProductsYtdShare * 100)}</span>
                <span className="Unit"> % YTD</span>
              </div>
            )}
          </div>
          {createLineGraph(recoveredSeries, allDates)}
        </div>
      )}
    </div>
  )
}

interface WasteRecyclingRateGraphProps {
  data: Serie[] | undefined
  dates: Date[] | undefined
  dateFormat: DateFormat
}

export const WasteRecyclingRateGraph = ({ data, dates }: WasteRecyclingRateGraphProps) => {
  const domain = Object.values(groupBy(dates, d => getIngkaFinancialYear(d).getFullYear()))[0]
  return (
    <ChartContainer
      generator={lineChart}
      series={data}
      dateFormat="month"
      domain={domain}
      lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
      yAxisTitle="%"
      yAxisLabelTooltipOnly
    />
  )
}

function createLineGraph(series: Serie[] | undefined, domain: Date[] | undefined) {
  return (
    <ChartContainer
      generator={lineChart}
      series={series}
      dateFormat="month"
      domain={domain}
      lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
      yAxisTitle="%"
      yAxisLabelTooltipOnly
      isSmallGraph
    />
  )
}

interface NonRecycledWasteGraphsProps {
  waste?: ApiTotalFootprint[]
  dates?: Date[]
  timeRange: TimeRange
  selectedUnit?: (unit: string) => void
}

export const NonRecycledWasteGraphs: React.FC<NonRecycledWasteGraphsProps> = ({
  waste,
  dates,
  timeRange,
  selectedUnit
}) => {
  const [byTypeUnit, setByTypeUnit] = React.useState(GraphUnit.RawWaste)

  const byTypeSeries = createSeriesWithKey(
    'footprintContributor',
    waste,
    dates,
    timeRange,
    wasteGroupColorMap,
    sumByUnit(byTypeUnit)
  )
  const currentUnit = byTypeUnit === 'rawwaste' ? 'tonnes' : 'tonnes CO2e'

  useEffect(() => {
    selectedUnit && selectedUnit(currentUnit)
  }, [currentUnit])

  return (
    <>
      {waste ? (
        <WasteByTypeGraph
          dateFormat={timeRange === 'monthly' ? 'monthWithYear' : 'fy'}
          domain={dates}
          series={byTypeSeries}
          unit={byTypeUnit}
          unitDescription={currentUnit}
          onUnitChange={setByTypeUnit}
        />
      ) : (
        <LoadingSkeleton />
      )}
    </>
  )
}
