import React from 'react'
import _ from 'lodash'

import { ApiTotalFootprint, RefrigerantFactorsType } from '../../../../../api/src/common-types'
import { PageHeader } from '../../../../components/PageHeader'
import { TopBar } from '../../../../components/TopBar'
import { useSharedSelections } from '../../../../SharedSelections'

import '../../../KPIPages/KPIPage.scss'
import '../ClimateExplorePage.scss'
import colours from '../../../../Colours.module.scss'
import { useLocations, useDataAvailabilityContext } from '../../../../context'
import {
  getCluster,
  getCountry,
  getLocationOrDefault,
  getLocationSelector,
  isSiteId
} from '../../../../components/Utils/utils'
import { getRefrigerantsFactors, getWaterFootprint, isSite, useEffectWithAbort } from '../../../../lib/APIClient'
import { isSameDay } from 'date-fns'
import { ChartContainer, Serie, DataPoint } from '../../../../components/BaseGraphs/ChartContainer'
import { DateFormat, lineChart, stackedBarChart } from '../../../../components/BaseGraphs/GraphUtil'
import { formatPercentage, formatRelativeNumber } from '../../../../components/Utils/format'
import { SumIndicator } from '../../../../components/BaseGraphs/Indicators'
import { ExploreGraphCard } from '../../ExploreGraphCard'
import { GraphUnit } from '../../../../components/UnitSelector'
import { colorScheme } from '../../../../components/BaseGraphs/Universal'
import { getDateRange } from '../../ExplorePage'
import { useFilters } from '../../ExplorePage'
import { Route } from '../../../../routes'

import { getYAxisText } from '../utils/getYAxisText'

import { formatNumberValue } from '../../../../components/BaseGraphs/Tooltip'

type TimeRange = 'monthly' | 'annual'

const kpis = ['water'] as const

const kpiLabels = {
  water: 'Water'
}

const waterColorMap = {
  'Water - Wells': colours.pink,
  'Water - Rain harvesting': colours.lightBlue7,
  'Water - Externally supplied': colours.blue7,
  'Water - Sold/allocated to tenants': colours.lightBlue4,
  'Water - landlord': colours.lightBlue5,
  'Water - obtained by tenants': colours.blue3
}

export type ClimateExplorePageContextType = {
  refrigerantFactors: RefrigerantFactorsType | null
}

export const ClimateExplorePageContext = React.createContext<ClimateExplorePageContextType>({
  refrigerantFactors: null
})

export const WaterExplorePage = () => {
  const locationId = getLocationOrDefault()
  const [{ func }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()
  const { currentLocation, clusters, locations } = useLocations()

  const [{ timeRange, rangeFrom, rangeTo }, filterSelectors] = useFilters(
    kpis,
    kpiLabels,
    dataAvailability?.planetCurrentFY ?? 2000
  )
  const [totalFootprint, setTotalFootprint] = React.useState<ApiTotalFootprint[] | undefined>(undefined)

  const [dates, setDates] = React.useState<Date[]>([])
  const [refrigerantFactors, setRefrigerantFactors] = React.useState<RefrigerantFactorsType | null>(null)

  useEffectWithAbort(
    signal => {
      setDates([])
      setTotalFootprint(undefined)
      if (locations.length === 0 || clusters.length === 0) {
        return
      }

      getRefrigerantsFactors(signal).then(factors => {
        setRefrigerantFactors(factors)
      })

      const countryOrLocationId = isSiteId(locationId) ? getCountry(locationId, locations).countryCode : locationId

      getWaterFootprint(
        {
          ...getLocationSelector(countryOrLocationId, getCluster(clusters, countryOrLocationId)?.countryCodes),
          func,
          start_fy: timeRange === 'monthly' ? parseInt(rangeFrom) : 2016,
          end_fy: timeRange === 'monthly' ? parseInt(rangeTo) : dataAvailability?.planetCurrentFY,
          isOld: true
        },
        undefined,
        signal
      )
        .then(response => {
          setDates(getDateRange(timeRange === 'annual', response.dates, response.data))
          setTotalFootprint(response.data)
        })
        .catch(e => {
          console.log(e)
        })
    },
    [JSON.stringify(func), locationId, JSON.stringify(clusters), rangeFrom, rangeTo, locations.length, timeRange]
  )

  const locationIsSite = !currentLocation.isCluster && isSite(currentLocation.location)

  return (
    <ClimateExplorePageContext.Provider value={{ refrigerantFactors }}>
      <div className="KPIPage">
        <TopBar currentPage={Route.WaterExplorePage} useInFlexLayout />
        <PageHeader className="ClimateFootprintHeader " route={Route.WaterExplorePage}>
          {/* <SwitchScopeBtn setIsOld={setIsOld} isOld={isOld} /> */}
        </PageHeader>
        <div className="PageContent">
          {filterSelectors}
          <WaterGraphs
            footprint={totalFootprint}
            dates={dates}
            timeRange={timeRange}
            locationId={locationId}
            isSite={locationIsSite}
          />
        </div>
      </div>
    </ClimateExplorePageContext.Provider>
  )
}

interface GraphProps {
  footprint: ApiTotalFootprint[] | undefined
  dates: Date[] | undefined
  timeRange: TimeRange
  locationId: string
  isSite: boolean
  modalState?: () => void
  setModalActionState?: ({ isOpen, page, fiscalYear }: { isOpen: boolean; page: Route; fiscalYear: number }) => void
}

const WaterGraphs = ({ footprint, dates, timeRange, locationId, isSite }: GraphProps) => {
  const isGlobalSelector = locationId === 'ALL'
  const locationGroupingKey = isGlobalSelector ? 'countryName' : 'siteId'

  const commonChartProps = {
    generator: lineChart,
    domain: dates,
    dateFormat: timeRange === 'monthly' ? 'monthWithYear' : 'fy'
  } as const

  const { series, totals } = React.useMemo(() => {
    const waterForLocation = footprint ? footprint.filter(f => (isSite ? f.siteId === locationId : true)) : undefined
    const byType = createSeriesWithKey(
      'treatmentType',
      waterForLocation,
      dates,
      timeRange,
      waterColorMap,
      sumByUnit(GraphUnit.RawWater)
    )
    const byLocation = createSeriesByLocation(
      footprint,
      dates,
      timeRange,
      locationId,
      isSite,
      sumByUnit(GraphUnit.RawWater),
      undefined,
      true
    )
    return {
      series: { byType, byLocation },
      totals: {
        byType: getTotal(waterForLocation, GraphUnit.RawWater),
        byLocation: getTotal(waterForLocation, GraphUnit.RawWater, locationGroupingKey, _.mean, 'avg.')
      }
    }
  }, [JSON.stringify(footprint), JSON.stringify(dates)])

  return (
    <>
      <ExploreGraphCard
        heading="Water consumption by type"
        description="This visualization shows the amount and source of water used by this location."
        {...totals.byType}
      >
        <WaterByTypeGraph
          series={series.byType}
          unit={GraphUnit.RawWater}
          showDeselectAll={!isSite}
          deselectAllUnit="bars"
          {...commonChartProps}
        />
      </ExploreGraphCard>
      <ExploreGraphCard
        heading="Water consumption by location"
        description="This visualization shows the amount and source of water used by each location."
        {...totals.byLocation}
      >
        <ChartContainer
          {...commonChartProps}
          testId="water-location-chart"
          series={series.byLocation}
          lineChartConfiguration={{ focusStyle: isSite ? 'top' : 'nearest', startFromZero: false }}
          disableLegendItems={isSite}
          showDeselectAll={!isSite}
          highLightedSerieName={isSite ? series.byLocation?.find(s => s.name !== 'Other locations')?.name : undefined}
          yAxisTitle={getYAxisText(GraphUnit.RawWater)}
        />
      </ExploreGraphCard>
    </>
  )
}

interface WaterByTypeGraphProps {
  dateFormat: DateFormat
  domain: Date[] | undefined
  series: Serie[] | undefined
  unit: GraphUnit
  unitDescription?: string
  showDeselectAll: boolean
  deselectAllUnit?: string
}

export const WaterByTypeGraph: React.FC<WaterByTypeGraphProps> = ({
  dateFormat,
  domain,
  series,
  unit,
  unitDescription,
  showDeselectAll,
  deselectAllUnit
}) => {
  return (
    <ChartContainer
      testId="water-type-chart"
      dateFormat={dateFormat}
      domain={domain}
      generator={stackedBarChart('descending')}
      series={series}
      yAxisTitle={unitDescription ?? getYAxisText(unit)}
      showDeselectAll={showDeselectAll}
      tooltipSummary={date => {
        const total = _(series)
          .flatMap(vals =>
            _(vals.data)
              .filter(d => isSameDay(d.x, date))
              .value()
          )
          .sumBy('y')
        return [
          {
            title: 'Total',
            value: Number.isNaN(total) ? 'N/A' : formatNumberValue(total, true, ''),
            icon: <SumIndicator />,
            unit: unitDescription ?? getYAxisText(unit)
          }
        ]
      }}
      withDynamicFormatting
      deselectAllUnit={deselectAllUnit}
    />
  )
}

const createSeries = <D extends { readableDate: string; fiscalYear: number }>(
  data: D[],
  domain: Date[],
  interval: TimeRange,
  groupingKey: keyof D,
  dataReduceFn: (slice: D[]) => number | null,
  getSerieName: (key: string, slice: D[]) => string,
  getSerieColor: (key: string, slice: D[]) => string,
  unit?: string,
  withDynamicFormatting?: boolean
): Serie[] => {
  const isRecordForDate = <D extends { readableDate: string; fiscalYear: number }>(
    record: D,
    date: Date,
    interval: TimeRange
  ) =>
    interval === 'monthly'
      ? isSameDay(date, new Date(record.readableDate))
      : date.getFullYear() === record.fiscalYear + 1999

  const series = _(data)
    .groupBy(groupingKey)
    .entries()
    .map(([key, slice]) => ({
      name: getSerieName(key, slice),
      color: getSerieColor(key, slice),
      data: _(domain)
        .map(date => [slice.filter(record => isRecordForDate(record, date, interval)), date] as const)
        .map(([slice, date]) => [dataReduceFn(slice), date] as const)
        .map(([value, date]) => ({ y: value, x: date }))
        .filter((datapoint): datapoint is DataPoint => !_.isNull(datapoint.y) && _.isFinite(datapoint.y))
        .value(),
      unit: unit
    }))
    .value()

  if (!withDynamicFormatting) return series

  const isMoreThenTen = Math.max(...series.map(serie => Math.max(...serie.data.map(item => item.y)))) > 10

  return series.map(serie => {
    return {
      ...serie,
      data: serie.data.map(item => ({ ...item, y: isMoreThenTen ? Math.round(item.y) : item.y }))
    }
  })
}

export const createSeriesWithKey = <K extends keyof Omit<ApiTotalFootprint, 'selectable'>>(
  groupingKey: K,
  data: ApiTotalFootprint[] | undefined,
  dates: Date[] | undefined,
  timeRange: TimeRange,
  colors: Record<Required<Omit<ApiTotalFootprint, 'selectable'>>[K], string>,
  dataReduceFn: (dataForDate: ApiTotalFootprint[]) => number | null,
  unit?: string,
  withDynamicFormatting?: boolean
) => {
  if (data === undefined || dates === undefined) return undefined

  return createSeries(
    data,
    dates,
    timeRange,
    groupingKey,
    dataReduceFn,
    key => key,
    key => colors[key as Required<ApiTotalFootprint>[K]],
    unit,
    withDynamicFormatting
  )
}

export const createSeriesByLocation = (
  data: ApiTotalFootprint[] | undefined,
  dates: Date[] | undefined,
  timeRange: TimeRange,
  selectedLocationId: string,
  isSite: boolean,
  dataReduceFn: (dataForDate: ApiTotalFootprint[]) => number | null,
  byLocationUnit?: string,
  withDynamicFormatting?: boolean
): Serie[] | undefined => {
  if (data === undefined || dates === undefined) return undefined

  const wildcardLabel = 'Other locations'
  const isGlobalSelector = selectedLocationId === 'ALL'
  const groupingKey = isGlobalSelector ? 'countryName' : 'siteId'
  const theme = colorScheme(_.uniq(data.map(d => d[groupingKey])))

  const getColorByLocationId = isSite
    ? (locationId: string) => (locationId === selectedLocationId ? colours.purple2 : colours.lightBlue4)
    : (locationId: string) => theme[locationId]

  const getSerieName = (_: string, data: ApiTotalFootprint[]) => {
    const locationId = data[0].siteId
    const fullName = data[0][isGlobalSelector ? 'countryName' : 'siteName']
    const shortName = fullName?.split(' ')[0] ?? ''
    if (isSite) {
      return locationId === selectedLocationId ? shortName : wildcardLabel
    } else {
      return isGlobalSelector ? fullName : shortName
    }
  }

  const unit = byLocationUnit === 'relative' || byLocationUnit === 'relativeraw' ? '%' : undefined

  return createSeries(
    data,
    dates,
    timeRange,
    groupingKey,
    dataReduceFn,
    getSerieName,
    getColorByLocationId,
    unit,
    withDynamicFormatting
  ).sort((a, b) => {
    if (a.name === wildcardLabel) return -1
    if (b.name === wildcardLabel) return +1
    return 0
  })
}

function getDataField(graphUnit: GraphUnit) {
  switch (graphUnit) {
    case GraphUnit.ConvertedUnits:
      return 'footprint'
    case GraphUnit.RelativeUnits:
      return 'footprintPerArea'
    case GraphUnit.RawUnits:
    case GraphUnit.RawWaste:
    case GraphUnit.RawWasteKg:
    case GraphUnit.RawWater:
    case GraphUnit.MassKg:
      return 'quantity'
    default:
      return 'quantityPerArea'
  }
}

function getTotal(
  footprint: ApiTotalFootprint[] | undefined,
  unit: GraphUnit,
  groupingKey?: keyof ApiTotalFootprint,
  reduceFn?: (data: number[]) => number,
  relativity?: 'total' | 'avg.'
): { amount: string; unit: string } {
  if (!footprint) return { amount: '', unit: '' }

  const field = getDataField(unit)
  const data = _(footprint)
    .groupBy(record => (groupingKey ? `${record[groupingKey]}/${record.readableDate}` : record.readableDate))
    .values()
    .map(groupedRecords => _.sumBy(groupedRecords, field))

  const reduced = reduceFn?.(data.value())
  const byAverage = data.mean()
  const bySum = data.sum()

  const fmtNumber = (value: number): string => {
    return formatNumberValue(value, true, unit).toString()
  }

  switch (unit) {
    case GraphUnit.ConvertedUnits:
      return { amount: fmtNumber(reduced ?? bySum / 1000), unit: `tonnes CO2e ${relativity ?? 'total'}` }
    case GraphUnit.RelativeUnits:
      return { amount: formatRelativeNumber(reduced ?? byAverage), unit: `kg CO2e / m2 ${relativity ?? 'avg.'}` }
    case GraphUnit.RawWater:
      return { amount: fmtNumber(reduced ?? bySum), unit: `litres ${relativity ?? 'total'}` }
    case GraphUnit.RawWaste:
      return { amount: fmtNumber(reduced ?? bySum), unit: `tonnes ${relativity ?? 'total'}` }
    case GraphUnit.RawWasteKg:
      return { amount: fmtNumber(reduced ?? bySum), unit: `kg ${relativity ?? 'total'}` }
    case GraphUnit.MassKg:
      return { amount: fmtNumber(reduced ?? bySum), unit: `kg ${relativity ?? 'total'}` }
    case GraphUnit.RawUnits:
      return { amount: fmtNumber(reduced ?? bySum), unit: `kWh ${relativity ?? 'total'}` }
    case GraphUnit.RecycledWaste:
    case GraphUnit.Landfill:
      return { amount: formatPercentage(reduced ?? byAverage), unit: `% avg.` }
    case GraphUnit.RelativeRawUnits:
      return { amount: '', unit: '' }
  }
}

export function sumByUnit(graphUnit: GraphUnit) {
  return (data: ApiTotalFootprint[]) => {
    if (data.length === 0) return null

    const field = getDataField(graphUnit)
    const total = _.sumBy(data, field)

    return graphUnit !== GraphUnit.ConvertedUnits ? total : total / 1000
  }
}
