import { formatLocale } from 'd3-format'
import { interpolateSinebow } from 'd3-scale-chromatic'

export const colors = [
  '#7530BD',
  '#FDC22D',
  '#E66F68',
  '#1AAB9F',
  '#1AAF40',
  '#608B99',
  '#1FBCD8',
  '#FC1262',
  '#CB40C5',
  '#FF764A',
  '#03A9F4',
  '#008CB1'
]

export const fadedColors = ['#CBAEEA', '#FEE8AE', '#F3BCB9', '#FFCCBC', '#B3E5FC', '#00dff8']

const nonBreakingSpace = '\xa0'
const locale = formatLocale({ thousands: nonBreakingSpace, grouping: [3], decimal: '.', currency: ['', ''] })
const defaultFormat = locale.format(',')
export const defaultValueFormatter = (value: number) => {
  return defaultFormat(Math.round(value))
}

export const createColorScheme =
  (baseColors: string[]) =>
  (items: string[]): Record<string, string> => {
    const sorted = items.sort()
    const length = sorted.length
    const colorGenerator =
      sorted.length > baseColors.length ? (i: number) => interpolateSinebow(i / length) : (i: number) => baseColors[i]
    const f = sorted.reduce((result, d, index) => ({ ...result, [d]: colorGenerator(index) }), {})
    return f
  }

export const colorScheme = createColorScheme(colors)
