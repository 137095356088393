import React from 'react'
import _ from 'lodash'
import { isSameDay } from 'date-fns'

import { formatAbsoluteNumber, formatRelativeNumber } from '../../../components/Utils/format'
import { SumIndicator } from '../../../components/BaseGraphs/Indicators'
import { Serie } from '../../../components/BaseGraphs/ChartContainer'

import { formatNumberValue, SummaryItem } from '../../../components/BaseGraphs/Tooltip'
import { getTotalReduction } from './utils/getTotalReduction'

export const TooltipSummaryByType =
  (series: Serie[], goalSerie: Serie, actionSerie: Serie, isConvertedUnits: boolean, isOld?: boolean) =>
  (date: Date): SummaryItem[] | undefined => {
    const total = _(series)
      .flatMap(vals =>
        _(vals.data)
          .filter(d => isSameDay(d.x, date))
          .value()
      )
      .sumBy('y')
    const rounded = isConvertedUnits ? formatNumberValue(total, true, '') : formatRelativeNumber(total)

    // FY30 Special case
    if (date.getFullYear() === 2029 && !isOld) {
      const totalReduction = getTotalReduction(actionSerie.data, goalSerie.data)

      const roundedReduction = isConvertedUnits
        ? formatAbsoluteNumber(totalReduction)
        : formatRelativeNumber(totalReduction)
      const gapToGoal = totalReduction - Number(_.last(goalSerie.data)?.y)
      const roundedGap = isConvertedUnits ? Math.round(gapToGoal) : gapToGoal.toFixed(1)

      const afterReduction = {
        title: 'After change',
        value: Number.isNaN(totalReduction) ? 'N/A' : roundedReduction,
        onTrack: gapToGoal <= 0,
        icon: <SumIndicator />
      }

      const gap = [
        {
          title: 'Gap to goal',
          value: formatAbsoluteNumber(Number(roundedGap)),
          icon: '#DD2A1A'
        }
      ]

      return [afterReduction, ...(gapToGoal <= 0 ? [] : gap)]
    }

    return !Number.isNaN(total) && total > 0
      ? [
          {
            title: 'Total',
            value: rounded,
            icon: <SumIndicator />
          }
        ]
      : undefined
  }
