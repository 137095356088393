import classNames from 'classnames'
import React from 'react'
import { ArrowIcon } from '../ArrowIcon'

import './TimeRangeSelector.scss'
import colours from '../../Colours.module.scss'

export interface TimeRangeSelectorProps<T extends string> {
  currentValue: T
  options: readonly T[]
  className?: string
  onValueChanged(value: T): void
}

export function TimeRangeSelector<T extends string>({
  currentValue,
  options,
  className,
  onValueChanged
}: TimeRangeSelectorProps<T>) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <div className={classNames('TimeRangeSelector', className)} onClick={() => setOpen(!open)}>
        <div className="TimeRangeSelector-text">{currentValue}</div>
        <ArrowIcon angle={open ? 0 : 180} width={24} height={24} color={colours.offWhite1} />
        <div className={classNames('TimeRangeSelectorMenu', { Open: open })}>
          {options.map(opt => (
            <div
              className={classNames('TimeRangeOption', { Selected: currentValue === opt })}
              onClick={() => onValueChanged(opt)}
              key={opt}
            >
              {opt}
            </div>
          ))}
        </div>
      </div>
      {open && <div className="ClickCatcher" onClick={() => setOpen(false)} />}
    </>
  )
}
