import React from 'react'
import { PortalWrapper } from '../../../components/Utils/PortalWrapper'
import {
  ChartTooltipProps,
  createTooltipIcon,
  formatDateForTooltip,
  formatNumberValue
} from '../../../components/BaseGraphs/Tooltip'
import classnames from 'classnames'
import { upperFirst } from 'lodash'
import { LanguageContext } from '../../../context'
import { ClimateExplorePageContext } from './ClimateExplorePage'

export const RefrigerantsTooltip: React.FC<ChartTooltipProps> = props => {
  const { x, y, data, dateFormat, subHeading } = props

  const { refrigerantFactors } = React.useContext(ClimateExplorePageContext)

  if (!refrigerantFactors) return null

  const xPlacement = x < window.innerWidth / 2 ? x + 60 : x - 420
  const lang = React.useContext(LanguageContext)

  const heading = data.tooltipContent?.heading
    ? data.tooltipContent.heading
    : data.tooltipContent?.date && dateFormat
    ? formatDateForTooltip(data.tooltipContent.date, dateFormat, lang)
    : data.tooltipProps?.heading

  const summary = data.tooltipContent ? data.tooltipContent.summary : data.tooltipProps.summary
  const subtitle = data.tooltipProps ? data.tooltipProps.subtitle : subHeading

  const body = data.tooltipContent
    ? [
        data.tooltipContent.items
          .filter(i => Number.isFinite(i.value))
          .map(i => ({
            title: i.name === 'Planned activities' ? 'Total actions' : i.name,
            value: formatNumberValue(i.value, true, i.unit || ''),
            color: i.color && i.color.length === 0 ? undefined : i.color,
            icon: i.icon ? i.icon : undefined,
            actionTypes: i.actionTypes
          }))
      ]
    : data.tooltipProps.body

  const items = body?.map((body, index) => (
    <ul
      key={`b${index}`}
      className={`ItemsList ${props.graphName === 'Climate Footprint – Zero Emission Deliveries' ? 'Deliveries' : ''}`}
    >
      {body
        .sort((itemFirst, itemSecond) => refrigerantFactors[itemSecond.title] - refrigerantFactors[itemFirst.title])
        .map(item => (
          <>
            <li key={item.title} className={item.title === `Difference to ${props.previousFY}` ? 'Difference' : ''}>
              <div className="LegendContainer">
                {createTooltipIcon(item.color)}
                <span className="Title">{upperFirst(item.title)}</span>
              </div>
              <div className="Measurement">{Math.round(refrigerantFactors[item.title])}</div>
              <div className="Measurement">{item.value}</div>
            </li>
          </>
        ))}
    </ul>
  ))

  const summaryItems = summary?.map(s => (
    <li key={s.title}>
      <div className="LegendContainer">
        <span className="Title">{upperFirst(s.title)}</span>
      </div>
      <div />
      <div className="Summary">
        <span className="Unit">{s.unit}</span>
        <span className={classnames('Measurement', { OnTrack: s.onTrack === true, NotOnTrack: s.onTrack === false })}>
          {s.value}
        </span>
      </div>
    </li>
  ))

  return (
    <PortalWrapper>
      <div
        style={{
          position: 'absolute',
          left: `${xPlacement}px`,
          top: `${y + window.scrollY - 50}px`,
          pointerEvents: 'none'
        }}
      >
        <div className="TooltipWrapper">
          <div className={classnames('Tooltip', 'TooltipRefrigerants')}>
            {heading && <TooltipHeading heading={heading} additionalTitle="Emission factor" subtitle={subtitle} />}
            {items}
            {summaryItems && summaryItems.length > 0 && <ul className="ItemsList Summary">{summaryItems}</ul>}
          </div>
        </div>
      </div>
    </PortalWrapper>
  )
}

const TooltipHeading = ({
  heading,
  subtitle,
  additionalTitle
}: {
  heading: string
  subtitle?: string
  additionalTitle: string
}) => (
  <div className="Heading">
    <span>{heading}</span>
    <span style={{ textAlign: 'right' }}>{additionalTitle}</span>
    <span style={{ textAlign: 'right' }}>{subtitle}</span>
  </div>
)
