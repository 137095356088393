import React from 'react'
import Skeleton from '@ingka/skeleton'

import './LoadingSkeleton.scss'
import classNames from 'classnames'

interface LoadingSkeletonProps {
  className?: string
}

export const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({ className }) => (
  <div className={classNames('LoadingSkeleton', className)}>
    <Skeleton />
  </div>
)
