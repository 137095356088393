import Select, { OptionTypeBase, StylesConfig } from 'react-select'
import React from 'react'

import { ArrowSelectDropdownIndicator, ArrowSelectDropdownIndicatorProps } from './ArrowIcon'
import { Tooltip } from './Tooltip'

import './UnitSelector.scss'
import colours from '../Colours.module.scss'

const selectStyle: StylesConfig<OptionTypeBase, false> = {
  container: provided => ({
    ...provided,
    minWidth: '200px',
    width: 'auto'
  }),
  menu: provided => ({
    ...provided,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1), 0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderStyle: 'none',
    borderRadius: '22px',
    overflow: 'hidden'
  }),
  control: (provided, state) => {
    const boxShadowAndBorder = state.isDisabled
      ? { border: '1.5px solid #F1F1F1' }
      : {
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1), 0px 0px 5px rgba(0, 0, 0, 0.1)',
          borderStyle: 'none'
        }
    return {
      ...provided,
      ...boxShadowAndBorder,
      backgroundColor: colours.white,
      height: '40px',
      borderStyle: 'none',
      borderRadius: '22px'
    }
  },
  option: (provided, state) => ({
    ...provided,
    backgroundColor: colours.white,
    color: colours.black,
    fontWeight: state.isSelected ? 'bold' : 'normal',
    paddingLeft: '16px',
    height: '40px',
    lineHeight: '28px'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    paddingRight: '12px'
  }),
  singleValue: provided => ({
    ...provided,
    paddingLeft: '16px',
    marginLeft: '0px'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '2px 0'
  })
}

export enum GraphUnit {
  ConvertedUnits = 'converted',
  RelativeUnits = 'relative',
  RawUnits = 'raw',
  RelativeRawUnits = 'relativeraw',
  RawWaste = 'rawwaste',
  RawWasteKg = 'rawwastekg',
  RawWater = 'rawwater',
  RecycledWaste = 'recycledwaste',
  Landfill = 'landfill',
  MassKg = 'masskg'
}

const formatOptionLabel = (value: GraphUnit) => {
  switch (value) {
    case GraphUnit.RelativeUnits:
      return <span>Relative (kg CO2e / m&sup2;)</span>

    case GraphUnit.RawUnits:
      return <span>Raw energy (kWh)</span>

    case GraphUnit.RelativeRawUnits:
      return <span>Energy efficiency (kWh / m&sup2;)</span>

    case GraphUnit.RawWaste:
      return <span>Waste (tonnes)</span>

    case GraphUnit.RawWasteKg:
      return <span>Waste (kg)</span>

    case GraphUnit.RawWater:
      return <span>Raw values (litres)</span>

    case GraphUnit.RecycledWaste:
      return <span>Recycling share (%)</span>

    case GraphUnit.Landfill:
      return <span>Landfill share (%)</span>

    case GraphUnit.MassKg:
      return <span>Mass (kg)</span>

    case GraphUnit.ConvertedUnits:
    default:
      return <span>Footprint (tonnes CO2e)</span>
  }
}

const defaultOptions = [
  { label: formatOptionLabel(GraphUnit.ConvertedUnits), value: GraphUnit.ConvertedUnits },
  { label: formatOptionLabel(GraphUnit.RelativeUnits), value: GraphUnit.RelativeUnits },
  { label: formatOptionLabel(GraphUnit.RawUnits), value: GraphUnit.RawUnits },
  { label: formatOptionLabel(GraphUnit.RelativeRawUnits), value: GraphUnit.RelativeRawUnits }
]

export interface UnitSelectorProps {
  enabled: boolean
  value: GraphUnit
  options?: GraphUnit[]
  disabledText?: string
  onChange(value: GraphUnit): void
  menuPlacement?: 'top' | 'bottom'
}

const UnitSelectorDropdownIndicator: React.FC<{
  selectProps: ArrowSelectDropdownIndicatorProps
}> = ({ selectProps }) => <ArrowSelectDropdownIndicator {...selectProps} />

export const UnitSelector = ({ enabled, value, options, disabledText, onChange, menuPlacement }: UnitSelectorProps) => {
  const optionObjects = options
    ? options.map(value => ({ label: formatOptionLabel(value), value: value }))
    : defaultOptions

  return (
    <Selector
      enabled={enabled}
      value={{ label: formatOptionLabel(value), value: value }}
      options={optionObjects}
      disabledText={disabledText}
      onChange={x => onChange(x)}
      menuPlacement={menuPlacement}
    />
  )
}

UnitSelector.displayName = 'UnitSelector'

interface ValueWithLabel<T> {
  label: string | JSX.Element
  value: T
}

interface SelectorProps<T> {
  enabled: boolean
  value: ValueWithLabel<T>
  options: ValueWithLabel<T>[]
  disabledText?: string
  onChange(value: T): void
  menuPlacement?: 'top' | 'bottom'
}

export function Selector<T>({ enabled, value, options, disabledText, onChange, menuPlacement }: SelectorProps<T>) {
  return (
    <div className="UnitSelector">
      <Tooltip className="UnitSelectorContainer" tooltipText={disabledText}>
        <Select
          classNamePrefix="UnitSelector"
          styles={selectStyle}
          options={options}
          isDisabled={!enabled}
          value={value}
          isSearchable={false}
          components={{
            DropdownIndicator: UnitSelectorDropdownIndicator,
            IndicatorSeparator: () => null
          }}
          formatOptionLabel={param => param.label}
          onChange={x => onChange(x?.value)}
          menuPlacement={menuPlacement ?? 'bottom'}
        />
      </Tooltip>
    </div>
  )
}
