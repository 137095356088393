import React from 'react'
import classNames from 'classnames'
import styles from './SimpleLegend.module.scss'

interface Data {
  name: string
  color: string
  value?: number
}

interface SimpleLegenedProps {
  className?: string
  series: Data[]
}

export const SimpleLegend: React.FC<SimpleLegenedProps> = ({ className, series }) => {
  return series.length > 0 ? (
    <div className={classNames(styles.SimpleLegend, className)} data-testid="GraphLegend">
      {series.map(serie => (
        <div className={styles.SimpleLegendContainer} key={serie.name}>
          <span className={styles.SimpleLegendDot} style={{ backgroundColor: serie.color }} />
          {serie.name}
        </div>
      ))}
    </div>
  ) : null
}
