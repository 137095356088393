import React from 'react'

export const KpiCustomerDeliveriesModalContent = () => (
  <>
    <p>
      Climate footprint - Zero emission deliveries measurement shows the impact that Ingka&#39;s deliveries to customers
      have on climate change.
    </p>
    <p>
      The key initative to reduce climate impact of deliveries is the Zero Emission Deliveries iniatiative. The main
      graph visualises the KPI Zero Emission Delivery Share, defined as % share of total deliveries in last mile
      performed with a zero-emission vehicle.
    </p>
    <p>The smaller graphs visualises the Climate footprint of Last mile Truck Home deliveries. </p>
    <ul>
      <li>Climate Footprint of Last Mile Truck Home Deliveries total tonnes CO2e YTD </li>
      <li>
        <b>Emissions per order:</b> kg CO2e/order. Total kg CO2e divided by total number of orders.{' '}
      </li>
      <li>
        <b>Emissions per km:</b> g CO2e/km. Total kg CO2e divided by total number of km.{' '}
      </li>
    </ul>

    <p>
      The scope of the KPI is last mile home deliveries done by truck, representing high share of the total climate
      footprint of deliveries. Other delivery flows, such as parcel deliveries, trunkline/linehaul, returns etc. will be
      added at a later stage, step by step until all delivery flows are included.
    </p>

    <h3>How are emissions estimated? </h3>

    <p>We estimate climate impact of customer deliveries (in tonnes CO2e) with the following high-level formula:</p>

    <p>
      Total tonnes CO2e = Distance (km) * Fuel consumption (L/km, kWh/km) * Emission factor (kg CO2e/L, kg CO2e/kWh)
    </p>
    <p>
      Distance is calculated by estimating routes, using country specific operational set-up. Estimated distance is then
      combined with fuel consumption of vehicle and fuel type used in each country and corresponding emission factor to
      calculate total CO2e emissions.
    </p>

    <h3>Why is it measured in Carbon Dioxide Equivalent (CO2e)? </h3>
    <p>
      Carbon dioxide equivalent or (CO2e) is a term for describing different greenhouse gases in a common unit. It is a
      standard unit for measuring carbon footprints and the best way to quantify our impact on climate change.{' '}
    </p>

    <h3>Where is the data coming from? How often it is updated? </h3>
    <p>Data is uploaded on a monthly basis. The raw data includes: </p>
    <ul>
      <li>Transactional order data from Centiro </li>
      <li>Fuel flag data from Centiro </li>
      <li>Country conditions provided by CFF ZE PIM in each country </li>
      <li>Emission factors from GLEC and IEA </li>
    </ul>

    <p>Raw data is calculated in a data product in the Digital DSM domain. </p>

    <h3>How to contribute to this KPI? </h3>

    <p>There are many ways to contribute to this KPI, by choosing or influencing the following: </p>
    <ul>
      <li>Type of fleet used (EV vehicles etc.) </li>
      <li>Service offer to customers (range of days vs specific time slot) </li>
      <li>Operational set-up (mix of orders of different service types and timeslots) </li>
      <li>Efficiency in delivery operations </li>
      <li>Delivery optimization </li>
    </ul>
  </>
)
