import React from 'react'
import classNames from 'classnames'
import { Breadcrumbs } from './Breadcrumbs'
import { Route } from '../routes'

import './PageHeader.scss'

interface PageHeaderProps {
  className: string
  route: Route
}

export const PageHeader: React.FC<PageHeaderProps> = ({ className, children, route }) => {
  return (
    <div className={classNames('Header', className)}>
      <div className="PageName">
        <Breadcrumbs route={route} />
        {children}
      </div>
    </div>
  )
}
