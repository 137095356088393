import React from 'react'
import changeTableImage from '../../images/HowIsThisCalculated/SocialImpactChangeTable.png'
import neighbourhoodImage from '../../images/HowIsThisCalculated/SocialImpactNeighbourhood.png'
import triangleImage from '../../images/HowIsThisCalculated/SocialImpactTriangle.png'

export const KpiSocialImpactModalContent = () => (
  <>
    <p className="IntroParagraph" data-testid="social-impact-modal-intro-para">
      Our vision is to create a better every day for the many people. As part of our journey to become people and planet
      positive. Fair and Equal is one of the strategic topic areas. The ambition is{' '}
      <strong>to create a positive social impact for everyone across the value chain and beyond by 2030</strong>. We are
      committed to being an inclusive business. That means to grow the business by providing opportunities for the many
      people.
    </p>
    <p>
      We have decided to begin our journey by measuring the social impact of the many of the initiatives done at local
      and country level to support people experiencing (or at risk of) poverty and social exclusion.
    </p>
    <h3>What metrics are we following?</h3>
    <p>We are measuring two indicators:</p>
    <ol>
      <li>
        <strong>Number of people positively impacted</strong> through initiatives with outcome levels – transform and
        improve. This is the indicator selected as Social Impact KPI.
      </li>
      <li>
        <strong>Total number of people supported</strong> through all the initiatives developed by Ingka Group (per
        level of outcome).
      </li>
    </ol>
    <h3>What is the outcome level?</h3>
    <p>
      We are doing different types of initiatives across the world – from reactive donations to support our communities
      in times of crisis (e.g. COVID, flooding) to partnering with social enterprises as suppliers to deliver products
      and services to our customers (e.g. sewing services, last mile collection points).
    </p>
    <p>
      Each type of activity has different impact on people’s livelihood after Ingka’s intervention –. In order to
      qualify the initiatives, we are using three level of outcomes based on an external methodology (
      <a className="Underlined" href="https://b4si.net">
        Business for Social Impact
      </a>
      ):
    </p>
    <ol>
      <li>
        <strong>Transform</strong>: people who can report an enduring change in their circumstances, or for whom a
        change can be observed, as a result of the improvements made (e.g. got a job as a result of the intervention;
        the person has improved their employability skills, etc.)
      </li>
      <li>
        <strong>Improve</strong>: people who can report some substantive improvement in their lives as a result of the
        activity (lasts at least 1 year) (e.g., people with a temporary home-solution, temporary capability).
      </li>
      <li>
        <strong>Connect</strong>: people reached by an activity who can report some limited change as a result of an
        activity (e.g., Donations of small items, food and initiatives with short term effect).
      </li>
    </ol>
    <p>
      The outcome level of an initiative will be calculated automatically by selecting the duration of the change and
      the level of change on the people’s livelihoods.
    </p>
    <img src={changeTableImage} alt="Table describing levels and durations of change" />
    <p>
      We have selected the <strong>number of people positively impacted</strong> (aggregating number of people from
      initiatives with outcomes level – transform and improved) as{' '}
      <strong>Social Impact Key Performance Indicator</strong> because we want to move from mainly short-term impact
      initiatives towards initiatives that create long-lasting positive changes in our neighbourhoods.
    </p>
    <h3>How we want to create social impact. New social impact approach Ingka / IKEA x neighbourhoods is coming</h3>
    <p>
      During FY21, we have re-designed the social impact approach in the local communities together internal and
      external stakeholders. The new social impact approach & framework are called:{' '}
      <strong>Ingka / IKEA x neighbourhoods</strong>.
    </p>
    <p>
      Our vision is to create a better everyday life for the many people. We know that better homes create better life
      and a better planet. However, many people in our communities can not access to better homes and a better life,
      despite the access to adequate standard of living, including housing is a human right.
    </p>
    <img src={triangleImage} alt="Better life triangle" />
    <p>
      We have decided to focus on enabling people experiencing (or risk of) poverty and social inclusion to develop
      their potential and capacity to have a better everyday life. We will do by increasing their access to better
      homes, opening pathways for them to decent work and enabling the many people to participate towards more inclusive
      neighbourhoods.
    </p>
    <img src={neighbourhoodImage} className="NeighbourhoodImage" alt="Ingka / IKEA x neighbourhoods in context" />
    <p>
      Ingka / IKEA x neighbourhoods guide us on how to create a positive social impact through the business and how to
      engage the many people - co-workers, customers, citizens and our partners - to co-create solutions by transforming
      our business and the customer meeting places and units into a force for good in the local neighbourhoods.
    </p>
    <h3>
      Why are we measuring this? It is part of our ambition to become a people and planet positive company by 2030
    </h3>
    <p>
      As part of the People and Planet Positive strategy, we are committed to creating a positive impact for everyone
      across the value chain and beyond by 2030.
    </p>
    <p>
      We are at the beginning of the exciting journey to measure our social impact footprint. We will start in the
      neighbourhoods. Then, we will develop new metrics across the value chain.
    </p>
    <p>
      <a
        href="https://iweof.sharepoint.com/:b:/t/o365g_analyticandimpact_iossemal/EXWQbjXW4hZApv2eObxG1WABHMxjzwLnQ017X42Q62KBSQ"
        className="Underlined"
      >
        Guideline for the reporting process of the positive social impact in the local neighbourhoods
      </a>
    </p>
    <h3>Where is the data coming from? How often it is updated?</h3>
    <ul>
      <li>
        The data is collected from the Google Form Survey “Ingka x Neighbourhoods - Data Collection”, filled in by units
        / meeting places, after a social impact initiative has ended.
      </li>
      <li>The data at 4P is updated on a monthly basis (when there are new social impact initiatives happening).</li>
    </ul>
    <h3>How the KPI values are calculated?</h3>
    <ul>
      <li>
        Social Impact KPI (People positively impacted) = nr of people supported by social impact initiatives with the
        outcome levels transform and improve.
      </li>
      <li>
        People Supported PI (Total People supported) = nr of people supported by social impact initiatives with all the
        outcome levels (transform, improve and connect).
      </li>
    </ul>
    <h3>How to contribute to this KPI?</h3>
    <p>To contribute to this KPI, one can do it by increasing the number of</p>
    <ul>
      <li>Participants in Skills for Employment initiative </li>
      <li>People supported by both planned and emergency social impact initiatives </li>
      <li>People hired through business partnership with social enterprises.</li>
    </ul>
  </>
)
