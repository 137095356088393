import React from 'react'
import { FunctionSelectorTip } from './FunctionSelectorTip'

export const CountryRankModalContent = () => (
  <>
    <div className="IntroParagraph">
      <p>
        Ranking on the Leaderboard shows how a selected country is doing compared to other countries. The Leaderboard
        rank is based on a country&apos;s performance across all sustainability KPIs. Country ranking is calculating
        performance across all sites within a country based on what function/functions have been selected.
      </p>
      <p>
        The Leaderboard is calculated tertial-to-date and the competition is reset with each new tertial. The
        competition ends when all KPI data has been received for the tertial based on standard data reporting lags (e.g.
        2-month lag for climate footprint).
      </p>
    </div>
    <h3>How do we calculate the country rank?</h3>
    <p>Country performance is the standardised, weighted average of the following (K)PIs:</p>
    <h4>Recycling Rate (% of waste recycled)*</h4>
    <p>(KPI weighting: 25%)</p>
    <h4>Climate Footprint (CO2e/m²)</h4>
    <p>(KPI weighting: 25%)</p>
    <h4>P+PP Sales Share (% of total sales)</h4>
    <p>(KPI weighting: 50%)</p>
    <br />
    <p>
      *The unit of Recycling Rate KPI is tonnes of non-recycled waste. However, for a fair comparison in the
      leaderboard, we use a relative measure PI called recycling rate %.
    </p>
    <FunctionSelectorTip text="view rankings within individual functions" />
  </>
)
