import React from 'react'

export const KpiChangeMakersModalContent = () => (
  <>
    <p className="IntroParagraph" data-testid="change-makers-modal-intro-para">
      Ingka / IKEA x neighbourhoods guide us on how to create a positive social impact through the business and how to
      engage the many people — co-workers, customers, citizens and our partners — to co-create solutions by transforming
      our business and the customer meeting places and units into a force for good in the local neighbourhoods.
    </p>
    <p>When the many people are engaged in creating positive social impact they are change-makers.</p>
    <p>
      <b>Change-makers are co-workers, customers, and citizens</b> engaged to support people in the local neighborhoods
      through all the social impact initiatives developed / supported by Ingka Group, in the frame of Ingka/IKEA
      Neighborhoods.
      <ul>
        <li>
          <b>Co-Workers engaged</b>: Number of co-workers actively involved in initiative during paid working time. This
          number will not include co-workers reached with the communication plans or advocacy campaigns.
        </li>
        <li>
          <b>Customers and citizens engaged</b>: The number of customers that are actively involved in the initiative.
          This number will not include consumers / people reached with the communication plans or advocacy campaigns.
        </li>
      </ul>
    </p>

    <h3>Where is the data coming from? How often it is updated?</h3>
    <div>
      <ul>
        <li>
          The data is collected from the Google Form Survey “Ingka x Neighbourhoods - Data Collection”, filled in by
          units / meeting places, after a social impact initiative that has the engagement of change-makers (co-workers,
          customers or citizens) has ended.
        </li>
        <li>
          The data at 4P is updated every month (if there are new social impact initiatives with the engagement of
          change-makers (co-workers, customers or citizens) happening).{' '}
        </li>
      </ul>
    </div>

    <h3>How to contribute to this KPI?</h3>
    <div>
      <ul>
        <li>By promoting the engagement of co-workers and customers in social impact initiatives.</li>
      </ul>
    </div>
  </>
)
