import React from 'react'
import { ChartContainer, isDataPoint } from '../../../components/BaseGraphs/ChartContainer'
import { stackedBarChart } from '../../../components/BaseGraphs/GraphUtil'
import { isArray } from 'lodash'
import colours from '../../../Colours.module.scss'
import { CustomerTravelContext } from './CustomerTravelProvider'
import { subtractOneYear, toDomainDay } from './FootprintByTransportTypeKPI'
import { CustomerTravelFootprint } from '../../../../api/src/types/climate'
import { formatRelativeNumber } from '../../../components/Utils/format'
import { useDynamicLabel } from './data/useDynamicLabel'

const prevFYDomainDay = 2
const currFYDomainDay = 16
const emptyDomain = 28

type DataWithDateType = CustomerTravelFootprint & { date: Date }

const getPercents = (data: DataWithDateType[], scope: string, key: keyof CustomerTravelFootprint) => {
  return data.map(item => {
    const sum =
      Number(item[`${scope}_people_car` as keyof CustomerTravelFootprint]) +
      Number(item[`${scope}_people_public` as keyof CustomerTravelFootprint]) +
      Number(item[`${scope}_people_moto` as keyof CustomerTravelFootprint]) +
      Number(item[`${scope}_people_foot` as keyof CustomerTravelFootprint])
    return {
      x: subtractOneYear(item.date),
      y: (100 * Number(item[key as keyof CustomerTravelFootprint])) / sum
    }
  })
}

export const PeopleByTransportTypeKPI = () => {
  const { footprint, dates } = React.useContext(CustomerTravelContext)

  const personLabel = useDynamicLabel()

  if (footprint.length === 0) return null

  const currentFY = footprint[footprint.length - 1].fiscalYear

  const dataPrevFy = footprint.map(toDomainDay(currentFY + 2000, prevFYDomainDay))
  const dataCurrFy = footprint.map(toDomainDay(currentFY + 2000, currFYDomainDay))

  const series = !isArray(footprint)
    ? undefined
    : footprint.length === 0
    ? []
    : [
        {
          name: `Car FY${currentFY}`,
          color: colours.lightBlue11,
          data: getPercents(dataCurrFy, 'curr', 'curr_people_car').filter(isDataPoint),
          unit: '%'
        },
        {
          name: `Public transport FY${currentFY}`,
          color: colours.blue9,
          data: getPercents(dataCurrFy, 'curr', 'curr_people_public').filter(isDataPoint),
          unit: '%'
        },
        {
          name: `Motorcycle FY${currentFY}`,
          color: colours.orange3,
          data: getPercents(dataCurrFy, 'curr', 'curr_people_moto').filter(isDataPoint),
          unit: '%'
        },
        {
          name: `Foot and Bike FY${currentFY}`,
          color: colours.green3,
          data: getPercents(dataCurrFy, 'curr', 'curr_people_foot').filter(isDataPoint),
          unit: '%'
        },
        {
          name: `Car FY${currentFY - 1}`,
          color: colours.lightBlue9,
          data: getPercents(dataPrevFy, 'prev', 'prev_people_car').filter(isDataPoint),
          unit: '%'
        },
        {
          name: `Public transport FY${currentFY - 1}`,
          color: colours.lightBlue10,
          data: getPercents(dataPrevFy, 'prev', 'prev_people_public').filter(isDataPoint),
          unit: '%'
        },
        {
          name: `Motorcycle FY${currentFY - 1}`,
          color: colours.orange4,
          data: getPercents(dataPrevFy, 'prev', 'prev_people_moto').filter(isDataPoint),
          unit: '%'
        },
        {
          name: `Foot and Bike FY${currentFY - 1}`,
          color: colours.green4,
          data: getPercents(dataPrevFy, 'prev', 'prev_people_foot').filter(isDataPoint),
          unit: '%'
        }
      ]

  const domain = dates?.reduce((result, current) => {
    const date = new Date(current).setFullYear(currentFY + 2000 - 1)

    const dateFirst = new Date(date)
    const dateSecond = new Date(date)
    const dateEmpty = new Date(date)
    dateFirst.setDate(prevFYDomainDay)
    dateSecond.setDate(currFYDomainDay)
    dateEmpty.setDate(emptyDomain)

    return [...result, new Date(dateFirst), new Date(dateSecond), new Date(dateEmpty)]
  }, [] as Date[])

  const [carShareSum, carShareSumCount] = getPercents(dataCurrFy, 'curr', 'curr_people_car')
    .filter(isDataPoint)
    .reduce(
      (result, current) => {
        if (!current.y) return result
        return [result[0] + current.y, result[1] + 1]
      },
      [0, 0]
    )

  return (
    <div className="CardContainer">
      <div className="CardHeading FirstItem">
        Share of {personLabel.multiple} by Transport Type
        <div className="Right">
          <span className="Amount">{formatRelativeNumber(carShareSum / carShareSumCount)}</span>{' '}
          <span className="Unit">% avg. {personLabel.multiple} by cars YTD</span>
        </div>
      </div>
      <ChartContainer
        domain={domain}
        series={series}
        generator={stackedBarChart('none')}
        dateFormat="monthTwoFYs"
        maxValue={100}
        isSmallGraph
      />
    </div>
  )
}
