import { FunctionArea } from '../../../api/src/common-types'
import { Route } from '../../routes'

export interface DashboardNotification {
  title: string
  text: string
  date: Date
  scope: FunctionArea[]
  link?: Route
  badge?: 'feature' | 'kpi'
}

export const Notifications: DashboardNotification[] = [
  {
    title: 'PP+P Sales - PMA view released',
    text: 'PMA view has been released on the PP+P sales page.',
    date: new Date('2024-09-20'),
    scope: ['centres', 'ingka', 'retail']
  },
  {
    title: 'PP+P Sales - New features release',
    text: 'P+PP Quantity has been released on PP+P sales page. Sales channel filter (All sales, In-store, Online, and Remote) has been released on PP+P sales page.',
    date: new Date('2024-08-20'),
    scope: ['centres', 'ingka', 'retail']
  },
  {
    title: 'KPI - Inspired & Enabled People has been removed',
    text: 'KPI - Inspired & Enabled People has been removed from the 4P dashboard, and the main reason is that this KPI and data are not reliable and accurate enough at this time.',
    date: new Date('2024-08-20'),
    scope: ['centres', 'ingka', 'retail']
  },
  {
    title: 'Sustainability mentions KPI has been removed',
    text: 'Sustainability mentions KPI has been removed from the 4P dashboard, and the main reason is that it was not accurate enough and countries usually have better local tools. For your information, sustainability mentions will be removed from Retail Flight Tower later as well.',
    date: new Date('2024-01-09'),
    scope: ['centres', 'ingka', 'retail']
  },
  {
    title: 'The data may differ from Flight Tower',
    text: 'The data may differ from Flight Tower. We expect the data to be in sync again on the 3rd of January.',
    date: new Date('2022-12-07'),
    scope: ['centres', 'ingka', 'retail']
  },
  {
    title: 'Mid-month update of Climate Data',
    text: 'To prepare for FY22 closing, we have made an extra update to FY22 Operational Climate Footprint data from September to July. All data changes reported to the Sustain helpdesk before September 20th (apart from one in FR) are implemented. Note: Retail China has a 9 000 tonne CO2e increase caused by sites identified to be leased. Work is ongoing to confirm this electricity will be renewable again.',
    date: new Date('2022-09-22'),
    scope: ['centres', 'ingka', 'retail']
  },
  {
    title: 'New Change Makers KPI page released',
    text: 'A new KPI page under Fair & Equal, Change Makers, has been added to the dashboard! Please share your comments on it through the feedback form.',
    date: new Date('2022-08-04'),
    scope: ['centres', 'ingka', 'retail'],
    link: Route.ChangeMakersKPIPage
  },
  {
    title: 'Exporting graphs to PowerPoint',
    text: 'You can now download individual Explore graphs as a PowerPoint presentation. This includes the charts themselves as well as the underlying data in Excel format. Try it out by navigating to an Explore page and clicking the download icon at the top of the main graph!',
    date: new Date('2022-06-21'),
    scope: ['centres', 'ingka', 'retail']
  },
  {
    title: 'New Social Impact KPI page design!',
    text: 'Social Impact is the latest page that got a new design. Let us know what you think via the feedback form.',
    date: new Date('2022-06-08'),
    scope: ['centres', 'ingka', 'retail'],
    link: Route.SocialImpactKPIPage
  },
  {
    title: 'Dashboard redesigned',
    text: 'Dashboard is getting a new design gradually. First changes are a new navigation and new climate footprint pages. Please use the feedback form to send us your comments.',
    date: new Date('2022-04-13'),
    scope: ['centres', 'ingka', 'retail'],
    link: Route.ClimateKPIPage
  },
  {
    title: 'Focused 4P Dashboard views for retail, centres and Ingka',
    text: 'You can change the scope of data from the landing page: select between retail or centres. Or Ingka, to get combined view from both.',
    date: new Date('2021-12-22'),
    scope: ['centres', 'ingka', 'retail'],
    link: Route.Root
  },
  {
    title: 'New Snapshot',
    text: 'Updated Snapshot page provides you more insights about performance of some of the key KPIs. Have a look!',
    date: new Date('2021-12-03'),
    scope: ['centres', 'ingka', 'retail'],
    link: Route.SnapshotPage
  },
  {
    title: 'Social Impact added to 4P!',
    text: 'To begin to measure our positive impact we will start with Decent Work and Better Homes.',
    date: new Date('2021-11-23'),
    scope: ['centres', 'ingka', 'retail'],
    link: Route.SocialImpactKPIPage,
    badge: 'kpi'
  },
  {
    title: 'New insight format for P+PP Sales',
    text: 'People + Planet Positive Sales KPI page has a new, more visual way of displaying insights. Have a look!',
    date: new Date('2021-11-11'),
    scope: ['ingka', 'retail'],
    link: Route.ProfitGrowthPageDeprecated
  },
  {
    title: 'Export Graph',
    text: 'You can now export graphs as images to be used for example in presentations. Export is available in Explore page for all the graphs.',
    date: new Date('2021-11-03'),
    scope: ['centres', 'ingka', 'retail'],
    link: Route.Explore
  },
  {
    title: 'Zero Waste',
    text: 'To begin to measure our circularity we will start with Zero Waste in our operations. Head over to Zero Waste KPI page to follow up and take action towards less non-recycled waste!',
    date: new Date('2021-10-07'),
    scope: ['centres', 'ingka', 'retail'],
    link: Route.ZeroWasteKPIPage,
    badge: 'kpi'
  },
  {
    title: 'New financial year has started!',
    text: 'The 4P Dashboard now displays FY22 data for People, Profit and Perception. Planet data for FY22 will be available in the beginning of November.',
    date: new Date('2021-09-22'),
    scope: ['centres', 'ingka', 'retail']
  },
  {
    title: 'Renewable Energy Share',
    text: "There's a new graph in Explore to view Renewable Energy Share by location.",
    date: new Date('2021-07-06'),
    scope: ['centres', 'ingka', 'retail'],
    link: Route.ExploreRenewablePage,
    badge: 'feature'
  },
  {
    title: 'All HFB categories are now available on P+PP Sales KPI page!',
    text: 'The new HFB graph displays sales and share of P+PP products within each HFB category and what are the top-selling products within each HFB category.',
    date: new Date('2021-06-30'),
    scope: ['ingka', 'retail'],
    link: Route.ProfitGrowthPageDeprecated
  },
  {
    title: 'New insight format available for Climate Footprint',
    text: 'Climate Footprint KPI page introduces new, more visual way of displaying insights. Have a click!',
    date: new Date('2021-06-21'),
    scope: ['centres', 'ingka', 'retail'],
    link: Route.PlanetFootprintPageDeprecated
  },
  {
    title: 'Notifications area added',
    text: 'To keep you constantly up to date, notifications area is now added to the 4P Dashboard. Data updates and new features are notified here.',
    date: new Date('2021-06-21'),
    scope: ['centres', 'ingka', 'retail']
  }
]
