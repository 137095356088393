import React from 'react'
import classNames from 'classnames'

import styles from './Toggle.module.scss'

interface StringType {
  toLowerCase: () => string
}

interface ToggleProps<ValueType> {
  lhsValue: ValueType
  rhsValue: ValueType
  currentValue: ValueType
  lhsLabel?: string
  rhsLabel?: string
  onChanged: (val: ValueType) => void
  disabled?: boolean
}

export function Toggle<ValueType extends StringType>({
  lhsValue,
  rhsValue,
  currentValue,
  lhsLabel,
  rhsLabel,
  onChanged,
  disabled
}: ToggleProps<ValueType>) {
  const lhsSelected = currentValue.toLowerCase() === lhsValue.toLowerCase()
  const selectionChanged = () => {
    if (!disabled) {
      onChanged(lhsSelected ? rhsValue : lhsValue)
    }
  }

  return (
    <div className={classNames(styles.Toggle, disabled && styles.Disabled)}>
      <span className={styles.Value}>{lhsLabel ?? lhsValue}</span>
      <div className={classNames(styles.Toggler, styles[lhsSelected ? 'Left' : 'Right'])} onClick={selectionChanged} />
      <span className={styles.Value}>{rhsLabel ?? rhsValue}</span>
    </div>
  )
}
