import { CustomerTravelFootprint } from '../../../../../api/src/types/climate'
import { isDataPoint, Serie } from '../../../../components/BaseGraphs/ChartContainer'
import { isSameMonth } from 'date-fns'
import colours from '../../../../Colours.module.scss'

export const formatCustomerDistanceSeries = (
  data: CustomerTravelFootprint[] | undefined,
  domain: Date[] | undefined
): Serie[] | undefined => {
  if (data === undefined || domain === undefined) {
    return undefined
  }
  const noData = data.every(d => !d.currentFY && !d.previousFY)
  if (noData) {
    return []
  }
  const fy = data[0].fiscalYear

  const dataWithDates = domain.map(date => {
    const d = data.find(d => isSameMonth(new Date(d.readableDate), date))
    return {
      ...d,
      date,
      curr: d?.curr_customer_distance,
      prev: d?.prev_customer_distance,
      global: d?.global_customer_distance
    }
  })

  return [
    {
      name: `FY${fy - 1}`,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: dataWithDates.map(d => ({ x: d.date, y: d.prev })).filter(isDataPoint)
    },
    {
      name: `FY${fy}`,
      color: colours.darkBlue1,
      data: dataWithDates.map(d => ({ x: d.date, y: d.curr })).filter(isDataPoint)
    },
    {
      name: 'Global',
      color: colours.orange5,
      data: dataWithDates.map(d => ({ x: d.date, y: d.global })).filter(isDataPoint)
    }
  ]
}
