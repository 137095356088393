import React from 'react'
import colours from '../Colours.module.scss'

interface ArrowIconProps {
  angle: number
  color: string
  width?: number
  height?: number
}

export const ArrowIcon: React.FC<ArrowIconProps> = ({ angle, color, width = 16, height = 16 }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`rotate(${angle}, 10, 10)`}>
      <ellipse cx="9.7583" cy="10" rx="10" ry="9.62085" transform="rotate(-90 9.7583 10)" fill={color} />
      <path
        d="M9.75869 8.69141L7.37458 11.5378C7.28386 11.6204 7.16136 11.6667 7.0337 11.6667C6.90603 11.6667 6.78353 11.6204 6.69281 11.5378C6.64802 11.4973 6.61245 11.4489 6.58817 11.3956C6.56389 11.3423 6.55139 11.2851 6.55139 11.2273C6.55139 11.1695 6.56389 11.1123 6.58817 11.059C6.61245 11.0057 6.64802 10.9574 6.69281 10.9168L9.41711 7.62879C9.50803 7.54632 9.63062 7.50008 9.75834 7.50008C9.88607 7.50008 10.0087 7.54632 10.0996 7.62879L12.8239 10.9168C12.8687 10.9574 12.9042 11.0057 12.9285 11.059C12.9528 11.1123 12.9653 11.1695 12.9653 11.2273C12.9653 11.2851 12.9528 11.3423 12.9285 11.3956C12.9042 11.4489 12.8687 11.4973 12.8239 11.5378C12.7332 11.6204 12.6107 11.6667 12.483 11.6667C12.3553 11.6667 12.2328 11.6204 12.1421 11.5378L9.75869 8.69141Z"
        fill="black"
      />
    </g>
  </svg>
)
ArrowIcon.displayName = 'ArrowIcon'

interface WithMenu {
  menuIsOpen?: boolean
}

interface Disableable {
  isDisabled?: boolean
}

export interface ArrowSelectDropdownIndicatorProps extends WithMenu, Disableable {}

export const ArrowSelectDropdownIndicator: React.FC<ArrowSelectDropdownIndicatorProps> = ({
  menuIsOpen,
  isDisabled
}) => {
  const rotation = menuIsOpen ? 0 : 180
  const color = isDisabled ? colours.grey1 : colours.yellow
  return <ArrowIcon angle={rotation} color={color} />
}
ArrowSelectDropdownIndicator.displayName = 'ArrowSelectDropdownIndicator'
