import { padStart } from 'lodash'

import { GeographicalScope, isCountry, isCountryOrGlobal, isSite, LocationWithType } from '../../lib/APIClient'
import { InStoreI18n } from '../../Localisation'
import { Language } from '../../Localisation'
import { ApiCluster, CountryCode, DbSiteFunction, GoalsResponse, SiteFunction } from '../../../api/src/common-types'
import { useParams } from 'react-router'
import { CurrentLocation } from '../../context'
import { centreFunctionCodes, retailFunctionCodes } from '../LocationSearch'
import { getIngkaFinancialYear } from './dates'

export function getLocationSelector(locationId: string, countryCodes?: CountryCode[]) {
  // Default to Global since we return the default if both site and country are ALL
  const defaultLocation = {
    locationId,
    site: 'ALL',
    countryCodes: []
  }

  if (!locationId) {
    return defaultLocation
  }

  if (isCluster(locationId) && countryCodes) {
    return {
      ...defaultLocation,
      countryCodes
    }
  }

  const singleCountryCode = isCountryCode(locationId) ? ([locationId] as CountryCode[]) : []
  const site = isSiteId(locationId) ? locationId : 'ALL'

  return { locationId, site, countryCodes: singleCountryCode }
}

export function parseFY(date: Date | string) {
  const d = new Date(date)
  if (d.getUTCMonth() > 7) {
    return d.getUTCFullYear() + 1 - 2000
  }
  return d.getUTCFullYear() - 2000
}

export function getLocation(siteOrCountry: string, locations: LocationWithType[]) {
  return locations.find(
    value => (isSite(value) && value.siteId === siteOrCountry) || value.countryCode === siteOrCountry
  )
}

export function getCountry(siteOrCountry: string, locations: LocationWithType[]): LocationWithType {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const location = getLocation(siteOrCountry, locations)!
  if (location.type === GeographicalScope.Site) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return locations.filter(isCountry).find(c => c.countryCode === location.countryCode)!
  }
  return location
}

export function getLocationId(currentLocation: CurrentLocation) {
  if (currentLocation.isCluster) {
    return currentLocation.cluster.clusterId
  }
  const { location } = currentLocation
  return isSite(location) ? location.siteId : location.countryCode
}

export function getLocationLabel(currentLocation: CurrentLocation) {
  if (currentLocation.isCluster) {
    return currentLocation.cluster.clusterName
  }
  return currentLocation.location.label
}

export function getCountryOrClusterName(currentLocation: CurrentLocation) {
  if (currentLocation.isCluster) {
    return currentLocation.cluster.clusterName
  }
  return currentLocation.location.countryName
}

export const getLocationFunctions = (currentLocation: CurrentLocation) => {
  if (currentLocation.isCluster) {
    return centreFunctionCodes
  }
  const { location } = currentLocation
  return isSite(location) ? [location.functionCode] : [...centreFunctionCodes, ...retailFunctionCodes]
}

export const getCurrentLocationLabel = (currentLocation: LocationWithType, lang: Language) =>
  isSite(currentLocation) ? currentLocation.siteName : InStoreI18n[lang].Countries[currentLocation.countryCode]

export const getCurrentCountryLabel = (
  currentLocation: LocationWithType,
  locations: LocationWithType[],
  lang: Language
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const country = locations.filter(isCountryOrGlobal).find(c => c.countryCode === currentLocation.countryCode)!
  return InStoreI18n[lang].Countries[country.countryCode]
}

export const getInStoreLabel = (currentLocation: LocationWithType, lang: string) =>
  isCountry(currentLocation)
    ? InStoreI18n[lang].Generic.country
    : currentLocation.functionCode === 'Retail'
    ? InStoreI18n[lang].Generic.store
    : currentLocation.functionCode === 'Ingka Centres'
    ? InStoreI18n[lang].Generic.meetingPlace
    : InStoreI18n[lang].Generic.unit

export const readIntCookie = (key: string) => {
  const value = document.cookie
    .split('; ')
    .find(c => c.startsWith(`${key}=`))
    ?.split('=')[1]
  return value ? parseInt(value) : 0
}

const year = 60 * 60 * 24 * 365
export const storeIntCookie = (key: string, value: number, age: number = year) => {
  document.cookie = `${key}=${value};max-age=${age};path=/`
}

export function toSiteFunction(f: DbSiteFunction): SiteFunction[] {
  return f === 'Ingka Centres' ? ['Common Areas', 'Tenants'] : [f]
}

export function getCurrencyCode(currentLocation: CurrentLocation) {
  return currentLocation.isCluster ? undefined : currentLocation.location.currencyCode
}

export function getCurrencySymbol(currencyCode = 'EUR', lang: Language = 'en') {
  try {
    // Intl.DisplayNames is not yet in the standard, so TypeScript doesn't know about it.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new (Intl as any).DisplayNames([lang], { type: 'currency', style: 'short' }).of(currencyCode)
  } catch (error) {
    return currencyCode
  }
}

export function isSiteId(locationId?: string) {
  return locationId?.length === 32
}

export function isCountryCode(locationId?: string) {
  return locationId?.length === 2 || locationId?.length === 3
}

export function isCluster(locationId?: string) {
  return !isSiteId(locationId) && !isCountryCode(locationId)
}

export function getCluster(clusters: ApiCluster[], clusterId: string) {
  return clusters.find(cluster => cluster.clusterId === clusterId)
}

export function getLivligStoreId(siteName: string) {
  const match = siteName.match(/STO(\d{1,})/)
  if (match && match.length >= 2) {
    return `STO${padStart(match[1], 5, '0')}`
  }
}

export function getLocationOrDefault() {
  const { siteOrCountry } = useParams()
  return siteOrCountry ?? 'ALL'
}

export function getGoalsForLocation(locationId: string, goals?: GoalsResponse) {
  return isSiteId(locationId)
    ? goals?.sites.find(x => x.siteId === locationId)
    : goals?.countries.find(x => x.countryCode === locationId)
}

export function sumObjectsByKey(objs: Record<string, number>[]) {
  return objs.reduce((a, b) => {
    for (const k in b) {
      // eslint-disable-next-line no-prototype-builtins
      if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k]
    }
    return a
  }, {})
}

export function getCurentAndPreviousFYMonth(actualPeriod: string | number | Date | null) {
  if (!actualPeriod) return { currentFYMonth: '', prevFYMonth: '' }
  const now = new Date(actualPeriod)
  const prev = new Date(actualPeriod)
  prev.setDate(1)
  prev.setMonth(now.getMonth() - 1)

  const currFY = getIngkaFinancialYear(now)
  const prevFY = getIngkaFinancialYear(prev)

  const currentFYMonth = `${now.toLocaleString('default', { month: 'long' })} FY${currFY?.getFullYear() - 2000}`
  const prevFYMonth = `${prev.toLocaleString('default', { month: 'long' })} FY${prevFY.getFullYear() - 2000}`

  return { currentFYMonth, prevFYMonth }
}

export interface Goal {
  goalPrevFY: number
  goal: number
  goalNextFY: number
}

export const mapGoalsResponse = (goals: GoalsResponse, goalType: string, nextType: string, prevType: string) => {
  return [...goals.countries, ...goals.sites].reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (acc, g: any) =>
      g.countryCode ?? g.siteId
        ? {
            ...acc,
            [g.countryCode ?? g.siteId]: { goal: g[goalType], goalPrevFY: g[prevType], goalNextFY: g[nextType] }
          }
        : { ...acc },
    {} as Record<string, Goal>
  )
}
