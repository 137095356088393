import { sub, format } from 'date-fns'
import React from 'react'

export const KpiPerceptionModalContent = () => {
  const start = format(sub(new Date(), { months: 12 }), 'MMM yyyy')
  const end = format(new Date(), 'MMM yyyy')
  return (
    <>
      <p data-testid="sustainability-mentions-modal-intro-para">
        Sustainability Mentions enables us to understand how people talk about IKEA and sustainability on Social Media.
        The way people talk about IKEA on Social Media platforms is one of the factors affecting consumers’ perception
        of the IKEA brand.{' '}
      </p>
      <h3>Why do we measure Sustainability Mentions?</h3>
      <p>
        Trust is one of the most valuable assets that a company can have, and sustainability is playing an increasingly
        important role in building a trusted and loved brand. By understanding how people talk about IKEA on Social
        Media, we can learn more about how consumers perceive us. Understanding and setting goals for social media
        mentions and consumer perception are key when we develop our communication, performance and impact on people,
        planet and IKEA.
      </p>
      <h3>How are we measuring this?</h3>
      <p>
        The source system for the sustainability mentions data is BrandWatch with monthly reporting frequency. Mentions
        are captured from social media users’ activities on platforms such as Twitter news sites, forums and blogs. The
        data capture is built on keywords (queries) that scope the pre-definied sustainability topics we see are
        relevant to customers and IKEA. The queries are designed and continuously updated in English, and thereafter
        directly translated to relevant languages for capturing mentions in each Ingka country.
      </p>
      <div className="ListWithHeading">
        <h3>We do queries from</h3>
        <ul>
          <li>Social media (mainly Twitter and Instagram)</li>
          <li>News sites</li>
          <li>Forums</li>
          <li>Blogs</li>
        </ul>
        <h3>This data is displayed in</h3>
        <ul>
          <li>Total of IKEA mentions that are positive</li>
          <li>Total of IKEA mentions that are neutral</li>
          <li>Total of IKEA mentions that are negative</li>
        </ul>
      </div>
      <div className="DataSource">
        Based on Brandwatch data between {start} - {end}
      </div>
    </>
  )
}
