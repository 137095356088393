import _ from 'lodash'

import { DataPoint, Serie } from '../../../../components/BaseGraphs/ChartContainer'
import { getTotalReduction } from './getTotalReduction'
import { GAP_NAME } from '../constants'

export const getLastYearGap = (actionsData: DataPoint[], goalData: DataPoint[]): Serie => {
  const totalReduction = getTotalReduction(actionsData, goalData)
  const lastGoal = _.last(goalData)
  const gap = totalReduction - Number(lastGoal?.y)

  const gapData =
    gap > 0
      ? [
          {
            startPoint: totalReduction,
            x: new Date('2029-09-30T00:00:00.000Z'),
            y: -gap
          }
        ]
      : []

  return {
    name: GAP_NAME,
    color: '#DD2A1A',
    data: gapData
  }
}
