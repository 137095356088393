import React from 'react'
import { LanguageContext } from '../../context'
import { InStoreI18n as i18n } from '../../Localisation'

import './IntroSlide.scss'

interface IntroSlideProps {
  siteOrCountry: string
}

export const IntroSlide: React.FC<IntroSlideProps> = ({ siteOrCountry }) => {
  const lang = React.useContext(LanguageContext)
  const l = i18n[lang]
  return (
    <div className="IntroSlide">
      <div className="IntroSlideLeft">
        <h1>{l.Intro.heading}</h1>
        <p>{l.Intro.purpose}</p>
        <p>{l.Intro.teaser}</p>
      </div>
      <div className="IntroSlideRight">
        <h2>{l.Intro.planetHeading}</h2>
        <p>{l.Intro.climateFootprintDescription}</p>
        <ul>
          <li>{l.Intro.climateMetric1}</li>
          <li>{l.Intro.climateMetric2}</li>
          {siteOrCountry.length > 3 && <li>{l.Intro.climateMetric3}</li>}
        </ul>
        <h2>{l.Intro.profitHeading}</h2>
        <p>{l.Intro.profitDescription}</p>
        <ul>
          <li>
            {l.Intro.productSales} <strong>{l.Intro.sustainableProduct1}</strong> {l.Generic.and}{' '}
            <strong>{l.Intro.sustainableProduct2}</strong>
          </li>
          <li>
            {l.Intro.sustainableSales} <strong>{l.Intro.hfb1}</strong> {l.Generic.or} <strong>{l.Intro.hfb2}</strong>
          </li>
        </ul>
      </div>
    </div>
  )
}
