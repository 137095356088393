import React from 'react'
import classNames from 'classnames'

import './Checkbox.scss'

interface CheckboxProps {
  id: string
  isSelected: boolean
  onChange: (id: string) => void
  disabled?: boolean
}

export const CheckBox: React.FC<CheckboxProps> = ({ id, disabled, isSelected, onChange }) => (
  <div className="CheckboxContainer" onClick={() => (disabled ? undefined : onChange(id))}>
    <div className={classNames('CheckBox', { Checked: isSelected })} />
  </div>
)
