import React from 'react'

import { LocationWithType, getWaterEfficiencyFootprint, isSite } from '../../lib/APIClient'
import { formatRelativeNumber } from '../Utils/format'
import {
  getCountry,
  getCurrentCountryLabel,
  getCurrentLocationLabel,
  getInStoreLabel,
  getLocationId,
  getLocationLabel,
  isSiteId
} from '../Utils/utils'
import './WaterEfficiencySlide.scss'
import colours from '../../Colours.module.scss'
import { InStoreSlide } from './InStoreSlide'
import { useLocations, useDataAvailabilityContext, LanguageContext } from '../../context'
import { dataSourceText, formatLocalisationString, InStoreI18n as i18n } from '../../Localisation'
import { ChartContainer, Legend, Serie } from '../BaseGraphs/ChartContainer'
import { CountryCode, KPIBenchmark } from '../../../api/src/common-types'
import { lineChart } from '../BaseGraphs/GraphUtil'
import { useSharedSelections } from '../../SharedSelections'
import { groupBy, isNil, maxBy, meanBy, toPairs } from 'lodash'
import { getIngkaFinancialYear } from '../Utils/dates'

const highlightedSiteColor = colours.blue5
const otherSitesColor = colours.offWhite5

export interface GoalsWaterResponse {
  siteId: string
  waterGoal: number
}

export interface WaterEfficiencySlide extends KPIBenchmark {
  readableDate: string
  fiscalYear: number
  goal: number
  goalNextFY: number
  currentFY: number
  currentYtd: number
}

export const WaterEfficiencySlide = ({ currentLocationWithType }: { currentLocationWithType: LocationWithType }) => {
  const [{ func }] = useSharedSelections()
  const { currentLocation, locations } = useLocations()
  const [domain, setDomain] = React.useState<Date[]>()
  const [benchmarks, setBenchmarks] = React.useState<WaterEfficiencySlide[]>()
  const { dataAvailability } = useDataAvailabilityContext()
  const lang = React.useContext(LanguageContext)
  const l = i18n[lang]
  const ls = l['Water Efficiency']
  const locationId = getLocationId(currentLocation)
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024
  const [footprintDataSource, setFootprintDataSource] = React.useState('')
  const [series, setSeries] = React.useState<Serie[]>([])
  const siteList = locations.filter(isSite)

  const currentLocationLabel = currentLocation.isCluster
    ? currentLocation.cluster.clusterName
    : getCurrentLocationLabel(currentLocation.location, lang)

  React.useEffect(() => {
    if (!locations || locations.length === 0) return

    setBenchmarks(undefined)

    const countryCode = isSiteId(locationId)
      ? getCountry(locationId, locations).countryCode
      : (locationId as CountryCode)

    getWaterEfficiencyFootprint(countryCode, func, currentFY).then(result => {
      const data = result.data
      const currentData =
        data && isSiteId(locationId) ? data : calculateAverageAndCreateObject(data, currentLocationLabel, countryCode)
      setBenchmarks(currentData)
      const sites = groupBy(currentData, 'id')
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const latestDateResult = maxBy(result.data, 'readableDate')!

      setFootprintDataSource(
        dataSourceText(lang, latestDateResult.readableDate, l.Generic.sustainData, dataAvailability)
      )

      const goals = Object.values(sites).map((s, i) => ({
        id: Object.keys(sites)[i],
        color: '#7d8fad',
        data: Object.values(
          groupBy(
            result.dates.map(dd => new Date(dd)),
            d => getIngkaFinancialYear(d).getFullYear()
          )
        )[0].map(date => ({
          x: date,
          y: s[0].goal ? s[0].goal : 0
        })),
        fill: undefined,
        name: 'Goal',
        zIndex: 0
      }))

      const series = toPairs(sites)
        .map(([id, currentFY], index) => ({
          id: id,
          color:
            (isSite(currentLocationWithType) ? currentLocationWithType.siteId : currentLocationWithType.countryCode) ===
            id
              ? highlightedSiteColor
              : otherSitesColor,
          data: currentFY
            .filter(({ fiscalYear }) => fiscalYear ?? 0 >= latestDateResult.fiscalYear - 1)
            .map(({ readableDate, currentFY }) => ({
              x: new Date(readableDate ?? ''),
              y: currentFY
            })),
          description: id,
          name: siteList.find(l => l.siteId === id)?.label ?? currentLocationWithType.label,
          zIndex: id === locationId ? data.length : index
        }))
        .filter(serie => !serie.data.every(datapoint => isNil(datapoint.y)))

      setSeries([...moveLocationIdToEnd(series, locationId), ...goals.filter(g => g.id === locationId)])
      setDomain(result.dates.map(d => new Date(d)))
    })
  }, [JSON.stringify(locations), JSON.stringify(func), JSON.stringify(currentLocation)])

  const countryBenchmark: WaterEfficiencySlide = React.useMemo(() => {
    return (
      benchmarks?.find(benchmark => benchmark.id === getLocationId(currentLocation)) ?? {
        id: getLocationId(currentLocation),
        label: getLocationLabel(currentLocation),
        goal: 0,
        goalNextFY: 0,
        selectable: true,
        currentYtd: 0,
        currentFY: 0,
        readableDate: '',
        fiscalYear: 0
      }
    )
  }, [JSON.stringify(currentLocation), JSON.stringify(benchmarks)])

  const [selectedLocationBenchmark] = React.useMemo(() => {
    const locationId = getLocationId(currentLocation)
    if (benchmarks === undefined) {
      return [undefined, []]
    }

    const selectedLocationBenchmark = isSiteId(locationId)
      ? maxBy(
          benchmarks.filter(b => b.id === locationId),
          'readableDate'
        )
      : countryBenchmark

    return [
      selectedLocationBenchmark,
      benchmarks
        .filter(b => b.id !== locationId)
        .sort((a, b) => b.goal - a.goal)
        .slice(0, selectedLocationBenchmark ? 3 : 4)
    ]
  }, [JSON.stringify(benchmarks)])

  const ytdSummary = selectedLocationBenchmark?.currentYtd?.toFixed(1) ?? '0'
  const ytdGoal = selectedLocationBenchmark?.goal?.toFixed(1) ?? '0'
  const countryLabel = getCurrentCountryLabel(currentLocationWithType, locations, lang)
  const filterdSeries = ytdGoal === '0' ? series.filter(s => s.name !== 'Goal') : series
  const onTrack = Number(ytdSummary) - Number(ytdGoal)

  return (
    <InStoreSlide
      className="WaterEfficiencySlide"
      header={{
        location: currentLocationLabel,
        description: formatLocalisationString(isSiteId(locationId) ? ls.description : ls.descriptionCountry, {
          country: countryLabel
        }),
        title: ls.title,
        dataSource: footprintDataSource
      }}
      series={{
        name: ls.seriesName,
        value: ytdSummary ?? '0',
        unit: l.Generic.literVisitor,
        badge:
          ytdGoal !== '0'
            ? `${formatRelativeNumber(Math.abs(Number(ytdSummary) - Number(ytdGoal)))} ${l.Generic.liters} ${
                Number(ytdSummary) <= Number(ytdGoal) ? l.Generic.below : l.Generic.above
              } ${l.Generic.goal}`
            : '',
        onTrack: onTrack === 0 ? -1 : onTrack
      }}
      graph={
        <>
          <ChartContainer
            domain={domain}
            series={filterdSeries}
            generator={lineChart}
            dateFormat="month"
            yAxisTitle="Litre/Visitor"
            showDecimals
            hideLegend
            lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
          />
          <Legend
            series={[
              {
                id: 'selected',
                name: isSiteId(locationId) ? currentLocationWithType.label : `Total ${currentLocationWithType.label}`,
                color: highlightedSiteColor,
                data: []
              },
              ...(Object.keys(filterdSeries).length > 1
                ? [
                    {
                      id: 'others',
                      name: formatLocalisationString(
                        isSiteId(locationId) ? l.Generic.otherLocations : l.Generic.otherLocationsCountry,
                        {
                          siteLabel: getInStoreLabel(currentLocationWithType, lang),
                          country: countryLabel
                        }
                      ),
                      color: otherSitesColor,
                      data: []
                    }
                  ]
                : []),
              ...(ytdSummary !== '0' ? [{ id: 'goal', name: 'Goal', color: '#7d8fad', data: [] }] : [])
            ]}
            selectedSeries={[
              isSiteId(locationId) ? currentLocationWithType.label : `Total ${currentLocationWithType.label}`,
              'Goal'
            ]}
            leftMargin={0}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            changeSelectionForAllItems={() => {}}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onItemClicked={() => {}}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            setHighlightedSerie={() => {}}
          />
        </>
      }
      isLoading={Number(ytdSummary) === -1}
    />
  )
}

const calculateAverageAndCreateObject = (arr: WaterEfficiencySlide[], countryName: string, locationId: string) => {
  const groupedByMonth = groupBy(arr, obj => obj.readableDate.slice(0, 7))

  const monthlyAverages = Object.keys(groupedByMonth).map(month => {
    const monthData = groupedByMonth[month]

    const averageFields = {
      currentYtd: meanBy(monthData, 'currentYtd'),
      currentFY: meanBy(monthData, 'currentFY'),
      previousFy: meanBy(monthData, 'previousFy'),
      previousYtd: meanBy(monthData, 'previousYtd'),
      rolling: meanBy(monthData, 'rolling'),
      visitorsCurrentYtd: meanBy(monthData, 'visitorsCurrentYtd'),
      waterCurrentYtd: meanBy(monthData, 'waterCurrentYtd'),
      waterPreviousYtd: meanBy(monthData, 'waterPreviousYtd'),
      visitorsPreviousYtd: meanBy(monthData, 'visitorsPreviousYtd')
    }

    const freshestObject = maxBy(monthData, 'readableDate')

    return {
      id: locationId,
      label: countryName,
      month,
      ...averageFields,
      fiscalYear: freshestObject?.fiscalYear || 2024,
      goal: freshestObject?.goal || 0,
      goalNextFY: freshestObject?.goalNextFY || 0,
      readableDate: freshestObject?.readableDate || ''
    }
  })

  return [...arr, ...monthlyAverages]
}

export const moveLocationIdToEnd = (series: Serie[], locationId: string) => {
  const locationIndex = series.findIndex(serie => serie.id === locationId)

  if (locationIndex !== -1) {
    const [locationObject] = series.splice(locationIndex, 1)
    series.push(locationObject)
  }

  return series
}
