import React from 'react'
import { stringify } from 'qs'
import classNames from 'classnames'
import { Link as ReactRouterLink, useLocation } from 'react-router-dom'

import styles from './Link.module.scss'
import { useSharedSelections } from '../SharedSelections'
import { Route } from '../routes'

interface LinkProps {
  className?: string
  page: Route
  siteOrCountry?: string
  onClick?: () => void
}

export const getPathnameSearch = (page: string, siteOrCountryProp?: string) => {
  const { pathname: currentPathname } = useLocation()
  const [{ func, functionArea, scope }] = useSharedSelections()

  if (page.startsWith('/')) {
    return {
      pathname: page
    }
  }

  if (page === 'instoreconfigurator') {
    return {
      pathname: '/instoreconfigurator'
    }
  }

  const match = currentPathname.match(/\/([^/]*)\//)
  const siteOrCountry = siteOrCountryProp ? siteOrCountryProp : match && match.length > 1 ? match[1] : 'ALL'

  const pathname = `/${siteOrCountry}/${page}`
  const search = stringify({ func, functionArea, scope }, { addQueryPrefix: true })

  return {
    pathname,
    search,
    url: `${pathname}${search}`
  }
}

export const Link: React.FC<LinkProps> = ({ children, className, page, siteOrCountry, onClick }) => {
  const { pathname, search } = getPathnameSearch(page, siteOrCountry)
  return (
    <ReactRouterLink className={classNames(styles.Link, className)} to={{ pathname, search }} onClick={onClick}>
      {children}
    </ReactRouterLink>
  )
}
