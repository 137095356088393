import React, { useEffect } from 'react'
import classNames from 'classnames'
import { RadioButton } from '../../components/RadioButton'
import { FilterDropdownMenu } from '../../components/FilterMenu/FilterDropdownMenu'
import { range, uniqBy } from 'lodash'
import { compareAsc } from 'date-fns'

export type TimeRange = 'monthly' | 'annual'

interface Filters<KPI> {
  kpi: KPI
  timeRange: TimeRange
  rangeFrom: string
  rangeTo: string
}

export function useFilters<KPI extends string>(
  kpis: readonly KPI[],
  kpiLabels: Record<KPI, string>,
  currentFy: number,
  useTimeRange = true
): [Filters<KPI>, JSX.Element] {
  const [selectedKpi, setSelectedKpi] = React.useState<KPI>(kpis[0])
  const [timeRange, setTimeRange] = React.useState<TimeRange>('monthly')
  const [rangeFrom, setRangeFrom] = React.useState('2021')
  const [rangeTo, setRangeTo] = React.useState(currentFy.toString())

  const yearRange = range(2016, currentFy + 1)
    .reverse()
    .map((fy, i) => ({
      label: `FY${fy - 2000}${i === 0 ? ' YTD' : ''}`,
      value: fy.toString()
    }))

  useEffect(() => {
    !kpis.includes(selectedKpi) && setSelectedKpi(kpis[0])
  }, [kpis])

  const elem = (
    <div className="FilterSelector">
      <div className="Left">
        <span className="Title">Explore</span>
        <div className="FilterButtons">
          {kpis.map(kpi => (
            <div
              key={kpi}
              className={classNames(`Button ${kpi}`, { Selected: selectedKpi === kpi })}
              onClick={() => setSelectedKpi(kpi)}
            >
              {kpiLabels[kpi]}
            </div>
          ))}
        </div>
        {useTimeRange && (
          <div className="RadioButtonContainer">
            <RadioButton text="Monthly" selected={timeRange === 'monthly'} onClick={() => setTimeRange('monthly')} />
            <RadioButton text="Annual" selected={timeRange === 'annual'} onClick={() => setTimeRange('annual')} />
          </div>
        )}
      </div>
      <div className="Right">
        <span className="TimelineHeader">Timeline</span>
        <FilterDropdownMenu
          label=""
          name="from"
          selected={timeRange === 'monthly' ? rangeFrom : '2016'}
          options={yearRange}
          onChange={setRangeFrom}
          disabled={timeRange === 'annual'}
        />
        <div className="FYSeparator" />
        <FilterDropdownMenu
          label=""
          name="to"
          selected={rangeTo}
          options={yearRange}
          onChange={setRangeTo}
          disabled={timeRange === 'annual'}
        />
      </div>
    </div>
  )

  return [{ kpi: selectedKpi, timeRange, rangeFrom, rangeTo }, elem]
}

export const getDateRange = (aggregateAnnually: boolean, dates: string[], data: { fiscalYear: number }[]) =>
  (aggregateAnnually
    ? uniqBy(data, 'fiscalYear').map(d => new Date(`${d.fiscalYear + 1999}-09-30`))
    : dates.map(d => new Date(d))
  ).sort(compareAsc)

interface HasFiscalYear {
  fiscalYear: number
}
export const fyBetween =
  (rangeFromFy: number, rangeToFy: number) =>
  ({ fiscalYear }: HasFiscalYear) =>
    fiscalYear >= rangeFromFy && fiscalYear <= rangeToFy
