import React, { useState } from 'react'
import classNames from 'classnames'
import { ArrowIcon } from '../ArrowIcon'
import { formatAbsoluteNumber } from '../Utils/format'

export interface subMenuData {
  category?: string
  projectName?: string
  projectType?: string
  status?: string
  energy?: number
  co?: number
  site?: string
  func?: string
  actions?: number
}

export interface KpiFootprintModalContentProps {
  category?: string
  projectName?: string
  projectType?: string
  status?: string
  energy?: number
  co?: number
  site?: string
  func?: string
  actions?: number
  subMenu?: subMenuData[]
  country?: string
  global?: string
}

export const siteHeaders = ['Category', 'Project name', 'Project type', 'Status', 'MWh (energy)', 't CO2e']
export const countryHeaders = ['Site', 'Actions', 'MWh (energy)', 't CO2e']
export const globalHeaders = ['Country', 'Actions', 'MWh (energy)', 't CO2e']

export default function KpiFootprintModalContent({ item }: { item: KpiFootprintModalContentProps }) {
  const [show, setShow] = useState<boolean>(false)
  const [isActive, setIsActive] = useState<boolean>(false)

  const hasSubMenu = 'subMenu' in item && item.subMenu !== undefined
  const isIcontryMockData = 'site' in item
  const subHeader = isIcontryMockData ? siteHeaders : countryHeaders

  if (hasSubMenu) {
    return (
      <div className="containerWithSub">
        <div className="withSubMain" onClick={() => setShow(!show)}>
          {Object.values(item).map((e, i) => {
            if (i === 0) {
              return (
                <div className="withSubMainArrow">
                  <ArrowIcon angle={show ? 0 : 180} color={'white'} height={25} width={25} />
                  <div>{e}</div>
                </div>
              )
            } else if (typeof e === 'object') {
              return
            } else if (typeof e === 'number') {
              return <div>{formatAbsoluteNumber(e)}</div>
            }
          })}
        </div>
        <div className={classNames('subMenu-container', show ? 'show' : 'hide')}>
          <div className={isIcontryMockData ? 'subMenu-list-header' : 'subMenu-list-global-header'}>
            {subHeader.map(h => (
              <div key={h}>{h}</div>
            ))}
          </div>
          {item.subMenu?.map((e, i) => {
            const isIcontryData = 'site' in e
            return (
              <div className={!isIcontryData ? 'subMenu-list' : 'subMenu-list-global'} key={i}>
                {Object.values(e).map((el, ind) => (
                  <div
                    key={ind}
                    onClick={() => {
                      setIsActive(!isActive)
                    }}
                    className="subMenu"
                  >
                    {typeof el === 'number' ? formatAbsoluteNumber(el) : el}
                  </div>
                ))}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
  return (
    <div
      onClick={() => {
        setIsActive(!isActive)
      }}
      className={classNames('container')}
    >
      {Object.values(item).map(e => (
        <div key={e}>{e}</div>
      ))}
    </div>
  )
}
