import { PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser'

export const tenantId = '720b637a-655a-40cf-816a-f22f40755c2c'

const msalConf = {
  auth: {
    clientId: 'fbcb1d9f-1c7c-4294-a4ae-2cb3133a0130',
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage
  }
}

export const msalApp = new PublicClientApplication(msalConf)

export const isLocal = () => window.location.hostname === 'localhost'
