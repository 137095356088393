import React from 'react'
import { Route, useLocation, Routes } from 'react-router-dom'
import WebFont from 'webfontloader'
import classnames from 'classnames'
import { flatten, has, isArray, isEqual } from 'lodash'
import qs from 'qs'

import { PageNotFound } from './components/PageNotFound'
import * as APIClient from './lib/APIClient'

import './App.scss'
import { centreFunctionCodes, retailFunctionCodes } from './components/LocationSearch'
import { Providers } from './context/Providers'
import { generateRoutes, generateRedirects, NotLoaded, isInStorePage } from './routes'
import { pageView } from './components/Utils/analytics'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  const { pathname, search } = useLocation()
  const queryParams = qs.parse(search, { ignoreQueryPrefix: true })

  React.useEffect(() => {
    // In-Store pages handle their own tracking
    if (!isInStorePage(pathname)) {
      pageView({ path: pathname })
    } else {
      // In-store page requires authentication information from the URL
      const token = queryParams['token']
      const key = queryParams['key']
      if (token && key) {
        sessionStorage['InStoreAuth'] = token
        sessionStorage['InStoreKey'] = key
      }
    }
  }, [pathname])

  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ['Noto Sans:300,400,700', 'Roboto Mono:400:latin', 'sans-serif']
      }
    })
  }, [])

  React.useEffect(() => {
    let initialAppVersion: string | undefined = undefined

    const checkAppVersion = async () => {
      try {
        const { appVersion: remoteVersion } = await APIClient.getAppVersion()
        if (!initialAppVersion) {
          initialAppVersion = remoteVersion
        } else if (initialAppVersion !== remoteVersion) {
          window.location.reload()
        }
      } catch (_err) {
        /* continue polling if request fails */
      }

      setTimeout(checkAppVersion, 30000)
    }

    checkAppVersion()
  }, [])

  React.useEffect(() => {
    // Replace outdated func parameter
    if (
      has(queryParams, 'func') &&
      isArray(queryParams.func) &&
      isEqual(queryParams.func as string[], ['ALL']) &&
      queryParams.functionArea !== 'ingka'
    ) {
      window.location.replace(
        `${window.location.origin}${pathname}${qs.stringify(
          {
            ...queryParams,
            func: queryParams.functionArea === 'retail' ? retailFunctionCodes : centreFunctionCodes
          },
          { addQueryPrefix: true }
        )}`
      )
    }

    if (
      has(queryParams, 'func') &&
      isArray(queryParams.func) &&
      (queryParams.func as string[]).includes('Ingka Centres')
    ) {
      window.location.replace(
        `${window.location.origin}${pathname}${qs.stringify(
          {
            ...queryParams,
            func: flatten(
              (queryParams.func as string[]).map(f => (f === 'Ingka Centres' ? ['Common Areas', 'Tenants'] : f))
            )
          },
          { addQueryPrefix: true }
        )}`
      )
    }
  }, [])

  return (
    <Providers notLoaded={NotLoaded}>
      <div className={classnames('Application')}>
        <ScrollToTop />
        <Routes>
          {generateRoutes()}
          {generateRedirects()}
          <Route element={<PageNotFound />} />
        </Routes>
      </div>
    </Providers>
  )
}

export default App
