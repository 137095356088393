import React from 'react'

export const EnergyEfficiencyModalContent = () => (
  <>
    <h3>Why do we measure Energy efficiency?</h3>
    <p>
      Reducing energy used is linked to Ingka core values of being cost effective and limit use of resources. It
      contributes to lowering the climate footprint through the transition to renewable energy and limiting grid losses
      for used energy.
    </p>
    <p>
      The energy efficiency is a measurement that has been used for long in Ingka to drive efficiency in how we operate
      our buildings. From FY24 it has been raised from site goal setting to also being part of Retail flight tower with
      a Retail country goal and linked to this getting a separate page in 4P.
    </p>
    <p>
      Ingka Retail has committed to a goal of reducing energy efficiency 40% from FY10 to FY30 and Centres joined
      committing to reduce 35% from FY16 to FY30.
    </p>
    <h3>How do we calculate Energy efficiency?</h3>
    <p>The simple explanation is we divide energy consumption with the gross building area and measure in kWh/m2.</p>
    <p>The more complete explanation reveals the difference purposes of the measurement.</p>
    <p>
      The goal in FY24 is set based on the scope historically used by FM for site goals and operational monitoring. That
      includes the energy where Ingka use the energy and own the contracts + where included in rent “Landlord” while all
      tenants energy is out of scope (“Allocated” + “Obtained”).
    </p>
    <ul>
      <li>
        The goal for Retail is only covering the customer meeting places and the offices, not customer fulfillment as it
        is not considered being a relevant measurement, investigations are ongoing if goods flow in m3 can be used to
        relate the energy amounts against.
      </li>
      <li>The goal for Centres is only for common area as that is what Ingka has the biggest influence on.</li>
    </ul>
    <p>
      To monitor operational performance this is a good measurement on a site level but it is comparing “apples and
      pears” as the GBA is for the full buildings including tenants. From FY25 it is agreed all reporting and goal
      setting down to site level need to be including tenants to match the externally reported scope and make sense in
      applying the same scope on energy included as the GBA.
    </p>
    <p>
      The Ingka performance linked to committed strategic goals was reported first time in annual report FY23 and there
      calculated as the total energy used divided by GBA where the total consumed energy is all energy reported in
      Sustain (Where we pay the bill + included in rent “Landlord” + tenants owning their own energy contracts
      “obtained”). The logic behind this is that we only have the full GBA and then we need to compare that with the
      complete energy used for that area. Due to reporting for electricity used for charging vehicles is today not
      possible to filter out it is also included but intention is to mature reporting and take it out.
    </p>
    <h3>What metrics are we following? </h3>
    <ul>
      <li>Gross building area (GBA)</li>
      <li>Total consumed energy incl EV and Landlord but excl all tenants*</li>
    </ul>
    <p>
      *as it is currently not possible to filter out tenants for Retail in 4P it is included here. It is not planned to
      be developed short term and from FY25 the goal internally will change scope to include tenants to match the full
      GBA and external reporting scope. Also from FY25 it is the ambition to have EV electricity sub-metered and thereby
      possible to exclude from energy efficiency goal and monitoring.
    </p>
  </>
)
