import { intersection } from 'lodash'
import { FunctionArea, SiteFunction } from '../../../api/src/common-types'
import { centreFunctionCodes, centreSiteFunctions, retailFunctionCodes, retailSiteFunctions } from '../LocationSearch'
import { CurrentLocation } from '../../context'
import { getLocationFunctions, getLocationId, isSiteId } from '../Utils/utils'

export const createNewSelections = (functionAreaOption: FunctionArea, currentLocation: CurrentLocation) => {
  const requiredFunctions =
    functionAreaOption === 'centres'
      ? centreFunctionCodes
      : functionAreaOption === 'retail'
      ? retailFunctionCodes
      : [...centreFunctionCodes, ...retailFunctionCodes]
  const locationHasNewFunctionArea = intersection(getLocationFunctions(currentLocation), requiredFunctions).length > 0
  const currentLocationId = getLocationId(currentLocation)
  const newLocationId = locationHasNewFunctionArea
    ? currentLocationId
    : isSiteId(currentLocationId)
    ? 'ALL'
    : currentLocation.isCluster
    ? currentLocation.cluster.countryCodes[0]
    : currentLocationId
  const pathname = locationHasNewFunctionArea
    ? undefined
    : `/${newLocationId}/${location.pathname.split('/').slice(-1)}`

  return {
    functionArea: functionAreaOption,
    func:
      functionAreaOption === 'retail'
        ? retailSiteFunctions
        : functionAreaOption === 'centres'
        ? centreSiteFunctions
        : (['ALL'] as SiteFunction[]),
    pathname
  }
}

export const getCurrentFunc = (functionArea: FunctionArea) => {
  switch (functionArea) {
    case 'retail':
      return ['Retail', 'Customer Fulfillment', 'Support units']
    case 'centres':
      return ['Common Areas', 'Tenants']
    default:
      return ['ALL']
  }
}
