import React from 'react'
import { barChart } from './BaseGraphs/GraphUtil'
import { ChartContainer } from './BaseGraphs/ChartContainer'
import colours from '../Colours.module.scss'

const ZeroGraph = ({ domain, fy }: { domain: Date[]; fy: number }) => {
  const zeroSeries = [
    {
      name: `FY${fy - 1}`,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: domain.map(d => ({ x: d, y: 0 }))
    },
    {
      name: `FY${fy}`,
      color: colours.darkBlue1,
      data: domain.map(d => ({ x: d, y: 0 }))
    }
  ]

  return (
    <div className="GraphContainer">
      <ChartContainer domain={domain} series={zeroSeries} generator={barChart} dateFormat="month" />
    </div>
  )
}

export default ZeroGraph
