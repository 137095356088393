import React from 'react'

interface FeedbackWithSetter {
  isFeedbackOpen: boolean
  openFeedbackModal: () => void
  closeFeedbackModal: () => void
}

const emptyFeedbackWithSetter = {
  isFeedbackOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openFeedbackModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeFeedbackModal: () => {}
}

export const FeedbackContext = React.createContext<FeedbackWithSetter>(emptyFeedbackWithSetter)
export const useFeedbackContext = () => React.useContext(FeedbackContext)
