import React from 'react'
import classnames from 'classnames'
import SkapaModal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal'
import { isSite, LocationWithType } from '../lib/APIClient'
import { CountryRankModalContent } from './ModalContent/CountryRankModalContent'
import { KpiPerceptionModalContent } from './ModalContent/KpiPerceptionModalContent'
import { KpiPlanetaryFootprintModalContent } from './ModalContent/KpiPlanetaryFootprintModalContent'
import { ClimateExplorerModalContent } from './ModalContent/ClimateExplorerModalContent'
import { KpiPppSalesModalContent } from './ModalContent/KpiPppSalesModalContent'
import { SiteOrCountryInfoModalContent } from './ModalContent/SiteOrCountryInfoModalContent'
import { SiteRankModalContent } from './ModalContent/SiteRankModalContent'
import { WhatIfModalContent } from './ModalContent/WhatIfModalContent'
import { KpiCustomerDeliveriesModalContent } from './ModalContent/KpiCustomerDeliveriesModalContent'

import defaultHeaderImage from '../images/HowIsThisCalculated/ModalHeader.png'
import perceptionHeader from '../images/HowIsThisCalculated/PerceptionHeader.png'
import planetaryFootprintHeader from '../images/HowIsThisCalculated/PlanetaryFootprintHeader.png'
import planetaryExploreFootprintHeader from '../images/HowIsThisCalculated/ExploreFootprintModal.jpg'
import pppSalesHeader from '../images/HowIsThisCalculated/PppSalesHeader.png'
import zeroWasteHeader from '../images/HowIsThisCalculated/ZeroWasteHeader.png'
import socialImpactHeader from '../images/HowIsThisCalculated/SocialImpactHeader.jpg'
import changeMakersHeader from '../images/HowIsThisCalculated/ChangeMakersHeader.jpg'
import foodHeader from '../images/HowIsThisCalculated/FoodHeader.png'
import customerTravelHeader from '../images/HowIsThisCalculated/CustomerTravelHeader.jpg'

import './Modal.scss'
import { KpiRecyclingRateContent } from './ModalContent/KpiRecyclingRateContent'
import { KpiSocialImpactModalContent } from './ModalContent/KpiSocialImpactModalContent'
import { ClimateBenchmarkForDownload, SiteFunction } from '../../api/src/common-types'
import { KpiFoodModalContent } from './ModalContent/KpiFoodModalContent'
import { KpiChangeMakersModalContent } from './ModalContent/KpiChangeMakersModalContent'
import { Route } from '../routes'
import KpiFootprintModalContent, { KpiFootprintModalContentProps } from './ModalContent/KpiFootprintModalContent'
import { formatAbsoluteNumber } from './Utils/format'
import { EnergyEfficiencyModalContent } from './ModalContent/EnergyEfficiencyModalContent'
import { CustomerTravelModalContent } from './ModalContent/CustomerTravelModalContent'
import ExcelExportButton from './ExcelExportButton'
import { WaterEfficiencyModalContent } from './ModalContent/WaterEfficiencyModalContent'
import { KpiCircularRevenueModalContent } from './ModalContent/KpiCircularRevenueModalContent'

interface KpiModalOpen {
  page: Route
  isOpen: true
  fiscalYear?: number
}

interface KpiModalClosed {
  isOpen: false
  fiscalYear?: number
}

export type KpiModalState = KpiModalOpen | KpiModalClosed

interface ModalWithContentProps {
  isOpen: boolean
  onClose: () => void
}

interface DetailedActionViewModalProps extends ModalWithContentProps {
  isSite: boolean
  series: KpiFootprintModalContentProps[]
  headers: string[]
  bottom: KpiFootprintModalContentProps
  fy?: number
}

interface KpiPageLearnMoreModalProps {
  isExplore?: boolean
  lastUpdated?: string
  modalState: KpiModalState
  onClose: () => void
}

interface SiteOrCountryInfoModalProps extends ModalWithContentProps {
  func: SiteFunction[]
  siteOrCountry: LocationWithType
}

interface ModalProps extends ModalWithContentProps {
  className?: string
  headerImage?: string
  size?: 'small' | 'medium' | 'large'
  title: string
}

interface TeaserModalProps {
  title: string
  image?: string
}

const Modal: React.FC<ModalProps> = ({
  className,
  children,
  headerImage = defaultHeaderImage,
  isOpen,
  onClose,
  size = 'large',
  title
}) => {
  return (
    <SkapaModal className="SkapaModal" visible={isOpen} keepContentMounted handleCloseBtn={onClose}>
      <Sheets size={size} header={<ModalHeader title={title} />} footer={null}>
        {headerImage === 'none' ? (
          <ModalBody className={classnames('Body', className)}>{children}</ModalBody>
        ) : (
          <>
            <img src={headerImage} className="HeaderImage" alt="Header image" />
            <ModalBody className={classnames('Body', className)}>{children}</ModalBody>
          </>
        )}
      </Sheets>
    </SkapaModal>
  )
}

export const CountryRankModal: React.FC<ModalWithContentProps> = props => (
  <Modal className="LearnMoreBody" title="How do we calculate your country rank?" {...props}>
    <CountryRankModalContent />
  </Modal>
)

export const KpiPageLearnMoreModal: React.FC<KpiPageLearnMoreModalProps> = ({
  isExplore,
  lastUpdated,
  modalState,
  ...props
}) => {
  const getHeaderAndContent = (): [string, string, React.ReactNode] => {
    if (modalState.isOpen) {
      switch (modalState.page) {
        case Route.PppSalesKPIPage:
          return [
            pppSalesHeader,
            'People + Planet Positive Sales',
            <KpiPppSalesModalContent lastUpdated={lastUpdated} key="profitgrowth" />
          ]
        case Route.SustainabilityMentionsKPIPage:
          return [perceptionHeader, 'Sustainability Mentions', <KpiPerceptionModalContent key="perception" />]
        case Route.ClimateKPIPage:
          return [
            planetaryFootprintHeader,
            'Climate Footprint',
            <KpiPlanetaryFootprintModalContent lastUpdated={lastUpdated} key="planetaryfootprint" />
          ]
        case Route.ClimateExplorePage:
          return [
            planetaryExploreFootprintHeader,
            'What are goals and actions?',
            <ClimateExplorerModalContent lastUpdated={lastUpdated} key="planetaryexplorefootprint" />
          ]
        case Route.ZeroWasteKPIPage:
          return [zeroWasteHeader, 'Recycling Rate', <KpiRecyclingRateContent key="recyclingrate" />]
        case Route.CircularRevenueKPIPage:
          return ['none', 'Circular Revenue', <KpiCircularRevenueModalContent key="circularrevenue" />]
        case Route.SocialImpactKPIPage:
          return [socialImpactHeader, 'Social Impact', <KpiSocialImpactModalContent key="socialimpact" />]
        case Route.ChangeMakersKPIPage:
          return [changeMakersHeader, 'Change Makers', <KpiChangeMakersModalContent key="changemakers" />]
        case Route.CustomerDeliveriesKPIPage:
          return [
            defaultHeaderImage,
            'Climate Footprint – Zero Emission Deliveries',
            <KpiCustomerDeliveriesModalContent key="customerdeliveries" />
          ]
        case Route.FoodIngredientsKPIPage:
          return [foodHeader, 'Climate Footprint of Food Ingredients', <KpiFoodModalContent key="food" />]
        case Route.EnergyEfficiencyKPIPage:
          return [foodHeader, 'Energy Efficiency', <EnergyEfficiencyModalContent key="energy" />]
        case Route.CustomerTravelKPIPage:
          return [customerTravelHeader, 'Customer Travel', <CustomerTravelModalContent key="travel" />]
        case Route.WaterEfficiencyKPIPage:
          return [foodHeader, 'WATER EFFICIENCY', <WaterEfficiencyModalContent key="water" />]
      }
    }
    return [defaultHeaderImage, '', null]
  }
  const [headerImage, title, content] = getHeaderAndContent()

  return (
    <Modal
      className="KpiLearnMoreBody"
      title={isExplore ? title : `How do we calculate ${title}?`}
      headerImage={headerImage}
      isOpen={modalState.isOpen}
      {...props}
    >
      {content}
    </Modal>
  )
}

export const SiteOrCountryInfoModal: React.FC<SiteOrCountryInfoModalProps> = ({ func, siteOrCountry, ...props }) => {
  if (!siteOrCountry) {
    return null
  }

  const title = isSite(siteOrCountry) ? siteOrCountry.siteName : siteOrCountry.countryName

  return (
    <Modal className="SiteOrCountryInfoBody" size="small" title={title} {...props}>
      <SiteOrCountryInfoModalContent func={func} siteOrCountry={siteOrCountry} />
    </Modal>
  )
}

export const SiteRankModal: React.FC<ModalWithContentProps> = props => (
  <Modal className="LearnMoreBody" title="How do we calculate your site rank?" {...props}>
    <SiteRankModalContent />
  </Modal>
)

export const WhatIfLearnMoreModal: React.FC<ModalWithContentProps> = props => (
  <Modal className="LearnMoreBody" title="What-If – How this is calculated" {...props}>
    <WhatIfModalContent />
  </Modal>
)

export const ZeroWasteLearnMoreModal: React.FC<ModalWithContentProps> = props => (
  <Modal className="LearnMoreBody" title="Recycling Rate" {...props}>
    <KpiRecyclingRateContent />
  </Modal>
)

export const TeaserModal: React.FC<TeaserModalProps> = ({ title, image, children }) => (
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  <SkapaModal className="SkapaModal" visible={true} keepContentMounted handleCloseBtn={() => {}}>
    <Sheets size="large" header={<ModalHeader title={`${title} - Coming soon`} />} footer={null}>
      <img src={image} className="HeaderImage" alt="Header image" />
      <ModalBody className={classnames('Body', 'LearnMoreBody')}>{children}</ModalBody>
    </Sheets>
  </SkapaModal>
)

export const DetailedActionViewModal: React.FC<DetailedActionViewModalProps> = props => {
  return (
    <Modal
      className="ActionsImpacting"
      title={props.isSite ? `Planned activities impacting FY${props.fy}` : `Actions impacting FY${props.fy}`}
      {...props}
      headerImage="none"
    >
      <div className={props.isSite ? 'ActionsImpacting-headers-site' : 'ActionsImpacting-headers-country'}>
        {props.headers.map(h => (
          <div key={h}>{h}</div>
        ))}
      </div>
      {props.series.map((i, ind) => (
        <KpiFootprintModalContent item={i} key={ind} />
      ))}
      <div className={props.isSite ? 'ActionsImpacting-bottom-site' : 'ActionsImpacting-bottom-country'}>
        {Object.values(props.bottom).map((b, i) => (
          <div key={i}>{typeof b === 'number' ? formatAbsoluteNumber(b) : b}</div>
        ))}
      </div>
    </Modal>
  )
}

interface KpiPageDownloadMoreModalProps {
  isExplore?: boolean
  lastUpdated?: string
  modalState: boolean
  onClose: () => void
  data: ClimateBenchmarkForDownload[]
}

export const BenchmarkingDownloadModal: React.FC<KpiPageDownloadMoreModalProps> = ({ modalState, data, ...props }) => {
  const relativeDataForDowload = data.map(benchmark => ({
    ...benchmark,
    currentYtd: safeDivide(benchmark.currentYtd, benchmark.area) ?? 0,
    previousFy: safeDivide(benchmark.previousFy, benchmark.area) ?? 0,
    previousYtd: safeDivide(benchmark.previousYtd, benchmark.area) ?? 0,
    rolling: safeDivide(benchmark.rolling, benchmark.area) ?? 0
  }))

  const dataForDowload = data.map(benchmark => ({
    ...benchmark,
    currentYtd: benchmark.currentYtd / 1000,
    previousFy: benchmark.previousFy / 1000,
    previousYtd: benchmark.previousYtd / 1000,
    rolling: benchmark.rolling / 1000
  }))
  return (
    <Modal
      className="BenchDownloadBody"
      title={`Select the data to be exported to Excel file`}
      isOpen={modalState}
      size="small"
      headerImage="none"
      {...props}
    >
      <ExcelExportButton classes="BenchDownloadContent" data={dataForDowload}>
        <span>Benchmarking</span> <span>tonnes CO2e</span>
      </ExcelExportButton>
      <ExcelExportButton classes="BenchDownloadContent" data={relativeDataForDowload}>
        <span>Benchmarking</span> <span>tonnes CO2e/m2</span>{' '}
      </ExcelExportButton>
    </Modal>
  )
}

const safeDivide = (numerator: number, denominator: number | undefined) => {
  if (denominator && (denominator === 0 || isNaN(denominator) || !isFinite(denominator))) {
    return 0
  }
  const result = denominator && numerator / denominator
  return result && (isNaN(result) || !isFinite(result) ? 0 : result)
}
