import React from 'react'
import classNames from 'classnames'
import { minBy, range as _range } from 'lodash'

import './Slider.scss'

interface SliderProps {
  range: [number, number]
  onChange: (value: number) => void
  step?: number
  value?: number
  overlay?: number
}

export const Slider: React.FC<SliderProps> = ({ range, onChange, step = 1, value, overlay }) => {
  const [internalValue, setInternalValue] = React.useState(range[0])
  const [dragging, setDragging] = React.useState(false)
  const [overlayLeftPx, setOverlayLeftPx] = React.useState<string>()
  const sliderRef = React.useRef<HTMLDivElement>(null)
  const lineRef = React.useRef<HTMLDivElement>(null)

  const controlledValue = value ? value / range[1] : undefined
  const values = React.useMemo(() => _range(range[0], range[1] + 1, step).map(n => n / range[1]), [range, step])

  const handleChangeEvent = (e: React.MouseEvent<HTMLDivElement>, animated: boolean) => {
    if (lineRef.current && sliderRef.current) {
      const lineRect = lineRef.current.getBoundingClientRect()
      const val = Math.min(Math.max((e.clientX - lineRect.left) / lineRect.width, 0), 1)
      const closestValue = minBy(values, n => Math.abs(n - val)) ?? 0
      sliderRef.current.className = classNames('Indicator', { Animated: animated })
      onChange(closestValue * range[1])
      setInternalValue(closestValue)
    }
  }

  React.useLayoutEffect(() => {
    if (sliderRef.current && lineRef.current) {
      const sliderBBox = sliderRef.current.getBoundingClientRect()
      const lineBBox = lineRef?.current?.getBoundingClientRect()
      sliderRef.current.style.left = `${
        (controlledValue || internalValue) * lineBBox.width + lineBBox.left - sliderBBox.width / 2
      }px`
    }
  }, [internalValue, controlledValue])

  React.useLayoutEffect(() => {
    if (!overlayLeftPx && overlay && lineRef.current) {
      const lineBBox = lineRef?.current?.getBoundingClientRect()
      setOverlayLeftPx(`${(overlay / range[1]) * lineBBox.width + lineBBox.left}px`)
    }
  }, [overlay, overlayLeftPx, lineRef.current])

  return (
    <div className="Slider" onClick={e => handleChangeEvent(e, true)}>
      <div ref={lineRef} className="SliderLine" />
      {overlay && (
        <div
          className="OverlayIndicator"
          style={{
            left: overlayLeftPx
          }}
        />
      )}
      <div
        ref={sliderRef}
        className="Indicator"
        onPointerDown={e => {
          setDragging(true)
          sliderRef.current?.setPointerCapture(e.pointerId)
        }}
        onPointerUp={e => {
          setDragging(false)
          sliderRef.current?.releasePointerCapture(e.pointerId)
        }}
        onPointerMove={e => {
          if (dragging) {
            handleChangeEvent(e, false)
            e.preventDefault()
          }
        }}
      />
    </div>
  )
}
