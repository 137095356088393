import React from 'react'
import classNames from 'classnames'
import { groupBy, isArray, max, min, sumBy } from 'lodash'
import { format, getMonth } from 'date-fns'

import { SumIndicator, RectIndicator } from './BaseGraphs/Indicators'
import { formatAbsoluteNumber, formatRelativeNumber } from './Utils/format'
import { ChartContainer, Serie } from './BaseGraphs/ChartContainer'
import { SimpleBar } from './BaseGraphs/SimpleBar'
import { stackedBarChart, lineChart } from './BaseGraphs/GraphUtil'
import { FoodFootprint, ClimateIntensityBenchmarkData } from '../../api/src/common-types'
import { LoadingSkeleton } from './LoadingSkeleton'
import { ArrowIcon } from './ArrowIcon'
import { getCurentAndPreviousFYMonth, getLocationOrDefault } from './Utils/utils'

import './FoodIngredientsGraphs.scss'
import colours from '../Colours.module.scss'
import { ClimateGraphDataContext } from '../pages/KPIPages/FoodIngredients/providers/ClimateGraphProvider'
import { getIngkaFinancialYear } from './Utils/dates'
import { FilterDropdownMenu } from './FilterMenu/FilterDropdownMenu'
import { monthFromDates, yearsFromDates } from '../pages/KPIPages/FoodIngredients/utils/mappers'

interface TotalClimateFootprintProps {
  data?: FoodFootprint[]
  currentFY?: number
  previousFY?: number
}

interface ClimateIntensityBenchmarkProps {
  data?: ClimateIntensityBenchmarkData[]
  benchmarkOpenFn: () => void
  ytdClimateIntensity?: number
  actualPeriod: string | null
  availableDates: string[]
  fetchIntensityData: (fy: number, period: number) => void
}

interface SalesShareChartProps extends TotalClimateFootprintProps {
  plantBasedAvgYTD: string
}

const prevFYDomainDay = 1
const currFYDomainDay = 16
const emptyDomainDay = 28

const zeroPad = (n: number) => (n < 10 ? `0${n}` : String(n))

const toDomainDay = (domainDay: number, fy?: number) => (food: FoodFootprint) => {
  const date = new Date(`${fy}-${zeroPad(getMonth(new Date(food.readableDate)) + 1)}-10`)
  date.setDate(domainDay)

  return {
    ...food,
    readableDate: format(date, 'yyyy-MM-dd')
  }
}

const formatDateUTC = (readableDate: string, dayOverride?: number) => {
  const splitDate = readableDate.split('-').map(num => (num[0] === '0' ? Number(num[1]) - 1 : Number(num) - 1))
  return new Date(Date.UTC(splitDate[0] + 1, splitDate[1], dayOverride ?? splitDate[2]))
}

export const TotalClimateFootprintChart: React.FC<TotalClimateFootprintProps> = ({ data, previousFY, currentFY }) => {
  const dataByFys = groupBy(data, 'fiscalYear')
  const prevFYTitle = `FY${(previousFY ?? 2000) - 2000}`
  const currFYTitle = `FY${(currentFY ?? 2000) - 2000}`
  const dataPrevFY = dataByFys[String(previousFY)]?.map(toDomainDay(prevFYDomainDay, previousFY)) ?? []
  const dataCurrFY = dataByFys[String(currentFY)]?.map(toDomainDay(currFYDomainDay, previousFY)) ?? []

  const series = !isArray(data)
    ? undefined
    : data.length === 0
    ? []
    : [
        {
          name: `Plant-based ${currFYTitle}`,
          color: colours.green1,
          data: dataCurrFY.map(x => ({
            x: formatDateUTC(x.readableDate, currFYDomainDay + 1),
            y: x.plantBasedFootprint
          }))
        },
        {
          name: `Non-red meat ${currFYTitle}`,
          color: colours.salmon,
          data: dataCurrFY.map(x => ({
            x: formatDateUTC(x.readableDate, currFYDomainDay + 1),
            y: x.nonRedMeatBasedFootprint
          }))
        },
        {
          name: `Red meat ${currFYTitle}`,
          color: colours.salmon1,
          data: dataCurrFY.map(x => ({
            x: formatDateUTC(x.readableDate, currFYDomainDay + 1),
            y: x.redMeatBasedFootprint
          }))
        },
        {
          name: `Plant-based ${prevFYTitle}`,
          color: colours.lightGreen2,
          data: dataPrevFY.map(x => ({
            x: formatDateUTC(x.readableDate, prevFYDomainDay + 1),
            y: x.plantBasedFootprint
          }))
        },
        {
          name: `Non-red meat ${prevFYTitle}`,
          color: colours.lightPink,
          data: dataPrevFY.map(x => ({
            x: formatDateUTC(x.readableDate, prevFYDomainDay + 1),
            y: x.nonRedMeatBasedFootprint
          }))
        },
        {
          name: `Red meat ${prevFYTitle}`,
          color: colours.lightPink1,
          data: dataPrevFY.map(x => ({
            x: formatDateUTC(x.readableDate, prevFYDomainDay + 1),
            y: x.redMeatBasedFootprint
          }))
        }
      ]

  const getTooltipSum = (date: Date) => {
    if (!data) {
      return []
    }

    const dataForDate =
      date.getDate() === prevFYDomainDay
        ? data.find(d => d.fiscalYear === previousFY && new Date(d.readableDate).getMonth() === date.getMonth())
        : data.find(d => d.fiscalYear === previousFY && new Date(d.readableDate).getMonth() === date.getMonth())

    const sum =
      (dataForDate?.plantBasedFootprint ?? 0) +
      (dataForDate?.nonRedMeatBasedFootprint ?? 0) +
      (dataForDate?.redMeatBasedFootprint ?? 0)

    return [{ title: 'Total', value: formatAbsoluteNumber(Math.round(sum)), icon: <SumIndicator /> }]
  }

  const dates = dataPrevFY
    ?.map(({ readableDate }) => {
      const d1 = formatDateUTC(readableDate, prevFYDomainDay + 1)
      const d2 = formatDateUTC(readableDate, currFYDomainDay + 1)
      const d3 = formatDateUTC(readableDate, emptyDomainDay)

      return [d1, d2, d3]
    })
    .flat()

  // Arrange domain to start from September
  const septemberDate = formatDateUTC(`${previousFY}-09-10`, prevFYDomainDay + 1)
  const domain = [
    ...dates.filter(d => d.getTime() >= septemberDate.getTime()),
    ...dates.filter(d => d.getTime() < septemberDate.getTime()).sort((a, b) => a.getTime() - b.getTime())
  ]

  return (
    <div className="GraphContainer">
      <ChartContainer
        domain={domain}
        series={series}
        generator={stackedBarChart('none')}
        dateFormat="monthTwoFYs"
        tooltipSummary={date => getTooltipSum(date)}
        yAxisTitle="tonnes CO2e"
      />
    </div>
  )
}

export const getTotalFootprint = (footprint: FoodFootprint) =>
  footprint.plantBasedFootprint + footprint.nonRedMeatBasedFootprint + footprint.redMeatBasedFootprint
export const getTotalAmountKg = (footprint: FoodFootprint) =>
  footprint.plantBasedAmountKg + footprint.nonRedMeatBasedAmountKg + footprint.redMeatBasedAmountKg

const generateClimateIntensitySeries = (
  dataByFys: Record<string, FoodFootprint[]>,
  countryData: Serie,
  globalData: Serie
) => {
  const fys = Object.keys(dataByFys).map(fy => parseInt(fy, 10))
  if (fys.length === 0) {
    return {
      average: 0,
      series: [],
      currFySerie: {} as Serie,
      prevFySerie: {} as Serie
    }
  }
  const currFy = `${max(fys)}`
  const prevFy = `${min(fys)}`

  const findNeededReadableDate = (date: string | Date) => {
    const element = dataByFys[prevFy].find(item => new Date(item.readableDate).getMonth() === new Date(date).getMonth())

    return new Date(element?.readableDate || '')
  }

  const currFySerie: Serie = {
    color: colours.darkBlue,
    name: `FY${parseInt(currFy) - 2000}`,
    data: dataByFys[currFy]
      .map(data => {
        return {
          x: findNeededReadableDate(data.readableDate),
          y: getTotalFootprint(data) / getTotalAmountKg(data)
        }
      })
      .sort((a, b) => a.x.getTime() - b.x.getTime()),
    unit: '%'
  }

  const prevFySerie: Serie = {
    color: colours.offWhite1,
    fill: colours.grey1,
    name: `FY${parseInt(prevFy) - 2000}`,
    data: dataByFys[prevFy]
      .map(data => {
        return {
          x: new Date(data.readableDate),
          y: getTotalFootprint(data) / getTotalAmountKg(data)
        }
      })
      .sort((a, b) => a.x.getTime() - b.x.getTime()),
    unit: '%'
  }

  const ytd = sumBy(dataByFys[currFy], getTotalFootprint) / sumBy(dataByFys[currFy], getTotalAmountKg)

  const series = [prevFySerie, currFySerie]

  if (countryData.data.length !== 0) {
    const countrySerie = {
      ...countryData,
      data: countryData.data.map(item => {
        return {
          ...item,
          x: findNeededReadableDate(item.x)
        }
      })
    }

    series.push(countrySerie)
  }
  if (globalData.data.length !== 0) {
    const globalSerie = {
      ...globalData,
      unit: '%',
      data: globalData.data.map(item => {
        return {
          ...item,
          x: findNeededReadableDate(item.x)
        }
      })
    }

    series.push(globalSerie)
  }

  return {
    ytd,
    series,
    currFySerie,
    prevFySerie
  }
}

export const ClimateIntensityChart = ({ data }: TotalClimateFootprintProps) => {
  const { globalData, countryData, isLoading } = React.useContext(ClimateGraphDataContext)

  const dataByFys = groupBy(data, 'fiscalYear')
  const { ytd, series, prevFySerie } = generateClimateIntensitySeries(dataByFys, countryData, globalData)

  const domain = prevFySerie ? prevFySerie?.data?.map(el => el.x) : series.length ? series[0].data.map(el => el.x) : []

  return (
    <div className="CardContainer IntensitySold">
      <div className="CardHeading FirstItem">
        Climate Intensity of Food Ingredients Sold
        {ytd && (
          <div className="Right">
            <span className="Amount">{formatRelativeNumber(ytd)}</span>{' '}
            <span className="Unit">kg CO2e/kg food sold YTD</span>
          </div>
        )}
      </div>
      {!isLoading ? (
        <ChartContainer
          generator={lineChart}
          domain={domain}
          dateFormat="month"
          series={series}
          yAxisTitle="KG CO2E/KG FOOD"
          lineChartConfiguration={{
            startFromZero: false,
            focusStyle: 'none'
          }}
          isSmallGraph
          yAxisLabelTooltipOnly
        />
      ) : (
        <LoadingSkeleton />
      )}
    </div>
  )
}

function getSalesQtyPercentage(f: FoodFootprint, key: keyof FoodFootprint): number {
  return ((f[key] as number) / (f.plantBasedSalesQty + f.nonRedMeatBasedSalesQty + f.redMeatBasedSalesQty)) * 100
}

export const SalesShareChart = ({ data, previousFY, currentFY, plantBasedAvgYTD }: SalesShareChartProps) => {
  const dataByFys = groupBy(data, 'fiscalYear')
  const prevFYTitle = `FY${(previousFY ?? 2000) - 2000}`
  const currFYTitle = `FY${(currentFY ?? 2000) - 2000}`
  const dataPrevFY = dataByFys[String(previousFY)]?.map(toDomainDay(prevFYDomainDay, previousFY)) ?? []
  const dataCurrFY = dataByFys[String(currentFY)]?.map(toDomainDay(currFYDomainDay, previousFY)) ?? []

  const salesQtySeries = !isArray(data)
    ? undefined
    : data.length === 0
    ? []
    : [
        {
          name: `Plant-based ${currFYTitle}`,
          color: colours.green1,
          data: dataCurrFY.map(x => ({
            x: formatDateUTC(x.readableDate, currFYDomainDay + 1),
            y: getSalesQtyPercentage(x, 'plantBasedSalesQty')
          })),
          unit: '%'
        },
        {
          name: `Non-red meat ${currFYTitle}`,
          color: colours.salmon,
          data: dataCurrFY.map(x => ({
            x: formatDateUTC(x.readableDate, currFYDomainDay + 1),
            y: getSalesQtyPercentage(x, 'nonRedMeatBasedSalesQty')
          })),
          unit: '%'
        },
        {
          name: `Red meat ${currFYTitle}`,
          color: colours.salmon1,
          data: dataCurrFY.map(x => ({
            x: formatDateUTC(x.readableDate, currFYDomainDay + 1),
            y: getSalesQtyPercentage(x, 'redMeatBasedSalesQty')
          })),
          unit: '%'
        },
        {
          name: `Plant-based ${prevFYTitle}`,
          color: colours.lightGreen2,
          data: dataPrevFY.map(x => ({
            x: formatDateUTC(x.readableDate, prevFYDomainDay + 1),
            y: getSalesQtyPercentage(x, 'plantBasedSalesQty')
          })),
          unit: '%'
        },
        {
          name: `Non-red meat ${prevFYTitle}`,
          color: colours.lightPink,
          data: dataPrevFY.map(x => ({
            x: formatDateUTC(x.readableDate, prevFYDomainDay + 1),
            y: getSalesQtyPercentage(x, 'nonRedMeatBasedSalesQty')
          })),
          unit: '%'
        },
        {
          name: `Red meat ${prevFYTitle}`,
          color: colours.lightPink1,
          data: dataPrevFY.map(x => ({
            x: formatDateUTC(x.readableDate, prevFYDomainDay + 1),
            y: getSalesQtyPercentage(x, 'redMeatBasedSalesQty')
          })),
          unit: '%'
        }
      ]

  const dates = dataPrevFY
    ?.map(({ readableDate }) => {
      const d1 = formatDateUTC(readableDate, prevFYDomainDay + 1)
      const d2 = formatDateUTC(readableDate, currFYDomainDay + 1)
      const d3 = formatDateUTC(readableDate, emptyDomainDay)

      return [d1, d2, d3]
    })
    .flat()

  // Arrange domain to start from September
  const septemberDate = formatDateUTC(`${previousFY}-09-10`, prevFYDomainDay + 1)
  const domain = [
    ...dates.filter(d => d.getTime() >= septemberDate.getTime()),
    ...dates.filter(d => d.getTime() < septemberDate.getTime()).sort((a, b) => a.getTime() - b.getTime())
  ]

  return (
    <div className="CardContainer">
      <div className="CardHeading FirstItem">
        Sales Quantity Share
        <div className="Right">
          <span className="Amount">{plantBasedAvgYTD}</span> <span className="Unit">% plant based YTD</span>
        </div>
      </div>
      <ChartContainer
        maxValue={100}
        domain={domain}
        series={salesQtySeries}
        generator={stackedBarChart('none')}
        dateFormat="monthTwoFYs"
      />
    </div>
  )
}

const createBenchmarkLocationLabel = (label: string, Bold = false) => (
  <div
    className={classNames({
      SiteLink: true,
      Bold
    })}
  >
    <div>{label}</div>
  </div>
)

export const ClimateIntensityBenchmarkChart = ({
  data = [],
  benchmarkOpenFn,
  actualPeriod,
  availableDates,
  fetchIntensityData
}: ClimateIntensityBenchmarkProps) => {
  const [yearValue, setYearValue] = React.useState<string>('')
  const [monthValue, setMonthValue] = React.useState<string>('')

  const locationId = getLocationOrDefault()
  const currentDate = new Date(actualPeriod || '')
  const prevMonth = new Date(actualPeriod || '')
  prevMonth.setDate(1)
  prevMonth.setMonth(currentDate.getMonth() - 1)
  const currMonthFY = `FY${getIngkaFinancialYear(currentDate).getFullYear() - 2000}`
  const prevMonthFY = `FY${getIngkaFinancialYear(prevMonth).getFullYear() - 2000}`

  const yearsOptions = yearsFromDates(availableDates)
  const monthOptions = monthFromDates(availableDates, Number(yearValue || 2000))

  React.useEffect(() => {
    const date = new Date(availableDates[availableDates.length - 1])
    const year = getIngkaFinancialYear(date).getFullYear().toString()
    const month = (date.getMonth() + 1).toString()

    setYearValue(year)
    setMonthValue(month)
  }, [availableDates])

  const series = [
    {
      name: `climateIntensity`,
      color: colours.salmon,
      data: data
        .filter(d => d.selectable)
        .map(d => ({
          name: d.label,
          value: d.currentMonthFootprint / d.currentMonthSoldWeight,
          createElement: () => createBenchmarkLocationLabel(d.label, d.id === locationId),
          overlay: {
            value: d.previousMonthFootprint / d.previousMonthSoldWeight,
            overlayBarColor: colours.lightPink,
            color: colours.black
          }
        }))
    }
  ]

  const totalLocations = series[0]?.data.length ?? 0

  const tooltipFn = (i: number) => {
    const location = data[i]
    const prevMonthData = location.previousMonthFootprint / location.previousMonthSoldWeight

    return {
      heading: location.label,
      body: [
        [
          {
            title: `${currentDate.toLocaleString('default', { month: 'long' })} ${currMonthFY}`,
            value: formatRelativeNumber(location.currentMonthFootprint / location.currentMonthSoldWeight),
            color: colours.salmon,
            icon: <RectIndicator width={16} className="CurrentPeriodTooltipIndicator" />
          },
          {
            title: `${prevMonth.toLocaleString('default', { month: 'long' })} ${prevMonthFY}`,
            value: prevMonthData ? formatRelativeNumber(prevMonthData) : 'N/A',
            color: colours.lightPink,
            icon: (
              <div className="PreviousPeriodTooltipIndicator">
                <RectIndicator width={16} />
              </div>
            )
          }
        ]
      ]
    }
  }

  return (
    <div className="MainCard">
      <div className="CardHeading FirstItem">
        Climate Intensity of Food Ingredients Sold - Benchmark
        <div className="Filters">
          <FilterDropdownMenu
            label="Year"
            name="year"
            selected={yearValue}
            options={yearsOptions}
            onChange={item => {
              const newMonthOptions = monthFromDates(availableDates, Number(item))
              const neededMonth = newMonthOptions.find(item => item.value === monthValue)
              const value = neededMonth?.value || newMonthOptions[newMonthOptions.length - 1].value

              setMonthValue(value)
              setYearValue(item)
              fetchIntensityData(Number(item), Number(value))
            }}
            disabled={false}
          />
          <FilterDropdownMenu
            label="Month"
            name="month"
            selected={monthValue}
            options={monthOptions}
            onChange={item => {
              setMonthValue(item)
              fetchIntensityData(Number(yearValue), Number(item))
            }}
            disabled={false}
          />
        </div>
      </div>
      <div className="ClimateIntensityBenchmarkGraphContainer">
        {data?.length ? (
          <>
            <ClimateIntensityBenchmarkLegend actualPeriod={actualPeriod || ''} />
            <SimpleBar
              series={series}
              withChange
              stacked={false}
              tooltipFn={tooltipFn}
              longSerieNames
              xAxisTitle="kg CO2e/kg food sold"
            />
            <div className="SeeMore" onClick={benchmarkOpenFn}>
              <ArrowIcon angle={90} width={24} height={24} color={colours.offWhite3} />
              <div className="Link">
                View all {totalLocations} {locationId === 'ALL' ? 'countries' : 'sites'}
              </div>
            </div>
          </>
        ) : (
          <LoadingSkeleton />
        )}
      </div>
    </div>
  )
}

interface LegendProps {
  actualPeriod: string
}

const ClimateIntensityBenchmarkLegend = ({ actualPeriod }: LegendProps) => {
  const { currentFYMonth, prevFYMonth }: { currentFYMonth: string; prevFYMonth: string } =
    getCurentAndPreviousFYMonth(actualPeriod)

  return (
    <div className="ClimateIntensityBenchmarkLegendContainer">
      <RectIndicator width={16} className="CurrentPeriodIndicator" />
      <span>{currentFYMonth}</span>
      <div className="LastPeriodIndicator">
        <RectIndicator width={16} />
      </div>
      <span>{prevFYMonth}</span>
    </div>
  )
}
