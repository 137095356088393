import React, { useEffect } from 'react'
import SkapaModal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal'
import { FlowOption, HFBShare } from '../../api/src/common-types'
import { SimpleBar } from './BaseGraphs/SimpleBar'
import { TopSellingArticlesGraph } from './TopSellingArticlesGraph'
import { ReactComponent as EyeIcon } from '../images/Icons/Eye.svg'

import './HFBSharesGraph.scss'
import colours from '../Colours.module.scss'
import { Tooltip } from './Tooltip'
import { ActiveIndicator, BlackInformationIndicator } from './BaseGraphs/Indicators'
import { formatAbsoluteNumber } from './Utils/format'
import { getISOWeek, max, min, subWeeks } from 'date-fns'
import { Language } from '../Localisation'
import { InStoreI18n } from '../Localisation'
import { getCurrencyCode, getCurrencySymbol } from './Utils/utils'
import { PortalWrapper } from './Utils/PortalWrapper'
import { useLocations, LanguageContext } from '../context'

interface HFBSharesGraphProps {
  shares: HFBShare[]
  siteOrCountry: string
  channel: string
  isSales: boolean
}

interface HFBDetailsOpen {
  isOpen: true
  hfbName: string
}

interface HFBDetailsClosed {
  isOpen: false
}

export const formatWeekRangeTexts = (prevPeriod: Date, currPeriod: Date, lang: Language) => ({
  previousPeriod: `${InStoreI18n[lang].Generic.weeks} ${getISOWeek(subWeeks(prevPeriod, 3))} - ${getISOWeek(
    prevPeriod
  )}`,
  currentPeriod: `${InStoreI18n[lang].Generic.weeks} ${getISOWeek(subWeeks(currPeriod, 3))} - ${getISOWeek(currPeriod)}`
})

export const HFBSharesGraphNewKpi = ({ shares, siteOrCountry, channel, isSales }: HFBSharesGraphProps) => {
  const [flow, setFlow] = React.useState<FlowOption>('total')
  const [hfbDetails, setHfbDetails] = React.useState<HFBDetailsOpen | HFBDetailsClosed>({ isOpen: false })
  const lang = React.useContext(LanguageContext)
  const { currentLocation } = useLocations()
  const currencyCode = isSales ? getCurrencyCode(currentLocation) : 'QTY'

  const currentPeriodColor = colours.salmon
  const previousPeriodColor = colours.black
  const prevPeriodEnd = min(shares.map(s => new Date(s.readableDate)))
  const latestDate = max(shares.map(s => new Date(s.readableDate)))
  const latestDateData = shares
    .filter(s => new Date(s.readableDate).getTime() === latestDate.getTime())
    .sort((a, b) => b.onlinePPPAsisSales + b.storePPPAsisSales - (a.onlinePPPAsisSales + a.storePPPAsisSales))

  const { previousPeriod, currentPeriod } = formatWeekRangeTexts(prevPeriodEnd, latestDate, lang)

  const createHfbLink = (hfbName: string) => (
    <div className="HFBLink">
      <Tooltip tooltipText="View top products" className="HFBInformation">
        <EyeIcon />
      </Tooltip>
      <div>{hfbName}</div>
    </div>
  )
  const isGlobal = siteOrCountry === 'ALL'
  const series = getHfbSeries(
    shares,
    latestDateData,
    flow,
    currentPeriodColor,
    previousPeriodColor,
    createHfbLink,
    isGlobal,
    isSales
  )

  useEffect(() => {
    const channelMapping: Record<string, FlowOption> = {
      all: 'total',
      Store: 'store',
      Online: 'online'
    }

    setFlow(channelMapping[channel] || 'remote')
  }, [channel])

  const tooltipFn = (i: number) => ({
    heading: latestDateData[i].hfbName,
    body: [
      [
        {
          title: currentPeriod,
          unit: currencyCode,
          value: formatAbsoluteNumber((isSales ? calculateSales : calculateQty)(latestDateData[i], flow, isGlobal)),
          color: currentPeriodColor
        },
        {
          title: previousPeriod,
          unit: currencyCode,
          value: formatAbsoluteNumber(
            (isSales ? calculateSales : calculateQty)(
              shares.find(
                sh => sh.hfbName === latestDateData[i].hfbName && sh.readableDate !== latestDateData[i].readableDate
              ),
              flow,
              isGlobal
            )
          ),
          color: previousPeriodColor,
          icon: <span className="PreviousPeriodTooltipIndicator" />
        },
        {
          title: `${currentPeriod} share`,
          unit: '%',
          value: latestDateData[i][flow],
          color: currentPeriodColor
        },
        {
          title: `${previousPeriod} share`,
          unit: '%',
          value:
            shares.find(
              sh => sh.hfbName === latestDateData[i].hfbName && sh.readableDate !== latestDateData[i].readableDate
            )?.[flow] ?? NaN,
          color: previousPeriodColor,
          icon: <span className="PreviousPeriodTooltipIndicator" />
        }
      ]
    ]
  })

  return (
    <div className="HFBSharesGraph NewKpi">
      <div className="CardHeading FirstItem Right">
        <div className="NewKpi-icon">
          P+PP {isSales ? 'Sales' : 'Quantity'} and share of each HFB category
          <Tooltip
            tooltipText={
              isSales
                ? 'This graph displays sales and share of P+PP products within each HFB category in the previous 4 weeks compared to 4 weeks before that. By clicking the category name, you can view top-selling products within each HFB category.'
                : 'This graph displays quantity and share of P+PP products with each HFB category  in the  previous 4 weeks compared to 4 weeks before that. By clicking the category name, you can  view top-selling products within each HFB category.'
            }
          >
            <BlackInformationIndicator />
          </Tooltip>
        </div>
      </div>
      <div className="HFBHeading FirstItem">
        <HFBLegend previousPeriod={previousPeriod} currentPeriod={currentPeriod} />
      </div>
      <SimpleBar
        series={series}
        withChange
        stacked={false}
        tooltipFn={tooltipFn}
        onSerieClick={name => setHfbDetails({ isOpen: true, hfbName: name })}
        longSerieNames
        xAxisTitle={getCurrencySymbol(currencyCode)}
      />
      <PortalWrapper>
        <SkapaModal
          className="TopSellingArticlesModal"
          visible={hfbDetails.isOpen}
          handleCloseBtn={_ => setHfbDetails({ isOpen: false })}
        >
          <Sheets
            header={
              <ModalHeader
                title={
                  hfbDetails.isOpen ? `${hfbDetails.hfbName.replace('´', "'")} - Top 10 selling P+PP products` : ''
                }
              />
            }
            size="large"
            footer={null}
          >
            <ModalBody className="TopSellingArticlesOverlayBody">
              <TopSellingArticlesGraph
                flow={flow}
                hfbName={hfbDetails.isOpen ? hfbDetails.hfbName : ''}
                siteOrCountry={siteOrCountry}
                isSales={isSales}
              />
            </ModalBody>
          </Sheets>
        </SkapaModal>
      </PortalWrapper>
    </div>
  )
}

const calculateSales = (hfbShare: HFBShare | undefined, flow: FlowOption, isGlobal: boolean) => {
  if (hfbShare === undefined) {
    return NaN
  }
  const currencyPostfix = isGlobal ? '' : 'Local'
  switch (flow) {
    case 'total':
      return Math.round(
        hfbShare[`onlinePPPAsisSales${currencyPostfix}`] + hfbShare[`storePPPAsisSales${currencyPostfix}`]
      )
    case 'online':
      return Math.round(hfbShare[`onlinePPPAsisSales${currencyPostfix}`])
    case 'store':
      return Math.round(hfbShare[`storePPPAsisSales${currencyPostfix}`])
    case 'remote':
      return Math.round(hfbShare[`remotePPPAsisSales${currencyPostfix}`])
  }
}

const calculateQty = (hfbShare: HFBShare | undefined, flow: FlowOption) => {
  if (hfbShare === undefined) {
    return NaN
  }
  switch (flow) {
    case 'total':
      return Math.round(hfbShare[`onlinePPPAsisQty`] + hfbShare[`storePPPAsisQty`])
    case 'online':
      return Math.round(hfbShare[`onlinePPPAsisQty`])
    case 'store':
      return Math.round(hfbShare[`storePPPAsisQty`])
    case 'remote':
      return Math.round(hfbShare[`remotePPPAsisQty`])
  }
}

export const getHfbSeries = (
  shares: HFBShare[],
  latestDateData: HFBShare[],
  flow: FlowOption,
  currentPeriodColor: string,
  previousPeriodColor: string,
  createElement: (hfbName: string) => JSX.Element,
  isGlobal: boolean,
  isSales: boolean
) => [
  {
    name: 'hfbShares',
    color: currentPeriodColor,
    data: latestDateData.map(s => {
      const prev = shares.find(sh => sh.hfbName === s.hfbName && sh.readableDate !== s.readableDate)
      return {
        name: s.hfbName,
        value: isSales ? calculateSales(s, flow, isGlobal) : calculateQty(s, flow),
        ...(prev
          ? {
              overlay: {
                value: isSales ? calculateSales(prev, flow, isGlobal) : calculateQty(prev, flow),
                color: previousPeriodColor
              }
            }
          : {}),
        createElement: () => createElement(s.hfbName.replace('´', "'"))
      }
    })
  }
]

interface HFBLegendProps {
  previousPeriod: string
  currentPeriod: string
}

export const HFBLegend = ({ previousPeriod, currentPeriod }: HFBLegendProps) => {
  return (
    <div className="HFBLegend">
      <ActiveIndicator width={10} className="CurrentPeriodIndicator" />
      <span>{currentPeriod}</span>
      <span className="LastPeriodIndicator" />
      <span>{previousPeriod}</span>
    </div>
  )
}
