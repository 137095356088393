import React from 'react'
import { subYears, getYear, endOfMonth, addYears } from 'date-fns'
import { ChartContainer, ChartContainerProps, DataPoint, Serie } from './ChartContainer'
import { lineChart } from './GraphUtil'
import { NoDataView, NoDataViewSmall } from './NoDataView'
import { minBy } from 'lodash'

const templateDomain = [
  '1970-09-30',
  '1970-10-31',
  '1970-11-30',
  '1970-12-31',
  '1971-01-31',
  '1971-02-28',
  '1971-03-31',
  '1971-04-30',
  '1971-05-31',
  '1971-06-30',
  '1971-07-31',
  '1971-08-31'
].map(d => new Date(d))

const mapToYear =
  (year: number) =>
  ({ y, x }: DataPoint, _idx: number, arr: DataPoint[]): DataPoint => {
    const startYear = arr[0].x.getMonth() < 8 ? getYear(arr[0].x) - 1 : getYear(arr[0].x)
    const yearDiff = startYear - year
    return { y, x: endOfMonth(subYears(endOfMonth(x), yearDiff)) }
  }

const mapToTargetDomain = (
  series: Serie[],
  targetSerieName: string
): { domain: Date[] | undefined; series: Serie[] | undefined } => {
  const targetSerie =
    series.find(s => s.name === targetSerieName) || series.find(s => s.secondaryName === targetSerieName)
  if (!targetSerie) throw Error(`FinancialYearChartContainer: No serie with name "${targetSerieName}" in series`)

  const earliestDataPoint = minBy(targetSerie.data, 'x')
  if (!earliestDataPoint) return { domain: undefined, series: undefined }

  const startYear = getYear(earliestDataPoint.x)
  const domainYearDifference = startYear - getYear(templateDomain[0])
  const mapToDataStartYear = mapToYear(startYear)

  return {
    domain: templateDomain.map(date => endOfMonth(addYears(date, domainYearDifference))),
    series: series.map(serie => ({
      ...serie,
      data: serie.data.map(mapToDataStartYear),
      secondaryData: serie.secondaryData?.map(mapToDataStartYear)
    }))
  }
}

type FinancialYearChartContainerProps = Omit<ChartContainerProps, 'domain' | 'dateFormat'> & {
  domainFromSerie: string
  loading?: boolean
  noData?: boolean
  testId?: string
  calculateDifferenceInTooltip?: string
  graphName?: string
}

export const FinancialYearChartContainer = ({
  series,
  domainFromSerie,
  generator = lineChart,
  isSmallGraph,
  loading = false,
  noData = false,
  calculateDifferenceInTooltip,
  graphName,
  ...props
}: FinancialYearChartContainerProps) => {
  const loadingChart = (
    <ChartContainer series={undefined} domain={[]} dateFormat="month" generator={() => []} testId={props.testId} />
  )

  if (loading || !series) return loadingChart
  if (noData || series.length === 0) return isSmallGraph ? <NoDataViewSmall /> : <NoDataView />

  const { domain, series: mappedSeries } = mapToTargetDomain(series, domainFromSerie)

  if (!domain || !mappedSeries) {
    return loadingChart
  }

  return (
    <ChartContainer
      series={mappedSeries}
      dateFormat="month"
      domain={domain}
      generator={generator}
      isSmallGraph={isSmallGraph}
      calculateDifferenceInTooltip={calculateDifferenceInTooltip}
      graphName={graphName}
      {...props}
    />
  )
}
