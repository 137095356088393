import React from 'react'
import classNames from 'classnames'
import { LoadingSkeleton } from '../LoadingSkeleton'

import './InStoreSlide.scss'

interface InStoreSlideProps {
  className?: string
  header: {
    location: string
    title: string
    description: string
    dataSource: string
  }
  series?: {
    name: string
    value: string
    unit: string
    badge?: string
    onTrack?: number
  }
  graph: React.ReactElement | null
  isLoading?: boolean
}

export const InStoreSlide: React.FC<InStoreSlideProps> = ({ className, header, series, graph, isLoading = false }) => (
  <div className={classNames('InStoreSlide', className)}>
    {!isLoading ? (
      <>
        <div className="InStoreSlideLeft">
          <div className={classNames('InStoreSlideLeftTop', series ? 'Part' : 'Full')}>
            <p className="InStoreSlideLocation">{header.location}</p>
            <h1 className="InStoreSlideTitle">{header.title}</h1>
            <p className="InStoreSlideDescription">{header.description}</p>
          </div>
          {series ? (
            <div className="InStoreSlideLeftBottom">
              <p className="InStoreSlideSeries">{series.name}</p>
              <span className="InStoreSlideSeriesValue">{series.value}</span>
              <span className="InStoreSlideSeriesUnit">{series.unit}</span>
              {series.badge ? (
                <div className="InStoreSlideSeriesBadge">
                  <span
                    className={
                      series.onTrack && series.onTrack < 0
                        ? 'OnTrack'
                        : series.onTrack && series.onTrack > 0
                        ? 'NotOnTrack'
                        : ''
                    }
                  >
                    {series.badge}
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}
          <span className="InStoreSlideDataSource">{header.dataSource}</span>
        </div>
        <div className="InStoreSlideRight">{graph}</div>
      </>
    ) : (
      <LoadingSkeleton />
    )}
  </div>
)
