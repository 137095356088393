import React from 'react'

export const Slashes = () => (
  <>
    <pattern id="pattern" width="8" height="10" patternUnits="userSpaceOnUse" patternTransform="rotate(135 50 50)">
      <line stroke="#00946b" strokeWidth="7px" y2="10" />
    </pattern>
    <pattern
      id="orange-pattern"
      width="8"
      height="10"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(135 50 50)"
    >
      <line stroke="#fc6812" strokeWidth="7px" y2="10" />
    </pattern>
  </>
)
