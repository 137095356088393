import React from 'react'

import { GraphUnit, UnitSelector, UnitSelectorProps } from './UnitSelector'

export const FootprintUnitSelector = ({ enabled, value, onChange }: UnitSelectorProps) => {
  return (
    <UnitSelector
      enabled={enabled}
      value={value}
      options={[GraphUnit.ConvertedUnits, GraphUnit.RelativeUnits]}
      disabledText={
        !enabled ? 'The m² data is missing for this site, so relative measurements are disabled' : undefined
      }
      onChange={onChange}
      menuPlacement="top"
    />
  )
}
