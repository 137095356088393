import { useEffect } from 'react'

export function useDocumentTitle(title: string) {
  useEffect(
    function setDocumentTitle() {
      document.title = title
    },
    [title]
  )
}
