import React, { useRef } from 'react'
import Select, { CommonProps, components, MenuListComponentProps } from 'react-select'
import classNames from 'classnames'
import styles from './LocationSearch.module.scss'
import colours from '../Colours.module.scss'
import { isCountry, isSite, LocationWithType } from '../lib/APIClient'
import { ApiCluster, DbSiteFunction, FunctionArea, SiteFunction } from '../../api/src/common-types'
import { flatMap, uniq } from 'lodash'
import { getLocation, getLocationId } from './Utils/utils'
import { useLocations } from '../context'

export type LocationOptionClass = 'CountryOption' | 'SiteOption' | 'ClusterOption'

interface LocationOption {
  label: string
  className: string
  value: string
  searchTerms: string
}

const getLocationOption = ({ label, className, value, countryName }: LocationWithType): LocationOption => ({
  label,
  className,
  value,
  searchTerms: countryName
})

const getLocationOptionFromCluster = ({ clusterId, clusterName }: ApiCluster): LocationOption => ({
  label: clusterName,
  className: styles.ClusterOption,
  value: clusterId,
  searchTerms: ''
})

const countriesWithFunction = (locations: LocationWithType[], func: DbSiteFunction[]) =>
  uniq(locations.filter(l => isSite(l) && func.includes(l.functionCode)).map(({ countryCode }) => countryCode))

const locationsFilter = (countryCodes: string[], func: DbSiteFunction[]) => (location: LocationWithType) => {
  if (location.countryCode === 'ALL') {
    return true
  }
  if (countryCodes.includes(location.countryCode)) {
    return isCountry(location) || (isSite(location) && func.includes(location.functionCode))
  }
  return false
}

export const centreFunctionCodes: DbSiteFunction[] = ['Ingka Centres']
export const retailFunctionCodes: DbSiteFunction[] = ['Customer Fulfillment', 'Retail', 'Support units']

export const centreSiteFunctions: SiteFunction[] = ['Common Areas', 'Tenants']
export const retailSiteFunctions: SiteFunction[] = ['Customer Fulfillment', 'Retail', 'Support units']

function DropdownIndicator<T>(props: CommonProps<T, false>) {
  return <div className={classNames(styles.DropdownIndicator, { [styles.Open]: props.selectProps.menuIsOpen })} />
}

function MenuList<T>(props: MenuListComponentProps<T, false>) {
  const { inputValue } = props.selectProps
  const inputValueRef = React.useRef(inputValue)
  const menuListRef = React.useRef<HTMLElement | null>(null)

  React.useEffect(() => {
    if (menuListRef.current instanceof HTMLElement && inputValueRef.current !== inputValue) {
      menuListRef.current.scrollTop = 0
      inputValueRef.current = inputValue
    }
  }, [inputValue])

  return (
    <components.MenuList
      {...props}
      innerRef={node => {
        if (props.innerRef && typeof props.innerRef === 'function') {
          props.innerRef(node)
        }
        menuListRef.current = node
      }}
    />
  )
}

interface LocationSearchProps {
  functionArea: FunctionArea
  filter?: (locationOption: LocationOption) => boolean
  onLocationSelected: (siteOrCountry: string) => void
}

export const LocationSearch: React.FC<LocationSearchProps> = ({ functionArea, filter, onLocationSelected }) => {
  const { locations, clusters, currentLocation } = useLocations()
  const locationsForCentres = React.useMemo(() => {
    const countriesWithCentres = countriesWithFunction(locations, centreFunctionCodes)
    return locations.filter(locationsFilter(countriesWithCentres, centreFunctionCodes))
  }, [JSON.stringify(locations)])

  const locationsForRetail = React.useMemo(() => {
    const countriesWithRetail = countriesWithFunction(locations, retailFunctionCodes)
    return locations.filter(locationsFilter(countriesWithRetail, retailFunctionCodes))
  }, [JSON.stringify(locations)])

  const clustersOptions = [
    ...(locations.length > 0 ? [getLocationOption(locations[0])] : []),
    ...flatMap(clusters, cluster => {
      const locations = locationsForCentres
        .filter(x => cluster.countryCodes.includes(x.countryCode))
        .map(getLocationOption)
      return locations.length > 0 ? [getLocationOptionFromCluster(cluster), ...locations] : []
    })
  ]

  React.useEffect(() => {
    if (
      functionArea === 'centres' &&
      !currentLocation.isCluster &&
      !getLocation(getLocationId(currentLocation), locationsForCentres)
    ) {
      onLocationSelected('ALL')
    }
    if (
      functionArea === 'retail' &&
      (currentLocation.isCluster || !getLocation(getLocationId(currentLocation), locationsForRetail))
    ) {
      onLocationSelected('ALL')
    }
  }, [functionArea, JSON.stringify(locationsForCentres), JSON.stringify(locationsForRetail)])

  const options =
    functionArea === 'centres'
      ? clustersOptions
      : (functionArea === 'retail' ? locationsForRetail : locations)
          .map(getLocationOption)
          .filter(filter ? filter : () => true)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedItemRef = useRef<any>()

  return (
    <Select
      className="LocationSearch"
      classNamePrefix="LocationSearch"
      value={options.find(option => option.value === getLocationId(currentLocation))}
      options={options}
      onChange={selection => (selection ? onLocationSelected(selection.value) : undefined)}
      formatOptionLabel={({ className, label }) => (
        <div className={className} style={{ display: 'flex' }}>
          <div className={className == 'CountryOption' ? styles.CountryIcon : styles.EmptyIcon}></div>
          <div>{label}</div>
        </div>
      )}
      filterOption={(option, rawInput) =>
        option.label.toLowerCase().includes(rawInput.toLowerCase()) ||
        option.data.searchTerms.toLowerCase().includes(rawInput.toLowerCase())
      }
      isClearable={false}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        MenuList
      }}
      ref={selectedItemRef}
      styles={{
        option: base => ({
          ...base,
          backgroundColor: 'red'
        })
      }}
      onMenuOpen={() => {
        setTimeout(() => {
          const ref = selectedItemRef?.current?.select?.focusedOptionRef
          if (ref) {
            ref.style.backgroundColor = colours.grey1
            ;(ref.parentNode as HTMLDivElement).scrollTop = ref.offsetTop
          }
        }, 1)
      }}
    />
  )
}

const functionAreaOptions = [
  { value: 'retail', label: 'Retail' },
  { value: 'centres', label: 'Centres' },
  { value: 'ingka', label: 'Ingka' }
] as const

interface FunctionAreaSelectProps {
  functionArea: FunctionArea
  onFunctionAreaSelected: (selection: FunctionArea) => void
}

export const FunctionAreaSelect: React.FC<FunctionAreaSelectProps> = ({ onFunctionAreaSelected, functionArea }) => {
  return (
    <Select
      className="FunctionAreaSelect"
      classNamePrefix="FunctionAreaSelect"
      value={functionAreaOptions.filter(({ value }) => value === functionArea)}
      options={functionAreaOptions}
      onChange={selection => (selection ? onFunctionAreaSelected(selection.value) : undefined)}
      isClearable={false}
      isSearchable={false}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        MenuList
      }}
    />
  )
}
