import React from 'react'
import { BenchmarkingContext, BenchmarkingProvider } from './Provider'
import { getLocationOrDefault, isCluster } from '../../../components/Utils/utils'
import { useLocations } from '../../../context'
import { HealthyLivingTable } from './HealthyLivingTable'
import { ClimateTable } from './ClimateTable'
import { SocialImpactTable } from './SocialImpactTable'
import '../../../components/Benchmark/Benchmark.scss'

const BenchmarkingImpl = () => {
  const { currentLocation } = useLocations()
  const locationId = getLocationOrDefault()
  const context = React.useContext(BenchmarkingContext)

  if (!context) return null

  return !currentLocation && !isCluster(locationId) ? null : (
    <>
      <div className="BenchmarkHeader">Benchmarking</div>
      <div className="Benchmark">
        <div className="HeadingLine">
          <h1>KPI and PI performance</h1>
        </div>
        <HealthyLivingTable />
        <ClimateTable />
        <SocialImpactTable />
      </div>
    </>
  )
}

export const Benchmarking = () => {
  return (
    <BenchmarkingProvider>
      <BenchmarkingImpl />
    </BenchmarkingProvider>
  )
}
