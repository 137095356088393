import React from 'react'
import CustomerTravelASIImage from '../../images/HowIsThisCalculated/CustomerTravelASI.png'
import CustomerTravelTableImage from '../../images/HowIsThisCalculated/CustomerTravelTabel.png'

export const CustomerTravelModalContent = () => (
  <>
    <h3>What does this measurement measure? </h3>
    <p>
      Climate Footprint of Customer Travel shows the impact that customers travelling to Ingka stores and visitors
      travelling to Ingka Centres meeting places have on climate change.
    </p>
    <p>Please note that this measurement is still under development. More content will be added during FY25.</p>
    <p>If you have any feedback or questions, please use the feedback feature on top of the page.</p>
    <h3>What is in the scope of this measurement? </h3>
    <p>This first version of the measurement includes:</p>
    <ul>
      <li>Retail: cash & carry customers traveling to stores</li>
      <li>Centres: visitors traveling to all meeting places</li>
    </ul>
    <p>
      For Retail, other customer meeting point formats will be added, together with other services such as click &
      collect.
    </p>
    <h3>How is the measurement calculated? </h3>
    <p>
      The emissions generated when a customer travels to an IKEA customer meeting point is calculated with the following
      high-level formulas:
    </p>
    <p>
      Retail: Total tonnes CO2e = Total number of customers * Distance travelled (km) * Emission factor (kg CO2e/km) per
      mode of transport
    </p>
    <p>
      Centres: Total tonnes CO2e = Total number of visitors * Distance travelled (km) * Emission factor (kg CO2e/km) per
      mode of transport
    </p>
    <p style={{ textDecoration: 'underline' }}>Number of customers/visitors</p>
    <p>
      For retail we measure the environmental impact of customers travelling to our stores. The general assumption is
      that 1 transaction = 1 customer. Scope of transactions includes home furnishing transactions incl returns but
      excludes food transactions to avoid double counting.
    </p>
    <p>
      For Ingka Centres we measure the environmental impact of visitors travelling to our meeting places. Total number
      of visitors excludes visitors also visiting the IKEA store, as they are part of the store customer travel
      footprint (cross visitation).
    </p>
    <p style={{ textDecoration: 'underline' }}>Distance </p>
    <p>
      Distance travelled is estimated using Google maps to calculate distance travelled from people{"'"}s home
      (postcode) to Ingka customer meeting point.
    </p>
    <p style={{ textDecoration: 'underline' }}>Postcodes</p>
    <p>To understand where people travelling to our meeting points live, a couple of data sources are used:</p>
    <ul>
      <li>
        Retail: Market Share City Level data – Delivery address {'>'} IKEA Family address {'>'} iPOS zip survey
      </li>
      <li>Centres: VSI Survey.</li>
    </ul>
    <p>As data is not available for all people, available data is extrapolated.</p>
    <p>
      For Retail, an ‘outliers’ logic has been applied to avoid including customers travelling from an abnormal distance
      from the store.
    </p>
    <p style={{ textDecoration: 'underline' }}>Mode of transport used</p>
    <p>
      Mode of transport used for travelling to Ingka units are collected from surveys. For retail stores Pulse CX is
      used, providing monthly updates of data. If Pulse CX is not available for a country or unit, Brand Capital survey
      is used, providing annual updates of data.
    </p>
    <p>
      For Ingka Centres VSI survey is used. A group size is applied to account for several visitors travelling in the
      same car. Such data is also coming from VSI survey.
    </p>
    <p>
      Cars are split into different fuel types (petrol, diesel, EV and hybrid), using country average statistics per
      year.
    </p>
    <p style={{ textDecoration: 'underline' }}>Emission factor</p>
    <p>
      Estimated distance is converted into Green House Gas (GHG) emissions using emission factors, expressed in CO2e.
    </p>
    <p>
      Carbon dioxide equivalent or (CO2e) is a term for describing different greenhouse gases in a common unit. It is a
      standard unit for measuring carbon footprints and the best way to quantify our impact on climate change. The full
      life cycle of emissions is covered in the emission factors as WTW (well-to-wheel).
    </p>
    <h3>Where is the data coming from? How often is it updated?</h3>
    <p>Data is updated monthly. The raw data includes:</p>
    <img src={CustomerTravelTableImage} alt="CustomerTravelTableImage" />
    <p style={{ textDecoration: 'underline' }}>Information security & data privacy</p>
    <p>
      All data is aggregated and anonymised so that it is not possible to link a specific individual to a postcode or
      survey response. In some countries, the last digits of the postcode have been removed to secure anonymity.
    </p>
    <p>The data and calculations have been reviewed and approved by Ingka Data Privacy team.</p>
    <h3>How to contribute to this measurement?</h3>
    <p>
      There are many ways to impact this measurement. An overall approach of reducing GHG emissions from transport
      referred to as the A-S-I approach can be used.
    </p>
    <img src={CustomerTravelASIImage} alt="CustomerTravelASIImage" />
    <p>Example of actions and activities are:</p>
    <p style={{ textDecoration: 'underline' }}>Avoid and reduce unnecessary travel:</p>
    <ul>
      <li>Reducing customer returns</li>
      <li>Improving stock accuracy</li>
      <li>Improving stock availability</li>
      <li>Optimizing our Omnichannel performance/offer</li>
      <li>Using clusters of high emissions to indicate where new pick-up points could add value</li>
    </ul>
    <p style={{ textDecoration: 'underline' }}>Shift to/Maintain more sustainable travel options: </p>
    <ul>
      <li>Effective communication of sustainable travel options (public transport, bike etc.)</li>
      <li>Maintenance and development of sustainable travel options (public transport, bike etc.)</li>
    </ul>
    <p style={{ textDecoration: 'underline' }}>Improve: </p>
    <ul>
      <li>Effective communication of EV charger infrastructure</li>
      <li>Maintenance and development of EV charger infrastructure</li>
    </ul>
    <p>
      This measurement provides further information and data about our customers which can help us get closer to our
      customer and understand their customer ‘journey’ better.
    </p>
  </>
)
