import React from 'react'
import planetaryFootprintTable from '../../images/HowIsThisCalculated/PlanetaryFootprintTable.jpg'
import planetaryFootprintTable2 from '../../images/HowIsThisCalculated/PlanetaryFootprintTable2.png'

interface KpiPlanetaryFootprintModalContentProps {
  lastUpdated?: string
}

export const KpiPlanetaryFootprintModalContent: React.FC<KpiPlanetaryFootprintModalContentProps> = () => (
  <>
    <p>
      We convert raw waste, energy and refrigerant usage (in each stream) to CO2e using conversion factors according to
      their respective contributions to climate change, then sum them up to get the total footprint.
    </p>
    <h3>Why is it measured in Carbon Dioxide Equivalent (CO2e)? </h3>
    <p>
      CO2e is a standard unit for measuring carbon footprints and the best way to quantify our impact on climate change.
      It allows us to understand how different substances impact the environment, and which ones are the right ones to
      focus on.
    </p>
    <h3>How the KPI values are calculated? </h3>
    <p>
      Data is taken from Sustain where it is mainly provided by FM locally on a monthly basis. There is one month
      collection period to allow invoices to come meaning September data is collected in October and show in here at the
      end of the first working day in November.{' '}
    </p>
    <p>
      Raw data in kWh, ton etc are converted into CO2e by using either supplier information or library values in
      Sustain.
    </p>
    <h3>Own vehicle fuel </h3>
    <p>
      Emissions from the use of fuels for own vehicles (passenger and transport vehicles) is estimated yearly. This is a
      pragmatic approach as impact is limited and we are working on implementing zero emission vehicles so impact is
      phasing out.{' '}
    </p>
    <p>
      Last year values are used until replaced by a new update once a year is closed. That brings that FY23 footprint
      from own vehicles is used as preliminary value throughout FY24 and divided in the 12 months. Once FY24 is closed
      and we have the actuals we will update FY24 to actuals.{' '}
    </p>
    <p>
      Passenger vehicles: Emissions are based on the spend on fuels (account 5612), that is converted into distance
      based on Numbeo factors. The estimated driven distance is then converted into emissions with DEFRA emission
      factors. Separately from this, we track the share of zero emission vehicles, and by transforming to those
      emissions from fuel use will decline, as less spend on fuel will be accounted for.{' '}
    </p>
    <p>
      Transport vehicles: Consumed fuels have been reported to Sustain until end of FY22, which serves as the basis for
      calculating the emission intensity per goods flow. The emission intensity of our FY22 goods flow is then applied
      to our goods flow of the reporting year.{' '}
    </p>

    <h3 className="RepresentativeExamplesHeader">Here are a few representative examples...</h3>
    <img src={planetaryFootprintTable} className="TableImage" />

    <h3>Historic refrigerant data FY16-19</h3>
    <p>
      The reporting of refrigerants has been mandatory since FY20. This brings that from earlier years we have scattered
      voluntary reporting. Still the base year for external commitments is FY16 so to handle this we had to estimate
      emissions for the years FY16-19. This means that we have assessed the average emissions and assigned our best
      guessing to each site. Due to this it is only added as CO2 and not by type and kilos leaked.
    </p>

    <h3>How to contribute to this KPI?</h3>
    <p>Climate footprint - Own operations is driven by:</p>
    <ul>
      <li>
        Energy (electricity, heating & cooling), in which energy efficiency (kWh / m2), renewable energy share (%) are
        some of the metrics followed.
      </li>
      <li>Refrigerant leakages (amounts and types) </li>
      <li>Waste (amounts and treatment), in which total waste (tonnes) and recycling rate (%) are followed. </li>
    </ul>
    <p>
      From FY25 the footprint scope will change to match the internal and external commitments, linked to Ingkas
      commitments to Science Based Targets initiative (SBTi). The changes are:
    </p>

    <ul>
      <li>
        Water it taken out of scope as not part of GHG protocol. Instead reported as a new metric in liter/visitor and
        share of consumption in high water stress areas.{' '}
      </li>
      <li>
        Own vehicle fuel is added to get the full scope 1+2 covered according to GHG protocol. This is expected to go
        down to zero as we change to zero emission vehicles but for now fuel is tracked through fuel cost in Coda
        account 5612, translate that to fuel amounts and CO2.
      </li>
      <li>
        Upstream emissions, fuel production being the “backpack” of emission a fuel has caused before we use if. It
        includes mining, refining and transporting the fuel. If going to renewable fuels this is significantly lower but
        not down to zero as for example the transport is usually not done by fully renewable fuels.
      </li>
      <li>
        Upstream emissions, grid loss is the electivity sent form the producer but never reaching us. Due to resistance
        in the grid there are a few percent loss and this is a direct correlation to how much electricity we purchase.
        Even if buying renewable electricity this gris loss is assumed being “country average” electricity according to
        GHG protocol.
      </li>
    </ul>

    <h3>Changes are describe din a training from January 2024 available here: </h3>
    <p>
      <a href="https://iweof.sharepoint.com/:p:/t/o365g_retailsustainabilitymatrix_issemal/ESRkTeNt8r1FsnuCKa0wxngBaLgU6lrGhedCLDaRqil5bQ?e=gjFj5T">
        FY25+30 VCG Operational Climate Footprint goal setting.pptx
      </a>
    </p>
    <img src={planetaryFootprintTable2} className="TableImage" />

    <h3>As Ingka overall we steer performance by: </h3>
    <ul>
      <li>
        Strategic decisions ensuring we buy renewable electricity and invest in future technology for renewable heating
        & cooling, low impact refrigerants (in heat pumps and freezers) and contracting partners supporting our vision
        (mobility, waste handling etc).
      </li>
      <li>
        Encouraging daily awareness in the “co-worker view” of 4P. There you see the impact our behaviors have by store
        for some main 4P KPIs.
      </li>
    </ul>
    <p>
      We can all contribute to decision making ensuring we save resources, prevent waste etc. The biggest contributions
      to the climate footprint are usually using fossil fuels like non-renewable electricity or natural gas along with
      sending waste to landfill. Once energy is renewable and waste recycled the “joker” is usually refrigerant leakages
      causing a spiking footprint in the month the systems are refilled.{' '}
    </p>
  </>
)
