import React from 'react'
import schema from '../../images/HowIsThisCalculated/CircularRevenueSchema.jpg'

export const KpiCircularRevenueModalContent = () => (
  <>
    <p className="IntroParagraph" data-testid="social-impact-modal-intro-para">
      As part of its ambition to contribute to limiting climate change to 1.5°C, decouple its growth from resource use,
      and become a just and regenerative business, Ingka is transitioning towards a circular business. The aim of
      measuring circular revenue is to have a robust measure of our progress in “transitioning towards a circular
      business”. This includes generating revenue by keeping materials and products in use for as long as possible,
      reducing waste, and fostering new circular business models like second-hand sales, care & repair range, and
      services. Ingka&apos;s Retail business focuses on prolonging product life of the IKEA range; Ingka centres enable
      visitors to acquire, care for, or pass on things in new ways; and Ingka Investments invests in recycling
      infrastructure. By doing so, Ingka can take a lead in the circular economy.
    </p>

    <h3>Followed Metrics: Circular Revenue from Retail </h3>

    <p>
      Definition: Revenue generated from pre-defined circular business models from products, services, and material
      outflows. Circular Revenue from Retail is divided into revenue from circular products, circular services and other
      income, which are divided into multiple categories. The prioritised breakdown that we will be focusing on is
      below:{' '}
    </p>

    <img src={schema} alt="The prioritised breakdown that we will be focusing on is below" />
    <p>
      To start with, the current version of this dashboard focuses on Retail Business and covers revenue coming from:{' '}
    </p>
    <ol>
      <li>
        <strong> BuyBack & Resell: Definition:</strong> <br /> BuyBack & Resell: Definition: A service that enables
        private customers to prolong product life, by selling back used IKEA products in return for a voucher card. IKEA
        is reselling the returned product in the As-Is area.
      </li>
      <li>
        <strong>Care & Repair Range: </strong> <br />
        Definition: Identified articles from the Care and Repair range created and managed by HFB18
      </li>
    </ol>
    <p>
      As we move forward on this journey we will add revenue from commercial furniture spare parts, products with
      circular capabilities and all circular services.{' '}
    </p>
    <h3>Data Sources</h3>
    <ol>
      <li>
        <strong>BuyBack & Resell:</strong>
        <br /> The data for Buyback & Resell is coming from the Circular Services Data Product
        (ingka-circularda-common-prod.dm_circular__data_product.fct_circular_offer)
      </li>
      <li>
        <strong>Care & Repair Range:</strong>
        <br />
        All care and repair products have been identified and noted in the TRIXIG list. Common Sales data product is
        then used to get the revenue from all the items in the TRIXING list.
      </li>
    </ol>

    <h3>Frequency of 4P data update</h3>
    <p>Currently the 4P dashboard will be updated every week. </p>
    <h3>How to contribute to this KPI?</h3>
    <p>
      We can all play a vital role in contributing to the Circular Revenue KPI by using our knowledge and creativity to
      steer the business towards the circular business. This includes supporting customers in adopting circular
      practices such as second-hand sales and care & repair solutions. Implementing IKEA&apos;s circular strategy will
      be key, whether by promoting second-hand offerings, encouraging repair and refurbishment services, or finding
      innovative ways to reduce waste.
    </p>
    <p>
      By focusing on circular products and services, we can enhance customer engagement and ensure that materials remain
      in circulation longer, while also adhering to the IKEA&apos;s sustainability principles.
    </p>
  </>
)
