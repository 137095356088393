import PptxGenJS from 'pptxgenjs'
import { exportDataProps } from '../pages/ExplorePages/ExploreGraphCard'
import { createPNGFromGraph } from './ExportGraph'

export const exportToPptx = (
  imgData: string,
  exportData: exportDataProps[],
  fileName: string | PptxGenJS.TextProps[]
) => {
  const pptx = new PptxGenJS()
  pptx.layout = 'LAYOUT_WIDE'
  const slide = pptx.addSlide()

  slide.addText(fileName, { x: 0.5, y: 0.5, fontSize: 18, bold: true })

  slide.addImage({ path: imgData, x: 1, y: 5, w: 9, h: 6 })

  slide.addText('Exported from 4P Dashboard', {
    hyperlink: { url: window.location.href, tooltip: 'View the graph in 4P Dashboard' },
    x: 6,
    y: 12,
    w: '25%',
    fontSize: 14,
    color: '000000'
  })

  if (exportData) {
    pptx.defineLayout({ name: 'Custom', width: 10, height: 18 })
    pptx.layout = 'Custom'
    const tableSlide = pptx.addSlide()
    tableSlide.addText(`${fileName} - Data Table`, { x: 0.5, y: 0.5, fontSize: 14, bold: true })

    const tableRows = convertDataToTableRows(exportData)
    tableSlide.addTable(tableRows, { x: 0.5, y: 1, w: 8, h: 14 })
  }

  pptx.writeFile({ fileName: `${fileName}.pptx` })

  URL.revokeObjectURL(imgData)
}

const convertDataToTableRows = (data: exportDataProps[]) => {
  const headers = Object.keys(data[0])
  const rows = data.map(item => Object.values(item))
  return [headers, ...rows]
}

export async function exportGraphAsUrl(chartContainer: Element | null): Promise<string | undefined> {
  const pngBlob = await createPNGFromGraph(chartContainer)

  if (pngBlob === undefined) {
    return undefined
  }

  const url = URL.createObjectURL(pngBlob)

  return url
}
