import React from 'react'

import { getMonthlyFootprint } from '../../lib/APIClient'
import { formatValueAsTons } from '../Utils/format'
import { getCurrentLocationLabel, getLocationId, getLocationSelector } from '../Utils/utils'
import { InStoreSlide } from './InStoreSlide'
import { useLocations, useDataAvailabilityContext, LanguageContext } from '../../context'
import { InStoreI18n as i18n } from '../../Localisation'
import { dataSourceText } from '../../Localisation'
import { ChartContainer } from '../BaseGraphs/ChartContainer'
import { YtdFootprintAndGoals } from '../../../api/src/common-types'
import { lineChart } from '../BaseGraphs/GraphUtil'
import { formatFootprintSeries } from '../../pages/KPIPages/Climate/ClimateKPIPage'

export const TotalClimateFootprintSlide = () => {
  const { currentLocation } = useLocations()
  const [footprintYTD, setFootprintYTD] = React.useState(-1)
  const [footprintGoalYTD, setFootprintGoalYTD] = React.useState(-1)
  const [footprintDataSource, setFootprintDataSource] = React.useState('')
  const [domain, setDomain] = React.useState<Date[]>()
  const [ytdFootprints, setYtdFootprints] = React.useState<YtdFootprintAndGoals[] | undefined>(undefined)
  const { dataAvailability } = useDataAvailabilityContext()
  const lang = React.useContext(LanguageContext)
  const l = i18n[lang]
  const ls = l['Climate Footprint YTD']

  const currentLocationLabel = currentLocation.isCluster
    ? currentLocation.cluster.clusterName
    : getCurrentLocationLabel(currentLocation.location, lang)
  React.useEffect(() => {
    getMonthlyFootprint({
      ...getLocationSelector(getLocationId(currentLocation)),
      func: ['ALL'],
      isOld: false
    }).then(({ data, dates }) => {
      const sortedData = data.sort((a, b) => new Date(b.readableDate).getTime() - new Date(a.readableDate).getTime())
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const latest = sortedData.find(f => f.currentFY != null)!

      setFootprintYTD(data.reduce((acc, d) => acc + (d.currentFY ?? 0), 0))
      setFootprintGoalYTD(latest.goal ?? 0)
      setFootprintDataSource(dataSourceText(lang, latest.readableDate, l.Generic.sustainData, dataAvailability))
      setDomain(dates.map(d => new Date(d)))
      setYtdFootprints(data)
    })
  }, [dataAvailability])

  return (
    <InStoreSlide
      header={{
        location: currentLocationLabel,
        description: ls.description,
        title: ls.title,
        dataSource: footprintDataSource
      }}
      series={{
        name: ls.seriesName,
        value: formatValueAsTons(footprintYTD),
        unit: l.Generic.tonnesCO2e,
        badge:
          footprintGoalYTD !== -1
            ? `${formatValueAsTons(Math.abs(footprintYTD - footprintGoalYTD))} ${l.Generic.tonnesCO2e} ${
                footprintYTD < footprintGoalYTD ? l.Generic.below : l.Generic.above
              } ${l.Generic.ytdGoal}`
            : undefined,
        onTrack: footprintYTD - footprintGoalYTD
      }}
      graph={
        <ChartContainer
          domain={domain}
          series={formatFootprintSeries(ytdFootprints, domain, true)}
          generator={lineChart}
          dateFormat="month"
          lineChartConfiguration={{ focusStyle: 'none', startFromZero: true }}
          yAxisTitle="tonnes CO2e"
        />
      }
      isLoading={footprintYTD === -1}
    />
  )
}
