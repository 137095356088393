import React, { ReactNode, useEffect, useState } from 'react'
import { format as libFmtDate } from 'date-fns'
import _ from 'lodash'

import styles from './SelfServicePage.module.scss'

import { DagConfig, DagRuns, DagRunInfo, SelfServeDagConfig } from '../../api/src/common-types'
import { triggerJob, getJobInfo } from '../lib/APIClient'

const DAG_POLL_INTERVAL = 1000
const BTN_COOLDOWN_TIMEOUT = 5000

const DAGS_ENABLED = ['sample_dag', 'self_serve_dag']

export const SelfServicePage = () => {
  useEffect(function trySelfServeRedirect() {
    const { host } = window.location
    const isDev = host.startsWith('dev') || host.startsWith('localhost') || process.env.NODE_ENV !== 'production'

    if (!isDev) window.location.host = `dev.${host}`
  }, [])

  return (
    <div className={styles.SelfServicePage}>
      <h1>4P Dashboard Self-Service</h1>
      <ul className={styles.DagList}>
        <Dag<SelfServeDagConfig>
          dagId="self_serve_dag"
          title="Self-Serve Data Pipeline"
          optionTitles={{
            conversion_factors: 'Conversion Factors',
            customer_deliveries: 'Climate Footprint – Zero Emission Deliveries',
            waste_material_map: 'Waste Material Map',
            climate_footprint: 'Climate Footprint',
            manual_site_update: 'Manual Site Updates'
          }}
        />
      </ul>
      <p className={styles.DocumentationLinkContainer}>
        <a
          className={styles.DocumentationLink}
          href="https://confluence.build.ingka.ikea.com/display/SBPSUS/Self-service+pipeline+%7C+User+documentation"
          target="_blank"
          rel="noreferrer"
        >
          Self-Service Pipeline Documentation in Confluence
        </a>
      </p>
    </div>
  )
}

type DagProps<D extends DagConfig> = {
  dagId: string
  title: ReactNode
  optionTitles: Omit<Record<keyof D, ReactNode>, 'deploy_to_prod'>
}

const Dag = <D extends DagConfig>({ dagId, title, optionTitles }: DagProps<D>) => {
  type OptionId = Extract<keyof D, string>
  const optionIds = _.keys(optionTitles) as OptionId[]
  const isDagEnabled = DAGS_ENABLED.includes(dagId)

  const [checkedOptions, setCheckedOptions] = useState<OptionId[]>([])
  const checkOption = (opt: OptionId) => setCheckedOptions(s => _.uniq([...s, opt]))
  const uncheckOption = (opt: OptionId) => setCheckedOptions(s => _.without(s, opt))

  const [info, setInfo] = useState<DagRuns>()

  const productionLatestWasSuccess = info?.latestProduction?.date === info?.latestProductionSuccessful?.date
  const stagingLatestWasSuccess = info?.latestStaging?.date === info?.latestStagingSuccessful?.date

  const [btnCooldown, setBtnCooldown] = useState(false)
  const isProductionActive = ['queued', 'running'].includes(info?.productionState ?? '')
  const isStagingActive = ['queued', 'running'].includes(info?.stagingState ?? '')
  const buttonsDisabled = !isDagEnabled || btnCooldown || !info || isProductionActive || isStagingActive

  async function fetchRunData() {
    const result = await getJobInfo(dagId)
    if (!result) return

    setInfo(result)
    return result
  }

  useEffect(() => {
    const updateInterval = setInterval(() => {
      fetchRunData()
    }, DAG_POLL_INTERVAL)
    return () => clearInterval(updateInterval)
  }, [])

  useEffect(() => {
    if (!btnCooldown) return

    const timeout = setTimeout(() => {
      setBtnCooldown(false)
    }, BTN_COOLDOWN_TIMEOUT)
    return () => clearTimeout(timeout)
  }, [btnCooldown])

  const doTrigger = async (production: boolean, dryRun = false) => {
    if (dryRun) setCheckedOptions([])

    const info = await fetchRunData()
    if (!info) return

    const isAlreadyRunning =
      ['queued', 'running'].includes(info.productionState ?? '') ||
      ['queued', 'running'].includes(info.stagingState ?? '')

    if (isAlreadyRunning) {
      alert(
        'You happened to trigger this Pipeline at the same time as someone else. Please wait for their deployment to finish first and then try your deployment again!'
      )
      return
    }

    const payload = _(optionIds)
      .map(opt => [opt, dryRun ? false : checkedOptions.includes(opt)] as const)
      .fromPairs()
      .value() as D

    payload.deploy_to_prod = dryRun ? false : production

    const response = await triggerJob(dagId, payload)
    setInfo(
      i =>
        i && {
          ...i,
          error: response?.error ?? 500,
          [production ? 'productionState' : 'stagingState']: response?.status ?? 'failed'
        }
    )
  }

  return (
    <li>
      <div className={styles.DagCard}>
        <summary>
          <span>{title}</span>
          <span>
            <button
              disabled={buttonsDisabled}
              onClick={() => {
                setBtnCooldown(true)
                doTrigger(false, true)
              }}
            >
              Perform a Dry Run
            </button>
            {typeof info?.error === 'number' && `Error (Airflow ${info.error})`}
          </span>
        </summary>
        <div className={styles.DagCardContents}>
          <div className={styles.DatesContainer}>
            <div className={styles.Header}>Production</div>
            <div className={styles.Status}>{fmtDagStatus(info?.productionState)}</div>
            {info?.productionState !== 'none' && (
              <>
                <div className={styles.DateGroup}>
                  <span className={styles.DateLabel}>Last run:</span>
                  <span>{fmtDate(info?.latestProduction?.date)}</span>
                </div>
                {!productionLatestWasSuccess && (
                  <div className={styles.DateGroup}>
                    <span className={styles.DateLabel}>Last succesful run:</span>
                    <span>{fmtDate(info?.latestProductionSuccessful?.date)}</span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.DatesContainer}>
            <div className={styles.Header}>Staging</div>
            <div className={styles.Status}>{fmtDagStatus(info?.stagingState)}</div>
            {info?.stagingState !== 'none' && (
              <>
                <div className={styles.DateGroup}>
                  <span className={styles.DateLabel}>Last run:</span>
                  <span>{fmtDate(info?.latestStaging?.date)}</span>
                </div>
                {!stagingLatestWasSuccess && (
                  <div className={styles.DateGroup}>
                    <span className={styles.DateLabel}>Last succesful run:</span>
                    <span>{fmtDate(info?.latestStagingSuccessful?.date)}</span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.ConfigContainer}>
            <div className={styles.Header}>
              {isDagEnabled ? 'Deploy' : 'Self-Service for this Pipeline is disabled'}
            </div>
            <ul className={styles.ConfigList}>
              {optionIds.map(optionId => {
                return (
                  <li key={optionId}>
                    <label htmlFor={`option-${optionId}-checkbox`}>
                      <input
                        type="checkbox"
                        id={`option-${optionId}-checkbox`}
                        checked={checkedOptions.includes(optionId)}
                        onChange={e => (e.target.checked ? checkOption(optionId) : uncheckOption(optionId))}
                        disabled={buttonsDisabled || !isDagEnabled}
                      />
                      <span>{optionTitles[optionId]}</span>
                    </label>
                  </li>
                )
              })}
            </ul>
            <div className={styles.TriggerButtonsContainer}>
              <button
                disabled={buttonsDisabled}
                onClick={() => {
                  setBtnCooldown(true)
                  doTrigger(false)
                }}
              >
                To Staging
              </button>
              <button
                disabled={buttonsDisabled}
                onClick={() => {
                  setBtnCooldown(true)
                  doTrigger(true)
                }}
              >
                To Production
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

function fmtDate(date: string | undefined) {
  return date ? libFmtDate(new Date(date), 'yyyy/MM/dd HH:mm:ss') : ''
}

function fmtDagStatus(status: DagRunInfo['status'] | 'none' | undefined) {
  switch (status) {
    case 'failed':
      return <span className={styles.Failed}>Run failed</span>
    case 'queued':
      return <span className={styles.Queued}>Queued on Server</span>
    case 'running':
      return <span className={styles.Running}>Running</span>
    case 'success':
      return <span className={styles.Ok}>OK</span>
    case 'none':
      return <span className={styles.Unknown}>No Data</span>
    default:
      return <span className={styles.Loading}>Loading</span>
  }
}
