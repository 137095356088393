import React from 'react'
import { sortBy, sumBy, flatten } from 'lodash'
import { ChartTooltip, TooltipData, TooltipPosition, TooltipSettings } from './Tooltip'
import './SunburstGraph.scss'

interface Segment {
  name: string
  color: string
  value: number
}

interface SunburstGraphProps {
  series: Segment[][]
  sort?: boolean
  onMouseOver?: () => void
  onMouseOut?: () => void
  testId?: string
  tooltipSettings?: TooltipSettings
}

// These define radius and stroke-length by the circle (serie). I guess the right way would be to define these somehow
// mathematically based on how many series are given, but at the moment it's easier this way.
const configuration: Record<number, [number, number][]> = {
  1: [[37.5, 25]],
  2: [
    [27.5, 15],
    [42.5, 15]
  ]
}
const mirrorYInPlace = { transform: 'scale(1,-1) translate(0,-100)' }

export const SunburstGraph: React.FC<SunburstGraphProps> = ({
  series,
  sort = true,
  onMouseOver,
  onMouseOut,
  tooltipSettings
}) => {
  const [tooltipPosition, setTooltipPosition] = React.useState<TooltipPosition>()

  const tooltipData: TooltipData = {
    tooltipContent: {
      heading: tooltipSettings?.title,
      items: flatten(series).map(({ name, color, value }) => ({
        name,
        color,
        value: tooltipSettings?.valueFn ? tooltipSettings?.valueFn(value) : value,
        unit: tooltipSettings?.unit ? tooltipSettings?.unit : ''
      }))
    }
  }

  if (series.length === 0 || series.length > 2) {
    throw new Error('SunburstGraph supports only one or two series')
  }

  const handleMouseOut = () => {
    setTooltipPosition(undefined)
    if (onMouseOut) onMouseOut()
  }

  const mouseMoved = (event: React.MouseEvent) => {
    if (!tooltipSettings) return

    setTooltipPosition({
      x: event.clientX,
      y: event.clientY
    })
  }

  const total = sumBy(series[0], 'value')

  return (
    // 100 by 100 assumption is hardcoded all over, but it doesn't really matter as the SVG scales to fill whatever
    // space it's given in the DOM. Hundred is just a nice round number that's easy to reason about.
    <>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        className="SunburstGraph"
        onMouseMove={mouseMoved}
        onMouseOver={() => (onMouseOver ? onMouseOver() : null)}
        onMouseOut={handleMouseOut}
      >
        {series.map((serie, index) => {
          const [radius, strokeWidth] = configuration[series.length][index]
          const circumference = radius * 2 * Math.PI
          const serieInOrder = sort ? sortBy(serie, 'value') : serie
          let used = 0
          return serieInOrder.map(({ name, value, color }) => {
            const element = (
              <circle
                data-index={name}
                key={name}
                className="SunburstGraphSegment"
                cx="50"
                cy="50"
                r={radius}
                fill="transparent"
                stroke={color}
                strokeWidth={strokeWidth}
                strokeDasharray={`${circumference * (value / total)} ${circumference}`}
                strokeDashoffset={circumference * (used / total) * -1}
                // SVG draws segments clockwise, that's wanted for the inner circle.
                // But the design draws the outer circle counter-clockwise, so we mirror it vertically in place.
                {...(index > 0 ? mirrorYInPlace : {})}
              />
            )
            used += value
            return element
          })
        })}
      </svg>
      {/* {tooltipPosition && <TooltipContainer tooltipContent={tooltipContent} tooltipPosition={tooltipPosition} />} */}
      {tooltipPosition && (
        <ChartTooltip
          subHeading={tooltipSettings?.subtitle}
          x={tooltipPosition.x}
          y={tooltipPosition.y}
          data={tooltipData}
        />
      )}
    </>
  )
}
