import React from 'react'
import IkeaMan from '../images/ikeamansad.jpg'

export const PageNotFound = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '3em' }}>
      <h1>Page not found.</h1>
      <img src={IkeaMan} alt="Sad IKEA man" height="500" />
    </div>
  )
}
