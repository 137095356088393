import React from 'react'

import { ReactComponent as InformationIndicator } from '../../images/Icons/InformationIndicator.svg'
import { ReactComponent as ExportIcon } from '../../images/Icons/Export.svg'
import { ReactComponent as CoWorkerScreenIcon } from '../../images/Icons/CoWorkerIcon.svg'

import './ExploreGraphCard.scss'
import { Tooltip } from '../../components/Tooltip'
import { Link } from '../../components/Link'
import { Serie } from '../../components/BaseGraphs/ChartContainer'
import { getLocationLabel } from '../../components/Utils/utils'
import { CurrentLocation, useLocations } from '../../context'
import { exportGraphAsPng, exportGraphAsPptx } from '../../lib/ExportGraph'
import { DateFormat } from '../../components/BaseGraphs/GraphUtil'
import { Route } from '../../routes'
import Button from '@ingka/button'
import { TimeRange } from './ExplorePage'
import { exportToExcel } from '../../components/ExcelExportButton'
import { exportGraphAsUrl, exportToPptx } from '../../lib/exportToPptx'

export interface exportDataProps {
  source: string
  target: string
  value: number
}

interface ExploreGraphCardProps {
  children: JSX.Element
  heading: string
  description?: string
  amount?: string
  unit?: string
  timeRange?: TimeRange
  onClickHandler?: () => void
  exportData?: exportDataProps[]
}

interface ChartData {
  domain: Date[]
  dateFormat: DateFormat
  series: Serie[]
  isLineChart: boolean
  unit: string
}

interface ChartDataSetter {
  setValues: (domain: Date[], dateFormat: DateFormat, series: Serie[], isLineChart: boolean, unit: string) => void
}

export const ChartDataContext = React.createContext<ChartDataSetter>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setValues: (_domain: Date[], _domainFormat: DateFormat, _series: Serie[], _isLineChart: boolean, _unit: string) => {}
})

export const ExploreGraphCard = ({
  children,
  description,
  heading,
  amount,
  unit,
  timeRange,
  onClickHandler,
  exportData
}: ExploreGraphCardProps) => {
  const graphRef = React.useRef<HTMLDivElement>(null)
  const { currentLocation } = useLocations()
  const [domainAndSeries, setDomainAndSeries] = React.useState<ChartData>({
    domain: [],
    dateFormat: 'month',
    series: [],
    isLineChart: true,
    unit: ''
  })

  const exportTooltipText =
    domainAndSeries.domain.length > 0 && domainAndSeries.series.length > 0
      ? 'Download chart and data as Powerpoint presentation'
      : graphRef.current != null
      ? exportData
        ? 'Download chart as Powerpoint presentation & Excel table data'
        : 'Download chart as PNG image'
      : ''
  return (
    <div className="ExploreGraphCard">
      <div className="ExploreGraphHeader">
        <div className="Left">
          {heading}
          {description && (
            <Tooltip tooltipText={description}>
              <InformationIndicator className="Icon" fill={undefined} />
            </Tooltip>
          )}
          <Tooltip tooltipText="Open Co-worker view configurator">
            <Link page={Route.InstoreConfiguratorPage}>
              <CoWorkerScreenIcon className="Icon" />
            </Link>
          </Tooltip>
          <Tooltip tooltipText={exportTooltipText}>
            <ExportIcon
              className="Icon"
              onClick={_ => {
                if (domainAndSeries.domain.length > 0 && domainAndSeries.series.length > 0) {
                  exportGraphAsPptx(
                    `${heading} - ${getLocationLabel(currentLocation)}`,
                    domainAndSeries.series,
                    domainAndSeries.domain,
                    domainAndSeries.dateFormat,
                    domainAndSeries.isLineChart,
                    domainAndSeries.unit
                  )
                } else if (graphRef.current != null) {
                  exportData
                    ? handleExport(graphRef.current, exportData, heading, currentLocation)
                    : exportGraphAsPng(graphRef.current, `${heading} - ${getLocationLabel(currentLocation)}`)
                }
              }}
            />
          </Tooltip>
        </div>
        <div className="TotalAmountContainer">
          {timeRange === 'annual' && <Button text="What are goals and actions?" onClick={onClickHandler} small />}
          {amount && unit && (
            <div className="TotalAmount">
              {amount}
              <span className="Unit">{unit}</span>
            </div>
          )}
        </div>
      </div>
      <ChartDataContext.Provider
        value={{
          setValues: (domain: Date[], dateFormat, series: Serie[], isLineChart: boolean, unit: string) =>
            setDomainAndSeries({ domain, dateFormat, series, isLineChart, unit })
        }}
      >
        <div className="ExploreGraphContainer" ref={graphRef}>
          {children}
        </div>
      </ChartDataContext.Provider>
    </div>
  )
}

const handleExport = async (
  graphRef: HTMLDivElement,
  exportData: exportDataProps[],
  heading: string,
  currentLocation: CurrentLocation
) => {
  const imageFileName = `${heading} - ${getLocationLabel(currentLocation)}`

  const imgData = await exportGraphAsUrl(graphRef)

  if (exportData) {
    exportToExcel(exportData, imageFileName)
  }

  imgData && exportToPptx(imgData, exportData, imageFileName)
}
