import React from 'react'
import classNames from 'classnames'

interface TitleProps {
  className?: string
}

export const Title: React.FC<TitleProps> = ({ children: title, className }) => (
  <div className={`Title ${className || title}`}>{title}</div>
)

interface GridHrProps {
  className?: string
  isHead?: boolean
}

export const GridHr: React.FC<GridHrProps> = ({ className, isHead }) => (
  <div className={classNames('GridHr', className, isHead ? 'Head' : '')} />
)
