import React from 'react'
import footerImage from '../../images/HowIsThisCalculated/FoodFooter.png'

export const KpiFoodModalContent = () => (
  <>
    <h3>How the KPI values are calculated? </h3>
    <ul>
      <li>Ingka Food Digital has built the data product that runs the calculations</li>
      <li>Kg CO2e = ∑ (product weight (kg) * product emission factor (kg CO2e/kg food sold) * product sales qty)</li>
      <li>Kg CO2e/kg food sold = ∑ kg CO2e / ∑ kg food sold</li>
    </ul>
    <p>Data is updated on a daily basis.</p>
    <p>The climate footprint of Food Ingredients sold is calculated in three steps:</p>
    <ol>
      <li>
        Each ingredient is allocated an emission factor representing that ingredient&#39;s climate impact (kg CO2e/kg
        food sold)
      </li>
      <li>
        Ingredient-level emission factors are rolled up to article-level (dish or product) emission factors, based on
        the proportion of ingredients in each recipe.
      </li>
      <li>
        Total emissions are calculated by multiplying the article emission factor with the article sales quantity.
      </li>
    </ol>

    <img src={footerImage} className="Centered" />

    <h3>What is in the scope of this KPI?</h3>
    <p>
      Initial scope is limited to measuring emissions from a limited number subset of the range. Within these range and
      product areas, both global and local range items are included:
    </p>
    <ul>
      <li>Range Area 961 (Consumer Packaged Goods)</li>
      <li>Product Area 9625 (Restaurant main meals & buffet)</li>
      <li>Product Area 9631 (Bistro hot snacks)</li>
    </ul>
    <p>
      The scope of emissions covered is also limited to the farm-level and land-use change. Downstream value chain steps
      (e.g. processing, transport, retail) are not yet covered.
    </p>
    <p>
      In the case of both product areas and value chain steps covered by the initial solution, this is to align with
      Inter IKEA’s current coverage for consistency in reporting according to the One IKEA principle.
    </p>

    <h3>Definition of plant-based products</h3>
    <p>
      Consumer Packaged Goods: Products where the ingredients are entirely derived from plants and do not contain
      animal-based products.
    </p>
    <p>
      Food Service: Meals with a main protein component that is plant-based and where the plate is composed of mainly
      plant-based products with the possibility to include ingredients produced by an animal such as eggs, honey and
      milk.
    </p>

    <h3>How to contribute to this KPI?</h3>
    <p>There are many ways to contribute to this KPI (some a dependency on the collaboration with Inter*):</p>
    <ul>
      <li>*Product & recipe design</li>
      <li>*Sourcing decisions</li>
      <li>*Range design & composition</li>
      <li>Sales steering</li>
    </ul>

    <h3>Where is the data coming from? How often it is updated? </h3>
    <p>There are different sources depending on the data:</p>
    <ul>
      <li>
        Product weights: provided by BA Food (Inter) for Global Range items and some Local Range items. Where the weight
        for a product is missing, Group Sustainability (Ingka) have assigned the weight from a similar product (e.g.
        Soup Borscht (20374989) assigned Soup Tomato (30343389) weight){' '}
      </li>
      <li>
        Product emission factors: Inter Sustainability have a database of ingredient-level emissions factors (these are
        global average emission factors). The scope of these emission factors covers first 2 steps of food value chain
        only – land use change and farm-level. Downstream steps (e.g. processing, transport, retail, waste) not
        currently included. The ingredient-level emission factors are rolled up to item-level emission factors, based on
        the proportion of ingredients in a recipe. Where the emission factor for an item is missing, Group
        Sustainability (Ingka) have assigned the emission factor from a similar product (e.g. Soup Borscht (20374989)
        assigned Soup Tomato (30343389) emission factor){' '}
      </li>
      <li>Product sales quantity: from Ingka Common Sales Table</li>
      <li>
        Plant-based/non-red meat/red meat classification: each item is classified as plant-based, non-red meat or red
        meat (classification into plant-based or non-plant-based was performed by BA Food for Global Range items and
        some Local Range items). Additional classification to split non-plant-based into non-red meat and red meat, and
        to include Local Range performed by Group Sustainability (Ingka){' '}
      </li>
    </ul>

    <h3>Where are the goals coming from? </h3>
    <p>
      The Food team is the owner of the goals and they are approved by the Food Manager and Commercial Manager/Leader in
      each country.
    </p>
    <p>
      For FY23 the goal-setting process was based on forecasted FY23 sales for in-scope items to forecast expected
      tonnes of CO2e and plant-based qty share. These 2 goals were then reviewed and approved by the country Food
      Managers and/or Commercial Managers/Leaders.
    </p>
    <p>
      Note that for Food, FY23 is a year of learning and understanding how to work with climate measurement and action,
      so the goals are soft rather than hard.
    </p>

    <h3>Want to dive deeper?</h3>
    <p>
      Visit the{' '}
      <a
        className="Underlined"
        href="https://app.powerbi.com/groups/me/apps/55c28f1a-1816-4179-9b2d-9643710f13fe/reports/7aa75b3e-02eb-4c56-8c14-ba3b4a4bc3bb/ReportSection?ctid=720b637a-655a-40cf-816a-f22f40755c2c"
        target="_blank"
        rel="noreferrer"
      >
        Food Dashboard
      </a>{' '}
      to filter and drill down on a more granular level, including into which products are driving CO2e emissions from
      food in your store/country.
    </p>
  </>
)
