import React from 'react'
import { format, max as maxDate, min, sub } from 'date-fns'
import { maxBy, minBy } from 'lodash'
import { HFBShare } from '../../../api/src/common-types'
import { getHFBPPPShares } from '../../lib/APIClient'
import { max, SimpleBar } from '../BaseGraphs/SimpleBar'
import { formatWeekRangeTexts, getHfbSeries, HFBLegend } from '../HFBSharesGraph'
import { getCurrencySymbol, getCurrentLocationLabel } from '../Utils/utils'
import { SlideProps } from './ClimateFootprintLargestContributorSlide'
import { InStoreSlide } from './InStoreSlide'

import './HfbSlide.scss'
import colours from '../../Colours.module.scss'
import { LanguageContext } from '../../context'
import { InStoreI18n } from '../../Localisation'
import { formatLocalisationString } from '../../Localisation'

interface HfbSlideProps extends SlideProps {
  siteOrCountry: string
  type: 'top' | 'bottom'
}

export const HfbSlide: React.FC<HfbSlideProps> = ({ currentLocation, siteOrCountry, type }) => {
  const lang = React.useContext(LanguageContext)
  const currentLocationLabel = getCurrentLocationLabel(currentLocation, lang)
  const [hfbSales, setHfbSales] = React.useState<HFBShare[] | null>(null)
  const [dataSource, setDataSource] = React.useState('')

  React.useEffect(() => {
    getHFBPPPShares(siteOrCountry).then(hfbSales => {
      setHfbSales(hfbSales.filter(({ total }) => total > 0))

      setDataSource(
        formatLocalisationString(InStoreI18n[lang].Generic.deliveredSalesDuringWeeks, {
          startWeek: format(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sub(new Date(minBy(hfbSales, 'readableDate')!.readableDate), { weeks: 4 }),
            'I/RRRR'
          ),
          endWeek: format(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            new Date(maxBy(hfbSales, 'readableDate')!.readableDate),
            'I/RRRR'
          )
        })
      )
    })
  }, [])

  const prevPeriodEnd = min((hfbSales ?? []).map(s => new Date(s.readableDate)))
  const latestDate = maxDate((hfbSales ?? []).map(s => new Date(s.readableDate)))
  const { previousPeriod, currentPeriod } = formatWeekRangeTexts(prevPeriodEnd, latestDate, lang)
  const latestDateData = (hfbSales ?? [])
    .filter(s => new Date(s.readableDate).getTime() === latestDate.getTime())
    .sort((a, b) => b.onlinePPPAsisSales + b.storePPPAsisSales - (a.onlinePPPAsisSales + a.storePPPAsisSales))

  const topOrBottomLatestDateData =
    type === 'top'
      ? latestDateData.slice(0, Math.floor(latestDateData.length / 2))
      : latestDateData.slice(-1 * Math.ceil(latestDateData.length / 2))

  const createHfbTitle = (hfbName: string) => (
    <div className="HFBLink">
      <div>{hfbName}</div>
    </div>
  )

  const isGlobal = siteOrCountry === 'ALL'
  return (
    <InStoreSlide
      className="HfbSlide"
      header={{
        location: currentLocationLabel,
        description: InStoreI18n[lang].HFB.description,
        title: type === 'top' ? InStoreI18n[lang].HFB.topTitle : InStoreI18n[lang].HFB.bottomTitle,
        dataSource
      }}
      graph={
        hfbSales ? (
          <>
            <div className="HfbSlideHeader">
              <span>
                {type === 'top' ? InStoreI18n[lang].HFB.topCategories : InStoreI18n[lang].HFB.bottomCategories}
              </span>
              <span>{getCurrencySymbol(currentLocation.currencyCode, lang)}</span>
            </div>
            <SimpleBar
              series={getHfbSeries(
                hfbSales,
                topOrBottomLatestDateData,
                'total',
                colours.salmon,
                colours.salmon1,
                createHfbTitle,
                isGlobal,
                true
              )}
              withChange
              stacked={false}
              longSerieNames
              maxValue={max(
                getHfbSeries(
                  hfbSales,
                  latestDateData,
                  'total',
                  colours.salmon,
                  colours.salmon1,
                  createHfbTitle,
                  isGlobal,
                  true
                )
              )}
            />
            <HFBLegend previousPeriod={previousPeriod} currentPeriod={currentPeriod} />
          </>
        ) : null
      }
      isLoading={!hfbSales}
    />
  )
}
