import classNames from 'classnames'
import { range } from 'lodash'
import React from 'react'

import styles from './Insights/Insights.module.scss'

interface SelectSlideProps {
  className?: string
  current: number
  total: number
  onChange: (index: number) => void
}

export const SelectSlide: React.FC<SelectSlideProps> = ({ className, current, total, onChange }) => {
  return total > 1 ? (
    <ol className={classNames(className, styles.SelectSlide)}>
      {range(total).map(index => (
        <li key={index} className={index === current ? styles.Active : ''}>
          <button onClick={() => onChange(index)}></button>
        </li>
      ))}
    </ol>
  ) : null
}
