import _, { isNil } from 'lodash'

import { LeaderboardListItem, LeaderboardRanking, SortByKey, SortDirection } from './LeaderboardPage'
import { CountryRanking, SiteRanking } from '../../../api/src/common-types'
import { WatchlistScope } from '../../SharedSelections'

export function getListContentCountry(
  rankings: CountryRanking[],
  sortByKey: SortByKey,
  sortDirection: SortDirection
): LeaderboardListItem[] {
  return _(rankings)
    .map(countryRanking => ({
      ...getLeaderboardRanking(countryRanking),
      id: countryRanking.entityId,
      name: countryRanking.countryName,
      countryCode: countryRanking.countryCode
    }))
    .orderBy(sortByKey, sortDirection)
    .value()
}

const getLeaderboardRanking = (ranking: CountryRanking | SiteRanking): LeaderboardRanking => ({
  rank: ranking.overallRank,
  change: ranking.overallRank && ranking.overallRankPrevious ? ranking.overallRankPrevious - ranking.overallRank : null,
  recyclingRate: ranking.recyclingRateRank,
  climateFootprint: ranking.planetRank,
  pppSalesShare: ranking.profitRank,
  pppShare: ranking.pppRate,
  recyclingShare: ranking.recyclingRate,
  climateFootprintShare: ranking.footprintRate,
  reason: !isNil(ranking.reason) ? ranking.reason : ''
})

export function getListContentSite(
  sites: SiteRanking[],
  sortByKey: SortByKey,
  sortDirection: SortDirection
): LeaderboardListItem[] {
  const sitesRanking = siteRankingToLeaderboardListItem(sites)
  return _.orderBy(sitesRanking, sortByKey, sortDirection)
}

export function getListContentSiteAdjustedRank(
  sites: SiteRanking[],
  sortByKey: SortByKey,
  sortDirection: SortDirection
): LeaderboardListItem[] {
  const sitesRanking = siteRankingToLeaderboardListItem(sites)
  const sitesRankingAdjusted = adjustRankToSelection(sitesRanking)
  return _.orderBy(sitesRankingAdjusted, sortByKey, sortDirection)
}

function siteRankingToLeaderboardListItem(siteRanking: SiteRanking[]) {
  return siteRanking.map(site => ({
    ...getLeaderboardRanking(site),
    id: site.entityId,
    name: site.siteName,
    countryCode: site.countryCode
  }))
}

export function getListContentWatchlist(
  scope: WatchlistScope,
  watchlist: string[],
  countryRankings: CountryRanking[],
  sites: SiteRanking[],
  sortByKey: SortByKey,
  sortDirection: SortDirection
): LeaderboardListItem[] {
  return scope === 'country'
    ? getListContentCountry(
        countryRankings.filter(({ entityId }) => watchlist.includes(entityId)),
        sortByKey,
        sortDirection
      )
    : getListContentSite(
        sites.filter(({ entityId }) => watchlist.includes(entityId)),
        sortByKey,
        sortDirection
      )
}

export function adjustRankToSelection(locationList: LeaderboardListItem[]): LeaderboardListItem[] {
  type LeaderboardListItemWithPrevRank = LeaderboardListItem & { previousRank: number | null }

  const addPreviousRank = (location: LeaderboardListItem) => ({
    ...location,
    previousRank: location.rank === null || location.change === null ? location.rank : location.rank + location.change
  })
  const renumberChange = (location: LeaderboardListItemWithPrevRank) => ({
    ...location,
    change: location.rank === null || location.previousRank === null ? null : location.previousRank - location.rank
  })

  const renumberRankBy = (rankPropertyName: SortByKey | 'previousRank') => {
    let oldPrevRank: number | null = 0
    let newPrevRank: number | null = 0

    const updatePrevRanks = (oldCurrRank: number | null, newCurrRank: number | null) => {
      oldPrevRank = oldCurrRank
      newPrevRank = newCurrRank
    }

    return (location: LeaderboardListItemWithPrevRank) => {
      let newCurrRank: number | null = -1
      const oldCurrRank = location[rankPropertyName]

      const keepDuplicateRank = (newPrevRank: number | null) => newPrevRank
      const skipGapsInRanking = (newPrevRank: number | null) => (newPrevRank === null ? newPrevRank : newPrevRank + 1)

      if (oldCurrRank === null) {
        newCurrRank = null
      } else if (oldCurrRank === oldPrevRank) {
        newCurrRank = keepDuplicateRank(newPrevRank)
      } else {
        newCurrRank = skipGapsInRanking(newPrevRank)
      }

      const locationWithRenumberedRank = { ...location }
      locationWithRenumberedRank[rankPropertyName] = newCurrRank as never

      updatePrevRanks(oldCurrRank, newCurrRank)
      return locationWithRenumberedRank
    }
  }

  return _(locationList)
    .map(addPreviousRank)
    .sortBy('rank')
    .map(renumberRankBy('rank'))
    .sortBy('previousRank')
    .map(renumberRankBy('previousRank'))
    .map(renumberChange)
    .sortBy('recyclingRate')
    .map(renumberRankBy('recyclingRate'))
    .sortBy('climateFootprint')
    .map(renumberRankBy('climateFootprint'))
    .sortBy('pppSalesShare')
    .map(renumberRankBy('pppSalesShare'))
    .value()
}

// Adapted from Ramda, https://ramdajs.com/docs/#groupWith
export function groupWith<T>(fn: (item: T) => boolean, list: T[]): T[][] {
  const res = []
  let idx = 0
  const len = list.length
  while (idx < len) {
    let nextidx = idx + 1
    while (nextidx < len && fn(list[nextidx - 1]) === fn(list[nextidx])) {
      nextidx += 1
    }
    res.push(list.slice(idx, nextidx))
    idx = nextidx
  }
  return res
}
