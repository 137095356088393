import React from 'react'
import { add, max, min } from 'date-fns'
import { groupBy, isNil, maxBy, sumBy, uniqBy } from 'lodash'
import { ApiEmissionsFootprint, GoalsResponse, SiteGoals } from '../../../api/src/common-types'
import { FootprintType, getGoals, getPlanetFootprints, isCountry, isSite, LocationWithType } from '../../lib/APIClient'
import { GraphUnit } from '../UnitSelector'
import { formatRelativeNumber } from '../Utils/format'
import {
  getCountry,
  getCurrentCountryLabel,
  getCurrentLocationLabel,
  getGoalsForLocation,
  getInStoreLabel,
  isCluster
} from '../Utils/utils'
import { SlideSelectorWithCountryCodesProps } from './ClimateFootprintLargestContributorSlide'
import { InStoreSlide } from './InStoreSlide'
import { useDataAvailabilityContext, useLocations, LanguageContext } from '../../context'
import { lineChart } from '../BaseGraphs/GraphUtil'

import './EnergyEfficiencySlide.scss'
import colours from '../../Colours.module.scss'
import { InStoreI18n } from '../../Localisation'
import { dataSourceTextWithStartDate, formatLocalisationString } from '../../Localisation'
import { ChartContainer, Legend, Serie } from '../BaseGraphs/ChartContainer'
import { useSharedSelections } from '../../SharedSelections'
import { getIngkaFinancialYear } from '../Utils/dates'

const highlightedSiteColor = colours.blue5
const otherSitesColor = colours.offWhite5

export const EnergyEfficiencySlide = ({ currentLocation, selector }: SlideSelectorWithCountryCodesProps) => {
  const { locations } = useLocations()
  const [dataSource, setDataSource] = React.useState('')
  const [rolling12Sum, setRolling12Sum] = React.useState<string>('N/A')
  const [badge, setBadge] = React.useState<string>('N/A')
  const [onTrack, setOnTrack] = React.useState<number>(0)
  const [series, setSeries] = React.useState<Serie[]>([])
  const [goals, setGoals] = React.useState<GoalsResponse>()
  const [{ func }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()
  const lang = React.useContext(LanguageContext)
  const locationId = isSite(currentLocation) ? currentLocation.siteId : currentLocation.countryCode
  const dateNow = new Date()
  const goalsFy = (dataAvailability?.energyCurrentFY || getIngkaFinancialYear(dateNow).getFullYear()) - 2000

  React.useEffect(() => {
    const countrySelector = {
      ...selector,
      site: 'ALL',
      locationId: locationId,
      start_fy: (dataAvailability?.planetPreviousFY ?? 1) - 1,
      countryCodes: [currentLocation.countryCode]
    }

    getPlanetFootprints(FootprintType.Emissions, countrySelector).then(response => {
      const result = (response.data as ApiEmissionsFootprint[]).filter(r => r.rawUnit === 'kWh')

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const latestDateResult = maxBy(result, 'readableDate')!

      const rolling12Sum = result
        .filter(
          r =>
            r.fiscalYear === latestDateResult.fiscalYear ||
            (r.fiscalYear === latestDateResult.fiscalYear - 1 && r.period > latestDateResult.period)
        )
        .filter(r => (isSite(currentLocation) ? r.siteId === currentLocation.siteId : true))
        .reduce((acc, r) => acc + r.raw, 0)

      const previous12Months = result
        .filter(
          r =>
            (r.fiscalYear === latestDateResult.fiscalYear - 1 && r.period <= latestDateResult.period) ||
            (r.fiscalYear === latestDateResult.fiscalYear - 2 && r.period > latestDateResult.period)
        )
        .filter(r => (isSite(currentLocation) ? r.siteId === currentLocation.siteId : true))
      const previous12MonthSum = previous12Months.reduce((acc, r) => acc + r.raw, 0)

      setDataSource(
        dataSourceTextWithStartDate(
          lang,
          min(previous12Months.map(r => new Date(r.readableDate))),
          max(result.map(r => new Date(r.readableDate))),
          InStoreI18n[lang].Generic.sustainData
        )
      )

      const area = isSite(currentLocation)
        ? currentLocation.areaM2
        : locations
            .filter(isSite)
            .filter(l => currentLocation.countryCode === 'ALL' || l.countryCode === currentLocation.countryCode)
            .reduce((acc, l) => acc + l.areaM2, 0)
      setRolling12Sum(formatRelativeNumber(rolling12Sum / area))
      setBadge(
        `${formatRelativeNumber(Math.round(Math.abs(rolling12Sum - previous12MonthSum) / area))} kWh/m² ${
          rolling12Sum < previous12MonthSum ? InStoreI18n[lang].Generic.less : InStoreI18n[lang].Generic.more
        } ${InStoreI18n[lang].Generic.thanThisTime}`
      )
      setOnTrack(rolling12Sum - previous12MonthSum)

      if (!isCluster(locationId)) {
        getGoals(getCountry(locationId, locations).countryCode, func, goalsFy + 2000).then(setGoals)
      }
      const dataSeries = formatEmissionsData(
        result.filter(r => r.fiscalYear >= latestDateResult.fiscalYear - 1),
        currentLocation.countryCode === 'ALL',
        locations,
        GraphUnit.RelativeRawUnits,
        countrySelector.locationId
      )
      setSeries(
        [
          ...dataSeries
            .filter(d => (isSite(currentLocation) ? d.id !== currentLocation.siteId : true))
            .map((d, i) => ({
              ...d,
              color: otherSitesColor,
              zIndex: i,
              unit: '%'
            })),
          // Add selected site always last, so it will be drawn on top of other sites
          ...(isSite(currentLocation)
            ? [
                {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  ...dataSeries.find(d => d.id === currentLocation.siteId)!,
                  name: currentLocation.label,
                  color: highlightedSiteColor,
                  zIndex: dataSeries.length,
                  unit: '%'
                }
              ]
            : [])
        ].filter(serie => serie.data.some(({ y }) => y !== -1))
      )
    })
  }, [dataAvailability])

  const domain = series.length > 0 ? series.find(s => s.id === locationId)?.data.map(({ x }) => x) : []
  const currLocationEnergyEfficiencyGoal = getGoalsForLocation(locationId, goals) as SiteGoals

  const erenergyEfficiencyWithGoals = series
    ? [
        ...series,
        {
          color: '#7d8fad',
          data: Object.values(groupBy(domain, d => getIngkaFinancialYear(d).getFullYear()))[0]
            ?.map(d => add(d, { years: 1 }))
            .map(date => ({
              x: date,
              y: currLocationEnergyEfficiencyGoal ? currLocationEnergyEfficiencyGoal?.energyEfficiencyGoal / 12 : 0
            })),
          fill: undefined,
          name: 'Goal',
          zIndex: 0,
          unit: '%'
        }
      ]
    : undefined

  const countryLabel = getCurrentCountryLabel(currentLocation, locations, lang)
  return (
    <InStoreSlide
      className="EnergyEfficiencySlide"
      header={{
        location: getCurrentLocationLabel(currentLocation, lang),
        description: formatLocalisationString(InStoreI18n[lang]['Energy Efficiency'].description, {
          siteLabel: getInStoreLabel(currentLocation, lang),
          country: countryLabel
        }),
        title: InStoreI18n[lang]['Energy Efficiency'].title,
        dataSource: dataSource
      }}
      series={{
        name: InStoreI18n[lang]['Energy Efficiency'].seriesName,
        value: rolling12Sum,
        unit: 'kWh/m²',
        badge,
        onTrack
      }}
      graph={
        <>
          <ChartContainer
            generator={lineChart}
            series={erenergyEfficiencyWithGoals}
            domain={series.length > 0 ? series.find(s => s.id === locationId)?.data.map(({ x }) => x) : []}
            dateFormat="month"
            hideLegend
            yAxisTitle="kWh/m²"
          />
          <Legend
            series={[
              { id: 'selected', name: currentLocation.label, color: highlightedSiteColor, data: [] },
              ...(Object.keys(series).length > 1
                ? [
                    {
                      id: 'others',
                      name: formatLocalisationString(InStoreI18n[lang].Generic.otherLocations, {
                        siteLabel: getInStoreLabel(currentLocation, lang),
                        country: countryLabel
                      }),
                      color: otherSitesColor,
                      data: []
                    }
                  ]
                : []),
              { id: 'selected', name: 'Goal', color: '#7d8fad', data: [] }
            ]}
            selectedSeries={[currentLocation.label, 'Goal']}
            leftMargin={0}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            changeSelectionForAllItems={() => {}}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onItemClicked={() => {}}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            setHighlightedSerie={() => {}}
          />
        </>
      }
      isLoading={series.length === 0}
    />
  )
}

const findAreaForCountryAndDate = (data: ApiEmissionsFootprint[], countryCode: string, date: Date) =>
  sumBy(
    uniqBy(
      data.filter(
        x => x.countryCode === countryCode && new Date(x.readableDate).getTime() === date.getTime() && !isNil(x.raw)
      ),
      'siteId'
    ),
    'areaM2'
  )

const areaDataMissingIndicator = -1

function formatEmissionsData(
  data: ApiEmissionsFootprint[],
  isGlobal: boolean,
  locations: LocationWithType[],
  ecLocUnit: GraphUnit,
  locationId: string
) {
  const countries = locations.filter(isCountry)
  const groupingField = isGlobal || isCluster(locationId) ? 'countryCode' : 'siteId'
  return data
    .reduce((result, d) => {
      let dataForLocation = result.find(r => r.id === d[groupingField])
      if (dataForLocation == null) {
        const name =
          groupingField === 'countryCode'
            ? countries.find(c => c.countryCode === d.countryCode)?.countryName ?? d.countryCode
            : d.siteName
        dataForLocation = {
          id: d[groupingField],
          name: groupingField === 'countryCode' ? name : name,
          data: []
        }
        result.push(dataForLocation)
      }
      const x = new Date(d.readableDate)
      let dataForDate = dataForLocation.data.find(l => l.x.getTime() === x.getTime())
      if (dataForDate == null) {
        dataForDate = { x: x, y: 0 }
        dataForLocation.data.push(dataForDate)
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataForDate.y! += d.raw
      return result
    }, Array<Omit<Serie, 'color'>>())
    .map(d => {
      if (ecLocUnit === GraphUnit.RawUnits) {
        return d
      }
      return {
        ...d,
        data: d.data.map(dp => {
          const areaM2 = isGlobal
            ? findAreaForCountryAndDate(data, d.id ?? '', dp.x)
            : data.find(x => x.siteId === d.id && dp.x.getTime() === new Date(x.readableDate).getTime())?.areaM2
          return {
            ...dp,
            y: dp.y ? (areaM2 ? dp.y / areaM2 : areaDataMissingIndicator) : NaN
          }
        })
      }
    })
}
