import React from 'react'
import { FunctionSelectorTip } from './FunctionSelectorTip'

import DecreaseExample from '../../images/HowIsThisCalculated/WhatIfDecrease.png'
import ElectricityExample from '../../images/HowIsThisCalculated/WhatIfElectricity.png'
import ShiftExample from '../../images/HowIsThisCalculated/WhatIfShift.png'

export const WhatIfModalContent = () => (
  <>
    <p className="IntroParagraph" data-testid="whatif-modal-intro-para">
      The What-If simulator allows you to create annual energy, water and waste usage scenarios based on your
      selection’s consumption and production patterns and shows you how those can impact your climate footprint. You
      have the option to ‘shift’ all or part of a source or disposal type to a more planet positive one or reduce your
      existing fractions and observe how that scenario could advance you towards your footprint goal(s).
    </p>
    <h3>How are calculating the scenario?</h3>
    <p>The performance line in the What-If graph has two components:</p>
    <ol>
      <li>Your actual consumption YTD for the current financial year</li>
      <li>
        A Scenario of your consumption for the rest of the financial year based on historical values and the most recent
        conversion factors available
      </li>
    </ol>
    <p>We use FY19 volumes as this is considered the most recent “normal” year not affected by Covid.</p>
    <h4>What-If calculation example</h4>
    <h2>Electricity</h2>
    <img src={ElectricityExample} alt="Electricity calculation example table" />
    <h4>What does ‘Shift’ mean?</h4>
    <p>
      The Shift option gives you the chance to experiment with sourcing, or disposing of, a fraction differently. (See
      the information icon to know what shift you are making.)
    </p>
    <p>
      In the case of waste, we show you the difference it would make to your climate footprint if you disposed of a
      waste source by material recycling instead of a less optimal disposal type. For example: What is the CO2e impact
      of sending your mixed waste to material recycling instead of landfill?
    </p>
    <p>
      With energy, you can experiment with switching biofuel and fossil fuel sources to your existing electricity
      source. If you currently use non-renewable electricity, you can play with scenarios of sourcing your electricity
      from renewables.
    </p>
    <h4>Calculation example</h4>
    <h2>Shifting quantity from one factor to another</h2>
    <img src={ShiftExample} alt="Shifting quantity from one factor to another calculation example" />
    <h4>Calculation example</h4>
    <h2>Decreasing the quantity of the same factor</h2>
    <img src={DecreaseExample} alt="Decreasing the quantity of the same factor calculation example" />
    <h4>How do we determine which fractions to show you?</h4>
    <p>
      For each category energy, water and waste, we show you the 3 fractions with the greatest potential to make an
      impact on your climate footprint (in T CO2e). The ranking is based on your projected climate footprint for the
      rest of the fiscal year.
    </p>
    <h4>Why am I not seeing all possible fractions?</h4>
    <p>
      We want to support you in focusing on the lowest-hanging fruits. The top 3 fractions typically make up the bulk of
      your footprint and are the fractions with which you have the greatest potential to make a difference in your
      climate performance.
    </p>
    <FunctionSelectorTip text="build scenarios within individual functions" />
  </>
)
