import { capitalize, flatMap, compact } from 'lodash'
import React from 'react'
import {
  InsightDominantCategory,
  InsightDominantFactor,
  InsightDominantWaste,
  InsightGlobalRelativeFpBest,
  InsightGlobalRelativeFpWorst,
  InsightHfbShareComparison1,
  InsightHfbShareComparison2,
  InsightLargestContributors,
  InsightRecoveredProducts,
  InsightRecyclingRate,
  InsightRelativeFootprint,
  InsightSalesShareQuartile,
  InsightYoyBiggestDecrease,
  InsightYoyBiggestIncrease,
  InsightTenantCASplit
} from '../../../api/src/common-types'

import styles from './Insights.module.scss'
import {
  SimpleVerticalBarChart,
  PercentageBarChart,
  VerticalComparisonBarChart,
  SideBySideComparisonBarChart
} from '../BaseGraphs/SimpleBar'
import { SunburstGraph } from '../BaseGraphs/SunburstGraph'
import { colors, globalColors } from './Insight'
import CoCreationLamp from '../../images/co-creation-lamp.jpg'
import SmallestContributors from '../../images/smallestcontributors.png'
import HappyPlanet from '../../images/happyearth.png'

const getCategorySeries = ({ plugins }: InsightDominantCategory | InsightDominantFactor) =>
  flatMap(['1', '2', '3', '4'] as const, index => {
    if (index in plugins) {
      return {
        name: capitalize(plugins[index].planet_lvl_1),
        color: colors[plugins[index].planet_lvl_1]?.primary,
        value: plugins[index].footprint_ytd
      }
    } else {
      return []
    }
  })

export const DominantCategoryGraph = (insight: InsightDominantCategory) => (
  <SunburstGraph
    series={[getCategorySeries(insight)]}
    tooltipSettings={{
      valueFn: x => x / 1000,
      title: 'Category',
      subtitle: 'tonnes CO2e'
    }}
  />
)

export const DominantFactorGraph = (insight: InsightDominantFactor) => (
  <SunburstGraph
    series={[
      getCategorySeries(insight),
      [
        {
          name: insight.plugins['1'].lvl_2.planet_lvl_2_desc,
          color: colors[insight.plugins['1'].planet_lvl_1].secondary,
          value: insight.plugins['1'].lvl_2.footprint_ytd
        }
      ]
    ]}
    tooltipSettings={{
      valueFn: x => x / 1000,
      title: 'Type',
      subtitle: 'tonnes CO2e'
    }}
  />
)

export const GlobalRelativeFpGraph = (insight: InsightGlobalRelativeFpBest | InsightGlobalRelativeFpWorst) => {
  const makeSerie = (idx: '1' | '2' | '3') => ({
    label: insight.plugins[idx]?.country_name ?? '',
    color: globalColors[Number(idx) - 1],
    value: insight.plugins[idx]?.entity_fp_m2 ?? 0
  })
  const series = compact([
    makeSerie('1'),
    insight.plugins['2'] && makeSerie('2'),
    insight.plugins['3'] && makeSerie('3')
  ])
  return <SimpleVerticalBarChart series={series} />
}

export const RecyclingRateGraph = (insight: InsightRecyclingRate) => (
  <>
    <PercentageBarChart
      color={colors.pink}
      label={insight.plugins.entity_unit.entity_name}
      percentage={insight.plugins.entity_unit.recycle_pc * 100}
      badge="Selected unit"
    />
    <PercentageBarChart
      color={colors.green.primary}
      label={insight.plugins.top_entity_unit.entity_name}
      percentage={insight.plugins.top_entity_unit.recycle_pc * 100}
      badge="Best performer"
    />
  </>
)

export const RelativeFootprintGraph = (insight: InsightRelativeFootprint) => (
  <>
    <PercentageBarChart
      color={colors.pink}
      label={insight.plugins.entity_unit.entity_name}
      percentage={100}
      fillRestWithGrey={false}
      badge="Selected unit"
    />
    <PercentageBarChart
      color={colors.green.primary}
      label={insight.plugins.top_entity_unit.entity_name}
      percentage={(insight.plugins.top_entity_unit.entity_fp_m2 / insight.plugins.entity_unit.entity_fp_m2) * 100}
      fillRestWithGrey={false}
      badge="Best performer"
    />
  </>
)

export const YoyBiggestChangeGraph = (insight: InsightYoyBiggestIncrease | InsightYoyBiggestDecrease) => (
  <VerticalComparisonBarChart
    className={styles.ModalInsightVerticalComparisonBarChart}
    label={insight.plugins['1'].planet_lvl_2_desc}
    previous={{ value: insight.plugins['1'].lvl_2.footprint_prev_ytd, color: colors.green.secondary }}
    current={{ value: insight.plugins['1'].lvl_2.footprint_ytd, color: colors.green.primary }}
    tooltipSettings={{
      title: 'Year',
      subtitle: 'tonnes CO2E',
      valueFn: x => x / 1000
    }}
  />
)

export const CoCreationLinkGraph = () => <img className={styles.RoundedImage} src={CoCreationLamp} alt="Co-Creation" />

export const HfbShareComparisonGraph = (insight: InsightHfbShareComparison1 | InsightHfbShareComparison2) => {
  return (
    <div className={styles.HfbShareComparisonChart}>
      {Object.entries(insight.plugins)
        .filter(([key]) => key !== 'entity')
        .map(([key, value]) => (
          <SideBySideComparisonBarChart
            key={key}
            left={{ value: value.ppp_share_entity * 100, color: colors.pink }}
            right={{ value: value.ppp_share_parent * 100, color: colors.green.primary }}
            label={value.hfb_name}
          />
        ))}
    </div>
  )
}

export const SalesShareQuartileGraph = (insight: InsightSalesShareQuartile) => (
  <>
    <PercentageBarChart
      color={colors.pink}
      label={insight.plugins.entity_unit.entity_name}
      percentage={insight.plugins.entity_unit.ppp_share * 100}
      badge="Selected location"
    />
    <PercentageBarChart
      color={colors.green.primary}
      label={insight.plugins.top_entity_unit.entity_name}
      percentage={insight.plugins.top_entity_unit.ppp_share * 100}
      badge="Top performer"
    />
  </>
)

const emptyWaste = {
  lvl_2: {
    footprint_ytd: 0,
    reference_quantity_ytd: 0,
    fp_pc: 0
  },
  footprint_ytd: 0
}

export const DominantWasteGraph = (insight: InsightDominantWaste) => {
  const recycled =
    insight.plugins['1'].waste_type === 'recycled' ? insight.plugins['1'] : insight.plugins['2'] ?? emptyWaste
  const nonRecycled =
    insight.plugins['1'].waste_type === 'non_recycled' ? insight.plugins['1'] : insight.plugins['2'] ?? emptyWaste

  return (
    <SunburstGraph
      series={[
        [
          { name: 'Recycled', color: colors.energy.primary, value: recycled.footprint_ytd },
          { name: 'Not Recycled', color: colors.waste.primary, value: nonRecycled.footprint_ytd }
        ],
        [
          {
            name: nonRecycled.lvl_2.planet_lvl_2_desc,
            color: colors.waste.secondary,
            value: nonRecycled.lvl_2.footprint_ytd
          }
        ]
      ]}
      sort={false}
      tooltipSettings={{
        valueFn: x => x / 1000,
        title: 'Waste type',
        subtitle: 'tonnes CO2e'
      }}
    />
  )
}

export const RecoveredProductsGraph = (insight: InsightRecoveredProducts) => (
  <SunburstGraph
    series={[
      [
        { name: 'As-Is', color: colors.recycling.asis, value: insight.plugins.asis_sold_pc * 100 },
        { name: 'Repack', color: colors.recycling.repack, value: insight.plugins.repack_pc * 100 }
      ]
    ]}
  />
)

export const SmallestContributorsGraph = () => (
  <div>
    <img className={styles.SmallestContributorsGraph} src={SmallestContributors} alt="Smallest Contributors" />
    <img className={styles.SmallestContributorsGraph} src={HappyPlanet} alt="Happy Planet" />
  </div>
)

export const LargestContributorsGraph = ({ plugins }: InsightLargestContributors) => {
  const sum = plugins[1].fp_pc + (plugins[2]?.fp_pc ?? 0) + (plugins[3]?.fp_pc ?? 0)
  const series = compact([
    { name: plugins[1].country_name, color: colors.green.primary, value: plugins[1].fp_pc * 100 },
    plugins[2] && { name: plugins[2].country_name, color: colors.darkBlue, value: plugins[2].fp_pc * 100 },
    plugins[3] && { name: plugins[3].country_name, color: colors.lightBlue, value: plugins[3].fp_pc * 100 },
    { name: 'Others', color: colors.grey.light, value: (1 - sum) * 100 }
  ])
  return (
    <SunburstGraph
      sort={false}
      series={[series]}
      tooltipSettings={{
        valueFn: x => Math.round(x),
        title: 'Country',
        subtitle: '%',
        unit: '%'
      }}
    />
  )
}

export const TenantCASplitGraph = ({ plugins }: InsightTenantCASplit) => {
  const series = compact([
    { name: plugins[1].planet_lvl_4_desc, color: colors.green.primary, value: plugins[1].footprint_ytd },
    plugins[2] && { name: plugins[2].planet_lvl_4_desc, color: colors.darkBlue, value: plugins[2].footprint_ytd }
  ])
  return (
    <SunburstGraph
      sort={false}
      series={[series]}
      tooltipSettings={{
        valueFn: x => x / 1000,
        title: 'Category',
        subtitle: 'tonnes CO2e'
      }}
    />
  )
}
