import React, { FunctionComponent } from 'react'
import ReactDOM from 'react-dom'
import { MsalProvider } from '@azure/msal-react'
import App from './App'
import { isLocal, msalApp } from './Authentication'

import './skapa-dependencies.scss'
import { BrowserRouter } from 'react-router-dom'
import { initialiseAnalytics } from './components/Utils/analytics'

initialiseAnalytics()

const Authenticator: FunctionComponent = ({ children }) => {
  if (isLocal()) {
    return <>{children}</>
  } else {
    return <MsalProvider instance={msalApp}>{children}</MsalProvider>
  }
}

ReactDOM.render(
  <Authenticator>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Authenticator>,
  document.getElementById('root')
)
