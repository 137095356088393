import classNames from 'classnames'
import { flatMap, isEmpty, compact } from 'lodash'
import React from 'react'
import { Insight as InsightType, InsightPlugins } from '../../../api/src/common-types'

import styles from './Insights.module.scss'
import colours from '../../Colours.module.scss'
import { SimpleLegend } from '../BaseGraphs/SimpleLegend'
import {
  isCoCreationLink,
  isDominantCategory,
  isDominantFactor,
  isDominantWaste,
  isGlobalLargestContributors,
  isGlobalRelativeFpBest,
  isGlobalRelativeFpBWorst,
  isGlobalSmallestContributors,
  isHfbShareComparison1,
  isHfbShareComparison2,
  isRecoveredProducts,
  isRecyclingRate,
  isRelativeFootprint,
  isSalesShareQuartile,
  isTenantCASplit,
  isYoyBiggestDecrease,
  isYoyBiggestIncrease
} from './guards'
import { InStoreI18n } from '../../Localisation'
import {
  CoCreationLinkGraph,
  DominantCategoryGraph,
  DominantFactorGraph,
  DominantWasteGraph,
  GlobalRelativeFpGraph,
  HfbShareComparisonGraph,
  LargestContributorsGraph,
  RecoveredProductsGraph,
  RecyclingRateGraph,
  RelativeFootprintGraph,
  SalesShareQuartileGraph,
  SmallestContributorsGraph,
  TenantCASplitGraph,
  YoyBiggestChangeGraph
} from './InsightGraphs'
import {
  CoCreationLinkText,
  DominantCategoryText,
  DominantFactorText,
  DominantWasteText,
  GlobalRelativeFpText,
  HfbShareComparisonText,
  RecoveredProductsText,
  RecyclingRateText,
  RelativeFootprintText,
  SalesShareQuartileText,
  SmallestOrLargestContributorsText,
  TenantCASplitText,
  YoyBiggestChangeText
} from './InsightTexts'
import { Language } from '../../Localisation'
import { useDataAvailabilityContext, useLocations, LanguageContext } from '../../context'
import { getCountryOrClusterName, getLocationId, getLocationLabel, isSiteId } from '../Utils/utils'
import { format } from 'date-fns'
import { SelectInsightAndFeedback } from '../SelectInsightAndFeedback'

export const colors = {
  darkBlue: colours.blue5,
  lightBlue: colours.lightBlue3,
  grey: {
    dark: colours.grey4,
    light: colours.grey2
  },
  green: {
    primary: colours.green1,
    secondary: colours.lightGreen1
  },
  pink: colours.lightPink2,
  purple: {
    primary: colours.purple2,
    secondary: colours.lightPurple1
  },
  recycling: {
    asis: colours.orange2,
    repack: colours.blue4
  },
  energy: {
    primary: colours.purple1,
    secondary: colours.darkPurple
  },
  refrigerants: {
    primary: colours.salmon2,
    secondary: colours.salmon3
  },
  waste: {
    primary: colours.yellow1,
    secondary: colours.orange
  },
  water: {
    primary: colours.blue8,
    secondary: colours.ightBlue8
  }
}

export const globalColors = [colors.green.primary, colors.darkBlue, colors.lightBlue]

interface InsightProps {
  insight: InsightType
  className?: string
}

interface InsightComponentProps extends InsightProps {
  currentLocationLabel?: string
  index?: number
  setIndex?: (index: number) => void
  insights?: InsightType[]
}

export const getDataSource = ({ type }: InsightType, lang: Language) =>
  type === 'sales' ? InStoreI18n[lang].Generic.deliveredSales : InStoreI18n[lang].Generic.sustainData

const getDateRange = (plugins: InsightPlugins) =>
  'date_from' in plugins && 'date_to' in plugins
    ? `${format(new Date(plugins.date_from), 'MMM yyyy')} - ${format(new Date(plugins.date_to), 'MMMM yyyy')}`
    : ''

export const Insight: React.FC<InsightComponentProps> = ({
  insight,
  currentLocationLabel,
  index,
  insights,
  setIndex
}) => {
  const lang = React.useContext(LanguageContext)
  const isWithGreenBackground = isEmpty(insight.plugins)

  return (
    <div className={styles.ModalInsight}>
      <div className={styles.ModalInsightText}>
        <p className={styles.ModalInsightLocationDate}>
          {currentLocationLabel
            ? currentLocationLabel
            : `Based on ${getDataSource(insight, lang)} ${getDateRange(insight.plugins)}`}
        </p>
        <div className={styles.ModalInsightTextCentered}>
          <h3 className={styles.ModalInsightTitle}>{insight.title}</h3>
          <h1 className={styles.ModalInsightContent}>
            <InsightText insight={insight} />
          </h1>
        </div>
        {insights?.length && typeof index === 'number' && setIndex && (
          <SelectInsightAndFeedback index={index} insights={insights} setIndex={setIndex} />
        )}
      </div>
      <div className={classNames(styles.ModalInsightGraph, { isWithGreenBackground })}>
        <div className={styles.ModalInsightGraphTop}>
          <TopLegend insight={insight} />
        </div>
        <div className={styles.ModalInsightGraphCenter}>
          <div className={styles.ModalInsightGraphContainer}>
            <InsightGraph insight={insight} />
          </div>
        </div>
        <div className={styles.ModalInsightGraphBottom}>
          <BottomLegend insight={insight} />
        </div>
      </div>
    </div>
  )
}

export const InsightGraph: React.FC<InsightProps> = ({ insight }) => {
  return isDominantCategory(insight) ? (
    <DominantCategoryGraph {...insight} />
  ) : isDominantFactor(insight) ? (
    <DominantFactorGraph {...insight} />
  ) : isGlobalRelativeFpBest(insight) || isGlobalRelativeFpBWorst(insight) ? (
    <GlobalRelativeFpGraph {...insight} />
  ) : isRecyclingRate(insight) ? (
    <RecyclingRateGraph {...insight} />
  ) : isRelativeFootprint(insight) ? (
    <RelativeFootprintGraph {...insight} />
  ) : isYoyBiggestDecrease(insight) || isYoyBiggestIncrease(insight) ? (
    <YoyBiggestChangeGraph {...insight} />
  ) : isCoCreationLink(insight) ? (
    <CoCreationLinkGraph />
  ) : isHfbShareComparison1(insight) || isHfbShareComparison2(insight) ? (
    <HfbShareComparisonGraph {...insight} />
  ) : isSalesShareQuartile(insight) ? (
    <SalesShareQuartileGraph {...insight} />
  ) : isDominantWaste(insight) ? (
    <DominantWasteGraph {...insight} />
  ) : isRecoveredProducts(insight) ? (
    <RecoveredProductsGraph {...insight} />
  ) : isGlobalSmallestContributors(insight) ? (
    <SmallestContributorsGraph />
  ) : isGlobalLargestContributors(insight) ? (
    <LargestContributorsGraph {...insight} />
  ) : isTenantCASplit(insight) ? (
    <TenantCASplitGraph {...insight} />
  ) : null
}

export const InsightText: React.FC<InsightProps> = ({ insight }) => {
  return isDominantCategory(insight) ? (
    <DominantCategoryText {...insight} />
  ) : isDominantFactor(insight) ? (
    <DominantFactorText {...insight} />
  ) : isGlobalRelativeFpBest(insight) || isGlobalRelativeFpBWorst(insight) ? (
    <GlobalRelativeFpText {...insight} />
  ) : isRecyclingRate(insight) ? (
    <RecyclingRateText {...insight} />
  ) : isRelativeFootprint(insight) ? (
    <RelativeFootprintText {...insight} />
  ) : isYoyBiggestDecrease(insight) || isYoyBiggestIncrease(insight) ? (
    <YoyBiggestChangeText {...insight} />
  ) : isCoCreationLink(insight) ? (
    <CoCreationLinkText />
  ) : isHfbShareComparison1(insight) || isHfbShareComparison2(insight) ? (
    <HfbShareComparisonText {...insight} />
  ) : isSalesShareQuartile(insight) ? (
    <SalesShareQuartileText {...insight} />
  ) : isDominantWaste(insight) ? (
    <DominantWasteText {...insight} />
  ) : isRecoveredProducts(insight) ? (
    <RecoveredProductsText {...insight} />
  ) : isGlobalSmallestContributors(insight) || isGlobalLargestContributors(insight) ? (
    <SmallestOrLargestContributorsText {...insight} />
  ) : isTenantCASplit(insight) ? (
    <TenantCASplitText {...insight} />
  ) : null
}

export const TopLegend: React.FC<InsightProps> = ({ insight, className }) => {
  const getSerie = () => {
    if (isDominantFactor(insight))
      return {
        name: insight.plugins['1'].lvl_2.planet_lvl_2_desc,
        color: colors[insight.plugins['1'].planet_lvl_1].secondary
      }

    if (isDominantWaste(insight)) {
      const { plugins } = insight
      const nonRecycled = plugins['1'].waste_type === 'non_recycled' ? plugins['1'] : plugins['2']
      return {
        name: nonRecycled.lvl_2.planet_lvl_2_desc,
        color: colors.waste.secondary
      }
    }
  }

  const serie = getSerie()

  return <SimpleLegend className={classNames(styles.ModalInsightLegend, className)} series={serie ? [serie] : []} />
}

export const BottomLegend: React.FC<InsightProps> = ({ insight, className }) => {
  const { dataAvailability } = useDataAvailabilityContext()
  const lang = React.useContext(LanguageContext)
  const i18n = InStoreI18n[lang]
  const { currentLocation } = useLocations()

  const getSeries = () => {
    if (isDominantCategory(insight) || isDominantFactor(insight)) {
      return flatMap(['1', '2', '3', '4'] as const, index => {
        if (index in insight.plugins) {
          return {
            name: i18n.Generic[insight.plugins[index].planet_lvl_1],
            color: colors[insight.plugins[index].planet_lvl_1]?.primary,
            value: insight.plugins[index].footprint_ytd
          }
        } else {
          return []
        }
      })
    }

    if (dataAvailability && (isYoyBiggestDecrease(insight) || isYoyBiggestIncrease(insight))) {
      return [
        { name: `FY${dataAvailability.planetPreviousFY - 2000}`, color: colors.green.secondary },
        { name: `FY${dataAvailability.planetCurrentFY - 2000}`, color: colors.green.primary }
      ]
    }

    if (isHfbShareComparison1(insight) || isHfbShareComparison2(insight)) {
      return [
        { name: getLocationLabel(currentLocation), color: colors.pink },
        {
          name: isSiteId(getLocationId(currentLocation)) ? getCountryOrClusterName(currentLocation) : 'Global',
          color: colors.green.primary
        }
      ]
    }

    if (isDominantWaste(insight)) {
      return [
        { name: 'Not Recycled', color: colors.waste.primary },
        { name: 'Recycled', color: colors.energy.primary }
      ]
    }

    if (isRecoveredProducts(insight)) {
      return [
        { name: 'As-Is sold', color: colors.recycling.asis },
        { name: 'Repack', color: colors.recycling.repack }
      ]
    }

    if (isGlobalLargestContributors(insight)) {
      const { plugins } = insight
      return compact([
        { name: plugins[1].country_name, color: colors.green.primary },
        plugins[2] && { name: plugins[2].country_name, color: colors.darkBlue },
        plugins[3] && { name: plugins[3].country_name, color: colors.lightBlue },
        { name: 'Others', color: colors.grey.light }
      ])
    }

    if (isTenantCASplit(insight)) {
      const { plugins } = insight
      return compact([
        { name: plugins[1].planet_lvl_4_desc, color: colors.green.primary },
        plugins[2] && { name: plugins[2].planet_lvl_4_desc, color: colors.darkBlue }
      ])
    }

    return []
  }

  return <SimpleLegend className={classNames(styles.ModalInsightLegend, className)} series={getSeries()} />
}
