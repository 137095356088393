import React from 'react'

export const KpiRecyclingRateContent = () => (
  <>
    <p className="IntroParagraph">To review and update the content</p>
    {/* <p className="IntroParagraph">
      To begin to measure our circularity we will start with Recycling Rate in our operations. We will also continue to
      develop this during FY22. Applying the recognized waste hierarchy, we have the ambition to reduce, prevent, re-use
      and recycle waste, and never incinerate or simply dispose of our waste. The Recycling Rate KPI will drive the
      action into minimizing the quantity of waste going to landfill and incineration and maximizing the share of waste
      going to recycling.
    </p>
    <h3>What metrics are we following?</h3>
    <ul>
      <li>
        <span>Recycling Rate (KPI)</span>
        <br />
        Reduction of non-recycled waste.
      </li>
      <li>
        <span>Share of Recovered Products (PI)</span>
        <br />
        Share of the number of products recovered from the potential waste products.
      </li>
      <li>
        <span>Share of waste to recycling (PI)</span>
        <br />
        Share of waste going to recycling.
      </li>
      <li>
        <span>Total Waste Generated (PI)</span>
        <br />
        Total waste generated by our operations.
      </li>
    </ul>
    <h3>How to contribute to Recycling Rate?</h3>
    <p>
      To contribute to this KPI it is important to focus on what are we doing with waste and understand why it becomes
      waste. It includes preventing, minimising and sorting the waste as much as possible, so it can be recycled, and
      avoid it ends up in landfill or incineration. It is also relevant to avoid waste generation, while focusing on
      reusing and recovering whenever possible.
    </p>
    <h3>Why are we measuring this? It is part of our sustainability ambitions</h3>
    <p>
      Reducing waste is part of the Recycling Rate ambition of Ingka’s People & Planet Positive strategy. This KPI is
      driving solutions and promoting circular processes and solutions for Recycling Rate in our operations.
    </p>
    <p>This KPI is contributing to the IKEA Business Plans and will be part of the Ingka Group Value Creation Goals.</p>
    <h3>How to calculate?</h3>
    <p>
      The Recycling Rate KPI is calculated by adding all the waste reported as waste going to landfill, incineration,
      no-value and external sorting (all non-recycling treatments). The total is then presented in weight.
    </p>
    <p>For the Products Recovered Share, this is the formula behind this performance indicator:</p>
    <p>
      Recovered Products: (As-Is Sales + TT450 Repackage) <br /> Recovery Inflow: (TT310 + TT325 + TT390 + TT391 +
      TT440)
    </p>
    <p>
      <i>Recovery Inflow Quantity:</i>
    </p>
    <ul>
      <li>TT310-Showroom Display </li>
      <li>TT325-Customer Return </li>
      <li>TT390-Internal Damage </li>
      <li>TT391-Recived Damage</li>
      <li>TT440-Undamaged to Recovery</li>
    </ul>
    <p>
      <i>Recovered Products Quantity:</i>
    </p>
    <ul>
      <li>As-Is Sales</li>
      <li>TT450-Repackaged</li>
    </ul>
    <h3>Where is the data coming from? How often it is updated?</h3>
    <ul>
      <li>
        For the Share of Products Recovered: The raw data is coming from different sources that are combined: Cognos
        Analytics-Retail Sales Gross Margin, MHS and IDSS. The data should match the Product Recovery Dashboard where
        more detailed data about this topic can be found.
      </li>
      <li>
        The Recycling Rate KPI, the Total Waste Generated and Recycling Rate: the data is coming from Sustain where it
        is mainly provided by Facility Management locally on a monthly basis. There is one month collection period to
        allow invoices to come meaning September data is collected in October and show in here at the end of the first
        working day in November.
      </li>
    </ul> */}
  </>
)
