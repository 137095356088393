import React from 'react'
import { BenchmarkingContext, CurrentFyTimeRange, PreviousFyTimeRange } from '../Provider'
import { getGoalsForLocation, getLocationOrDefault, isSiteId } from '../../../../components/Utils/utils'
import { useDataAvailabilityContext, useLocations } from '../../../../context'
import { maxBy, sumBy, uniq } from 'lodash'
import { emptyResult, sortByComparisonValue, useBenchmarkHelperData } from './helpers'
import { FairAndEqualBenchmark } from '../../../../../api/src/types/benchmark'
import { CountryGoals, SiteGoals } from '../../../../../api/src/types/goals'
import { getCurrentPeriod } from '../../../../components/Utils/dates'

export const getSocialImpact = (
  prevTimeRange: PreviousFyTimeRange,
  currTimeRange: CurrentFyTimeRange,
  data: FairAndEqualBenchmark[],
  locationName: string,
  currFy: number,
  latestPeriod: number,
  isInPreviousFy: boolean,
  goal?: CountryGoals | SiteGoals
) => {
  if (data.length === 0) {
    return {
      ...emptyResult,
      goal: goal?.socialImpactGoal ?? null,
      goalNextFy: goal?.socialImpactGoalNextFY ?? null,
      goalPrevFy: goal?.socialImpactGoalPrevFY ?? null
    }
  }

  const prevFy = currFy - 1

  const prev = data
    .filter(x => x.fiscalYear === prevFy - 2000)
    .filter(x => x.fiscalPeriod <= latestPeriod || prevTimeRange === 'Result')

  const curr = data.filter(x =>
    currTimeRange === 'YTD' ? x.fiscalYear === currFy - 2000 : x.fiscalYear === currFy || x.fiscalPeriod > latestPeriod
  )

  const comparisonValue = isInPreviousFy && currTimeRange === 'YTD' ? null : sumBy(curr, 'total')

  return {
    selectable: true,
    locationName,
    comparisonValue,
    previousFy: sumBy(isInPreviousFy ? curr : prev, 'total'),
    goal: goal?.socialImpactGoal ?? null,
    goalNextFy: goal?.socialImpactGoalNextFY ?? null,
    goalPrevFy: goal?.socialImpactGoalPrevFY ?? null
  }
}

export const getChangeMakers = (
  prevTimeRange: PreviousFyTimeRange,
  currTimeRange: CurrentFyTimeRange,
  data: FairAndEqualBenchmark[],
  locationName: string,
  currFy: number,
  latestPeriod: number,
  isInPreviousFy: boolean,
  goal?: CountryGoals | SiteGoals
) => {
  if (data.length === 0) {
    return {
      ...emptyResult,
      goal: goal?.changeMakersGoal ?? null,
      goalNextFy: goal?.changeMakersGoalNextFY ?? null,
      goalPrevFy: goal?.changeMakersGoalPrevFY ?? null
    }
  }

  const prevFy = currFy - 1

  const prev = data
    .filter(x => x.fiscalYear === prevFy - 2000)
    .filter(x => x.fiscalPeriod <= latestPeriod && x.siteId !== null)

  const curr = data.filter(x => (currTimeRange === 'YTD' ? x.fiscalYear === currFy - 2000 : null))

  const comparisonValue = isInPreviousFy && currTimeRange === 'YTD' ? null : sumBy(curr, 'total')

  return {
    selectable: true,
    locationName,
    comparisonValue,
    previousFy: sumBy(isInPreviousFy ? curr : prev, 'total'),
    goal: goal?.changeMakersGoal ?? null,
    goalNextFy: goal?.changeMakersGoalNextFY ?? null,
    goalPrevFy: goal?.changeMakersGoalPrevFY ?? null
  }
}

export const useSocialData = () => {
  const context = React.useContext(BenchmarkingContext)
  const locationId = getLocationOrDefault()
  const { currentLocation } = useLocations()
  const { dataAvailability } = useDataAvailabilityContext()
  const socialImpactCurrentFY = dataAvailability?.socialImpactCurrentFY ?? -1
  const { currFy } = useBenchmarkHelperData(socialImpactCurrentFY)

  if (!context) return null

  const { prevTimeRange, currTimeRange, goals, socialImpact, changeMakers } = context.data

  const socialImpactData = !socialImpact
    ? null
    : {
        locationResult: getSocialImpact(
          prevTimeRange,
          currTimeRange,
          isSiteId(locationId) ? socialImpact.filter(x => x.siteId === locationId) : socialImpact,
          currentLocation.isCluster ? currentLocation.cluster.clusterName : currentLocation.location.label,
          socialImpactCurrentFY,
          maxBy(
            socialImpact.filter(x => x.fiscalYear === socialImpactCurrentFY - 2000),
            'fiscalPeriod'
          )?.fiscalPeriod ?? -1,
          socialImpactCurrentFY < currFy,
          getGoalsForLocation(locationId, goals)
        ),
        childLocationResults:
          locationId === 'ALL'
            ? uniq(socialImpact.map(x => x.countryCode))
                .map(countryCode =>
                  getSocialImpact(
                    prevTimeRange,
                    currTimeRange,
                    socialImpact.filter(x => x.countryCode === countryCode),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    socialImpact.find(x => x.countryCode === countryCode)!.countryName,
                    socialImpactCurrentFY,
                    maxBy(
                      socialImpact.filter(x => x.fiscalYear === socialImpactCurrentFY - 2000),
                      'fiscalPeriod'
                    )?.fiscalPeriod ?? -1,
                    socialImpactCurrentFY < currFy,
                    getGoalsForLocation(countryCode, goals)
                  )
                )
                .sort(sortByComparisonValue(true))
            : uniq(socialImpact.filter(x => x.selectable).map(x => x.siteId))
                .filter(siteId => siteId !== locationId)
                .map(siteId =>
                  getSocialImpact(
                    prevTimeRange,
                    currTimeRange,
                    socialImpact.filter(x => x.siteId === siteId),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    socialImpact.find(x => x.siteId === siteId)!.siteName,
                    socialImpactCurrentFY,
                    maxBy(
                      socialImpact.filter(x => x.fiscalYear === socialImpactCurrentFY - 2000),
                      'fiscalPeriod'
                    )?.fiscalPeriod ?? -1,
                    socialImpactCurrentFY < currFy,
                    getGoalsForLocation(siteId, goals)
                  )
                )
                .sort(sortByComparisonValue(true))
      }

  const latestPeriod = getCurrentPeriod(new Date())

  const changeMakersData = !changeMakers
    ? null
    : {
        locationResult: getChangeMakers(
          prevTimeRange,
          currTimeRange,
          isSiteId(locationId) ? changeMakers.filter(x => x.siteId === locationId) : changeMakers,
          currentLocation.isCluster ? currentLocation.cluster.clusterName : currentLocation.location.label,
          socialImpactCurrentFY,
          latestPeriod,
          socialImpactCurrentFY < currFy,
          getGoalsForLocation(locationId, goals)
        ),
        childLocationResults:
          locationId === 'ALL'
            ? uniq(changeMakers.map(x => x.countryCode))
                .map(countryCode =>
                  getChangeMakers(
                    prevTimeRange,
                    currTimeRange,
                    changeMakers.filter(x => x.countryCode === countryCode),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    changeMakers.find(x => x.countryCode === countryCode)!.countryName,
                    socialImpactCurrentFY,
                    latestPeriod,
                    socialImpactCurrentFY < currFy,
                    getGoalsForLocation(countryCode, goals)
                  )
                )
                .sort(sortByComparisonValue(true))
            : uniq(changeMakers.filter(x => x.selectable).map(x => x.siteId))
                .filter(siteId => siteId !== locationId)
                .map(siteId =>
                  getChangeMakers(
                    prevTimeRange,
                    currTimeRange,
                    changeMakers.filter(x => x.siteId === siteId),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    changeMakers.find(x => x.siteId === siteId)!.siteName,
                    socialImpactCurrentFY,
                    latestPeriod,
                    socialImpactCurrentFY < currFy,
                    getGoalsForLocation(siteId, goals)
                  )
                )
                .sort(sortByComparisonValue(true))
      }

  return { socialImpactData, changeMakersData }
}
