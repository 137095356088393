import { add, eachMonthOfInterval, endOfMonth, format } from 'date-fns'

export function getFinancialYearTimeRange(year: number, timeOfMonthMap: (x: Date) => Date = endOfMonth) {
  const startDate = new Date(`${year - 1}-09-01`)
  return eachMonthOfInterval({ start: startDate, end: add(startDate, { months: 11 }) })
    .map(d => timeOfMonthMap(d))
    .map(d => format(d, 'yyyy-MM-dd'))
}

export function getFinancialYear(readableDate: string) {
  return `FY${format(getIngkaFinancialYear(new Date(readableDate)), 'yy')}`
}

export function getIngkaFinancialYear(d: Date) {
  // Ingka starts its financial year in September, so adding four months so calendar year matches it
  return add(d, { months: 4 })
}

export function getCurrentPeriod(date: Date): number {
  if (!date) return NaN
  // First month/period in FY will be September and last one will be August
  const currentMonth = date?.getMonth() + 1
  return currentMonth > 8 ? currentMonth - 8 : currentMonth + 4
}
