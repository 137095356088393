import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

import { retailFunctionCodes } from '../components/LocationSearch'
import * as APIClient from '../lib/APIClient'
import { useLocations } from '../context'
import { isLocal } from '../Authentication'
import { getLocationId } from '../components/Utils/utils'
import { useSharedSelections } from '../SharedSelections'
import { LoadingSkeleton } from '../components/LoadingSkeleton'
import { Route } from './Constants'

export function WithAuthentication({ children }: { children: JSX.Element }) {
  const { pathname } = useLocation()
  if (isLocal() || isInStorePage(pathname)) {
    return children
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        scopes: APIClient.msGraphScopes
      }}
    >
      {children}
    </MsalAuthenticationTemplate>
  )
}
interface RetailLinkProps {
  children: JSX.Element
}

export const RetailLink = ({ children }: RetailLinkProps) => {
  const { currentLocation } = useLocations()
  const [{ functionArea }] = useSharedSelections()

  if (currentLocation.isCluster || functionArea === 'centres') {
    return <NavigateTo page={Route.SnapshotPage} />
  }

  const { location } = currentLocation
  const isRetail = APIClient.isSite(location) ? retailFunctionCodes.includes(location.functionCode) : true
  if (!isRetail) {
    return <NavigateTo page={Route.SnapshotPage} />
  }
  return children
}

export const NotLoaded = (
  <WithAuthentication>
    <LoadingSkeleton />
  </WithAuthentication>
)

export const isInStorePage = (pathname: string) =>
  pathname.includes(Route.InStorePage) && !pathname.includes(Route.InstoreConfiguratorPage)

interface NavigateToProps {
  page: string
}

export const NavigateTo = ({ page }: NavigateToProps) => {
  const { currentLocation } = useLocations()
  const location = useLocation()
  return <Navigate to={{ pathname: `/${getLocationId(currentLocation)}/${page}`, search: location.search }} replace />
}

export function locationId(route: Route): string {
  return `/:siteOrCountry/${route}`
}

export function locationIdKpi(route: Route): string {
  return `${locationId(route)}/:kpi`
}

export function isRoute(path: string, route: Route): boolean {
  return path.split('/').reverse()[0] === route
}
