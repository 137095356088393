import { parse, subWeeks } from 'date-fns'
import React from 'react'

import { FlowOption, WeeklyHfbArticleSalesData } from '../../api/src/common-types'
import { getHfbTopArticleSales } from '../lib/APIClient'
import { NoDataView } from './BaseGraphs/NoDataView'
import { SimpleBar } from './BaseGraphs/SimpleBar'
import { formatWeekRangeTexts, HFBLegend } from './HFBSharesGraph'
import { LoadingSkeleton } from './LoadingSkeleton'
import { formatRelativeNumber } from './Utils/format'

import './TopSellingArticlesGraph.scss'
import colours from '../Colours.module.scss'
import { useLocations, LanguageContext } from '../context'
import { getCurrencyCode } from './Utils/utils'

interface TopSellingArticlesGraphProps {
  flow: FlowOption
  hfbName: string
  siteOrCountry: string
  isSales: boolean
}

const currentWeekColor = colours.salmon
const lastWeekColor = 'black'

export const TopSellingArticlesGraph = ({ flow, hfbName, siteOrCountry, isSales }: TopSellingArticlesGraphProps) => {
  const [topSellingItems, setTopSellingItems] = React.useState<WeeklyHfbArticleSalesData[] | undefined>(undefined)
  React.useEffect(() => {
    setTopSellingItems(undefined)
    if (hfbName.length > 0) {
      getHfbTopArticleSales(hfbName, siteOrCountry, flow, isSales).then(result => {
        setTopSellingItems(result)
      })
    }
  }, [hfbName])

  return (
    <ArticlesGraph
      topSellingItems={topSellingItems}
      hfbName={hfbName}
      siteOrCountry={siteOrCountry}
      isSales={isSales}
    />
  )
}

interface ArticlesGraphProps {
  topSellingItems: WeeklyHfbArticleSalesData[] | undefined
  hfbName: string
  siteOrCountry: string
  isSales: boolean
}

export const ArticlesGraph = ({ topSellingItems, hfbName, siteOrCountry, isSales }: ArticlesGraphProps) => {
  const lang = React.useContext(LanguageContext)
  const { currentLocation } = useLocations()
  const currencyCode = getCurrencyCode(currentLocation)

  const week = topSellingItems && topSellingItems.length > 0 ? topSellingItems[0].weekNumber : undefined
  const weekDate = week ? parse(week.toString(), 'RRRRII', new Date()) : new Date()
  const { previousPeriod, currentPeriod } = formatWeekRangeTexts(subWeeks(weekDate, 4), weekDate, lang)
  const currencyPostfix = siteOrCountry === 'ALL' ? 'Eur' : 'Local'
  const series = [
    {
      name: hfbName,
      color: currentWeekColor,
      data:
        topSellingItems?.map(item => {
          return {
            name: item.itemName,
            value: isSales
              ? Number(Math.round(item[`pppAsisSales${currencyPostfix}`]) ?? 0)
              : Number(Math.round(item['pppAsisQty']) ?? 0),
            ...((isSales ? item[`pppAsisSales${currencyPostfix}LW`] : item['pppAsisQtyLW'])
              ? {
                  overlay: {
                    value: isSales
                      ? Number(Math.round(item[`pppAsisSales${currencyPostfix}LW`]))
                      : Number(Math.round(item['pppAsisQtyLW'])),
                    color: lastWeekColor
                  }
                }
              : {}),
            // eslint-disable-next-line react/display-name
            createElement: () => (
              <div className="PPPArticleNameAndNo">
                <div>{item.itemName}</div>
                <span className="PPPArticleNo">{item.itemNo.match(/.{1,3}/g)?.join('.')}</span>
              </div>
            )
          }
        }) ?? []
    }
  ]

  const tooltipFn = (i: number) => {
    if (topSellingItems == null || topSellingItems.length === 0) {
      return {}
    }
    const item = topSellingItems[i]
    return {
      heading: item.itemName,
      subtitle: currencyCode,
      body: [
        [
          {
            title: currentPeriod,
            value: formatRelativeNumber(Math.round(item[`pppAsisSales${currencyPostfix}`] ?? 0)),
            color: currentWeekColor,
            hideBullet: true
          },
          {
            title: previousPeriod,
            value: formatRelativeNumber(Math.round(item[`pppAsisSales${currencyPostfix}LW`] ?? 0)),
            color: lastWeekColor,
            hideBullet: true
          }
        ]
      ],
      fixedPosition: true
    }
  }

  return (
    <div className="TopSellingArticles">
      {topSellingItems == null ? (
        <LoadingSkeleton />
      ) : topSellingItems.length === 0 ? (
        <NoDataView />
      ) : (
        <>
          <HFBLegend previousPeriod={previousPeriod} currentPeriod={currentPeriod} />
          <SimpleBar series={series} stacked={false} withChange tooltipFn={tooltipFn} longSerieNames largeBars />
        </>
      )}
    </div>
  )
}
