import React from 'react'
import SkapaModal, { Prompt as SkapaPrompt, ModalBody } from '@ingka/modal'
import Button from '@ingka/button'
import PromptStyles from './Prompt.module.scss'
import { SelectSlide } from './SelectSlide'
import { ReactComponent as RankingImage } from '../images/leaderboard-prompt-ranking.svg'
import { ReactComponent as RecyclingRateImage } from '../images/recycling.svg'

interface PromptProps {
  isOpen: boolean
  onClose: () => void
}

export const LeaderboardPrompt: React.FC<PromptProps> = ({ isOpen, onClose }) => {
  const [index, setIndex] = React.useState(0)

  return (
    <SkapaModal visible={isOpen} handleCloseBtn={onClose}>
      <SkapaPrompt className={PromptStyles.Prompt} title="Updates to the Leaderboard" footer={null}>
        <div className={PromptStyles.FixedHeightContainerLeaderboard}>
          {index === 0 && <RankingImage />}
          <ModalBody>
            <div className={PromptStyles.PromptContent}>
              {index === 0 ? (
                <>
                  <p>
                    The leaderboard has been updated to a tertial-to-date model, which makes it more responsive to your
                    improvements.
                  </p>
                  <p>The ranking resets at the end of every tertial. Make sure you make it to the top every time!</p>
                </>
              ) : (
                <>
                  <RecyclingRateImage width={150} height={150} className={PromptStyles.ImageAboveText} />
                  <p>
                    People KPI has been removed from the Leaderboard. <b>Recycling Rate %</b> (PI contributing to “Zero
                    Waste”) has been added.
                  </p>
                </>
              )}
            </div>
          </ModalBody>
        </div>
        <ModalBody>
          <SelectSlide current={index} total={2} onChange={index => setIndex(index)} />
          <div className={PromptStyles.PromptFooter}>
            <div>
              <span className={PromptStyles.BackButton} onClick={() => setIndex(index => Math.max(index - 1, 0))}>
                {index !== 0 ? '‹ back' : ''}
              </span>
            </div>
            {index === 1 ? (
              <Button text="Got it" type="primary" onClick={onClose} />
            ) : (
              <Button text="Next" type="primary" onClick={() => setIndex(index => index + 1)} />
            )}
          </div>
        </ModalBody>
      </SkapaPrompt>
    </SkapaModal>
  )
}
