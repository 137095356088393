import React from 'react'
import { Insight } from '../../api/src/common-types'
import { SelectSlide } from './SelectSlide'
import styles from './Insights/Insights.module.scss'
import { ReactComponent as ThumbsUpIcon } from '../images/Icons/ThumbCircle.svg'
import classNames from 'classnames'
import { analyticsEvent } from './Utils/analytics'

interface SelectInsightAndFeedbackProps {
  index: number
  insights: Insight[]
  setIndex: (index: number) => void
}

export const SelectInsightAndFeedback: React.FC<SelectInsightAndFeedbackProps> = ({ index, insights, setIndex }) => {
  const [usefulInsights, setUsefulInsights] = React.useState<string[]>([])
  const [unusefulInsights, setUnusefulInsights] = React.useState<string[]>([])

  const thumbsup = () => {
    if (usefulInsights.includes(insights[index].name)) {
      return
    }
    analyticsEvent({
      category: 'Insight',
      action: 'Useful',
      label: insights[index].name
    })
    setUsefulInsights([...usefulInsights, insights[index].name])
  }
  const thumbsdown = () => {
    if (unusefulInsights.includes(insights[index].name)) {
      return
    }
    analyticsEvent({
      category: 'Insight',
      action: 'Unuseful',
      label: insights[index].name
    })
    setUnusefulInsights([...unusefulInsights, insights[index].name])
  }

  return (
    <div className={styles.SelectInsightAndFeedback}>
      <SelectSlide current={index} total={insights.length} onChange={setIndex} />
      <p className={styles.ModalInsightFeedback}>
        Was this information useful?{' '}
        <ThumbsUpIcon
          className={classNames(styles.ThumbsUp, { isActive: usefulInsights.includes(insights[index].name) })}
          onClick={thumbsup}
        />
        <ThumbsUpIcon
          className={classNames(styles.ThumbsDown, {
            isActive: unusefulInsights.includes(insights[index].name)
          })}
          onClick={thumbsdown}
        />
      </p>
    </div>
  )
}
