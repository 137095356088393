import React from 'react'

import { NotificationsSeen } from '../../api/src/common-types'

interface NotificationsWithSetter {
  notificationsSeen: NotificationsSeen | undefined
  setNotificationsSeen: () => void
}

const emptyNotificationsWithSetter = {
  notificationsSeen: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNotificationsSeen: () => {}
}
export const NotificationsContext = React.createContext<NotificationsWithSetter>(emptyNotificationsWithSetter)
export const useNotificationsContext = () => React.useContext(NotificationsContext)
