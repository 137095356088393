import React from 'react'
import classNames from 'classnames'
import { format } from 'date-fns'

import {
  FoodFootprint,
  FoodIngredientsBenchmark,
  ClimateIntensityBenchmarkData
} from '../../../../api/src/common-types'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import { TopBar } from '../../../components/TopBar'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import {
  getCountryOrClusterName,
  getCurentAndPreviousFYMonth,
  getLocationLabel,
  getLocationOrDefault,
  isCluster,
  isSiteId
} from '../../../components/Utils/utils'
import {
  getAvailableDatesFood,
  getClimateIntensityBenchmarks,
  getFoodFootprint,
  getFoodIngredientsBenchmarks
} from '../../../lib/APIClient'
import {
  formatValueAsTons,
  formatPercentage,
  formatChangePercentage,
  formatAbsoluteNumber,
  formatRelativeNumber
} from '../../../components/Utils/format'
import { Route } from '../../../routes'
import { useLocations, useDataAvailabilityContext } from '../../../context'
import {
  Benchmarking,
  CardRow,
  KPIPerformance,
  Stripe,
  BenchmarkingModal,
  DataSourceAndModalButton,
  RenderBenchmarkType,
  MainCard,
  HeadingItem
} from '../../../components/KPIPage'

import {
  TotalClimateFootprintChart,
  ClimateIntensityChart,
  SalesShareChart,
  ClimateIntensityBenchmarkChart,
  getTotalFootprint,
  getTotalAmountKg
} from '../../../components/FoodIngredientsGraphs'
import { sumBy } from 'lodash'
import { ClimateGraphProvider } from './providers/ClimateGraphProvider'
import { ChartContainer, isDataPoint, Serie } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import colours from '../../../Colours.module.scss'
import { formatFoodSeries } from '../../../components/SnapshotTopCards'
import { getIngkaFinancialYear } from '../../../components/Utils/dates'

const emptyClimateIntensity = {
  id: '',
  label: '',
  selectable: false,
  currentMonthFootprint: 0,
  currentMonthSoldWeight: 0,
  currentFYFootprint: 0,
  currentFYSoldWeight: 0,
  previousFYFootprint: 0,
  previousFYSoldWeight: 0,
  previousMonthFootprint: 0,
  previousMonthSoldWeight: 0
}

const formatClimateIntensity = (footprint: number, soldWeight: number) =>
  Number.isFinite(footprint) && Number.isFinite(soldWeight) ? formatRelativeNumber(footprint / soldWeight) : 'N/A'

export const FoodIngredientsKPIPage = () => {
  useDocumentTitle('Food Ingredients')

  const page = Route.FoodIngredientsKPIPage
  const { dataAvailability } = useDataAvailabilityContext()
  const { currentLocation } = useLocations()
  const locationId = getLocationOrDefault()
  const [graphData, setGraphData] = React.useState<FoodFootprint[]>()
  const [lastUpdated, setLastUpdated] = React.useState('')
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const [benchmarks, setBenchmarks] = React.useState<FoodIngredientsBenchmark[]>()
  const [climateIntensityBenchmarks, setClimateIntensityBenchmarks] = React.useState<ClimateIntensityBenchmarkData[]>(
    []
  )
  const [climateIntensityModalOpen, setClimateIntensityModalOpen] = React.useState<boolean>(false)
  const dates = graphData?.map(d => new Date(d.readableDate)).slice(0, 12)
  const [actualPeriod, setActualPeriod] = React.useState<string | null>(null)
  const [availableDates, setAvailableDates] = React.useState<string[]>([])
  const goalsFy = (dataAvailability?.foodCurrentFY ?? 2024) - 2000

  React.useEffect(() => {
    setGraphData(undefined)
    setLastUpdated('')
    getFoodFootprint(locationId).then(({ lastUpdated, data }) => {
      setGraphData(data.filter(x => x.fiscalYear >= (dataAvailability?.foodPreviousFY ?? 2023)))
      setLastUpdated(lastUpdated)
    })

    if (isCluster(locationId)) {
      return
    }
    setBenchmarks([])
    getFoodIngredientsBenchmarks(locationId).then(setBenchmarks)
    setClimateIntensityBenchmarks([])
    ;(async () => {
      const { data } = await getAvailableDatesFood(locationId)
      if (data.length === 0) return

      const date = new Date(data[data.length - 1])
      const fy = getIngkaFinancialYear(date).getFullYear()
      const month = date.getMonth() + 1

      await getClimateIntensityBenchmarks(locationId, fy, month).then(({ data }) => {
        setClimateIntensityBenchmarks(data ?? [])
      })
      setAvailableDates(data)
      setActualPeriod(data[data.length - 1])
    })()
  }, [locationId])

  const sortedBenchmarks = (benchmarks?.filter(x => x.selectable) ?? [])
    .slice(isSiteId(locationId) ? 1 : 0) // Parent benchmark shouldn't show up for sites
    .sort((a, b) =>
      a.id === locationId
        ? -1
        : b.id === locationId
        ? 1
        : a.currentFYTotalFootprint > b.currentFYTotalFootprint
        ? -1
        : 1
    )
  const benchmarksWithoutSelectedLocation = sortedBenchmarks.filter(b => b.id !== locationId)

  const currFyHeading = `FY${(dataAvailability?.foodCurrentFY ?? 2000) - 2000} YTD`
  const prevFyHeading = `FY${(dataAvailability?.foodPreviousFY ?? 2000) - 2000}`
  const prevFyTDHeading = `FY${(dataAvailability?.foodPreviousFY ?? 2000) - 2000} YTD`
  const goalHeading = `FY${goalsFy} Goal`
  const goalHeadingNext = `FY${goalsFy + 1} Goal`
  const totalGoalHeading = `FY${goalsFy} Goal YTD`
  const totalGoalHeadingNext = `FY${goalsFy + 1} Goal YTD`
  const goalHeadingPrev = `FY${goalsFy - 1} Goal`
  const totalGoalHeadingPrev = `FY${goalsFy - 1} Goal YTD`

  const renderBenchmark: RenderBenchmarkType<FoodIngredientsBenchmark> = (benchmark, keys, classes) => {
    const className = !benchmark.goalTotalFootprintYTD
      ? ''
      : benchmark.currentFYTotalFootprint < benchmark.goalTotalFootprintYTD
      ? 'OnTrack'
      : 'YTD'

    const prevYearData =
      keys[0] === 'previousYTDTotalFootprint' ? benchmark.previousYTDTotalFootprint : benchmark.previousFYTotalFootprint

    const goalDataPrev =
      keys[2] === 'goalTotalFootprintPrevYTD'
        ? benchmark.goalTotalFootprintPrevYTD
          ? formatAbsoluteNumber(benchmark.goalTotalFootprintPrevYTD)
          : 'N/A'
        : benchmark.goalPrevFY
        ? formatValueAsTons(benchmark.goalPrevFY)
        : 'N/A'

    const goalData =
      keys[3] === 'goalTotalFootprintYTD'
        ? benchmark.goalTotalFootprintYTD
          ? formatAbsoluteNumber(benchmark.goalTotalFootprintYTD)
          : 'N/A'
        : benchmark.goal
        ? formatValueAsTons(benchmark.goal)
        : 'N/A'

    const goalDataNext =
      keys[4] === 'goalTotalFootprintNextYTD'
        ? benchmark.goalTotalFootprintNextYTD
          ? formatAbsoluteNumber(benchmark.goalTotalFootprintNextYTD)
          : 'N/A'
        : benchmark.goalNextFY
        ? formatValueAsTons(benchmark.goalNextFY)
        : 'N/A'

    return (
      <React.Fragment key={benchmark.id}>
        <div className={classNames('FirstItem', classes)}>{benchmark.label}</div>

        <div className={classNames('Right', classes)}>
          {prevYearData ? formatAbsoluteNumber(Math.round(prevYearData as number)) : 'N/A'}
        </div>

        <div className={classNames('Right', className, classes)}>
          {benchmark.currentFYTotalFootprint
            ? formatAbsoluteNumber(Math.round(benchmark.currentFYTotalFootprint))
            : 'N/A'}
        </div>
        <div className={classNames('Right', classes)}>{goalDataPrev}</div>
        <div className={classNames('Right', classes)}>{goalData}</div>
        <div className={classNames('Right', classes)}>{goalDataNext}</div>
        <div />
      </React.Fragment>
    )
  }

  const footerBenchmark =
    benchmarks?.[0] ??
    ({
      id: locationId,
      label: getLocationLabel(currentLocation),
      selectable: true,
      currentFYTotalFootprint: 0,
      previousFYTotalFootprint: 0,
      goal: 0
    } as FoodIngredientsBenchmark)

  const totalClimateFootprintSubtitle = graphData ? (
    <span>
      {formatAbsoluteNumber(
        Math.round(
          graphData
            .filter(x => x.fiscalYear === dataAvailability?.foodCurrentFY)
            .reduce((acc, d) => acc + d.plantBasedFootprint + d.nonRedMeatBasedFootprint + d.redMeatBasedFootprint, 0)
        )
      )}{' '}
      <span className="Label">tonnes CO2e YTD</span>
    </span>
  ) : undefined

  const ytdSalesQty = graphData
    ?.filter(d => d.fiscalYear === dataAvailability?.foodCurrentFY)
    .reduce(
      (ytd, currentMonth) => ({
        plantBasedSalesQty: ytd.plantBasedSalesQty + currentMonth.plantBasedSalesQty,
        totalQty:
          ytd.totalQty +
          currentMonth.plantBasedSalesQty +
          currentMonth.nonRedMeatBasedSalesQty +
          currentMonth.redMeatBasedSalesQty
      }),
      { plantBasedSalesQty: 0, totalQty: 0 }
    )

  const currentLocationBenchmark = benchmarks?.find(x => x.id === locationId)
  const plantBasedQtyAvg = formatPercentage(ytdSalesQty ? ytdSalesQty.plantBasedSalesQty / ytdSalesQty?.totalQty : NaN)
  const plantBasedQtyGoal = formatPercentage(currentLocationBenchmark?.plantBasedQtyShareGoal || NaN)
  const kpiPerformance = currentLocationBenchmark
    ? [
        {
          key: 'Tonnes CO2e',
          value: [
            currentLocationBenchmark?.currentFYTotalFootprint
              ? `${formatAbsoluteNumber(Math.round(currentLocationBenchmark?.currentFYTotalFootprint))}`
              : 'N/A',
            currentLocationBenchmark.goal ? formatValueAsTons(currentLocationBenchmark.goal) : 'N/A'
          ],
          colorStyle:
            currentLocationBenchmark?.currentFYTotalFootprint > currentLocationBenchmark.goalTotalFootprintYTD
              ? 'YTD'
              : 'OnTrack'
        },
        {
          key: 'Plant-based sales quantity share-%',
          value: [`${plantBasedQtyAvg}%`, `${plantBasedQtyGoal}%`],
          colorStyle: plantBasedQtyAvg < plantBasedQtyGoal ? 'YTD' : 'OnTrack'
        }
      ]
    : []

  const ytdSummary = currentLocationBenchmark ? (
    <span>
      {Math.round(currentLocationBenchmark.currentFYTotalFootprint)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}
      <span className="Label">tonnes CO2e YTD</span>
    </span>
  ) : undefined
  const currentFY = dataAvailability?.foodCurrentFY
  const previousFY = dataAvailability?.foodPreviousFY

  const sortedClimateIntensityBenchmarks = [...climateIntensityBenchmarks]
    .sort((a, b) =>
      a.currentMonthFootprint / a.currentMonthSoldWeight < b.currentMonthFootprint / b.currentMonthSoldWeight ? -1 : 1
    )
    .sort(a => (a.id === locationId ? -1 : 1))
    .sort((a, b) =>
      !Number.isFinite(a.currentMonthFootprint) || !Number.isFinite(a.currentMonthSoldWeight)
        ? 1
        : !Number.isFinite(b.currentMonthFootprint) || !Number.isFinite(b.currentMonthSoldWeight)
        ? -1
        : 0
    )

  const climateItensityFooterBenchmark =
    climateIntensityBenchmarks.reduce(
      (acc, curr) => ({
        id: locationId,
        label: getCountryOrClusterName(currentLocation),
        selectable: true,
        currentMonthFootprint: acc.currentMonthFootprint + curr.currentMonthFootprint,
        currentMonthSoldWeight: acc.currentMonthSoldWeight + curr.currentMonthSoldWeight,
        currentFYFootprint: acc.currentFYFootprint + curr.currentFYFootprint,
        currentFYSoldWeight: acc.currentFYSoldWeight + curr.currentFYSoldWeight,
        previousFYFootprint: acc.previousFYFootprint + curr.previousFYFootprint,
        previousFYSoldWeight: acc.previousFYSoldWeight + curr.previousFYSoldWeight,
        previousMonthFootprint: acc.previousMonthFootprint + curr.previousMonthFootprint,
        previousMonthSoldWeight: acc.previousMonthSoldWeight + curr.previousMonthSoldWeight
      }),
      emptyClimateIntensity
    ) ?? emptyClimateIntensity

  const openClimateIntensityBenchmarking = () => {
    setClimateIntensityModalOpen(true)
  }

  const renderClimateIntensityBenchmark: RenderBenchmarkType<ClimateIntensityBenchmarkData> = (
    benchmark,
    _keys,
    classes
  ) => {
    const change =
      benchmark.currentMonthFootprint /
        benchmark.currentMonthSoldWeight /
        (benchmark.previousMonthFootprint / benchmark.previousMonthSoldWeight) -
      1

    return (
      <React.Fragment key={benchmark.id}>
        <div
          className={classNames(
            'FirstItem',
            benchmark.label === climateItensityFooterBenchmark?.label && 'Bold',
            classes
          )}
        >
          {benchmark.label}
        </div>
        <div className={classNames('Right', classes)}>
          {formatClimateIntensity(benchmark.currentMonthFootprint, benchmark.currentMonthSoldWeight)}
        </div>
        <div className={classNames('Right', classes)}>
          {formatClimateIntensity(benchmark.previousMonthFootprint, benchmark.previousMonthSoldWeight)}
        </div>
        <div className={classNames('Right', classes, `${change < 0 ? 'OnTrack' : 'NotOnTrack'}`)}>
          {formatChangePercentage(change)}
        </div>
        <div />
      </React.Fragment>
    )
  }

  const fetchIntensityData = (fy: number, month: number) => {
    getClimateIntensityBenchmarks(locationId, fy, month).then(({ data }) => {
      const period = availableDates.find(item => {
        const date = new Date(item)
        return fy === getIngkaFinancialYear(date).getFullYear() && date.getMonth() + 1 === month
      })

      setClimateIntensityBenchmarks(data)
      setActualPeriod(period || '')
    })
  }

  const currentFyData = graphData?.filter(x => x.fiscalYear === currentFY)
  const ytdClimateIntensity = sumBy(currentFyData, getTotalFootprint) / sumBy(currentFyData, getTotalAmountKg)
  const lastUpdatedFormatted = lastUpdated ? format(new Date(lastUpdated), 'dd/MM/yyyy') : ''

  const { currentFYMonth, prevFYMonth }: { currentFYMonth: string; prevFYMonth: string } =
    getCurentAndPreviousFYMonth(actualPeriod)

  const benchmarkHeaders: HeadingItem<FoodIngredientsBenchmark>[] = [
    [
      { name: prevFyTDHeading, key: 'previousYTDTotalFootprint' },
      { name: prevFyHeading, key: 'previousFYTotalFootprint' }
    ],
    [{ name: currFyHeading, key: 'currentFYTotalFootprint' }],
    [
      { name: goalHeadingPrev, key: 'goalPrevFY' },
      { name: totalGoalHeadingPrev, key: 'goalTotalFootprintPrevYTD' }
    ],
    [
      { name: totalGoalHeading, key: 'goalTotalFootprintYTD' },
      { name: goalHeading, key: 'goal' }
    ],
    [
      { name: goalHeadingNext, key: 'goalNextFY' },
      { name: totalGoalHeadingNext, key: 'goalTotalFootprintNextYTD' }
    ]
  ]

  return (
    <div className="KPIPage">
      <TopBar currentPage={page} useInFlexLayout />
      <PageHeader className="ClimateFootprintHeader" route={Route.FoodIngredientsKPIPage} />
      <div className="PageContent">
        <Stripe title="Climate Footprint - Food Ingredients">
          <DataSourceAndModalButton
            dataSource="Food Dashboard"
            lastUpdated={lastUpdatedFormatted}
            updateFrequency="daily"
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        <CardRow className="BenchmarkingAndGoals">
          <Benchmarking
            key="benchmarking"
            benchmarks={sortedBenchmarks.slice(0, 4)}
            label="tonnes CO2e"
            headers={benchmarkHeaders}
            locationId={locationId}
            renderBenchmark={renderBenchmark}
            openModal={() => setBenchmarkModalOpen(true)}
            // For countries and global "sortedBenchmarks" includes the comparison (country, global), so that's why -1
            totalLocations={isSiteId(locationId) ? sortedBenchmarks.length : sortedBenchmarks.length - 1}
          />
          <KPIPerformance
            heading="KPI Performance"
            key="goals"
            units={[`FY${(currentFY ?? 2024) - 2000} YTD`, `FY${(currentFY ?? 2024) - 2000} Goal`]}
            kpis={kpiPerformance}
          />
        </CardRow>
        <MainCard title="Cumulative Food Emissions" subtitle={ytdSummary}>
          <div className="GraphContainer">
            <ChartContainer
              domain={dates}
              series={formatFootprintSeries(graphData, currentLocationBenchmark, dates, true)}
              generator={lineChart}
              dateFormat="month"
              lineChartConfiguration={{ focusStyle: 'none', startFromZero: true }}
              yAxisTitle="tonnes CO2e"
            />
          </div>
        </MainCard>
        <MainCard title="Climate Footprint of Food Ingredients Sold" subtitle={totalClimateFootprintSubtitle}>
          <TotalClimateFootprintChart data={graphData} currentFY={currentFY} previousFY={previousFY} />
        </MainCard>
        <CardRow>
          <ClimateGraphProvider locationId={locationId} countryCode={(benchmarks || [])[0]?.id} loading={!graphData}>
            <ClimateIntensityChart data={graphData} />
          </ClimateGraphProvider>
          <SalesShareChart
            data={graphData}
            currentFY={currentFY}
            previousFY={previousFY}
            plantBasedAvgYTD={plantBasedQtyAvg}
          />
        </CardRow>
        <ClimateIntensityBenchmarkChart
          data={sortedClimateIntensityBenchmarks.filter(x => x.selectable)}
          benchmarkOpenFn={openClimateIntensityBenchmarking}
          ytdClimateIntensity={ytdClimateIntensity}
          actualPeriod={actualPeriod}
          availableDates={availableDates}
          fetchIntensityData={fetchIntensityData}
        />
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdatedFormatted}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {benchmarks && (
        <BenchmarkingModal
          benchmarks={isSiteId(locationId) ? sortedBenchmarks : benchmarksWithoutSelectedLocation}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={footerBenchmark}
          headers={benchmarkHeaders}
          isOpen={benchmarkModalOpen}
          locationId={locationId}
          renderBenchmark={renderBenchmark}
          sortBy="currentFYTotalFootprint"
          sortDirection="asc"
          title="tonnes of CO2e"
        />
      )}
      {sortedClimateIntensityBenchmarks && (
        <BenchmarkingModal<ClimateIntensityBenchmarkData>
          benchmarks={sortedClimateIntensityBenchmarks.filter(x => x.selectable)}
          closeFn={() => setClimateIntensityModalOpen(false)}
          headers={[
            [{ name: currentFYMonth, key: 'currentMonthFootprint' }],
            [{ name: prevFYMonth, key: 'previousMonthFootprint' }],
            [{ name: `Change`, key: 'changePercent' }]
          ]}
          isOpen={climateIntensityModalOpen}
          locationId={locationId}
          renderBenchmark={renderClimateIntensityBenchmark}
          sortBy={benchmark => benchmark.currentMonthFootprint / benchmark.currentMonthSoldWeight}
          sortDirection="desc"
          title="Benchmark of climate intensity - monthly"
          footerBenchmark={climateItensityFooterBenchmark}
        />
      )}
    </div>
  )
}

export const formatFootprintSeries = (
  data: FoodFootprint[] | undefined,
  currentLocationBenchmark: FoodIngredientsBenchmark | undefined,
  domain: Date[] | undefined,
  showGoals: boolean
): Serie[] | undefined => {
  if (data === undefined || domain === undefined) {
    return undefined
  }
  if (data.length === 0) {
    return []
  }
  const fy = +data[0].fiscalYear.toString().slice(2)
  const graphData = data?.reduce<Record<string, FoodFootprint[]>>((acc, value) => {
    if (acc[value.fiscalYear]) acc[value.fiscalYear].push(value)
    else acc[value.fiscalYear] = [value]
    return acc
  }, {})
  let series: Serie[] = []
  let goalData: {
    x: Date
    y: number
  }[] = []
  if (graphData && domain) {
    series = formatFoodSeries(graphData, domain)
  }
  if (currentLocationBenchmark && domain) {
    goalData = domain.map((d, i) => ({
      x: d,
      y: ((currentLocationBenchmark.goal / 12) * (i + 1) ?? 0) / 1000
    }))
  }

  return [
    {
      name: series[0].name,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: series[0].data.map(d => ({ x: d.x, y: d.y })).filter(isDataPoint)
    },
    ...(series[1]
      ? [
          {
            name: series[1].name,
            color: colours.darkBlue1,
            data: series[1].data.map(d => ({ x: d.x, y: d.y })).filter(isDataPoint)
          }
        ]
      : []),
    showGoals &&
      goalData && {
        name: `FY${fy + 1} Goal`,
        color: colours.lightBlue2,
        data: goalData.map(d => ({
          x: d.x,
          y: d.y
        }))
      }
  ].filter((s): s is Serie => s !== false)
}
