import React from 'react'
import classNames from 'classnames'

import { Link } from './Link'
import { getBreadCrumbs, Route } from '../routes'

import styles from './Breadcrumbs.module.scss'

const Arrow: React.FC = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.ArrowIcon}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.59961 6.00066L1.81456 11.7864L0.400261 10.3723L4.77136 6.00065L0.400257 1.62896L1.81457 0.214843L7.59961 6.00066Z"
      fill="white"
    />
  </svg>
)

interface BreadcrumbProps {
  route: Route
}

export const Breadcrumbs: React.FC<BreadcrumbProps> = ({ route }) => {
  const breadcrumbs = getBreadCrumbs(route)

  return (
    <div className={styles.Breadcrumbs}>
      {breadcrumbs.map((breadcrumb, i) => (
        <React.Fragment key={i}>
          <Link page={breadcrumb.route} className={classNames(breadcrumb.route === route && styles.Title)}>
            {breadcrumb.title}
            {i < breadcrumbs.length - 1 && <Arrow />}
          </Link>
        </React.Fragment>
      ))}
    </div>
  )
}
