import React from 'react'
import { capitalize, groupBy, isNil, maxBy, toPairs } from 'lodash'
import { getGoals, getWasteRecycling, isSite } from '../../lib/APIClient'
import {
  getCountry,
  getCurrentCountryLabel,
  getCurrentLocationLabel,
  getGoalsForLocation,
  getInStoreLabel,
  isCluster
} from '../Utils/utils'
import { SlideProps } from './ClimateFootprintLargestContributorSlide'
import { InStoreSlide } from './InStoreSlide'
import { formatPercentage } from '../Utils/format'
import { lineChart } from '../BaseGraphs/GraphUtil'

import './WasteRecyclingRateSlide.scss'
import colours from '../../Colours.module.scss'
import { InStoreI18n } from '../../Localisation'
import { dataSourceText, formatLocalisationString } from '../../Localisation'
import { useDataAvailabilityContext, useLocations, LanguageContext } from '../../context'
import { ChartContainer, Legend, Serie } from '../BaseGraphs/ChartContainer'
import { useSharedSelections } from '../../SharedSelections'
import { GoalsResponse, SiteGoals } from '../../../api/src/common-types'
import { getIngkaFinancialYear } from '../Utils/dates'
import { add } from 'date-fns'
import { moveLocationIdToEnd } from './WaterEfficiencySlide'

const highlightedSiteColor = colours.blue5
const otherSitesColor = colours.offWhite5

export const WasteRecyclingRateSlide = ({ currentLocation, selector }: SlideProps) => {
  const [dataSource, setDataSource] = React.useState('')
  const [totalWasteYtd, setTotalWasteYtd] = React.useState<string>('N/A')
  const [badge, setBadge] = React.useState<string>('N/A')
  const [onTrack, setOnTrack] = React.useState<number>(0)
  const [series, setSeries] = React.useState<Serie[]>([])
  const [goals, setGoals] = React.useState<GoalsResponse>()
  const [{ func }] = useSharedSelections()
  const { locations } = useLocations()
  const siteList = locations.filter(isSite)
  const lang = React.useContext(LanguageContext)
  const { dataAvailability } = useDataAvailabilityContext()
  const locationId = isSite(currentLocation) ? currentLocation.siteId : currentLocation.countryCode
  const dateNow = new Date()
  const goalsFy = (dataAvailability?.planetCurrentFY || getIngkaFinancialYear(dateNow).getFullYear()) - 2000

  React.useEffect(() => {
    getWasteRecycling({
      ...selector,
      isOld: true,
      locationId: currentLocation.countryCode,
      countryCodes: [currentLocation.countryCode],
      site: 'ALL'
    }).then(result => {
      const sites = groupBy(result, 'siteId')
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const latestDateResult = maxBy(result, 'readableDate')!
      const series = toPairs(sites)
        .map(([siteId, wasteRecyclings], index) => ({
          id: siteId,
          color: isSite(currentLocation) && currentLocation.siteId === siteId ? highlightedSiteColor : otherSitesColor,
          data: wasteRecyclings
            .filter(({ fiscalYear }) => fiscalYear >= latestDateResult.fiscalYear - 1)
            .map(({ readableDate, recycledWaste, totalWaste }) => ({
              x: new Date(readableDate),
              y: totalWaste > 0 ? (recycledWaste / totalWaste) * 100 : NaN
            })),
          description: siteId,
          name: siteList.find(l => l.siteId === siteId)?.label ?? currentLocation.label,
          zIndex: siteId === locationId ? 100 : index
        }))
        .filter(serie => !serie.data.every(datapoint => isNil(datapoint.y)))

      const ytdSums = result
        .filter(r => r.fiscalYear === latestDateResult.fiscalYear)
        .filter(r => (isSite(currentLocation) ? r.siteId === currentLocation.siteId : true))
        .reduce(
          ({ totalWaste, recycledWaste }, r) => ({
            totalWaste: totalWaste + r.totalWaste,
            recycledWaste: recycledWaste + r.recycledWaste
          }),
          {
            totalWaste: 0,
            recycledWaste: 0
          }
        )

      const lastYearYtdSums = result
        .filter(
          r => r.fiscalYear === latestDateResult.fiscalYear - 1 && r.fiscalPeriod <= latestDateResult.fiscalPeriod
        )
        .filter(r => (isSite(currentLocation) ? r.siteId === currentLocation.siteId : true))
        .reduce(
          ({ totalWaste, recycledWaste }, r) => ({
            totalWaste: totalWaste + r.totalWaste,
            recycledWaste: recycledWaste + r.recycledWaste
          }),
          {
            totalWaste: 0,
            recycledWaste: 0
          }
        )
      setBadge(
        `${capitalize(
          ytdSums.recycledWaste / ytdSums.totalWaste < lastYearYtdSums.recycledWaste / lastYearYtdSums.totalWaste
            ? InStoreI18n[lang].Generic.less
            : InStoreI18n[lang].Generic.more
        )} ${InStoreI18n[lang].Generic.thanThisTime} ${formatPercentage(
          lastYearYtdSums.recycledWaste / lastYearYtdSums.totalWaste
        )}%`
      )
      setDataSource(
        dataSourceText(
          lang,
          result[result.length - 1].readableDate,
          InStoreI18n[lang].Generic.sustainData,
          dataAvailability
        )
      )
      setOnTrack(
        lastYearYtdSums.recycledWaste / lastYearYtdSums.totalWaste - ytdSums.recycledWaste / ytdSums.totalWaste
      )
      setSeries(moveLocationIdToEnd(series, locationId))
      setTotalWasteYtd(formatPercentage(ytdSums.recycledWaste / ytdSums.totalWaste))

      if (!isCluster(locationId)) {
        getGoals(getCountry(locationId, locations).countryCode, func, goalsFy + 2000).then(setGoals)
      }
    })
  }, [])

  const domain = series.length > 0 ? series.find(s => s.id === locationId)?.data.map(({ x }) => x) : []
  const currLocationEnergyEfficiencyGoal = getGoalsForLocation(locationId, goals) as SiteGoals

  const recyclingSeriesWithGoals = series
    ? [
        ...series,
        {
          color: '#7d8fad',
          data: Object.values(groupBy(domain, d => getIngkaFinancialYear(d).getFullYear()))[0]
            ?.map(d => add(d, { years: 1 }))
            .map(date => ({
              x: date,
              y: currLocationEnergyEfficiencyGoal ? currLocationEnergyEfficiencyGoal?.recyclingRateGoal : 0
            })),
          fill: undefined,
          name: 'Goal',
          zIndex: 0
        }
      ]
    : undefined

  const countryLabel = getCurrentCountryLabel(currentLocation, locations, lang)

  return (
    <InStoreSlide
      className="WasteRecyclingRateSlide"
      header={{
        location: getCurrentLocationLabel(currentLocation, lang),
        description: formatLocalisationString(InStoreI18n[lang].Recycling.description, {
          siteLabel: getInStoreLabel(currentLocation, lang),
          country: countryLabel
        }),
        title: InStoreI18n[lang].Recycling.title,
        dataSource
      }}
      series={{ name: InStoreI18n[lang].Recycling.seriesName, value: totalWasteYtd, unit: '%', badge, onTrack }}
      graph={
        <>
          <ChartContainer
            generator={lineChart}
            series={recyclingSeriesWithGoals}
            domain={series.length > 0 ? series.find(s => s.id === locationId)?.data.map(({ x }) => x) : []}
            dateFormat="month"
            yAxisTitle={InStoreI18n[lang].Recycling.recyclingPercentage}
            hideLegend
            lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
          />
          <Legend
            series={[
              { id: 'selected', name: currentLocation.label, color: highlightedSiteColor, data: [] },
              ...(Object.keys(series).length > 1
                ? [
                    {
                      id: 'others',
                      name: formatLocalisationString(InStoreI18n[lang].Generic.otherLocations, {
                        siteLabel: getInStoreLabel(currentLocation, lang),
                        country: countryLabel
                      }),
                      color: otherSitesColor,
                      data: []
                    }
                  ]
                : []),
              { id: 'selected', name: 'Goal', color: '#7d8fad', data: [] }
            ]}
            selectedSeries={[currentLocation.label, 'Goal']}
            leftMargin={0}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            changeSelectionForAllItems={() => {}}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onItemClicked={() => {}}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            setHighlightedSerie={() => {}}
          />
        </>
      }
      isLoading={series.length === 0}
    />
  )
}
