import React from 'react'
import { ChartContainer } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { CustomerTravelContext } from './CustomerTravelProvider'
import { formatFootprintYtdSeries } from './data/footprint'
import { formatRelativeNumber } from '../../../components/Utils/format'
import { last, get } from 'lodash'

export const FootprintYtdKPI = () => {
  const { footprint, dates } = React.useContext(CustomerTravelContext)

  if (footprint.length === 0) return null

  const series = formatFootprintYtdSeries(footprint, dates)
  const ytd = Number(last<{ x: Date; y: number }>(get(series, '1.data'))?.y) || 0

  return (
    <div className="CardContainer">
      <div className="CardHeading FirstItem">
        Climate Footprint of Customer Travel
        <div className="Right">
          <span className="Amount">{formatRelativeNumber(ytd)}</span> <span className="Unit">tonnes CO2e YTD</span>
        </div>
      </div>
      <ChartContainer
        domain={dates}
        series={series}
        generator={lineChart}
        dateFormat="month"
        lineChartConfiguration={{ focusStyle: 'none', startFromZero: true }}
        yAxisTitle="tonnes CO2e"
        showDecimals
        isSmallGraph
        withDynamicFormatting
      />
    </div>
  )
}
