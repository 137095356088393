import Button from '@ingka/button'
import React from 'react'
import { unsubscribeNewsletter } from '../lib/APIClient'

export const UnsubscriptionPage = () => {
  const [isUnsubscribed, setIsUnsubscribed] = React.useState(false)

  React.useEffect(() => {
    document.title = 'Newsletter Unsubscription'
  }, [])

  const unsubscribe = async () => {
    await unsubscribeNewsletter()
    setIsUnsubscribed(true)
  }

  return (
    <div className="UnsubscribePage">
      {!isUnsubscribed ? (
        <Button text="Unsubscribe from our newsletter" type="primary" onClick={unsubscribe} />
      ) : (
        <a href="/" style={{ textDecoration: 'underline' }}>
          Go to the Sustainability Dashboard
        </a>
      )}
    </div>
  )
}
