import React from 'react'
import Accordion, { AccordionItem } from '@ingka/accordion'
import Button from '@ingka/button'
import { endOfMonth, isBefore } from 'date-fns'
import { groupBy, maxBy, uniqBy } from 'lodash'

import { PlanetFootprint, WhatIfPlanetFraction } from '../../api/src/common-types'
import { lineChart } from '../components/BaseGraphs/GraphUtil'
import { InformationIndicator } from '../components/BaseGraphs/Indicators'
import { NoDataView } from '../components/BaseGraphs/NoDataView'
import { LoadingSkeleton } from '../components/LoadingSkeleton'
import { WhatIfLearnMoreModal } from '../components/Modal'
import { Slider } from '../components/Slider'
import { Toggle } from '../components/Toggle'
import { Tooltip } from '../components/Tooltip'
import { TopBar } from '../components/TopBar'
import { getIngkaFinancialYear } from '../components/Utils/dates'
import { formatAbsoluteNumber, formatRelativeNumber } from '../components/Utils/format'
import { useDocumentTitle } from '../components/Utils/use-document-title'
import { getCluster, getLocationOrDefault, getLocationSelector, readIntCookie } from '../components/Utils/utils'
import { getWhatIfPlanetFractions, getWhatIfPlanetGoals } from '../lib/APIClient'
import { useLocations } from '../context'
import { useSharedSelections } from '../SharedSelections'
import { Route } from '../routes'

import styles from './WhatIfPage.module.scss'
import colours from '../Colours.module.scss'
import { analyticsEvent } from '../components/Utils/analytics'
import { ChartContainer, DataPoint } from '../components/BaseGraphs/ChartContainer'

const cookieMaxAge = 60 * 60 * 24 * 365

interface Emission {
  key: string
  planetIndicator: PlanetFootprint
  months: WhatIfPlanetFraction[]
  total: number
}

interface FractionType {
  key: string
  planetIndicator: string
  fraction: string
  disposalType: string | null
  factor: number
  shiftFactor: number | undefined
  shiftOption: string | undefined
  ratio: number
  reduction: boolean
}

export const WhatIfPage = () => {
  useDocumentTitle('What-If')

  React.useEffect(() => {
    const instructionsShownCount = readIntCookie('whatifhelp')
    if (instructionsShownCount < 4) {
      document.cookie = `whatifhelp=${instructionsShownCount + 1};max-age=${cookieMaxAge};path=/`
    }
  }, [])

  const [loading, setLoading] = React.useState(true)
  const [fractions, setFractions] = React.useState<WhatIfPlanetFraction[]>([])
  const [tyGoal, setTyGoal] = React.useState<DataPoint[]>([])
  const [nyGoal, setNyGoal] = React.useState<DataPoint[]>([])
  const [fractionTypes, setFractionTypes] = React.useState<FractionType[]>([])
  const [currentYear, setCurrentYear] = React.useState(0)
  const [maxValue, setMaxValue] = React.useState(0)
  const locationId = getLocationOrDefault()
  const [{ func }] = useSharedSelections()
  const [learnMoreVisible, setLearnMoreVisible] = React.useState(false)
  const { clusters } = useLocations()

  const selector = {
    ...getLocationSelector(locationId, getCluster(clusters, locationId)?.countryCodes),
    func: func,
    isOld: true
  }

  React.useEffect(() => {
    setLoading(true)
    setMaxValue(0)
    setFractions([])
    getWhatIfPlanetFractions(selector).then(result => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setCurrentYear(getIngkaFinancialYear(new Date(maxBy(result, 'readableDate')!.readableDate)).getFullYear() - 2000)
      const allFractionTypes = uniqBy(result, d => `${d.fraction}.${d.disposalType}`)
      setFractionTypes(
        allFractionTypes.map(f => {
          const [shiftFactor, shiftOption] = findShiftOption(f, allFractionTypes)
          return {
            key: `${f.fraction}.${f.disposalType}`,
            planetIndicator: f.planetIndicator,
            fraction: f.fraction,
            disposalType: f.disposalType,
            factor: f.factor,
            shiftFactor,
            shiftOption,
            ratio: 0,
            reduction: false
          }
        })
      )
      const filtered = result.filter(
        r => (r.actualFootprint ?? 0) + (r.projectionFootprint ?? 0) + (r.footprintRef ?? 0) > 0
      )
      setFractions(filtered)
      setLoading(false)
    })
    getWhatIfPlanetGoals(selector).then(result => {
      const tyGoalXY = result.map(goal => ({ x: new Date(goal.readableDate), y: goal.goal ?? 0 }))
      const nyGoalXY = result.map(goal => ({ x: new Date(goal.readableDate), y: goal.goalNextFy ?? 0 }))
      setTyGoal(tyGoalXY)
      setNyGoal(nyGoalXY)
    })
  }, [locationId, JSON.stringify(func), JSON.stringify(selector)])

  const groupedByDate = groupBy(fractions, 'readableDate')
  const lyFootprint = calculateCumulativeFootprint(groupedByDate, 'footprintRef')

  // when there are duplicate values, they are months not having data, filter them out
  const actual = uniqBy(calculateCumulativeFootprint(groupedByDate, 'actualFootprint'), 'y')
  const projection = calculateProjection(groupedByDate, actual, fractionTypes)

  const groupedFractions: Emission[] = React.useMemo(() => {
    const groups = groupBy(fractions, d => `${d.fraction}.${d.disposalType}`)
    return Object.keys(groups)
      .map(key => ({
        key,
        planetIndicator: groups[key][0].planetIndicator,
        months: groups[key],
        total: groups[key].reduce((sum, d) => sum + (d.projectionFootprint ?? 0), 0)
      }))
      .filter(f => locationId.length < 4 || f.months[0].whatIfFlag === 1)
      .sort((a, b) => b.total - a.total)
  }, [fractions])

  const max = Math.max(...[lyFootprint, tyGoal, nyGoal, actual, projection].map(s => getLastValue(s) / 1000))
  if (max > maxValue) {
    setMaxValue(max)
  }

  const ratioChanged = (id: string, ratio: number, reduction: boolean) => {
    const types = [...fractionTypes]
    const idx = types.findIndex(f => f.key === id)
    fractionTypes[idx].ratio = ratio
    fractionTypes[idx].reduction = reduction
    setFractionTypes(types)
  }

  const toTonnes = (data: DataPoint) => ({ ...data, y: (data.y ?? 0) / 1000 })
  const series = [
    {
      id: 'lastYear',
      name: `FY${currentYear - 1}`,
      data: lyFootprint.map(toTonnes),
      color: colours.offWhite1,
      fill: colours.grey1
    },
    { id: 'projection', name: 'Scenario', data: projection.map(toTonnes), color: colours.blue6 },
    {
      id: 'goalNextFy',
      name: `FY${currentYear + 1} Goal`,
      data: nyGoal.map(toTonnes),
      color: colours.lightBlue6
    },
    {
      id: 'goalYTD',
      name: `FY${currentYear} Goal`,
      data: tyGoal.map(toTonnes),
      color: colours.lightBlue2
    },
    {
      id: 'actual',
      name: `FY${currentYear}`,
      data: actual.map(toTonnes),
      color: colours.darkBlue1
    }
  ]
  const legendOrder = ['actual', 'goalYTD', 'goalNextFy', 'projection', 'lastYear']

  const domain = lyFootprint.length > 0 ? lyFootprint.map(({ x }) => x) : []

  const instructionsShownCount = readIntCookie('whatifhelp')
  return (
    <div className={styles.PageContainer}>
      <TopBar currentPage={Route.WhatIfPlanetPage} />
      <div className={styles.Stripe} data-testid="stripe">
        <span className={styles.Title}>What-If</span>
        <div className={styles.Description}>
          Create annual energy, water and waste usage scenarios based on your selection&apos;s consumption and
          production patterns to view how those can impact your climate footprint.
        </div>
        <Button
          small
          data-testid="learn-more-button"
          className={styles.LearnMoreButton}
          onClick={() => {
            analyticsEvent({
              category: 'LearnMore',
              action: 'Open',
              label: 'what-if'
            })
            setLearnMoreVisible(true)
          }}
        >
          Learn more
        </Button>
      </div>
      {!loading && fractions.length === 0 ? (
        <NoDataView />
      ) : (
        <div className={styles.PageContentContainer}>
          <div className={styles.ConfigGroupsContainer} data-testid="config-groups-container">
            <Accordion className={styles.Accordion}>
              <AccordionItem id="whatif-instructions" title="Scenario builder" open={instructionsShownCount <= 3}>
                <p>
                  The underlying assumption of the Scenario Builder is that if you continue operating with business as
                  usual, your footprint will be the same as the forecast which is based on historical values.
                </p>
                <p>
                  The Scenario is made up of two parts: i. a fixed component - your footprint YTD (red line); and ii. a
                  variable component - your projected footprint till the end of the current financial year (dark blue
                  Scenario line) - which is extrapolated from your footprint over the same period historically. This is
                  the part of the Scenario you can influence by simulating the impact of a change in consumption per
                  category.
                </p>
              </AccordionItem>
            </Accordion>
            {loading ? (
              <LoadingSkeleton className={styles.ConfigLoading} />
            ) : (
              <>
                <div className={styles.ConfiguratorGroup}>
                  <div className={styles.Heading}>Energy</div>
                  {createFractionConfigurator(
                    groupedFractions.filter(d => d.planetIndicator === 'energy'),
                    fractionTypes.filter(f => f.planetIndicator === 'energy'),
                    'energy',
                    ratioChanged
                  )}
                </div>
                <div className={styles.ConfiguratorGroup}>
                  <div className={styles.Heading}>Waste</div>
                  {createFractionConfigurator(
                    groupedFractions.filter(d => d.planetIndicator === 'waste'),
                    fractionTypes.filter(f => f.planetIndicator === 'waste'),
                    'waste',
                    ratioChanged
                  )}
                </div>
                <div className={styles.ConfiguratorGroup}>
                  <div className={styles.Heading}>Water</div>
                  {createFractionConfigurator(
                    groupedFractions.filter(f => f.planetIndicator === 'water'),
                    fractionTypes.filter(f => f.planetIndicator === 'water'),
                    'water',
                    ratioChanged
                  )}
                </div>
              </>
            )}
          </div>
          <div className={styles.ResultsContainer} data-testid="results-container">
            {loading ? (
              <LoadingSkeleton className={styles.ResultsLoading} />
            ) : (
              <>
                <div className={styles.Totals}>
                  <div className={styles.Item}>
                    <div className={styles.Title}>Scenario</div>
                    <div className={styles.Value}>{getFormattedLastValue(projection)} t CO2e</div>
                  </div>
                  <div className={styles.Item}>
                    <div className={styles.Title}>{`FY${currentYear - 1}`}</div>
                    <div className={styles.Value}>{getFormattedLastValue(lyFootprint)} t CO2e</div>
                  </div>
                  <div className={styles.Item}>
                    <div className={styles.Title}>{`FY${currentYear} Goal`}</div>
                    <div className={styles.Value}>
                      {getFormattedLastValue(tyGoal) === '0' ? '-' : `${getFormattedLastValue(tyGoal)} t CO2e`}
                    </div>
                  </div>
                </div>
                <div className={styles.GraphContainer}>
                  <ChartContainer
                    generator={lineChart}
                    series={series}
                    domain={domain}
                    dateFormat="month"
                    legendOrder={legendOrder}
                    lineChartConfiguration={{
                      startFromZero: true,
                      focusStyle: 'none'
                    }}
                    hideTooltip
                    yAxisTitle="t CO2e"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <WhatIfLearnMoreModal isOpen={learnMoreVisible} onClose={() => setLearnMoreVisible(false)} />
    </div>
  )
}

function getLastValue(data: Array<DataPoint>): number {
  return data[data.length - 1]?.y ?? NaN
}

function getFormattedLastValue(data: Array<DataPoint>) {
  const lastVal = getLastValue(data)
  return Number.isFinite(lastVal) ? formatAbsoluteNumber(Math.round(lastVal / 1000)) : 'N/A'
}

const currentMonth = endOfMonth(new Date())
const isCurrentMonthOrEarlier = (month: WhatIfPlanetFraction) => isBefore(new Date(month.readableDate), currentMonth)

function calculateEmission(w: WhatIfPlanetFraction, fractionTypes: FractionType[]): number {
  if (w.actualFootprint) {
    return w.actualFootprint
  }

  // We accept projection as is for the current month, as it cannot really be affected anymore
  if (isCurrentMonthOrEarlier(w)) {
    return w.projectionFootprint ?? 0
  }

  const fraction = fractionTypes.find(f => f.fraction === w.fraction && f.disposalType === w.disposalType)
  if (fraction == null) {
    return 0
  }
  // calculate factor from projection as then factor reflects better country level aggregation
  let factor = (w.projectionFootprint ?? 0) / (w.projectionQty ?? 1)
  if (Number.isNaN(factor)) {
    factor = fraction.factor
  }

  if (fraction.reduction) {
    return (w.projectionQty ?? 0) * ((100 - fraction.ratio) / 100) * factor
  }
  return (
    (w.projectionQty ?? 0) * ((100 - fraction.ratio) / 100) * factor +
    (w.projectionQty ?? 0) * (fraction.ratio / 100) * (fraction.shiftFactor ?? 0)
  )
}

function createFractionConfigurator(
  fractions: Emission[],
  fractionTypes: FractionType[],
  footprint: string,
  onChange: (id: string, ratio: number, reduction: boolean) => void
) {
  const configurators = fractions
    .filter(f => f.total > 0)
    .filter(
      f =>
        Math.round(
          f.months.reduce((sum, m) => {
            if ((m.actualFootprint ?? 0) > 0) {
              return sum
            }
            return sum + (m.projectionFootprint ?? 0)
          }, 0) / 1000 // We show tonnes in the UI, so there must be at least 1 tonne of CO2e to be included
        ) > 0
    )
    .slice(0, 3)
    .map((fraction, idx) => {
      const fractionType = fractionTypes.find(ft => ft.key === fraction.key)
      return (
        <FractionConfigurator
          key={fraction.key}
          monthlyData={fraction.months}
          description={fractionType?.shiftOption}
          unit={fraction.months[0].referenceUnitName}
          fractionTypes={fractionTypes}
          onChange={onChange}
          testId={`configurator-${footprint}-${idx}`}
        />
      )
    })
  if (configurators.length === 0) {
    return <div className="NoEmissions">No {footprint} footprint</div>
  }
  return configurators
}

function findShiftOption(
  fraction: WhatIfPlanetFraction,
  allFractions: WhatIfPlanetFraction[]
): [number | undefined, string | undefined] {
  if (fraction.planetIndicator === 'waste') {
    if (
      fraction.disposalType === 'material-recycling' ||
      fraction.disposalType === 'incineration-with-energy-recovery'
    ) {
      return [undefined, undefined]
    } else {
      const option = allFractions.find(f => f.fraction === fraction.fraction && f.disposalType === 'material-recycling')
      return [option?.factor, 'Shift to recycling']
    }
  } else if (fraction.planetIndicator === 'water') {
    const option = allFractions.find(f => f.planetIndicator === fraction.planetIndicator && f.factor < fraction.factor)
    return option?.factor ? [option.factor, `Shift to ${formatFraction(option).toLowerCase()}`] : [undefined, undefined]
  } else {
    if (fraction.fraction === 'electricity' && fraction.factor > 0) {
      return [0, 'Shift to renewable electricity']
    }
    const electricity = allFractions.find(f => f.fraction === 'electricity')
    if (fraction.referenceUnitName === 'kWh' && fraction.factor > (electricity?.factor ?? 0)) {
      return [electricity?.factor, 'Shift to electricity']
    }
    return [undefined, undefined]
  }
}

interface FractionConfiguratorProps {
  monthlyData: Array<WhatIfPlanetFraction>
  description: string | undefined
  unit: string
  fractionTypes: FractionType[]
  onChange: (id: string, ratio: number, reduction: boolean) => void
  testId: string
}

const FractionConfigurator: React.FC<FractionConfiguratorProps> = ({
  monthlyData,
  description,
  unit,
  fractionTypes,
  onChange,
  testId
}) => {
  type Action = 'Shift' | 'Reduction'
  const [action, setAction] = React.useState<Action>(description != null ? 'Shift' : 'Reduction')
  const [ratio, setRatio] = React.useState(0)
  React.useEffect(() => {
    onChange(`${monthlyData[0].fraction}.${monthlyData[0].disposalType}`, ratio, action === 'Reduction')
  }, [ratio, action])

  const co2Amount = monthlyData.reduce((sum, m) => sum + calculateEmission(m, fractionTypes), 0)
  const raw = monthlyData.reduce((sum, m) => {
    if (m.actualQty) {
      return sum + m.actualQty
    }
    if (action === 'Reduction' && !isCurrentMonthOrEarlier(m)) {
      return sum + (m.projectionQty ?? 0) * ((100 - ratio) / 100)
    }
    return sum + (m.projectionQty ?? 0)
  }, 0)

  return (
    <div className={styles.Configurator} data-testid={testId}>
      <div className={styles.Heading}>
        <div className="Title">{formatFraction(monthlyData[0])}</div>
        {description && (
          <Tooltip tooltipText={description} className="InformationIcon">
            <InformationIndicator />
          </Tooltip>
        )}
        <Toggle<Action>
          lhsValue="Shift"
          rhsValue="Reduction"
          currentValue={action}
          onChanged={v => setAction(v)}
          disabled={description == null}
        />
      </div>
      <div className={styles.Content}>
        <div className={styles.SliderContainer}>
          <Slider range={[0, 100]} onChange={val => setRatio(val)} step={5} />
        </div>
        <div className={styles.Ratio} data-testid={`${testId}-ratio`}>
          {`${action === 'Reduction' ? '-' : ''}${formatRelativeNumber(ratio)}`}%
        </div>
        <div className={styles.AmountsContainer}>
          <div className={styles.Amount}>
            <span className={styles.MainHighlight}>{formatAbsoluteNumber(Math.round(co2Amount / 1000))}</span>
            <span className={styles.Unit}>t CO2e</span>
          </div>
          <div className={styles.Amount}>
            <span className={styles.Highlight}>{formatAbsoluteNumber(Math.round(raw))}</span>
            <span className={styles.Unit}>{unit}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

function formatFraction(fraction: WhatIfPlanetFraction) {
  return fraction.disposalType == null
    ? fraction.fractionDescription
    : `${fraction.fractionDescription} to ${fraction.disposalTypeDescription?.toLowerCase() || '(missing type)'}`
}

function sortByDateString(a: string, b: string) {
  return new Date(a).getTime() - new Date(b).getTime()
}

function calculateCumulativeFootprint(
  fractions: Record<string, WhatIfPlanetFraction[]>,
  key: 'footprintRef' | 'actualFootprint'
) {
  let cumSum = 0
  return Object.keys(fractions)
    .sort(sortByDateString)
    .map(dateKey => {
      const dataForDate = fractions[dateKey]
      const value = dataForDate.reduce((sum, d) => sum + (d[key] ?? 0), 0)
      cumSum += value
      return { x: new Date(dateKey), y: cumSum }
    })
}

function calculateProjection(
  fractions: Record<string, WhatIfPlanetFraction[]>,
  actual: Array<DataPoint>,
  fractionTypes: FractionType[]
) {
  let cumSum = 0
  const result = Object.keys(fractions)
    .sort(sortByDateString)
    .map(dateKey => {
      const actualValue = actual.find(a => a.x.getTime() === new Date(dateKey).getTime())
      if (actualValue) {
        cumSum = actualValue.y ?? 0
        return { ...actualValue, hidden: true }
      }
      const dataForDate = fractions[dateKey]
      cumSum += dataForDate.reduce((sum, d) => sum + calculateEmission(d, fractionTypes), 0)
      return { x: new Date(dateKey), y: cumSum, hidden: false }
    })
  if (result.length > 0 && actual.length > 0) {
    result[actual.length - 1].hidden = false
  }
  return result
}
