import React from 'react'
import { getCountry, getLocationId, isSiteId } from '../../../components/Utils/utils'
import { CountryCode } from '../../../../api/src/types/location'
import { getCustomerTravelBenchmarking, getCustomerTravelFootprint } from '../../../lib/APIClient'
import { useLocations } from '../../../context'
import { useSharedSelections } from '../../../SharedSelections'
import { CustomerTravelBenchmark } from '../../../../api/src/types/benchmark'
import { format } from 'date-fns'
import { CustomerTravelFootprint } from '../../../../api/src/types/climate'

type CustomerTravelContextType = {
  benchmarks: CustomerTravelBenchmark[] | undefined
  locationId: string
  benchmarkingFY: number | null
  footprint: CustomerTravelFootprint[]
  dates: Date[] | undefined
  lastUpdated: string
  noData: boolean
}

export const CustomerTravelContext = React.createContext<CustomerTravelContextType>({
  benchmarks: undefined,
  locationId: '',
  benchmarkingFY: null,
  footprint: [],
  dates: undefined,
  lastUpdated: '',
  noData: false
})

type Props = {
  children?: React.ReactNode
}

export const CustomerTravelProvider: React.FC<Props> = props => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [benchmarks, setBenchmarks] = React.useState<CustomerTravelBenchmark[]>()
  const [benchmarkingFY, setBenchmarkingFY] = React.useState<number | null>(null)
  const [footprint, setFootprint] = React.useState<CustomerTravelFootprint[]>([])
  const [dates, setDates] = React.useState<Date[]>()
  const [lastUpdated, setLastUpdated] = React.useState('')

  const { currentLocation, locations } = useLocations()
  const [{ func }] = useSharedSelections()

  const locationId = getLocationId(currentLocation)

  React.useEffect(() => {
    if (!locations || locations.length === 0) return

    setLoading(true)
    setBenchmarks(undefined)
    setDates(undefined)

    const countryCode = isSiteId(locationId)
      ? getCountry(locationId, locations).countryCode
      : (locationId as CountryCode)

    getCustomerTravelBenchmarking(countryCode, func).then(({ data, currFY }) => {
      setBenchmarkingFY(currFY)
      setBenchmarks(data)
      setLoading(false)
    })

    getCustomerTravelFootprint(locationId, func).then(result => {
      setFootprint(result.data)
      setDates(result.dates.map(d => new Date(d)))
      result.lastUpdated && setLastUpdated(format(new Date(result.lastUpdated), 'dd/MM/yyyy'))
    })
  }, [JSON.stringify(locations), JSON.stringify(func), JSON.stringify(currentLocation)])

  const noData = React.useMemo(() => {
    const benchmark = benchmarks?.find(item => item.id === locationId)

    return !loading && !(benchmark?.currentYtd || benchmark?.previousYtd)
  }, [benchmarks, locationId, loading])

  return (
    <CustomerTravelContext.Provider
      value={{ benchmarks, locationId, benchmarkingFY, footprint, dates, lastUpdated, noData }}
    >
      {props.children}
    </CustomerTravelContext.Provider>
  )
}
