import React from 'react'
import classNames from 'classnames'
import { Slide } from '../../pages/InStore/InStorePage'

import './SlideContainer.scss'

interface SlideContainerProps {
  currentSlide: Slide
  name: Slide
  slides: Slide[]
}

export const SlideContainer: React.FC<SlideContainerProps> = ({ children, currentSlide, name, slides }) =>
  slides.includes(name) ? (
    <div className={classNames('SlideContainer', { isVisible: currentSlide === name })}>{children}</div>
  ) : null
