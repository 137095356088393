import React, { Fragment } from 'react'
import classnames from 'classnames'
import _, { isNil, throttle } from 'lodash'
import Toast from '@ingka/toast'

import { CountryCode, CountryRanking, SiteFunction, SiteRanking } from '../../../api/src/common-types'
import { useSharedSelections } from '../../SharedSelections'
import { ExclamationIndicator } from '../../components/BaseGraphs/Indicators'
import { NoDataView } from '../../components/BaseGraphs/NoDataView'
import { LoadingSkeleton } from '../../components/LoadingSkeleton'
import { SiteOrCountryInfoModal, CountryRankModal, SiteRankModal } from '../../components/Modal'
import { Tooltip } from '../../components/Tooltip'
import { TopBar } from '../../components/TopBar'
import {
  getCountry,
  getLocation,
  getLocationOrDefault,
  readIntCookie,
  storeIntCookie
} from '../../components/Utils/utils'
import {
  getCountryRankings,
  getSiteRankings,
  getLeaderboardWatchlist,
  LocationWithType,
  saveLeaderboardWatchlist
} from '../../lib/APIClient'
import { ReactComponent as AddButton } from '../../images/Icons/CloseGreyBG.svg'
import { LeaderboardPrompt } from '../../components/LeaderboardPrompt'

import './LeaderboardPage.scss'
import colours from '../../Colours.module.scss'
import { useLocations } from '../../context'
import {
  getListContentCountry,
  getListContentSiteAdjustedRank,
  getListContentWatchlist,
  groupWith
} from './leaderboardPageHelpers'
import { Route } from '../../routes'
import { analyticsEvent } from '../../components/Utils/analytics'
import { formatRelativeNumber } from '../../components/Utils/format'

const promptCookieKey = 'leaderboardprompt'
const promptContentVersion = 1

export interface LeaderboardRanking {
  rank: number | null
  change: number | null
  recyclingRate: number | null
  climateFootprint: number | null
  pppSalesShare: number | null
  reason: string
  pppShare: number | null
  recyclingShare: number | null
  climateFootprintShare: number | null
}

export interface LeaderboardListItem extends LeaderboardRanking {
  id: string
  name: string
  countryCode: CountryCode
}

enum SortByTitle {
  Rank = 'Rank',
  'Recycling Rate' = 'Recycling Rate',
  'Climate Footprint' = 'Climate Footprint',
  'P+PP Sales Share' = 'P+PP Sales Share'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSortable<SortByTitle>(value: any): value is SortByTitle[keyof SortByTitle] {
  return Object.values(SortByTitle).includes(value)
}

export type SortByKey = 'rank' | 'recyclingRate' | 'climateFootprint' | 'pppSalesShare'
const sortByKeys: Record<SortByTitle, SortByKey> = {
  Rank: 'rank',
  'Recycling Rate': 'recyclingRate',
  'Climate Footprint': 'climateFootprint',
  'P+PP Sales Share': 'pppSalesShare'
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export const useCountryRankings = (func: SiteFunction[]) => {
  const [countryRankings, setCountryRankings] = React.useState<CountryRanking[]>()

  React.useEffect(() => {
    async function loadCountryRankings() {
      const rankings = await getCountryRankings(func)
      setCountryRankings(rankings)
    }

    loadCountryRankings()
  }, [JSON.stringify(func.sort())])

  return countryRankings
}

export const useSiteRankings = (func: SiteFunction[]) => {
  const [siteRankings, setSiteRankings] = React.useState<SiteRanking[]>()

  React.useEffect(() => {
    async function loadSiteRankings() {
      const rankings = await getSiteRankings(func)
      setSiteRankings(rankings)
    }

    loadSiteRankings()
  }, [JSON.stringify(func.sort())])

  return siteRankings
}

interface LearnMoreProps {
  onClick: () => void
}

const LearnMore: React.FC<LearnMoreProps> = ({ children, onClick }) => (
  <div className="LearnMore">
    <span>{children}</span>
    <button
      onClick={() => {
        analyticsEvent({
          category: 'LearnMore',
          action: 'Open',
          label: 'leaderboard'
        })
        onClick()
      }}
    >
      How this is calculated
    </button>
  </div>
)

export const LeaderboardPage = () => {
  React.useEffect(() => {
    document.title = 'Leaderboard'
  }, [])
  const { currentLocation, locations } = useLocations()
  const [watchlist, setWatchlist] = React.useState<string[]>([])
  const [sortByKey, setSortByKey] = React.useState<SortByKey>('rank')
  const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.Asc)
  const [isLearnMoreCountryModalOpen, setIsLearnMoreCountryModalOpen] = React.useState(false)
  const [isLearnMoreSiteModalOpen, setIsLearnMoreSiteModalOpen] = React.useState(false)
  const [isCountryInfoModalOpen, setIsCountryInfoModalOpen] = React.useState(false)
  const [infoModalSiteOrCountryId, setInfoModalSiteOrCountryId] = React.useState('')
  const [newWatchlistItem, setNewWatchlistItem] = React.useState<string | undefined>(undefined)
  const siteOrCountry = getLocationOrDefault()
  const [isPromptOpen, setIsPromptOpen] = React.useState(false)

  const [{ func, leaderboardScope, watchlistScope }, updateSharedSelections] = useSharedSelections()
  const countryRankings = useCountryRankings(func)
  const siteRankings = useSiteRankings(func)

  React.useEffect(() => {
    getLeaderboardWatchlist().then(result => setWatchlist(result.locations))
  }, [])

  React.useEffect(() => {
    setTimeout(() => setIsPromptOpen(true), 2000)
  }, [])

  const storeWatchlist = React.useCallback(
    throttle(watchlist => {
      saveLeaderboardWatchlist(watchlist)
    }, 2000),
    []
  )

  const openSiteOrCountryInfoModal = (siteOrCountryId: string) => {
    setIsCountryInfoModalOpen(true)
    setInfoModalSiteOrCountryId(siteOrCountryId)
  }

  if (locations.length === 0 || !countryRankings || !siteRankings) {
    return <LoadingSkeleton />
  }

  const country = getCountry(siteOrCountry, locations)

  const isInScope = ({ countryCode: siteCountryCode }: SiteRanking) =>
    leaderboardScope === 'site-global' || siteCountryCode === country.countryCode
  const siteRankingsInScope = siteRankings.filter(isInScope)

  const locationList =
    leaderboardScope === 'country'
      ? getListContentCountry(countryRankings, sortByKey, sortDirection)
      : leaderboardScope === 'site-global' || leaderboardScope === 'site-country'
      ? getListContentSiteAdjustedRank(siteRankingsInScope, sortByKey, sortDirection)
      : getListContentWatchlist(watchlistScope, watchlist, countryRankings, siteRankings, sortByKey, sortDirection)
  const topThreeLocationList = (
    leaderboardScope === 'country'
      ? getListContentCountry(countryRankings, 'rank', SortDirection.Asc).slice(0, 3)
      : getListContentSiteAdjustedRank(siteRankingsInScope, 'rank', SortDirection.Asc).slice(0, 3)
  ).filter(({ rank }) => rank != null)

  const addOrRemoveItem = (siteOrCountry: string) => {
    const removing = watchlist.includes(siteOrCountry)
    const newWatchlist = removing
      ? watchlist.filter(watchlistSiteOrCountry => watchlistSiteOrCountry !== siteOrCountry)
      : [...watchlist, siteOrCountry]
    storeWatchlist(newWatchlist)
    setWatchlist(newWatchlist)

    if (!removing) {
      setNewWatchlistItem(siteOrCountry)
    }
  }

  const onSortChange = (title: string) => {
    if (isSortable(title)) {
      const newSortByKey = sortByKeys[title]
      if (newSortByKey === sortByKey) {
        setSortDirection(sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc)
      } else {
        setSortDirection(SortDirection.Asc)
        setSortByKey(newSortByKey)
      }
    }
  }

  const sortTitleTooltips = {
    Rank: 'Rank by overall performance',
    'Recycling Rate': 'Rank by Recycling Rate PI\n(% of waste recycled)',
    'Climate Footprint': 'Rank by Climate Footprint KPI\n(CO2E/m2)',
    'P+PP Sales Share': 'Rank by P+PP Sales Share KPI\n(% of total sales)'
  }

  const isCountryScope =
    leaderboardScope === 'country' || (leaderboardScope === 'watchlist' && watchlistScope === 'country')
  const isSiteScope =
    leaderboardScope === 'site-global' || (leaderboardScope === 'watchlist' && watchlistScope === 'site')
  const getIsHighlightedRow = (item: LeaderboardListItem): boolean => {
    if (siteOrCountry.length > 2) {
      return item.id === siteOrCountry
    } else {
      if (leaderboardScope === 'site-country') {
        return false
      } else if (isCountryScope || isSiteScope) {
        return item.countryCode === country.countryCode
      }
    }
    return false
  }
  const locationListGroupedByHighlights = groupWith(getIsHighlightedRow, locationList)

  const closePrompt = () => {
    storeIntCookie(promptCookieKey, promptContentVersion)
    setIsPromptOpen(false)
  }
  const showPrompt = readIntCookie(promptCookieKey) < promptContentVersion

  return currentLocation.isCluster ? (
    <></>
  ) : (
    <div className="LeaderboardPage">
      <TopBar currentPage={Route.LeaderboardPage} />
      {siteOrCountry !== 'ALL' && (
        <LeaderboardTopPanel func={func} locations={locations} siteOrCountry={siteOrCountry} />
      )}
      <div className="ContentPanel">
        <div className={`ScopeSelector ${currentLocation.location.value !== 'ALL' ? 'Four' : 'Three'}`}>
          <div
            className={`Scope Country ${leaderboardScope === 'country' ? '' : 'Inactive'}`}
            onClick={() => updateSharedSelections({ leaderboardScope: 'country' })}
          >
            Countries
          </div>
          <div
            className={`Scope SiteGlobal ${leaderboardScope === 'site-global' ? '' : 'Inactive'}`}
            onClick={() => updateSharedSelections({ leaderboardScope: 'site-global' })}
          >
            Sites globally
          </div>
          {currentLocation.location.value !== 'ALL' && (
            <div
              className={`Scope SiteCountry ${leaderboardScope === 'site-country' ? '' : 'Inactive'}`}
              onClick={() => updateSharedSelections({ leaderboardScope: 'site-country' })}
            >
              Sites within the selected country
            </div>
          )}
          <div
            className={`Scope Watchlist ${leaderboardScope === 'watchlist' ? '' : 'Inactive'}`}
            onClick={() => updateSharedSelections({ leaderboardScope: 'watchlist' })}
          >
            My watchlist
          </div>
        </div>
        {leaderboardScope === 'country' && (
          <LearnMore onClick={() => setIsLearnMoreCountryModalOpen(true)}>
            Country Leaderboard visualizes country&apos;s performance through Recycling Rate, Climate Footprint and P+PP
            Sales Share.
          </LearnMore>
        )}
        {(leaderboardScope === 'site-global' || leaderboardScope === 'site-country') && (
          <LearnMore onClick={() => setIsLearnMoreSiteModalOpen(true)}>
            Site Leaderboard visualizes site&apos;s performance through Recycling Rate, Climate Footprint and P+PP Sales
            Share.
          </LearnMore>
        )}
        {leaderboardScope !== 'watchlist' && topThreeLocationList.length > 0 && (
          <LeaderboardTopThree topThreeLocationList={topThreeLocationList} />
        )}
        <div className="PagingSelector">
          {leaderboardScope === 'watchlist' && (
            <>
              <div
                className={classnames('PagingSelectorItem', { Selected: watchlistScope === 'country' })}
                onClick={() => updateSharedSelections({ watchlistScope: 'country' })}
              >
                Countries
              </div>
              <div
                className={classnames('PagingSelectorItem', { Selected: watchlistScope === 'site' })}
                onClick={() => updateSharedSelections({ watchlistScope: 'site' })}
              >
                Sites
              </div>
            </>
          )}
        </div>
        <div className="LeaderboardList">
          <div className="LeaderboardListRow">
            {[
              'Rank',
              'Change from last tertial',
              leaderboardScope === 'country' || (leaderboardScope === 'watchlist' && watchlistScope === 'country')
                ? 'Country'
                : 'Site',
              'Recycling Rate',
              'Climate Footprint',
              'P+PP Sales Share',
              ''
            ].map(title => {
              const isActiveSorting = isSortable(title) && sortByKey === sortByKeys[title]
              return (
                <div
                  key={title}
                  className={classnames('LeaderboardListItem', 'LeaderboardHeader', {
                    isSortable: isSortable(title),
                    isActiveSorting
                  })}
                  onClick={() => onSortChange(title)}
                >
                  {isSortable(title) ? (
                    <Tooltip className="LeaderboardListItemTooltip" tooltipText={sortTitleTooltips[title]}>
                      {title}
                    </Tooltip>
                  ) : (
                    <span>{title}</span>
                  )}
                  {isActiveSorting && <SortSymbol direction={sortDirection} />}
                </div>
              )
            })}
          </div>
          {locationListGroupedByHighlights.map((locationList, index) => {
            const elements = locationList.map((item, innerIndex) => (
              <ListRow
                key={`ListRow${innerIndex}`}
                item={item}
                addClicked={addOrRemoveItem}
                inWatchlist={watchlist.includes(item.id)}
                openModal={openSiteOrCountryInfoModal}
              />
            ))
            return getIsHighlightedRow(locationList[0]) ? (
              <div key={`ListRow${index}`} className="LeaderboardRowHighlight">
                {elements}
              </div>
            ) : (
              <Fragment key={`ListRow${index}`}>{elements}</Fragment>
            )
          })}
          {locationList.length === 0 && (
            <NoDataView
              className="LeaderboardNoDataView"
              text={`No ${watchlistScope === 'country' ? 'countries' : 'sites'} have yet been added to the watchlist`}
            />
          )}
        </div>
      </div>
      <CountryRankModal isOpen={isLearnMoreCountryModalOpen} onClose={() => setIsLearnMoreCountryModalOpen(false)} />
      <SiteRankModal isOpen={isLearnMoreSiteModalOpen} onClose={() => setIsLearnMoreSiteModalOpen(false)} />
      <SiteOrCountryInfoModal
        func={func}
        isOpen={isCountryInfoModalOpen}
        onClose={() => setIsCountryInfoModalOpen(false)}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        siteOrCountry={getLocation(infoModalSiteOrCountryId, locations)!}
      />
      <Toast
        text="Added to watchlist!"
        actionButtonText="View"
        actionClick={() => updateSharedSelections({ leaderboardScope: 'watchlist' })}
        isOpen={newWatchlistItem !== undefined}
        onCloseRequest={() => setNewWatchlistItem(undefined)}
        // Skapa throws an error without `onTransitionEnd`, even though it's not needed
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onTransitionEnd={() => {}}
      />
      {showPrompt && <LeaderboardPrompt isOpen={isPromptOpen} onClose={closePrompt} />}
    </div>
  )
}

interface LeaderboardTopPanelProps {
  func: SiteFunction[]
  locations: LocationWithType[]
  siteOrCountry: string
}

export const LeaderboardTopPanel: React.FC<LeaderboardTopPanelProps> = ({ func, locations, siteOrCountry }) => {
  const country = getCountry(siteOrCountry, locations)
  const isInSameCountry = ({ countryCode }: SiteRanking) => countryCode === country.countryCode

  const siteRankings = useSiteRankings(func)
  const siteRankingsSorted = _.orderBy(siteRankings, 'overallRank', SortDirection.Asc)
  const siteRankingsSortedInCountry = siteRankingsSorted.filter(isInSameCountry)

  let secondPanel
  let title
  let label
  if (siteOrCountry.length > 3 && siteRankingsSortedInCountry.length > 0) {
    const selectedSiteIndex = siteRankingsSortedInCountry.findIndex(({ entityId }) => entityId === siteOrCountry)
    const selectedSite: SiteRanking = {
      ...siteRankingsSortedInCountry[selectedSiteIndex],
      overallRank: selectedSiteIndex + 1
    }
    secondPanel = selectedSite
    title = 'Selected site'
    label = 'Site rank within the country'
  } else if (siteRankingsSortedInCountry.length > 0) {
    secondPanel = siteRankingsSortedInCountry[0]
    title = 'Best site in the country'
    label = 'Site rank globally'
  }

  const countryRankings = useCountryRankings(func)
  const thisCountryRanking = countryRankings?.find(ranking => ranking.countryCode === country.countryCode)

  return thisCountryRanking?.overallRank ? (
    <div className="LeaderboardTopPanel">
      <RankPanel
        title="Country rank"
        location={thisCountryRanking.countryName}
        ranking={thisCountryRanking.overallRank}
        additionalLabel="Country rank globally"
      />
      {secondPanel && title && label && (
        <>
          <div className="Divider" />
          <RankPanel
            title={title}
            location={secondPanel.siteName}
            ranking={secondPanel.overallRank}
            additionalLabel={label}
          />
        </>
      )}
    </div>
  ) : null
}

interface LeaderboardTopThreeProps {
  topThreeLocationList: LeaderboardListItem[]
}

export const LeaderboardTopThree: React.FC<LeaderboardTopThreeProps> = ({ topThreeLocationList }) => {
  return (
    <div className="LeaderboardTopThree">
      {topThreeLocationList.map(l => (
        <div key={l.name} className="TopThreeItem">
          <LeaderboardRanking ranking={l.rank} change={0} />
          <div className="Name">{l.name}</div>
        </div>
      ))}
    </div>
  )
}

interface SortSymbolProps {
  direction: SortDirection
}

const SortSymbol: React.FC<SortSymbolProps> = ({ direction }) => (
  <span className="SortSymbol">{direction === SortDirection.Asc ? '▲' : '▼'}</span>
)

interface RankPanelProps {
  title: string
  location: string
  ranking: number | null
  additionalLabel: string
}

const RankPanel: React.FC<RankPanelProps> = ({ title, location, ranking, additionalLabel }) => {
  return (
    <div className="RankPanel">
      <div className="Heading">
        <span className="Title">{title}</span>
        <span className="Location">{location}</span>
      </div>
      <div className="Ranking">
        <div className="AdditionalLabel">{additionalLabel}</div>
        <LeaderboardRanking ranking={ranking} change={0} />
      </div>
    </div>
  )
}

interface ListRowProps {
  item: LeaderboardListItem
  addClicked: (id: string) => void
  inWatchlist: boolean
  openModal: (id: string) => void
}

const ListRow: React.FC<ListRowProps> = ({ item, inWatchlist, addClicked }) => {
  const getChange = (change: number | null) =>
    isNil(change) || change === 0 ? 'N/A' : change < 0 ? change : `+${change}`
  const className = `LeaderboardListItem`
  const changeClassName = isNil(item.change) || item.change === 0 ? '' : item.change < 0 ? ' ChangeNeg' : ' ChangePos'

  return (
    <div className="LeaderboardListRow">
      <div className={className}>
        {isNil(item.rank) && !isNil(item.reason) ? (
          <Tooltip tooltipText={item.reason} className="MissingDataIcon">
            <ExclamationIndicator fill={colours.red} />
          </Tooltip>
        ) : (
          item.rank
        )}
      </div>
      <div className={`${className} ${changeClassName}`}>{getChange(item.change)}</div>
      <div className={`${className} Left`}>{item.name}</div>
      <div className={className}>
        {item.recyclingRate ? (
          <>
            <span>{item.recyclingRate} </span>
            <span className="brackets">
              ({item.recyclingShare ? formatRelativeNumber(item.recyclingShare * 100) : 'n/a'}%)
            </span>
          </>
        ) : (
          'n/a'
        )}
      </div>
      <div className={className}>
        {item.climateFootprint ? (
          <>
            <span>{item.climateFootprint} </span>
            <span className="brackets">
              ({item.climateFootprintShare ? item.climateFootprintShare.toFixed(2) : 'n/a'})
            </span>
          </>
        ) : (
          'n/a'
        )}
      </div>
      <div className={className}>
        {item.pppSalesShare ? (
          <>
            <span>{item.pppSalesShare} </span>
            <span className="brackets">({item.pppShare ? formatRelativeNumber(item.pppShare * 100) : 'n/a'}%)</span>
          </>
        ) : (
          'n/a'
        )}
      </div>
      <div className={className}>
        <Tooltip tooltipText={`${inWatchlist ? 'Remove from' : 'Add to'} watchlist`} className="WatchlistToggle">
          {inWatchlist ? (
            <span className="InWatchlistIcon" onClick={() => addClicked(item.id)} />
          ) : (
            <AddButton onClick={() => addClicked(item.id)} />
          )}
        </Tooltip>
      </div>
    </div>
  )
}

interface LeaderboardRankingProps {
  ranking: number | null
  change: number
}

export const LeaderboardRanking: React.FC<LeaderboardRankingProps> = ({ ranking, change }) => (
  <div className={`LeaderboardRanking ${change < 0 ? 'ChangeNeg' : 'ChangePos'}`}>{ranking}</div>
)
