import React from 'react'
import { useLocation, useParams } from 'react-router'
import { ApiCluster } from '../../api/src/common-types'
import { UserInformationContext } from './UserInformationContext'
import { getLocation, isCluster } from '../components/Utils/utils'
import { isCountry, LocationWithType } from '../lib/APIClient'

export type CurrentLocation =
  | { isCluster: false; location: LocationWithType }
  | { isCluster: true; cluster: ApiCluster }

export interface Locations {
  clusters: ApiCluster[]
  locations: LocationWithType[]
}
export const LocationsContext = React.createContext<Locations | undefined>(undefined)

export const useLocations = () => {
  const context = React.useContext(LocationsContext)
  const userInformation = React.useContext(UserInformationContext)
  const location = useLocation()

  if (context === undefined) {
    throw new Error('useLocations must be called within LocationsContext')
  }

  if (location.pathname === '/instoreconfigurator') {
    // a dummy location for instore configurator page which doesn't require the current location information
    const currentLocation: CurrentLocation = {
      isCluster: true,
      cluster: { clusterId: 'ALL', clusterName: 'ALL', countryCodes: [] }
    }
    return {
      ...context,
      currentLocation
    }
  }

  const { siteOrCountry } =
    location.pathname === '/'
      ? {
          siteOrCountry: context?.locations.filter(isCountry).find(l => l.countryName === userInformation?.country)
            ?.countryCode
        }
      : useParams()
  let currentLocation: CurrentLocation
  if (siteOrCountry === undefined) {
    throw new Error('Current location not found in useLocations')
  }

  if (isCluster(siteOrCountry)) {
    const cluster = context.clusters.find(c => c.clusterId === siteOrCountry)
    if (cluster === undefined) {
      throw new Error('Cluster not found in useLocations')
    }
    currentLocation = { isCluster: true, cluster }
  } else {
    const location = getLocation(siteOrCountry, context.locations)
    if (location === undefined) {
      throw new Error('Location not found in useLocations')
    }
    currentLocation = { isCluster: false, location }
  }
  return { ...context, currentLocation }
}
