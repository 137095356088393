import { DataPoint } from '../../../../components/BaseGraphs/ChartContainer'
import { FoodFootprint } from '../../../../../api/src/types/food'
import { getTotalAmountKg, getTotalFootprint } from '../../../../components/FoodIngredientsGraphs'
import { Option } from '../../../../components/FilterMenu/FilterDropdownMenu'
import { getIngkaFinancialYear } from '../../../../components/Utils/dates'

export const mapToClimateGraphIntensitySeries = (data: FoodFootprint[]): DataPoint[] => {
  return data
    .map(item => {
      return {
        x: new Date(item.readableDate),
        y: getTotalFootprint(item) / getTotalAmountKg(item)
      }
    })
    .sort((a, b) => a.x.getTime() - b.x.getTime())
}

export const yearsFromDates = (dates: string[]): Option[] => {
  return dates.reduce((result: Option[], current): Option[] => {
    const year = getIngkaFinancialYear(new Date(current)).getFullYear().toString()
    const alreadyIn = result.some(item => item.value === year)

    return alreadyIn
      ? result
      : [
          ...result,
          {
            label: `FY${Number(year) - 2000}`,
            value: year
          }
        ]
  }, [])
}

export const monthFromDates = (dates: string[], fy: number): Option[] => {
  return dates
    .filter(date => getIngkaFinancialYear(new Date(date)).getFullYear() === fy)
    .reduce((result: Option[], current): Option[] => {
      const label = new Date(current).toLocaleString('default', { month: 'long' })
      const value = (new Date(current).getMonth() + 1).toString()

      return [
        ...result,
        {
          label,
          value
        }
      ]
    }, [])
}
