import ReactGA from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'

const dashboardTrackingId = 'G-1BLBPNHLK4'
const inStoreTrackingId = 'G-G7ENRXQBXB'

export function initialiseAnalytics() {
  const trackingId =
    window.location.pathname.includes('/instore') && !window.location.pathname.includes('/instoreconfigurator')
      ? inStoreTrackingId
      : dashboardTrackingId
  ReactGA.initialize(trackingId)
  ReactGA.set({ anonymizeIp: true })
}

export function analyticsEvent(options: UaEventOptions) {
  return ReactGA.event(options)
}

interface PageViewParams {
  path?: string
  title?: string
}

export function pageView({ path, title }: PageViewParams) {
  return ReactGA.send({ hitType: 'pageview', page: path, title })
}
