import React from 'react'
import { Insight } from '../../../api/src/common-types'
import { getInsights, LocationWithType, SelectorWithCountryCodes, SelectorWithFunctions } from '../../lib/APIClient'
import { dataSourceTextWithStartDate } from '../../Localisation'
import { useLocations, LanguageContext } from '../../context'
import { isDominantCategory } from '../Insights/guards'
import { getDataSource, Insight as InsightComponent } from '../Insights/Insight'
import { getCurrentLocationLabel } from '../Utils/utils'

export interface SlideProps {
  currentLocation: LocationWithType
  selector: SelectorWithFunctions
}

export interface SlideSelectorWithCountryCodesProps {
  currentLocation: LocationWithType
  selector: SelectorWithCountryCodes
}

export const FootprintInsightSlide: React.FC<SlideProps> = ({ selector }) => {
  const [footprintInsight, setFootprintInsight] = React.useState<Insight | undefined>(undefined)
  const [dateRange, setDateRange] = React.useState<[string, string]>(['', ''])
  const lang = React.useContext(LanguageContext)
  const { currentLocation } = useLocations()

  setFootprintInsight

  const currentLocationLabel = currentLocation.isCluster
    ? currentLocation.cluster.clusterName
    : getCurrentLocationLabel(currentLocation.location, lang)
  React.useEffect(() => {
    if (!currentLocation.isCluster) {
      getInsights(selector).then(result => {
        const largestContributorInsight = result.filter(isDominantCategory).find(i => i.type === 'climate-footprint')
        setFootprintInsight(largestContributorInsight)
        largestContributorInsight?.plugins
        if (largestContributorInsight) {
          setDateRange([largestContributorInsight.plugins.date_from, largestContributorInsight.plugins.date_to])
        }
      })
    }
  }, [])

  const dataSource =
    footprintInsight && dateRange[0].length > 0
      ? dataSourceTextWithStartDate(lang, dateRange[0], dateRange[1], getDataSource(footprintInsight, lang))
      : ''
  return (
    <>
      {footprintInsight && <InsightComponent insight={footprintInsight} currentLocationLabel={currentLocationLabel} />}
      <span className="FootprintInsightDataSource">{dataSource}</span>
    </>
  )
}
