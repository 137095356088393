import React from 'react'
import { GridHr, Title } from '../../../components/Benchmark/GridHelpers'
import { TimeRangeSelector } from '../../../components/Benchmark/TimeRangeSelector'
import { BenchmarkingContext } from './Provider'
import { useBenchmarkHelperData } from './hooks/helpers'
import { useClimateData } from './hooks/useClimateData'
import { BenchmarkTableRow, TableRowKey } from '../../../components/Benchmark/BenchmarkTableRow'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'
import { multiFormatNumber } from '../../../components/Utils/format'
import { useSharedSelections } from '../../../SharedSelections'
import { Show } from '../../../components/Conditions/Show'

export const ClimateTable = () => {
  const context = React.useContext(BenchmarkingContext)
  const data = useClimateData()
  const [{ func }] = useSharedSelections()
  const { prevOptions, currOptions, currFyLabel, nextFyLabel, minusOneYear, prevFyLabel } = useBenchmarkHelperData()
  const { prevOptionsMinusOne, currOptionsMinusOne, currFyLabelMinusOne, nextFyLabelMinusOne, prevFyLabelMinusOne } =
    minusOneYear

  if (!context) return null

  const { prevTimeRange, currTimeRange, expandedKey } = context.data

  const setPrevTimeRange = (value: 'YTD' | 'Result') => {
    context.dispatch({ type: 'prevTimeRange', payload: value })
  }

  const setCurrTimeRange = (value: 'YTD' | 'Rolling 12 mos') => {
    context.dispatch({ type: 'currTimeRange', payload: value })
  }

  const commonRowProps = {
    expandedKey,
    toggleExpanded: context.toggleExpanded
  }

  const isCentres = func.some(item => item === 'Common Areas' || item === 'Tenants')

  return (
    <>
      <div className="TableHeadTitle Climate">CIRCULAR & CLIMATE AND NATURE</div>
      <div className="Table">
        <Title className="KPI">KPI / PI</Title>
        <Title className="SiteCountry">Site/country</Title>
        <TimeRangeSelector
          className="ResultPrevFy Title"
          options={prevOptions}
          currentValue={prevTimeRange === 'YTD' ? prevOptions[0] : prevOptions[1]}
          onValueChanged={value => (value === prevOptions[0] ? setPrevTimeRange('YTD') : setPrevTimeRange('Result'))}
        />
        <TimeRangeSelector
          className="Comparison Title"
          options={currOptions}
          currentValue={currTimeRange === 'YTD' ? currOptions[0] : currOptions[1]}
          onValueChanged={value =>
            value === currOptions[0] ? setCurrTimeRange('YTD') : setCurrTimeRange('Rolling 12 mos')
          }
        />
        <Title className="GoalPrev">{prevFyLabel} Goal</Title>
        <Title className="Goal">{currFyLabel} Goal</Title>
        <Title className="GoalNext">{nextFyLabel} Goal</Title>
        <GridHr isHead />
        {!data?.climateData ? (
          <LoadingSkeleton />
        ) : (
          <BenchmarkTableRow
            kpiUnits="kg CO2e/m²"
            locationResults={data.climateData.locationResult}
            childLocationResults={data.climateData.childLocationResults}
            rowKey={TableRowKey.PlanetFootprint}
            tooltip="Please note that the goal is in absolute ton CO2e, not the ratio of CO2e/m2. The ratio is displayed here to allow benchmarking."
            {...commonRowProps}
          />
        )}
        <GridHr />
        {!data?.recyclingRateData ? (
          <LoadingSkeleton />
        ) : (
          <BenchmarkTableRow
            kpiUnits="% of waste being recycled"
            locationResults={data.recyclingRateData.locationResult}
            childLocationResults={data.recyclingRateData.childLocationResults}
            rowKey={TableRowKey.PlanetRecycling}
            formatNumber={multiFormatNumber}
            {...commonRowProps}
          />
        )}
        <GridHr />
        {!data?.energyEfficiencyData ? (
          <LoadingSkeleton />
        ) : (
          <BenchmarkTableRow
            kpiUnits="kWh/m²"
            locationResults={data.energyEfficiencyData.locationResult}
            childLocationResults={data.energyEfficiencyData.childLocationResults}
            formatNumber={multiFormatNumber}
            rowKey={TableRowKey.EnergyEfficiency}
            {...commonRowProps}
          />
        )}
        <GridHr />
        {!data?.waterEfficiencyData ? (
          <LoadingSkeleton />
        ) : (
          <BenchmarkTableRow
            kpiUnits="litres/visitor"
            locationResults={data.waterEfficiencyData.locationResult}
            childLocationResults={data.waterEfficiencyData.childLocationResults}
            formatNumber={multiFormatNumber}
            rowKey={TableRowKey.WaterEfficiency}
            {...commonRowProps}
          />
        )}
        <GridHr />

        <Show when={!isCentres}>
          {!data?.foodIngredientsData ? (
            <LoadingSkeleton />
          ) : (
            <BenchmarkTableRow
              kpiUnits="tonnes CO2e"
              locationResults={data.foodIngredientsData.locationResult}
              childLocationResults={data.foodIngredientsData.childLocationResults}
              rowKey={TableRowKey.FoodIngredients}
              formatNumber={multiFormatNumber}
              {...commonRowProps}
            />
          )}
        </Show>
      </div>
      <Show when={!isCentres}>
        <div className="Table">
          <Title className="KPI"></Title>
          <Title className="SiteCountry"></Title>
          <TimeRangeSelector
            className="ResultPrevFy Title"
            options={prevOptionsMinusOne}
            currentValue={prevTimeRange === 'YTD' ? prevOptionsMinusOne[0] : prevOptionsMinusOne[1]}
            onValueChanged={value =>
              value === prevOptionsMinusOne[0] ? setPrevTimeRange('YTD') : setPrevTimeRange('Result')
            }
          />
          <TimeRangeSelector
            className="Comparison Title"
            options={currOptionsMinusOne}
            currentValue={currTimeRange === 'YTD' ? currOptionsMinusOne[0] : currOptionsMinusOne[1]}
            onValueChanged={value =>
              value === currOptionsMinusOne[0] ? setCurrTimeRange('YTD') : setCurrTimeRange('Rolling 12 mos')
            }
          />
          <Title className="GoalPrev">{prevFyLabelMinusOne} Goal</Title>
          <Title className="Goal">{currFyLabelMinusOne} Goal</Title>
          <Title className="GoalNext">{nextFyLabelMinusOne} Goal</Title>
          <GridHr isHead />
          <GridHr />
          {!data?.customerDeliveriesData ? (
            <LoadingSkeleton />
          ) : (
            <BenchmarkTableRow
              kpiUnits="%"
              locationResults={data.customerDeliveriesData.locationResult}
              childLocationResults={data.customerDeliveriesData.childLocationResults}
              rowKey={TableRowKey.CustomerDeliveries}
              formatNumber={multiFormatNumber}
              {...commonRowProps}
            />
          )}
        </div>
      </Show>
    </>
  )
}
