import { useCallback } from 'react'
import { NavigateOptions, useNavigate, useParams } from 'react-router-dom'

/**
 * Returns a callback function that, when called, navigates to a page keeping
 * the query parametres and location selector as they are
 */
export const usePageRedirect = (to: string, options?: NavigateOptions & { keepQuery: boolean }) => {
  const navigate = useNavigate()
  const { siteOrCountry } = useParams()

  const callback = useCallback(
    function doRedirection() {
      const query = options?.keepQuery ?? true ? window.location.search : ''
      navigate(`/${siteOrCountry}/${to}${query}`, options)
    },
    [to, JSON.stringify(options)]
  )

  return callback
}
