import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { difference } from 'lodash'
import { useMsal } from '@azure/msal-react'
import { format } from 'date-fns'

import {
  ApiCluster,
  DataAvailability,
  DisabledFeatures,
  NotificationsSeen,
  UserInformation
} from '../../api/src/common-types'
import * as APIClient from '../lib/APIClient'
import { createColorScheme } from '../components/BaseGraphs/Universal'
import { DisabledFeaturesContext } from './DisabledFeaturesContext'
import { UserInformationContext } from './UserInformationContext'
import { DataAvailabilityContext } from './DataAvailabilityContext'
import { LocationsContext } from './LocationsContext'
import { NotificationsContext } from './NotificationsContext'
import { FeedbackContext } from './FeedbackContext'
import { isLocal, msalApp, tenantId } from '../Authentication'
import colours from '../Colours.module.scss'

const energyColors: { [key: string]: string } = {
  Electricity: colours.darkBlue1,
  'Fossil fuel onsite': colours.blue4,
  'Biofuel onsite': colours.purple2,
  'District Heating': colours.hotPink,
  'District Cooling': colours.pink1,
  Refrigerants: colours.orange2,
  Transport: colours.orange3
}

const energyColorScheme: (items: string[]) => { [key: string]: string } = createColorScheme([
  colours.darkBlue1,
  colours.blue4,
  colours.purple2,
  colours.hotPink,
  colours.pink1,
  colours.orange2,
  colours.orange3
])

export const ThemeContext = React.createContext(energyColors)
export const InStoreContext = React.createContext(false)

const isInStorePage = (pathname: string) => pathname.includes('/instore') && !pathname.includes('/instoreconfigurator')

export const Providers: React.FC<{ notLoaded: ReactNode }> = ({ children, notLoaded }) => {
  const [theme, setTheme] = React.useState<Record<string, string>>(energyColors)
  const [featureFlags, setFeatureFlags] = React.useState<DisabledFeatures[] | null>(null)
  const [userInformation, setUserInformation] = React.useState<UserInformation | null>(null)
  const [clusters, setClusters] = React.useState<ApiCluster[]>([])
  const [locations, setLocations] = React.useState<APIClient.LocationWithType[]>([])
  const [notifications, setNotifications] = React.useState<NotificationsSeen | undefined>(undefined)
  const [isFeedbackOpen, setFeedbackOpen] = React.useState(false)
  const [dataAvailability, setDataAvailability] = React.useState<DataAvailability>()
  const { accounts } = useMsal()
  const { pathname } = useLocation()

  const notificationsSeen = notifications ?? notifications ?? { lastSeen: '2036-01-01' }
  const setNotificationsSeen = () => {
    setNotifications({ lastSeen: format(new Date(), 'yyyy-MM-dd') })
    APIClient.saveNotificationsLastSeen()
  }

  const openFeedbackModal = () => {
    setFeedbackOpen(true)
  }
  const closeFeedbackModal = () => {
    setFeedbackOpen(false)
  }

  React.useEffect(() => {
    const ingkaAccounts = isLocal()
      ? [
          {
            homeAccountId: 'dummy',
            environment: 'localhost',
            tenantId: 'tenantId',
            username: 'localuser',
            localAccountId: 'localAccountId'
          }
        ]
      : accounts.filter(a => a.tenantId === tenantId)
    const inInStorePage = isInStorePage(pathname)
    if (!inInStorePage && ingkaAccounts.length === 0) {
      return
    } else if (!inInStorePage) {
      msalApp.setActiveAccount(ingkaAccounts[0])
    }

    async function refreshLocations() {
      const [locations, clusters] = await Promise.all([APIClient.getAllLocations(), APIClient.getClusters()])
      setLocations(locations)
      setClusters(clusters)
    }
    refreshLocations()

    async function loadDisabledFeatures() {
      const disabledFeatures = await APIClient.getDisabledFeatures()
      setFeatureFlags(disabledFeatures)
    }
    loadDisabledFeatures()

    async function refreshColorTheme() {
      const energyTypes = await APIClient.getEnergyTypes()
      // check if we don't have all returned keys in the energyColors object
      if (difference(energyTypes, Object.keys(energyColors)).length > 0) {
        setTheme(energyColorScheme(energyTypes))
      }
    }
    refreshColorTheme()

    async function loadUserInformation() {
      const userInformation = await APIClient.getUserInformation()
      setUserInformation(userInformation)
    }
    if (!inInStorePage) {
      loadUserInformation()
    }

    async function getNotificationsLastSeen() {
      const notificationsSeen = await APIClient.getNotificationsLastSeen()
      setNotifications(notificationsSeen)
    }
    if (!inInStorePage) {
      getNotificationsLastSeen()
    }

    async function getDataAvailability() {
      const availability = await APIClient.getDataAvailability()
      setDataAvailability(availability)
    }
    getDataAvailability()
  }, [JSON.stringify(accounts)])

  const isDisabledFeature = React.useCallback(
    feature => !featureFlags || featureFlags.includes(feature),
    [featureFlags]
  )

  return (
    <>
      {clusters.length > 0 &&
      locations.length > 0 &&
      dataAvailability &&
      (isInStorePage(location.pathname) || userInformation != null) ? (
        <ThemeContext.Provider value={theme}>
          <DisabledFeaturesContext.Provider value={isDisabledFeature}>
            <UserInformationContext.Provider value={userInformation}>
              <LocationsContext.Provider value={{ clusters, locations }}>
                <NotificationsContext.Provider value={{ notificationsSeen, setNotificationsSeen }}>
                  <FeedbackContext.Provider value={{ isFeedbackOpen, openFeedbackModal, closeFeedbackModal }}>
                    <DataAvailabilityContext.Provider value={{ dataAvailability, setDataAvailability }}>
                      {children}
                    </DataAvailabilityContext.Provider>
                  </FeedbackContext.Provider>
                </NotificationsContext.Provider>
              </LocationsContext.Provider>
            </UserInformationContext.Provider>
          </DisabledFeaturesContext.Provider>
        </ThemeContext.Provider>
      ) : (
        notLoaded
      )}
    </>
  )
}
